import { DataTable, DataTableColumn as Column } from "@cuda-networks/bds-core";
import { process, State } from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IProductSerial from "../../../models/Products/IProductSerial";
import { IAppState } from "../../../store/store";
import { getButtonCount, getShowEditSerialButton } from "../../../utility";
import ButtonCommandCell from "../../ButtonCommandCell";
import AccountIconsCell from "../AccountIconsCell";
import SerialStatusCell from "./SerialStatusCell";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { useMediaQuery } from "@material-ui/core";
import { getEditSerialTitle, isEditSerialDisabled } from "../../../businessLogic/components/Products/Serials/SerialsTable";
import { filterDateInput, filterTextInput, getColorForColumn } from "../../TableHelpers";
import DateCell from "../../DateCell";
import ISerial from "../../../models/Products/ISerial";
import { getAccountNameForSerial } from "../../../Utilities/productsHelper";
import TooltipElement from "../../Users/TooltipElement";
import MspType from "../../../models/MspType";
import CheckM365StatusButtonCommandCell from "../CheckM366Status/CheckM365StatusButtonCommandCell";
import ProductFamily from "../../../models/Products/ProductFamily";
import { setTablePropsForSerial } from "../../../actions/productActions";
import BandwidthCell from "./BandwidthCell";
import { PAGE_SIZES } from "../../../models/TableConfig";

interface ICustomSerialsTableProps {
  serials: ISerial[];
  onGoToAccount: (accountId: number) => void;
  showIssueDate?: boolean;
  productType?: string;
  isSecureEdgeEdgeService?: boolean;
}

const CustomSerialsTable: React.FC<ICustomSerialsTableProps> = ({ onGoToAccount, showIssueDate, serials, productType, isSecureEdgeEdgeService }) => {
  const dispatch = useDispatch();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const tableState = useSelector((state: IAppState) => state.productState.serialTableState);
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [showActionColumn, setShowActionColumn] = useState(true);
  const [showCheckM365Column, setShowCheckM365Column] = useState(false);
  const [accountsWidth, setAccountsWidth] = useState(200);
  const [serialWidth, setSerialWidth] = useState(140);
  const [usersWidth, setUsersWidth] = useState(90);
  const [issueDateWidth, setIssueDateWidth] = useState(120);
  const [statusWidth, setStatusWidth] = useState(100);
  const [editActionWidth, setEditActionWidth] = useState(100);

  useEffect(() => {
    if (showIssueDate) {
      setAccountsWidth(200);
      setUsersWidth(90);
      setIssueDateWidth(120);
      setStatusWidth(100);
      setSerialWidth(140);
      setEditActionWidth(showCheckM365Column ? 100 : 90);
    } else {
      setAccountsWidth(300);
      setUsersWidth(140);
      setIssueDateWidth(140);
      setStatusWidth(140);
      setEditActionWidth(120);
      setSerialWidth(140);
    }
  }, [showIssueDate, showCheckM365Column]);

  useEffect(() => {
    if (responsiveViewPortTriggerMin) {
      setSerialWidth(140);
      setAccountsWidth(showCheckM365Column ? 250 : 300);
    } else {
      setSerialWidth(100);
      setAccountsWidth(200);
    }
  }, [responsiveViewPortTriggerMin, showCheckM365Column]);

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    collapsedGroups: [],
    filter: tableState.filter,
    selectedItem: "any",
    lastSelectedIndex: 0,
    sort: tableState.sort,
  };

  useEffect(() => {
    setShowActionColumn(getShowEditSerialButton(mspAccountLoggedIn));
    setShowCheckM365Column(mspAccountLoggedIn.type === MspType.BillingAggregator && productType === ProductFamily.ESSENTIALS_SERIVICES);
  }, [mspAccountLoggedIn, productType]);

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(serials, dataState as any),
  });

  const enterEdit = (serial: IProductSerial): void => {
    onGoToAccount(serial.accountId);
  };

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(serials, e.dataState),
    });
  };

  useEffect(() => {
    const updatedSerialsToDisplay = serials.map(serial => {
      const accName = getAccountNameForSerial(serial, mspAccounts, accountNames);
      if (serial.issueDate && showIssueDate) {
        let issueDateObject = new Date(serial.issueDate);
        return {
          ...serial,
          accountName: accName,
          issueDate: new Date(Date.UTC(issueDateObject.getFullYear(), issueDateObject.getMonth(), issueDateObject.getDate())),
          issueDateToDisplay: serial.issueDate,
        };
      } else {
        return { ...serial, accountName: accName };
      }
    });
    dispatch(setTablePropsForSerial({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
    setGridState({ dataState: gridState.dataState, dataResult: process(updatedSerialsToDisplay, gridState.dataState as any) });
    // eslint-disable-next-line
  }, [serials, gridState.dataState]);

  useEffect(() => {
    if (gridState.dataState.skip !== 0 && gridState.dataState.take && gridState.dataResult.total === gridState.dataState.skip) {
      let newSkip = gridState.dataState.skip - gridState.dataState.take;
      const ds = { ...gridState.dataState, skip: newSkip };
      setGridState({
        dataState: ds,
        dataResult: process(serials, ds as any),
      });
      dispatch(setTablePropsForSerial({ skip: newSkip }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataResult, gridState.dataState, serials]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const GoToCommandCell = (props: any) => <ButtonCommandCell {...props} dataTestId={"goToAccount"} action={enterEdit} label={"GO TO"} isOutlined={false} color={"secondary"} disabled={isEditSerialDisabled(props.dataItem.accountId, mspAccounts, accountNames)} title={getEditSerialTitle(props.dataItem.accountId, mspAccounts, accountNames)} />;
  const M365StatusCommandCell = (props: any) => <CheckM365StatusButtonCommandCell {...props} accountId={props.dataItem.accountId} />;
  const SerialStatus = (props: any) => <SerialStatusCell {...props} status={props.dataItem.status} isBBS={false} />;
  const SerialAccCell = (props: any) => <AccountIconsCell {...props} serial={props.dataItem} showAccountName={true} accountName={props.dataItem.accountName} />;
  const IssueDate = (props: any) => <DateCell {...props} date={props.dataItem.issueDate} />;
  const TooltipTable = (props: any, tooltipType: any) => <TooltipElement {...props} tooltipType={"table"} />;
  const BwCell = (props: any) => <BandwidthCell {...props} item={props.dataItem.activatedSeats} />;

  return (
    <div data-testid="serialsTable">
      <DataTable
        className={showCheckM365Column ? "SerialsTableBaEss noScrollbar noBorders center" : "SerialsTable noScrollbar noBorders center"}
        data={gridState.dataResult}
        resizable
        // page
        pageConfig={{
          pageable: {
            pageSizes: PAGE_SIZES,
            buttonCount: buttonCount,
          },
          skip: gridState.dataState.skip,
          take: gridState.dataState.take,
          total: gridState.dataResult.total,
        }}
        // sort
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
      >
        <Column key={"serialCustomers"} field={"accountName"} title={"ACCOUNTS"} cell={SerialAccCell} minResizableWidth={30} width={accountsWidth} resizable={true} sortable={true} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("accountName", gridState.dataState as State)} />
        <Column key={"serialSerial"} field={"serial"} title={"SERIAL"} minResizableWidth={30} cell={TooltipTable} width={serialWidth} resizable={true} sortable={true} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("serial", gridState.dataState as State)} />
        {!isSecureEdgeEdgeService && <Column key={"serialUsers"} field={"activatedSeats"} title={"USERS"} minResizableWidth={30} width={usersWidth} resizable={true} sortable={true} filterable filter={"numeric"} columnMenu={filterTextInput} headerClassName={getColorForColumn("activatedSeats", gridState.dataState as State)} />}
        {isSecureEdgeEdgeService && <Column key={"serialUsers"} field={"activatedSeats"} cell={BwCell} title={"SIZE"} minResizableWidth={30} width={usersWidth} resizable={true} sortable={true} filterable filter={"numeric"} columnMenu={filterTextInput} headerClassName={getColorForColumn("activatedSeats", gridState.dataState as State)} />}
        {showIssueDate && <Column key={"serialIssueDate"} field={"issueDate"} cell={IssueDate} title={"ACTIVATION DATE"} minResizableWidth={30} sortable={true} filter="date" columnMenu={filterDateInput} width={issueDateWidth} resizable={true} headerClassName={getColorForColumn("issueDate", gridState.dataState as State)} />}
        <Column key={"serialStatus"} title={"STATUS"} cell={SerialStatus} minResizableWidth={30} width={statusWidth} groupable={true} />
        {showActionColumn && <Column key={"serialsEdit"} title={"ACTIONS"} cell={GoToCommandCell} width={editActionWidth} sortable={false} resizable={false} />}
        {showCheckM365Column && <Column key={"checkM365Status"} title={"M365"} cell={M365StatusCommandCell} width={editActionWidth} sortable={false} resizable={false} />}
      </DataTable>
    </div>
  );
};

export default CustomSerialsTable;
