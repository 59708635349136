import React from "react";
import { Grid, Typography, Alert, Link } from "@cuda-networks/bds-core";
import DeleteAccountSerialsTable, { IDeleteAccountSerial } from "./DeleteAccountSerialsTable";
import { getAccountDisplayType } from "../../../Utilities/accountsHelper";
import ActionMessageType from "../../../models/ActionMessageType";

interface IDeleteAccountWithPendingSerialsProps {
  serials: IDeleteAccountSerial[];
  type: string;
  onRefreshSerials(): void;
}

const DeleteAccountWithPendingSerials: React.FC<IDeleteAccountWithPendingSerialsProps> = ({ serials, type, onRefreshSerials }) => {
  return (
    <div>
      <Grid item container spacing={3} data-testid="deleteAccountWithPendingSerialsMessage">
        <Grid container item xs={12}>
          <Grid item xs={12} container direction="column">
            <Grid item spacing={3} style={{ paddingBottom: "15px" }}>
              <Alert severity={ActionMessageType.Info}>
                <Typography gutterBottom>This {getAccountDisplayType(type).toLowerCase()} cannot be deleted because one or more products/services are being activated.</Typography>
              </Alert>
            </Grid>
            <Grid item spacing={3} style={{ paddingBottom: "10px" }}>
              <Typography gutterBottom>Please try again after the products/services finish activating.</Typography>
              <Typography gutterBottom>
                If a product/service remains in a pending state, contact support{" "}
                <Link href="https://barracudamsp.com/essentials-change/" rel="noopener noreferrer" target="_blank">
                  click here
                </Link>{" "}
                or call <b style={{ whiteSpace: "nowrap" }}>1-800-569-0155</b>, Option 1.
              </Typography>
            </Grid>
            {/*   <Grid item spacing={3}>
              <div data-testid={"deleteAccountPendingSerialsTable"} style={{ justifyContent: "flex-end", display: "flex" }}>
                <Button data-testid="refreshSerialsStatus" variant={"outlined"} onClick={() => onRefreshSerials()} startIcon={<Refresh />}>
                  Refresh Serials Status
                </Button>
              </div>
            </Grid> */}
            <Grid item spacing={3}>
              <div data-testid={"deleteAccountPendingSerialsTable"}>
                <DeleteAccountSerialsTable serials={serials} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeleteAccountWithPendingSerials;
