import { State } from "@progress/kendo-data-query";
import { Reducer } from "redux";

import { IntegrationsBillingMapActions, IntegrationsBillingMapActionTypes } from "../../actions/integrations/integrationsBillingActions";
import { IIntegrationBillingMap, IIntegrationBillingMapFamily, IIntegrationBillingMapOption, IntegrationBllingMapType } from "../../models/Integrations/IntegrationsBillingMap";

export interface IIntegrationsBillingMapState {
  loadingIntegrationBillingMap: boolean;
  integrationBillingMaps: IIntegrationBillingMapFamily[];
  integrationBillingMapsOptions: IIntegrationBillingMapOption[];
  integrationBillingMapOrderlineItems: IIntegrationBillingMap[];
  loadingIntegrationBillingMapCanceled: boolean;
  loadingResetPastPlans: boolean;
  ibuBillingMapTableState: State;
  essBillingMapTableState: State;
  csBillingMapTableState: State;
  expandedBillingMapStatus: Record<IntegrationBllingMapType, boolean>;
  integrationBillingUsageType: number;
  setupBillingMapTablePageNumber: number;
  setupBillingMapTablePageSize: number;
}

const initialIntegrationBillingMapState: IIntegrationsBillingMapState = {
  loadingIntegrationBillingMap: false,
  integrationBillingMaps: [],
  integrationBillingMapsOptions: [],
  integrationBillingMapOrderlineItems: [],
  loadingIntegrationBillingMapCanceled: false,
  ibuBillingMapTableState: { sort: [{ field: "name", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  essBillingMapTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  csBillingMapTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  expandedBillingMapStatus: {
    [IntegrationBllingMapType.ESSENTIALS_SERIVICES]: true,
    [IntegrationBllingMapType.CONTENT_SHIELD_PLUS]: true,
    [IntegrationBllingMapType.IBU_PLANS]: true,
  },
  integrationBillingUsageType: 0,
  setupBillingMapTablePageNumber: 1,
  setupBillingMapTablePageSize: 10,
  loadingResetPastPlans: false,
};

export const integrationsBillingMapReducer: Reducer<IIntegrationsBillingMapState, IntegrationsBillingMapActions> = (state = initialIntegrationBillingMapState, action: IntegrationsBillingMapActions) => {
  switch (action.type) {
    case IntegrationsBillingMapActionTypes.GET_INTEGRATION_BILLING_MAP:
      return {
        ...state,
        loadingIntegrationBillingMap: action.loadingIntegrationBillingMap,
        integrationBillingMaps: action.integrationBillingMaps,
        integrationBillingMapsOptions: action.integrationBillingMapsOptions,
        integrationBillingMapOrderlineItems: action.integrationBillingMapOrderlineItems,
        integrationBillingUsageType: action.integrationBillingUsageType,
      };
    case IntegrationsBillingMapActionTypes.SET_LOAD_INTEGRATION_BILLING_MAP_CANCELED:
      return {
        ...state,
        loadingIntegrationBillingMapCanceled: action.loadingIntegrationBillingMapCanceled,
      };
    case IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_IBU:
      return {
        ...state,
        ibuBillingMapTableState: action.ibuBillingMapTableState,
      };
    case IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_ESS:
      return {
        ...state,
        essBillingMapTableState: action.essBillingMapTableState,
      };
    case IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_CS:
      return {
        ...state,
        csBillingMapTableState: action.csBillingMapTableState,
      };
    case IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_EXPANDED_STATUS: {
      return {
        ...state,
        expandedBillingMapStatus: action.expandedBillingMapStatus,
      };
    }
    case IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_NUMBER: {
      return {
        ...state,
        setupBillingMapTablePageNumber: action.setupBillingMapTablePageNumber,
      };
    }
    case IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_SIZE: {
      return {
        ...state,
        setupBillingMapTablePageSize: action.setupBillingMapTablePageSize,
      };
    }
    case IntegrationsBillingMapActionTypes.SET_INTEGRATION_BILLING_MAPS:
      return {
        ...state,
        loadingIntegrationBillingMap: action.loadingIntegrationBillingMap,
        integrationBillingMaps: action.integrationBillingMaps,
        integrationBillingUsageType: action.integrationBillingUsageType,
      };
    case IntegrationsBillingMapActionTypes.RESET_INTEGRATION_BILLING_MAPS_STATE: {
      return {
        ...state,
        ibuBillingMapTableState: { sort: [{ field: "name", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
        essBillingMapTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
        csBillingMapTableState: { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
        expandedBillingMapStatus: {
          [IntegrationBllingMapType.ESSENTIALS_SERIVICES]: true,
          [IntegrationBllingMapType.CONTENT_SHIELD_PLUS]: true,
          [IntegrationBllingMapType.IBU_PLANS]: true,
        },
      };
    }
    case IntegrationsBillingMapActionTypes.RESET_PAST_PLANS: {
      return {
        ...state,
        loadingResetPastPlans: action.loadingResetPastPlans,
      };
    }
    default:
      return state;
  }
};
