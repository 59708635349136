import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridColumnMenuFilterUI } from "@progress/kendo-react-grid";
import { GridColumnMenuFilterUIProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridColumnMenuFilterUIProps";
import * as React from "react";
import { getDateFormatString } from "../utility";

export const DateFilter = (props: GridColumnMenuFilterUIProps) => {
  const handleChange = (event: DatePickerChangeEvent) => {
    const { firstFilterProps } = props;
    const filterValue = new Date(event.value as Date);
    firstFilterProps.onChange({
      value: new Date(Date.UTC(filterValue.getFullYear(), filterValue.getMonth(), filterValue.getDate())),
      operator: props.firstFilterProps.operator,
      syntheticEvent: event.syntheticEvent,
    });
  };
  const format = getDateFormatString(window.navigator.language).replace(/Y/g, "y").replace(/D/g, "d");
  props.firstFilterProps.filterType = "date";
  let datePickerValue;
  if (props.firstFilterProps.value) {
    let filterDate = new Date(props.firstFilterProps.value);
    datePickerValue = new Date(Date.UTC(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate()));
  }
  return (
    <div className="CustomDateFilter">
      <div className="GridColumnMenuFilterUI">
        <GridColumnMenuFilterUI {...props} />
      </div>
      <DatePicker value={datePickerValue} onChange={handleChange} format={format} />
    </div>
  );
};
