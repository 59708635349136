import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../store/store";
import { getTabsWithIndex } from "../utility";
import { getDisplayedTabs } from "../components/Tabs";
import { IDetailsTabs } from "../components/InfoPanel";
import IAccount from "../models/IAccount";

export function getInitialAccountTabs(selectedAccount: IAccount | undefined, mspAccountLoggedIn: IAccount, selectedTabName: string | undefined) {
  if (selectedAccount === undefined) {
    return;
  }
  const initialTabs = getDisplayedTabs(mspAccountLoggedIn, selectedAccount);
  const tabs = getTabsWithIndex(initialTabs);

  const found = initialTabs.find(tab => tab.tab.label === selectedTabName);
  const initialTab = found ? found.routeId.toString() : initialTabs[0].routeId.toString();

  return { tabs, initialTab };
}

export default function useAccountTabs() {
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const selectedTabName = useSelector((state: IAppState) => state.generalState.selectedTabName);

  const [tabs, setTabs] = useState<IDetailsTabs[]>([]);

  const [initialTab, setInitialTab] = useState("products-services");

  useEffect(() => {
    if (selectedAccount === undefined) {
      return;
    }
    const initialTabs = getDisplayedTabs(mspAccountLoggedIn, selectedAccount);
    setTabs(getTabsWithIndex(initialTabs));

    const found = initialTabs.find(tab => tab.tab.label === selectedTabName);
    if (found) {
      setInitialTab(found.routeId.toString());
    } else {
      setInitialTab(initialTabs[0].routeId.toString());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  return { tabs, initialTab };
}
