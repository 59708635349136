import { State } from "@progress/kendo-data-query";
import { Reducer } from "redux";
import { IntegrationsLogsActions, IntegrationsLogsActionTypes } from "../../actions/integrations/integrationsLogsActions";
import IIntegrationLog from "../../models/Integrations/IIntegrationLog";
export interface IIntegrationsLogsState {
  loadingIntegrationLogs: boolean;
  integrationLogs: IIntegrationLog[];
  loadingIntegrationLogsCanceled: boolean;
  logsTableState: State;
}

const initialIntegrationLogsState: IIntegrationsLogsState = {
  loadingIntegrationLogs: false,
  integrationLogs: [],
  loadingIntegrationLogsCanceled: false,
  logsTableState: { sort: [{ field: "date", dir: "desc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
};

export const integrationsLogsReducer: Reducer<IIntegrationsLogsState, IntegrationsLogsActions> = (state = initialIntegrationLogsState, action: IntegrationsLogsActions) => {
  switch (action.type) {
    case IntegrationsLogsActionTypes.GET_INTEGRATION_LOGS:
      return {
        ...state,
        loadingIntegrationLogs: action.loadingIntegrationsLogs,
        integrationLogs: action.integrationLogs,
      };
    case IntegrationsLogsActionTypes.SET_LOAD_INTEGRATION_LOGS_CANCELED:
      return {
        ...state,
        loadingIntegrationLogsCanceled: action.loadingIntegrationLogsCanceled,
      };
    case IntegrationsLogsActionTypes.SET_LOGS_TABLE_PROPS:
      return {
        ...state,
        logsTableState: action.logsTableState,
      };
    default:
      return state;
  }
};
