import React, { useEffect, useState } from "react";
import { Checkbox } from "@cuda-networks/bds-core";
import { CheckedState } from "../models/CheckedState";

interface ICheckBoxWithIndeterminateStateProps {
  checkedState: CheckedState | undefined;
  onCheck: (checked: boolean) => void;
  disabled: boolean;
  size: string;
}

const CheckBoxWithIndeterminateState: React.FC<ICheckBoxWithIndeterminateStateProps> = ({ onCheck, disabled, checkedState, size }) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setIndeterminate(getCheckboxIndeterminateState(checkedState));
    setChecked(getCheckboxCheckedState(checkedState));
  }, [checkedState]);

  function onHandleChangeState(value: boolean) {
    if (indeterminate && value === false) {
      setIndeterminate(false);
      onCheck(true);
    } else {
      onCheck(value);
    }
  }
  return <Checkbox disabled={disabled} size={size} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onHandleChangeState(ev.currentTarget.checked)} checked={checked} indeterminate={indeterminate} />;
};

export default CheckBoxWithIndeterminateState;

export function getCheckboxCheckedState(checkedState: CheckedState | undefined) {
  return checkedState === CheckedState.Checked || checkedState === CheckedState.Indeterminate;
}

export function getCheckboxIndeterminateState(checkedState: CheckedState | undefined) {
  return checkedState === CheckedState.Indeterminate;
}
