import React from "react";
import { TextField } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import TextboxLabel from "../../TextboxLabel";

interface IAddEditAccountNotesProps {
  accountNotes: string | undefined;
  accountNotesError: string;
  isActionInProgress: boolean;
  onAccountNotesChanged: (value: string) => void;
}

const AddEditAccountNotes: React.FC<IAddEditAccountNotesProps> = ({ accountNotes, accountNotesError, isActionInProgress, onAccountNotesChanged }) => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} container direction="column" style={{ padding: "10px" }}>
        <TextField
          size={"small"}
          data-testid="accountNotes"
          label={<TextboxLabel label={"NOTES"} dataTestId={"accountNotesLengthInfo"} optional={true} maxNoOfCharacters={50} />}
          value={accountNotes}
          error={accountNotesError.length > 0}
          disabled={isActionInProgress}
          helperText={accountNotesError}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAccountNotesChanged(ev.target.value)}
          inputProps={{
            maxLength: 50,
          }}
          className="AccountNotesTextField"
        />
      </Grid>
    </Grid>
  );
};

export default AddEditAccountNotes;
