import { AxiosResponse } from "axios";
import { CheckUserErrorCode } from "../models/CheckUserErrorCode";
import { API_URL_CHECKUSER } from "../service/clientAPI";
import { CommunicationError } from "../errors/CommunicationError";
import { isTokenExpiredError } from "../utility";
import { TokenStorage } from "../TokenStorage";
import mspService from "../service/mspService";

export async function getApiUrlIndexRecursive(possibleApis: string[]): Promise<number | CheckUserErrorCode | undefined> {
  let tokenExpired = false;
  let unreachable = false;

  const apiPromises = possibleApis.map(async (api: string) => {
    return mspService.checkUser(api);
  });

  const index = await Promise.any(apiPromises)
    .then(value => {
      return getApiUrlIndexFromSuccessResponse(possibleApis, value);
    })
    .catch(errors => {
      ({ unreachable, tokenExpired } = getErrorReason(errors, unreachable, tokenExpired));
    });

  return await interpretResponse(index, tokenExpired, unreachable, () => getApiUrlIndexRecursive(possibleApis));
}

export const getErrorReason = (errors: AggregateError, unreachable: boolean, tokenExpired: boolean) => {
  errors.errors.every((error: Error) => {
    if (isTokenExpiredError(error)) {
      tokenExpired = true;
      return false;
    } else if (error instanceof CommunicationError) {
      unreachable = true;
      return false;
    } else {
      //  user not found, do not throw error;
    }
    return true;
  });
  return { unreachable, tokenExpired };
};

export const getApiUrlIndexFromSuccessResponse = (possibleApis: string[], value: AxiosResponse<any, any>): number | undefined => {
  if (value.status === 204) {
    const apiIndex = possibleApis.findIndex(x => `${x}/${API_URL_CHECKUSER}` === value.config.url);
    if (apiIndex > -1) {
      return apiIndex;
    }
  }
  return undefined;
};

export const interpretResponse = (index: number | void | undefined, tokenExpired: boolean, unreachable: boolean, callback: { (): Promise<number | undefined>; (): any }) => {
  if (index !== undefined && index > -1) {
    return index;
  } else {
    if (tokenExpired) {
      return TokenStorage.refreshBccAccessToken().then(async () => {
        return await callback();
      });
    } else if (unreachable) {
      return CheckUserErrorCode.UnreachableServer;
    } else {
      return CheckUserErrorCode.NotFound;
    }
  }
};
