import React, { useEffect, useState } from "react";
import TooltipElement from "../../../Users/TooltipElement";
import DropDown from "../../../DropDown";
import { IIntegrationBillingMap, IntegrationBllingMapType } from "../../../../models/Integrations/IntegrationsBillingMap";
import { setBillingSetupDisableTooltipMessage } from "../../../../Utilities/integrationsBillingHelper";

export function getAvailableOptionsForPlans(item: IIntegrationBillingMap, stillAvailableItems: IIntegrationBillingMap[]): string[] {
  if (item.name !== "") {
    let result: string[] = stillAvailableItems
      .filter(x => x.type === item.type && x.name === item.name)
      .map(item => item.subname)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (item.subname !== "" && result.findIndex(x => x === item.subname) < 0) {
      result.push(item.subname);
    }
    result.sort();
    return result;
  } else {
    return [];
  }
}

const BillingMapPlanCell = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [ops, setOps] = useState<string[]>([]);

  useEffect(() => {
    if (props.rowType !== "groupHeader") {
      if (props.dataItem.subname !== "") {
        setOps([props.dataItem.subname]);
        setSelectedIndex(1);
      }
    }
  }, [props]);

  if (props.rowType === "groupHeader") {
    return null;
  }

  const onBillingMapPlanChanged = (value: number) => {
    if (value > 0) {
      let newProduct = ops[value - 1];
      setSelectedIndex(value);
      props.onChange(newProduct);
    }
  };

  const handleOnOpen = (isOpen: boolean) => {
    if (isOpen) {
      let options = getAvailableOptionsForPlans(props.dataItem, props.availableOrderLineItems);
      setOps(options);
      let selIndex = options.findIndex((x: string) => x === props.dataItem.subname);
      if (selIndex > -1) {
        setSelectedIndex(selIndex + 1);
      }
    }
  };

  return (
    <td>
      <div data-testid="billingMappingPlan" className={props.disabled ? "cursorNotAllowed" : ""}>
        <TooltipElement title={setBillingSetupDisableTooltipMessage(props.title, props.dataItem.type)}>
          <DropDown options={ops} selectedOptionId={selectedIndex} error={props.errorMessage} disabled={props.dataItem.type === undefined || props.dataItem.name === "" || props.dataItem.type === IntegrationBllingMapType.IBU_PLANS} handleChange={onBillingMapPlanChanged} onOpen={handleOnOpen} label={""} />
        </TooltipElement>
      </div>
    </td>
  );
};

export default BillingMapPlanCell;
