enum IntegrationStatus {
  Undefined = -1,
  Active = 0,
  NewLogs = 1,
  InvalidField = 2,
  InvalidCredentials = 3,
  ConnectionError = 4,
  ConnectWiseInvalidCompany = 5,
  ConnectWisePastPlanError = 6,
  ConnectWiseErrorDuplicateAccounts = 7,
}

export default IntegrationStatus;
