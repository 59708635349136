import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Checkbox, Typography } from "@cuda-networks/bds-core";
import { setDisabledColor } from "../../../utility";

interface IAddEditAccountLinkM365Props {
  isM365Linked: boolean;
  isActionInProgress: boolean;
  onHandleLinkM365: (isChecked: boolean) => void;
}

const AddEditAccountLinkM365: React.FC<IAddEditAccountLinkM365Props> = ({ isM365Linked, isActionInProgress, onHandleLinkM365 }) => {
  return (
    <Grid container alignItems="center" direction="row" spacing={1}>
      <Grid item>
        <Checkbox data-testid="linkM365CheckBox" size="medium" checked={isM365Linked} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onHandleLinkM365(ev.currentTarget.checked)} disabled={isActionInProgress} />
      </Grid>
      <Grid item xs>
        <Typography data-testid="linkM365CheckBoxLabel" variant="body1" className={setDisabledColor("", !isActionInProgress)}>
          Link M365
        </Typography>
        <Typography data-testid="linkM365CheckBoxInfo" variant="body2" style={{ lineHeight: "7px", fontSize: 11 }} className={setDisabledColor("", !isActionInProgress)}>
          It is recommended to link the M365 Account to optimize the accuracy of billable user information.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddEditAccountLinkM365;
