import { IFieldValidator } from "../components/Accounts/AddEditAccount/addressValidator";
import { IIntegrationBillingMap, IIntegrationBillingMapFamily, IIntegrationBillingMapOption, IIntegrationBillingMaps, IntegrationBllingMapType } from "../models/Integrations/IntegrationsBillingMap";
import UsageType from "../models/Integrations/UsageType";
import { isStringNullOrEmpty } from "../utility";

export const EchoOnlyOption = "This option is available in ECHOplatform";
export function computeBillingMapsFamilies(billingMaps: IIntegrationBillingMaps | undefined, options: IIntegrationBillingMapOption[], orderLineItems: IIntegrationBillingMap[]): IIntegrationBillingMapFamily[] {
  if (billingMaps === undefined) {
    return [];
  }

  let result: IIntegrationBillingMapFamily[] = [];
  let essBillingMap: IIntegrationBillingMap[] = [];
  let csBillingMaps: IIntegrationBillingMap[] = [];
  let ibuBillingMaps: IIntegrationBillingMap[] = [];

  billingMaps?.essentialsMap.forEach(element => {
    const { name, subname, canceled } = getBillingMapOptionName(orderLineItems, element.id);
    essBillingMap.push({ ...element, name, subname, type: IntegrationBllingMapType.ESSENTIALS_SERIVICES, canceled: canceled });
  });
  billingMaps?.contentShieldPlusMap.forEach(element => {
    const { name, subname, canceled } = getBillingMapOptionName(orderLineItems, element.id);
    csBillingMaps.push({ ...element, name, subname, type: IntegrationBllingMapType.CONTENT_SHIELD_PLUS, canceled: canceled });
  });
  billingMaps?.billingMap.forEach(element => {
    const { name, canceled } = getIBUBillingMapOptionName(options, element.id);
    ibuBillingMaps.push({ ...element, name, subname: name, type: IntegrationBllingMapType.IBU_PLANS, canceled: canceled });
  });

  if (csBillingMaps.length > 0) {
    result.push({ type: IntegrationBllingMapType.CONTENT_SHIELD_PLUS, maps: csBillingMaps });
  }
  if (essBillingMap.length > 0) {
    result.push({ type: IntegrationBllingMapType.ESSENTIALS_SERIVICES, maps: essBillingMap });
  }
  if (ibuBillingMaps.length > 0) {
    result.push({ type: IntegrationBllingMapType.IBU_PLANS, maps: ibuBillingMaps });
  }
  return result;
}

function getBillingMapOptionName(orderLineItems: IIntegrationBillingMap[], id: number): { name: string; subname: string; canceled: boolean } {
  let result = { name: "", subname: "", canceled: false };
  const index = orderLineItems.findIndex(x => x.id === id);
  if (index > -1) {
    result.name = orderLineItems[index].name;
    result.subname = orderLineItems[index].subname;
  } else {
    result.canceled = true;
  }
  return result;
}

function getIBUBillingMapOptionName(options: IIntegrationBillingMapOption[], id: number): { name: string; canceled: boolean } {
  let result = { name: "", canceled: false };
  const index = options.findIndex(x => x.id === id);
  if (index > -1) {
    result.name = options[index].text;
  } else {
    result.canceled = true;
  }
  return result;
}
export function getAvailableBillingMapsFamilies(billingMaps: IIntegrationBillingMapFamily[], orderlineItems: IIntegrationBillingMap[]): IIntegrationBillingMap[] {
  let result: IIntegrationBillingMap[] = [];
  orderlineItems.forEach(element => {
    let found = false;
    billingMaps.forEach(billingMapFam => {
      billingMapFam.maps.forEach(billingMap => {
        if (billingMap.id === element.id) {
          found = true;
        }
      });
    });
    if (!found) {
      result.push(element);
    }
  });

  return result;
}

export function getBillingMapList(billingMaps: IIntegrationBillingMapFamily[]): IIntegrationBillingMap[] {
  let result: IIntegrationBillingMap[] = [];
  billingMaps.forEach(billingMapFam => {
    billingMapFam.maps.forEach(billingMap => {
      result.push(billingMap);
    });
  });

  return result;
}

export function hasIBUBillingMapFamily(billingMaps: IIntegrationBillingMapFamily[]): IIntegrationBillingMapFamily | undefined {
  return billingMaps.find(billingMapFam => {
    return billingMapFam.type === IntegrationBllingMapType.IBU_PLANS;
  });
}

export function setBillingSetupDisableTooltipMessage(title: string, type: IntegrationBllingMapType): string {
  if (!isStringNullOrEmpty(title)) {
    return title;
  } else if (type === IntegrationBllingMapType.IBU_PLANS) {
    return EchoOnlyOption;
  } else {
    return "";
  }
}

export function generateApiIntegrationBillingPayload(billingMapsList: IIntegrationBillingMap[], type: UsageType): any {
  let essBillingMap: any = [];
  let csBillingMaps: any = [];
  let ibuBillingMaps: any = [];

  billingMapsList.forEach(element => {
    if (element.type === IntegrationBllingMapType.ESSENTIALS_SERIVICES) {
      essBillingMap.push({ id: element.id, level: element.level });
    } else if (element.type === IntegrationBllingMapType.CONTENT_SHIELD_PLUS) {
      csBillingMaps.push({ id: element.id, level: element.level });
    } else if (element.type === IntegrationBllingMapType.IBU_PLANS) {
      ibuBillingMaps.push({ id: element.id, level: element.level });
    }
  });
  return { billingMap: ibuBillingMaps, essentialsMap: essBillingMap, contentShieldPlusMap: csBillingMaps, usageType: type };
}

export function setBillingSetupErrorMessage(errors: IFieldValidator[], index: number, field: string): string {
  let result = "";
  errors.forEach(error => {
    if (error.index === index && error.name === field) {
      result = error.error;
    }
  });
  return result;
}
