import React from "react";
import { getAmountToDisplay } from "../utility";

const PriceCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return <td>{getAmountToDisplay(props.amount as number, props.currency)}</td>;
};

export default PriceCell;
