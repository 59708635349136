import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import CustomSerialsTable from "./CustomSerialsTable";

interface ISerialsTableProps {
  onGoToAccount: (accountId: number) => void;
  showIssueDate?: boolean;
  isSecureEdgeEdgeService?: boolean;
}

const SerialsTable: React.FC<ISerialsTableProps> = ({ onGoToAccount, showIssueDate, isSecureEdgeEdgeService }) => {
  const serialsToDisplay = useSelector((state: IAppState) => state.productState.serialsToDisplay);
  return <CustomSerialsTable serials={serialsToDisplay} onGoToAccount={onGoToAccount} showIssueDate={showIssueDate} isSecureEdgeEdgeService={isSecureEdgeEdgeService} />;
};

export default SerialsTable;
