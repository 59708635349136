import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab } from "@cuda-networks/bds-core";
import { useDispatch, useSelector } from "react-redux";
import { cancelCurrentAction, setSelectedTabName } from "../actions/generalActions";
import { fetchM365AuthAction, getAccountAddressAction, loadParentAccountWithChildrenAction, setLoadedAccountAction, fetchAccountAllChildren, getAccountInfoAction, getAccountEchoUserNameAction, updateBaMspWithChildren } from "../actions/accountActions";
import { getAccountUsersAction } from "../actions/userActions";
import DetailsTabs from "../models/DetailsTabs";
import { IAppState } from "../store/store";
import { getCurrencyAction, getHasSubpartnersWithProducts, getParentMspProductsAction, getProductsAction, resetProductsToDisplayAction } from "../actions/productActions";
import { getDetailsTabById } from "../utility";
import MspType from "../models/MspType";
import IAccount from "../models/IAccount";
import { TabProps } from "@cuda-networks/bds-core/dist/Tabs";
import AuditUsersCard from "./Products/AuditUsers/AuditUsersCard";
import { canViewBillingTab } from "../Utilities/accountsHelper";
import { getInvoices, getPaymentMethod, setInvoicesTableStateOnFirstPage } from "../actions/financeActions";
import { Link, useParams } from "react-router-dom";
import TabContent from "./TabContent";
import useAccountTabs from "../Utilities/useAccountTabs";
import { getApiCredentialApplications } from "../actions/apiCredentialActions";
import { getAccountIdFromUrl } from "../Utilities/UrlParser";

export interface IDetailsTabs {
  id: number;
  routeId: string;
  tab: TabProps;
}

export interface ICustomMessageDialog {
  userTabLoaded: boolean;
  detailsTabLoaded: boolean;
  productsTabLoaded: boolean;
  financeTabLoaded: boolean;
  apiCredentialsTabLoaded: boolean;
  setTabLoaded: (loaded: boolean, tab: DetailsTabs) => void;
  showUserBilling?: boolean;
}

const InfoPanel: React.FC<ICustomMessageDialog> = ({ userTabLoaded, detailsTabLoaded, productsTabLoaded, financeTabLoaded, apiCredentialsTabLoaded, setTabLoaded, showUserBilling }) => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadedAccount = useSelector((state: IAppState) => state.accountState.loadedAccount);
  const loadingUsersCanceledForAccountId = useSelector((state: IAppState) => state.userState.loadingUsersCanceledForAccountId);
  const loadingProductsCanceledForAccountId = useSelector((state: IAppState) => state.productState.loadingProductsCanceledForAccountId);
  const loadingParentProductsCanceled = useSelector((state: IAppState) => state.productState.loadingParentProductsCanceled);
  const loadingInvoicesCanceled = useSelector((state: IAppState) => state.financeState.loadingInvoicesCanceled);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);

  const { tabs } = useAccountTabs();
  const { tabId } = useParams();
  const accountId = getAccountIdFromUrl();
  const selectedDetailTab = getDetailsTabById(tabs, tabId);
  const [selectedTab, setSelectedTab] = useState(selectedDetailTab);

  const handleChange: any = (_e: React.SyntheticEvent, newValue: any) => {
    let resultValue = tabs.filter(x => x.id === newValue)[0];
    if (resultValue !== null && resultValue !== undefined) {
      setSelectedTab(resultValue);
      dispatch(setSelectedTabName(resultValue.tab.label));
    }
  };

  const handleChangeByTabName: any = (_e: React.SyntheticEvent, newValue: any) => {
    let resultValue = tabs.filter(x => x.routeId?.toString() === newValue)[0];
    if (resultValue !== null && resultValue !== undefined) {
      setSelectedTab(resultValue);
      dispatch(setSelectedTabName(resultValue.tab.label));
    }
  };

  const [tabObject, setTabObject] = useState(selectedDetailTab);

  useEffect(() => {
    if (accountId !== undefined && accountId === selectedAccount?.id.toString()) {
      if (tabId && tabs) handleChangeByTabName(null, tabId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId]);

  useEffect(() => {
    setSelectedTab(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDetailTab]);

  useEffect(() => {
    setTabObject(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const loadProducts = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getProductsAction(account));
      resolve(result);
    });

  const getParentAccount = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(loadParentAccountWithChildrenAction(account.id, false));
      resolve(result);
    });

  function getParentProducts(selectedAccount: IAccount) {
    if (selectedAccount.closestParentId !== undefined) {
      dispatch(getParentMspProductsAction(selectedAccount));
    } else {
      getParentAccount(selectedAccount).then(result => {
        if (result) {
          const partnerId = result.closestParentId;
          if (partnerId) {
            dispatch(getParentMspProductsAction({ ...selectedAccount, partnerId: partnerId }));
          }
        }
      });
    }
  }

  const loadProductsAndFollowUp = (loadProductsAction: (account: IAccount) => Promise<any>, selectedItem: IAccount, followUpAction: any) => {
    loadProductsAction(selectedItem).then(result => {
      if (result) {
        if (followUpAction) {
          followUpAction();
        }
      }
    });
  };

  const loadCurrency = (account: IAccount) =>
    new Promise<any>(resolve => {
      const result = dispatch(getCurrencyAction(account));
      resolve(result);
    });

  const loadAllChildren = (account: IAccount) =>
    new Promise<any>(resolve => {
      const result = dispatch(fetchAccountAllChildren(account));
      resolve(result);
    });

  const getProductsAndDoNextAction = (followUpAction: any, selectedItem: IAccount, loggedInPartner: IAccount, selAccountToFilterProducts: IAccount | undefined) => {
    if (isBaLoggedIn) {
      if (selectedItem.type === MspType.Partner) {
        if (selAccountToFilterProducts === undefined) {
          loadCurrency(selectedItem).then(result => {
            if (result) {
              if (selectedAccount?.accounts === undefined) {
                loadAllChildren(selectedItem).then(allChildren => {
                  if (allChildren && allChildren.accounts) {
                    dispatch(updateBaMspWithChildren(selectedItem, allChildren.accounts));
                  }
                });
              }
            }
          });
          loadProductsAndFollowUp(loadProducts, selectedItem, followUpAction);
        }
      } else if (selectedItem.type === MspType.BillingAggregator) {
        dispatch(cancelCurrentAction());
        dispatch(resetProductsToDisplayAction());
      }
    } else {
      loadProductsAndFollowUp(loadProducts, selectedItem, followUpAction);
    }
  };

  useEffect(() => {
    if (selectedAccount && selectedTab) {
      let accountHasChanged = false;
      if (loadedAccount?.id !== selectedAccount.id) {
        accountHasChanged = true;
        dispatch(setLoadedAccountAction(selectedAccount));
        setTabLoaded(false, DetailsTabs.Products);
        setTabLoaded(false, DetailsTabs.Users);
        setTabLoaded(false, DetailsTabs.AccountsDetails);
        setTabLoaded(false, DetailsTabs.Finance);
        setTabLoaded(false, DetailsTabs.ApiCredentials);
      }
      if (selectedTab.tab.label === DetailsTabs.AccountsDetails) {
        if (accountHasChanged || !detailsTabLoaded) {
          setTabLoaded(true, DetailsTabs.AccountsDetails);
          if (!productsTabLoaded || accountHasChanged) {
            dispatch(getAccountInfoAction(selectedAccount));
          }
          if (selectedAccount.type !== MspType.BillingAggregator) {
            dispatch(getAccountEchoUserNameAction(selectedAccount));
          }
          dispatch(getAccountAddressAction(selectedAccount));
          if (selectedAccount?.type === MspType.Customer) {
            dispatch(fetchM365AuthAction(selectedAccount));
          }
        }
      } else if (selectedTab.tab.label === DetailsTabs.Users) {
        if (accountHasChanged || !userTabLoaded || loadingUsersCanceledForAccountId === selectedAccount.id) {
          setTabLoaded(true, DetailsTabs.Users);
          dispatch(getAccountUsersAction(selectedAccount));
        }
      } else if (selectedTab.tab.label === DetailsTabs.Finance && canViewBillingTab(mspAccountLoggedIn, selectedAccount)) {
        if ((accountHasChanged || !financeTabLoaded || loadingInvoicesCanceled) && canViewBillingTab(mspAccountLoggedIn, selectedAccount)) {
          setTabLoaded(true, DetailsTabs.Finance);
          dispatch(setInvoicesTableStateOnFirstPage({}));
          dispatch(getPaymentMethod(selectedAccount));
          dispatch(getInvoices(selectedAccount));
        }
      } else if (selectedTab.tab.label === DetailsTabs.ApiCredentials) {
        if (accountHasChanged || !apiCredentialsTabLoaded) {
          setTabLoaded(true, DetailsTabs.ApiCredentials);
          dispatch(getApiCredentialApplications());
        }
      } else if (selectedTab.tab.label === DetailsTabs.Products) {
        if (accountHasChanged || !productsTabLoaded || loadingProductsCanceledForAccountId === selectedAccount.id || loadingParentProductsCanceled) {
          setTabLoaded(true, DetailsTabs.Products);
          let followUpAction: any = undefined;
          if (selectedAccount.type === MspType.Partner && mspAccountLoggedIn.type !== MspType.BillingAggregator) {
            followUpAction = () => dispatch(getHasSubpartnersWithProducts());
          } else if (hasPresetParent(mspAccountLoggedIn, selectedAccount)) {
            followUpAction = () => {
              getParentProducts(selectedAccount);
            };
          }
          if (accountHasChanged || !productsTabLoaded || loadingProductsCanceledForAccountId === selectedAccount.id) {
            getProductsAndDoNextAction(followUpAction, selectedAccount, mspAccountLoggedIn, selectedAccountToFilterProductsForBA);
            dispatch(getAccountInfoAction(selectedAccount));
          } else {
            if (followUpAction) {
              followUpAction();
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedAccount, loadingUsersCanceledForAccountId, loadingProductsCanceledForAccountId, loadingParentProductsCanceled, userTabLoaded, financeTabLoaded, detailsTabLoaded, apiCredentialsTabLoaded, selectedAccountToFilterProductsForBA]);

  const onHandleBackToProduct = () => {};

  return (
    <Card>
      {selectedAccount?.type === MspType.Customer && showUserBilling ? (
        <AuditUsersCard data-testid="billingExclusionsListCard" handleBackToProducts={onHandleBackToProduct} />
      ) : (
        selectedTab &&
        tabObject && (
          <div>
            <Tabs variant="scrollable" scrollButtons="auto" value={selectedTab.id} onChange={handleChange} indicatorColor="primary" textColor="primary">
              {tabs.map((t, index) => (
                <Tab component={Link} to={`/accounts/${selectedAccount?.id}/${t.routeId}` + window.location.search} key={"infoTab" + index} data-testid={"infoTab" + t.tab.label?.toString().toLocaleLowerCase().replace(/\s/g, "")} label={t.tab.label} />
              ))}
            </Tabs>
            <TabContent tabs={tabs} tabId={tabId} />
          </div>
        )
      )}
    </Card>
  );
};

export default InfoPanel;
function hasPresetParent(mspAccountLoggedIn: IAccount, selectedAccount: IAccount): boolean {
  return (selectedAccount.type === MspType.Subpartner && mspAccountLoggedIn.type === MspType.Partner) || (selectedAccount.type === MspType.Customer && mspAccountLoggedIn.type !== MspType.Customer);
}
