import React, { useEffect, useState } from "react";
import "../../table.css";
import { DataTable, DataTableColumn as Column } from "@cuda-networks/bds-core";
import { process, State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { getButtonCount } from "../../utility";
import { Backdrop, useMediaQuery } from "@material-ui/core";
import InvoicesActionsSplitButton from "./InvoicesActionsSplitButton";
import IInvoice from "../../models/Invoices/IInvoice";
import InvoiceStatusEnum from "../../models/Invoices/InvoiceStatus";
import ViewExclusions from "./Exclusions/ViewExclusions";
import CreditCardDialog from "./CreditCardDialog";
import { getAccountsWithExclusions, getCreditCards, getInvoices, payInvoiceAction, sendEmailInvoiceAction, setAccountsWithExclusionsTableProps, setInvoicesTableState, setSelectedCreditCardAction } from "../../actions/financeActions";
import { getCountriesAction } from "../../actions/accountActions";
import { cancelCurrentAction, setSelectedTabName, setSnackBarMessage } from "../../actions/generalActions";
import EmailInvoice from "./EmailInvoice";
import IAccount from "../../models/IAccount";
import DetailsTabs from "../../models/DetailsTabs";
import { filterDateInput, filterTextInput, getColorForColumn } from "../TableHelpers";
import InvoiceStatus from "./InvoiceStatus";
import InvoiceAmount from "./InvoiceAmount";
import DateCell from "../DateCell";
import { getInvoiceStatusLabel, getOptionsForInvoice } from "../../Utilities/financeHelper";
import { IInvoiceOptions, InvoiceOptions } from "../../models/Invoices/InvoiceOptions";
import ActionMessageType from "../../models/ActionMessageType";
import IApiCreditCard from "../../models/Invoices/IApiCreditCard";
import IExistingCreditCard from "../../models/Invoices/IExistingCreditCard";
import ICreditCardPayment from "../../models/Invoices/ICreditCardPayment";
import { FilterDropdownInput } from "../FilterDropdownInput";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import { PAGE_SIZES } from "../../models/TableConfig";

interface IInvoicesTable {
  onOpenMakePayment: (isLoading: boolean) => void;
}

const InvoicesTable: React.FC<IInvoicesTable> = ({ onOpenMakePayment }) => {
  const dispatch = useDispatch();
  const invoices = useSelector((state: IAppState) => state.financeState.invoicesToDisplay);
  const tableState = useSelector((state: IAppState) => state.financeState.invoicesTableState);
  const currency = useSelector((state: IAppState) => state.financeState.currency);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const accountsWithExclusionsTableState = useSelector((state: IAppState) => state.financeState.accountsWithExclusionsTableState);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [buttonCount, setButtonCount] = useState(10);
  const [actionButtonWidth, setActionButtonWidth] = useState(240);
  const [invoicesToDisplay, setTnvoicesToDisplay] = useState<IInvoice[]>(invoices);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>();
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [isLoadingCardsData, setIsLoadingCardsData] = useState(false);

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "DATE",
        field: "date",
        show: true,
        filter: "date",
        filtrable: true,
        sortable: true,
      },
      {
        title: "INVOICE NUMBER",
        field: "number",
        show: false,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
      {
        title: "AMOUNT",
        field: "amount",
        show: true,
        filter: "numeric",
        filtrable: false,
        sortable: true,
      },
      {
        title: "STATUS",
        field: "status",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(invoicesToDisplay, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(invoicesToDisplay, e.dataState),
    });
  };

  useEffect(() => {
    if (responsiveViewPortTriggerMin) {
      setActionButtonWidth(270);
    } else {
      setActionButtonWidth(240);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiveViewPortTriggerMin]);

  useEffect(() => {
    if (currency !== "") {
      const newInvoicesToDisplay = invoicesToDisplay.map(invoice => ({
        ...invoice,
        statusLabel: getInvoiceStatusLabel(invoice.status),
      }));
      dispatch(setInvoicesTableState({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
      setGridState({ dataState: gridState.dataState, dataResult: process(newInvoicesToDisplay, gridState.dataState as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesToDisplay, gridState.dataState, currency]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const setSelectedAction = (selectedAction: IInvoiceOptions, invoice: IInvoice): void => {
    switch (selectedAction.value) {
      case 0:
        handleOpenMakePayment(invoice.id);
        setSelectedInvoice({ ...invoice, optionSelected: selectedAction, viewExclusions: false, viewPayment: true, loadingOption: true, viewInvoice: false });
        break;
      case 1:
        setShowEmailInvoice(invoice.id, true);
        setSelectedInvoice({ ...invoice, optionSelected: selectedAction, viewExclusions: false, viewPayment: false, viewInvoice: true });
        break;
      case 2:
        setShowExclusions(invoice.id, true);
        dispatch(getAccountsWithExclusions(selectedAccount?.id, invoice));
        setSelectedInvoice({ ...invoice, optionSelected: selectedAction, viewExclusions: true, viewPayment: false, viewInvoice: false });
        break;
      default:
        break;
    }
  };

  const setShowMakePayment = (invoiceId: number, showMakePayment: boolean): void => {
    const newSelectedAction = InvoiceOptions.find(x => x.value === 0);
    if (newSelectedAction) {
      let result = invoicesToDisplay.map(invoice => (invoice.id === invoiceId ? { ...invoice, optionSelected: newSelectedAction, viewExclusions: false, viewPayment: showMakePayment, viewInvoice: false } : invoice));
      setTnvoicesToDisplay(result);
      let selectedInvoiceIndex = result.findIndex(invoice => invoice.id === invoiceId);
      setSelectedInvoice(result[selectedInvoiceIndex]);
    }
  };

  const setShowEmailInvoice = (invoiceId: number, showEmailInvoice: boolean): void => {
    const newSelectedAction = InvoiceOptions.find(x => x.value === 1);
    if (newSelectedAction) {
      let result = invoicesToDisplay.map(invoice => (invoice.id === invoiceId ? { ...invoice, optionSelected: newSelectedAction, viewExclusions: false, viewPayment: false, viewInvoice: showEmailInvoice } : invoice));
      setTnvoicesToDisplay(result);
    }
  };

  const setShowExclusions = (invoiceId: number, showExculsions: boolean): void => {
    const newSelectedAction = InvoiceOptions.find(x => x.value === 2);
    if (newSelectedAction) {
      let result = invoicesToDisplay.map(invoice => (invoice.id === invoiceId ? { ...invoice, optionSelected: newSelectedAction, viewExclusions: showExculsions, viewPayment: false, viewInvoice: false } : invoice));
      setTnvoicesToDisplay(result);
    }
  };

  const setInvoiceLoading = (invoiceId: number, showLoading: boolean): void => {
    let result = invoicesToDisplay.map(invoice => (invoice.id === invoiceId ? { ...invoice, loadingOption: showLoading } : invoice));
    setTnvoicesToDisplay(result);
    let selectedInvoiceIndex = result.findIndex(invoice => invoice.id === invoiceId);
    setSelectedInvoice(result[selectedInvoiceIndex]);
  };

  const handleOnCancelViewExclusions = (invoiceId: number): void => {
    setSelectedInvoice(undefined);
    dispatch(setAccountsWithExclusionsTableProps({ ...accountsWithExclusionsTableState, skip: 0 }));
    setShowExclusions(invoiceId, false);
    dispatch(cancelCurrentAction());
  };

  const handleOnCancelMakePayment = (invoiceId: number): void => {
    setSelectedInvoice(undefined);
    dispatch(setSelectedCreditCardAction(undefined));
    setShowMakePayment(invoiceId, false);
  };

  const handleOnCancelEmailInvoice = (invoiceId: number): void => {
    setSelectedInvoice(undefined);
    setShowEmailInvoice(invoiceId, false);
  };

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const loadCreditCards = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const existingCreditCards = dispatch(getCreditCards(account));
      resolve(existingCreditCards);
    });

  const onLoadingCardsData = (isLoading: boolean) => {
    setIsLoadingCardsData(isLoading);
    onOpenMakePayment(isLoading);
  };

  const handleOpenMakePayment = (invoiceId: number) => {
    if (selectedAccount) {
      onLoadingCardsData(true);
      setInvoiceLoading(invoiceId, true);
      loadCreditCards(selectedAccount).then(getCredtiCardsResult => {
        if (countries.length <= 0) {
          loadCountries().then(countriesResult => {
            onLoadingCardsData(false);
            setInvoiceLoading(invoiceId, false);
            setShowMakePayment(invoiceId, true);
          });
        } else {
          onLoadingCardsData(false);
          setInvoiceLoading(invoiceId, false);
          setShowMakePayment(invoiceId, true);
        }
      });
    }
  };

  const payInvoice = (account: IAccount, currentInvoice: IInvoice, creditCardPayment: ICreditCardPayment) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(payInvoiceAction(account.id, currentInvoice.number, creditCardPayment));
      resolve(newAccountId);
    });

  const handleOnSubmitPayment = (creditCard: IApiCreditCard | undefined, existingCreditCard: IExistingCreditCard | undefined) => {
    if (selectedAccount && selectedInvoice) {
      setIsActionInProgress(true);
      let creditCardPayment: ICreditCardPayment = {
        creditCardId: undefined,
        creditCard: undefined,
      };
      if (creditCard) {
        creditCardPayment.creditCard = creditCard;
      } else if (existingCreditCard) {
        creditCardPayment.creditCardId = existingCreditCard.token;
      } else {
        return;
      }
      payInvoice(selectedAccount, selectedInvoice, creditCardPayment)
        .then(result => {
          setIsActionInProgress(false);
          if (result) {
            handleOnCancelMakePayment(selectedInvoice.id);
            dispatch(getInvoices(selectedAccount));
            dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.PayInvoice].infoMessage, type: ActionMessageType.Info }));
          }
        })
        .catch(error => {
          setIsActionInProgress(false);
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.PayInvoice].infoMessage, type: ActionMessageType.Error }));
        });
    }
  };

  const sendEmailInvoice = (account: IAccount, currentInvoice: IInvoice) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(sendEmailInvoiceAction(account.id, currentInvoice?.number));
      resolve(result);
    });

  const handleOnSendEmailInvoice = (invoice: IInvoice) => {
    if (selectedAccount) {
      setIsActionInProgress(true);
      sendEmailInvoice(selectedAccount, invoice).then(result => {
        setIsActionInProgress(false);
        if (result) {
          handleOnCancelEmailInvoice(invoice.id);
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.SendEmailInvoice].infoMessage, type: ActionMessageType.Info }));
        }
      });
    }
  };
  const handleBillingInformationLink = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setSelectedTabName(DetailsTabs.AccountsDetails));
  };

  const InvoiceStatusCell = (props: any) => <InvoiceStatus {...props} />;
  const InvoiceAmountCell = (props: any) => <InvoiceAmount {...props} currency={currency} />;
  const InvoiceDateCell = (props: any) => <DateCell {...props} date={props.dataItem.date} />;
  const ActionsCell = (props: any) => <InvoicesActionsSplitButton {...props} invoice={props.dataItem} selectedAccountId={selectedAccount?.id} isLoading={props.dataItem.loadingOption} disabled={props.dataItem.loadingOption || isActionInProgress} buttonColor={""} setSelectedAction={setSelectedAction} options={getOptionsForInvoice(props.dataItem, mspAccountLoggedIn)} />;
  const filterDropdownInput = (props: any) => FilterDropdownInput(props, [{ statusLabel: getInvoiceStatusLabel(InvoiceStatusEnum.Received) }, { statusLabel: getInvoiceStatusLabel(InvoiceStatusEnum.Pending) }]);

  return (
    <div style={{ position: "relative" }}>
      <Backdrop data-testid="loadingMakePayment" className={"parentOpacity"} open={isLoadingCardsData} style={{ position: "absolute", zIndex: 4000 }}></Backdrop>
      <DataTable
        className={"InvoicesTable noScrollbar noBorders"}
        data={gridState.dataResult}
        resizable
        // page
        pageConfig={{
          pageable: {
            pageSizes: PAGE_SIZES,
            buttonCount: buttonCount,
          },
          skip: tableState.skip,
          take: tableState.take,
          total: gridState.dataResult.total,
        }}
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        filter={gridState.dataState.filter}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
      >
        <Column key={"invoiceDate"} field={"date"} title={"DATE"} minResizableWidth={30} cell={InvoiceDateCell} resizable={true} sortable={true} filter="date" columnMenu={filterDateInput} headerClassName={getColorForColumn("date", gridState.dataState as State)} />
        <Column key={"invoiceNumber"} field={"number"} title={"INVOICE NUMBER"} minResizableWidth={30} resizable={true} sortable={true} columnMenu={filterTextInput} headerClassName={getColorForColumn("number", gridState.dataState as State)} />
        <Column key={"invoiceAmount"} field={"amount"} title={"AMOUNT"} cell={InvoiceAmountCell} minResizableWidth={30} resizable={true} sortable={true} filter="numeric" columnMenu={filterTextInput} headerClassName={getColorForColumn("amount", gridState.dataState as State)} />
        <Column key={"invoiceStatus"} field={"statusLabel"} title={"STATUS"} cell={InvoiceStatusCell} minResizableWidth={30} resizable={true} sortable={true} columnMenu={filterDropdownInput} headerClassName={getColorForColumn("statusLabel", gridState.dataState as State)} />
        <Column key={"invoiceActions"} title={"ACTIONS"} cell={ActionsCell} width={actionButtonWidth} sortable={false} resizable={false} />
      </DataTable>
      {selectedInvoice?.viewExclusions && <ViewExclusions invoice={selectedInvoice} showDialog={selectedInvoice.viewExclusions} onCancel={() => handleOnCancelViewExclusions(selectedInvoice.id)} />}
      {selectedInvoice?.viewPayment && selectedInvoice?.loadingOption !== true && (
        <CreditCardDialog
          showDialog={selectedInvoice.viewPayment}
          onCancel={() => handleOnCancelMakePayment(selectedInvoice.id)}
          onSubmit={handleOnSubmitPayment}
          invoice={selectedInvoice}
          actionInProgress={isActionInProgress}
          onDelete={() => {
            /*nothing*/
          }}
          currency={currency}
        />
      )}
      {selectedInvoice?.viewInvoice && <EmailInvoice showDialog={selectedInvoice.viewInvoice} onCancel={() => handleOnCancelEmailInvoice(selectedInvoice.id)} onSendEmail={handleOnSendEmailInvoice} invoice={selectedInvoice} isActionInProgress={isActionInProgress} onBillingInformationLink={handleBillingInformationLink} />}
    </div>
  );
};

export default InvoicesTable;
