import React from "react";
import { Typography } from "@cuda-networks/bds-core";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <section className="pageNotFound">
      <Typography data-testid="resourceNotFound" variant="h3">
        Sorry, we couldn't find the
        <br />
        page you were looking for.
        <br />
      </Typography>

      <Typography variant="body1">
        <br />
        Please check the URL or visit the &nbsp;
        <Link data-testid="resourceNotFoundLink" to="/" style={{ color: "white" }}>
          home page.
        </Link>
      </Typography>
    </section>
  );
};
