import React, { useEffect, useRef, useState } from "react";
import { Typography, Card, CardHeader, CardContent } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import IAddress from "../../models/IAddress";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import { isStringNullOrEmpty } from "../../utility";
import { useMediaQuery } from "@material-ui/core";
import ICountry, { IState } from "../../models/ICountry";
import { getStateLable, isUK } from "../../Utilities/accountsHelper";

interface IAddressCardProps {
  isBilling: boolean;
  address: IAddress | undefined;
  onSetHeight: (height: number) => void;
  height?: number;
  countries: ICountry[];
  displayBillingAsContactMessage?: boolean;
}
const AddressCard: React.FC<IAddressCardProps> = ({ isBilling, address, onSetHeight, height, countries, displayBillingAsContactMessage }) => {
  const ref = useRef(null);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const responsiveViewPortTriggerMax = useMediaQuery("(max-width: 1600px)");
  const [state, setState] = useState(address?.state);
  const [stateLabel, setStateLabel] = useState("State / Province");

  useEffect(() => {
    if (ref.current !== null) {
      let refElem = ref.current as any;
      if (!isBilling) {
        onSetHeight(refElem.clientHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    if (responsiveViewPortTriggerMin) {
      let refElem = ref.current as any;
      if (!isBilling) {
        onSetHeight(refElem.clientHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiveViewPortTriggerMin]);

  useEffect(() => {
    if (responsiveViewPortTriggerMax) {
      let test = ref.current as any;
      if (!isBilling) {
        onSetHeight(test.clientHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiveViewPortTriggerMax]);

  useEffect(() => {
    if (address) {
      let ukCountry = countries.find((country: ICountry) => isUK(country.countryName));
      if (ukCountry?.states) {
        let ukState = ukCountry?.states.find((state: IState) => state.state === address.state);
        if (ukState) {
          setState(ukState.stateName);
        }
      } else {
        setState(address.state);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, countries]);

  useEffect(() => {
    if (address) {
      setStateLabel(getStateLable(address.country, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <Grid item xs={12} style={{ alignContent: "center" }}>
      <Card variant="outlined" elevation={0} style={{ textAlign: "left", border: 0 }}>
        <CardHeader data-testid="addressTitle" title={isBilling ? "Billing Information" : "Contact Information"} />
        <CardContent ref={ref} style={{ height: height && displayBillingAsContactMessage ? height : "auto", backgroundColor: "whitesmoke" }}>
          {displayBillingAsContactMessage ? (
            <Typography className={"contactSameAsBillingInfo"} data-testid="contactSameAsBillingInfo" variant="body1" style={{ color: "darkgray", textAlign: "center" }}>
              Same as Contact Information
            </Typography>
          ) : (
            address !== undefined && (
              <div>
                {!isBilling && (
                  <div>
                    <Typography data-testid="companyLbl" variant="subtitle2" style={{ color: "#8a8a8a" }} noWrap>
                      Company Name
                    </Typography>
                    <Tooltip title={address?.company !== undefined && address?.company.length > 20 ? address?.company : ""} disableHoverListener={address?.company === undefined} placement="bottom-start">
                      <Typography data-testid="companyTxt" variant="body1" gutterBottom noWrap>
                        {address?.company}
                      </Typography>
                    </Tooltip>
                  </div>
                )}
                <Typography data-testid="addressNameLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  {isBilling ? "Billing Name" : "Contact Name"}
                </Typography>
                <Tooltip title={address?.name !== undefined && address?.name.length > 20 ? address?.name : ""} disableHoverListener={address?.name === undefined} placement="bottom-start">
                  <Typography data-testid="addressNameTxt" variant="body1" gutterBottom noWrap>
                    {address?.name}
                  </Typography>
                </Tooltip>

                <Typography data-testid="emailLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Email
                </Typography>
                <Typography data-testid="emailTxt" variant="body1" gutterBottom noWrap>
                  {address?.email}
                </Typography>
                <Typography data-testid="addresLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Address
                </Typography>
                <Typography data-testid="addresTxt" variant="body1" gutterBottom>
                  {address?.street}
                </Typography>

                {address?.street2 && (
                  <Typography data-testid="addresTxt2" variant="body1" gutterBottom>
                    {address?.street2}
                  </Typography>
                )}
                {address?.street3 && (
                  <Typography data-testid="addresTxt3" variant="body1" gutterBottom>
                    {address?.street3}
                  </Typography>
                )}
                {address?.street4 && (
                  <Typography data-testid="addresTxt4" variant="body1" gutterBottom>
                    {address?.street4}
                  </Typography>
                )}

                <Typography data-testid="cityLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  City
                </Typography>
                <Typography data-testid="cityTxt" variant="body1" gutterBottom>
                  {address?.city}
                </Typography>

                {!isStringNullOrEmpty(address?.state) && (
                  <Typography data-testid="stateLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                    {stateLabel}
                  </Typography>
                )}
                {!isStringNullOrEmpty(address?.state) && (
                  <Typography data-testid="stateTxt" variant="body1" gutterBottom>
                    {state}
                  </Typography>
                )}
                <Typography data-testid="countryLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Country
                </Typography>
                <Typography data-testid="countryTxt" variant="body1" gutterBottom>
                  {address?.country}
                </Typography>
                {!isStringNullOrEmpty(address?.zip) && (
                  <Typography data-testid="zipLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                    Zip/Postal Code
                  </Typography>
                )}
                {!isStringNullOrEmpty(address?.zip) && (
                  <Typography data-testid="zipTxt" variant="body1" gutterBottom>
                    {address?.zip}
                  </Typography>
                )}

                <Typography data-testid="phoneLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Phone
                </Typography>
                <Typography data-testid="phoneTxt" variant="body1" gutterBottom>
                  {address?.phone}
                </Typography>

                {!isBilling && (
                  <div style={{ display: "block" }}>
                    <Typography variant="subtitle2"></Typography>
                    <Typography variant="body1" gutterBottom></Typography>
                  </div>
                )}
              </div>
            )
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
export default AddressCard;
