import React from "react";
import { Button } from "@cuda-networks/bds-core";
import AddAccountSplitButton from "./AddAccountSplitButton";
import MspType from "../../../models/MspType";
import { AddAccountButtonType } from "../../../models/AddAccountButtonType";

interface IAddAccountButtonProps {
  addButtonId: AddAccountButtonType;
  handleOpenDialog: () => void;
  onSelectedMspType: (mspType: MspType) => void;
  canCreateSubPartners: boolean;
  buttonColor: string;
}

const AddAccountButton: React.FC<IAddAccountButtonProps> = ({ canCreateSubPartners, addButtonId, handleOpenDialog, onSelectedMspType, buttonColor }) => {
  return (
    <div>
      {addButtonId === AddAccountButtonType.AddSubpartner && canCreateSubPartners && (
        <Button data-testid="addSubpartnerBtn" variant={"contained"} color={buttonColor} size={"large"} onClick={handleOpenDialog}>
          ADD SUBPARTNER
        </Button>
      )}
      {addButtonId === AddAccountButtonType.AddSubpartnerOrAccount && canCreateSubPartners && <AddAccountSplitButton buttonColor={buttonColor} onClick={handleOpenDialog} onSelectedMspType={onSelectedMspType} />}
      {addButtonId === AddAccountButtonType.AddAccount && (
        <Button data-testid="addAccountBtn" variant={"contained"} color={buttonColor} size={"large"} onClick={handleOpenDialog}>
          ADD ACCOUNT
        </Button>
      )}
    </div>
  );
};

export default AddAccountButton;
