import React from "react";
import { isSecureEdgeServiceSku } from "../../Utilities/productsHelper";
import BandwidthCell from "./Serials/BandwidthCell";

const SizeDisplayCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  if (isSecureEdgeServiceSku(props.dataItem.sku)) {
    return <BandwidthCell {...props} item={props.item.users} />;
  } else {
    return <td>N/A</td>;
  }
};

export default SizeDisplayCell;
