import { State } from "@progress/kendo-data-query";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../../models/IAccount";
import IIntegrationAccount from "../../models/Integrations/IIntegrationAccount";
import IIntegrationCompany from "../../models/Integrations/IIntegrationCompany";
import mspService from "../../service/mspService";
import { IAppState } from "../../store/store";
import { dynamicSort } from "../../utility";
import { handleError } from "../actionsErrorHandler";
import { cancelGeneralActionTokenAndCreateNew } from "../cancelAction";
import { ActionTypes } from "../ActionTypes";
import { LocalStoragePreferences, localStorageService } from "../../service/localStorageService";

export enum IntegrationsAccountsActionTypes {
  SET_SWOW_INTEGRATION_ACCOUNTS = "SET_SWOW_INTEGRATION_ACCOUNTS",
  GET_INTEGRATION_ACCOUNTS = "GET_INTEGRATION_ACCOUNTS",
  SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED = "SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED",
  GET_INTEGRATION_UNLINKED_ACCOUNTS = "GET_INTEGRATION_UNLINKED_ACCOUNTS",
  SET_LOAD_INTEGRATION_UNLINKED_ACCOUNTS_CANCELED = "SET_LOAD_INTEGRATION_UNLINKED_ACCOUNTS_CANCELED",
  SET_INTEGRATION_ACCOUNTS_TABLE_PROPS = "SET_INTEGRATION_ACCOUNTS_TABLE_PROPS",
  GET_INTEGRATION_FILTERED_ACCOUNTS = "GET_INTEGRATION_FILTERED_ACCOUNTS",
  GET_INTEGRATION_ACCOUNTS_COMPANIES = "GET_INTEGRATION_ACCOUNTS_COMPANIES",
  LINK_ACCOUNT = "LINK_ACCOUNT",
  UNLINK_ACCOUNT = "UNLINK_ACCOUNT",
}

export interface ISetShowUnlinkedAccountsAction {
  type: IntegrationsAccountsActionTypes.SET_SWOW_INTEGRATION_ACCOUNTS;
  showUnlinkedAccounts: boolean;
}

export interface IGetIntegrationAccountsAction {
  type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS;
  integrationAccounts: IIntegrationAccount[];
  integrationAccountsCount: number;
  integrationUnlinkedAccountsCount: number;
  loadingIntegrationAccounts: boolean;
  loadingIntegrationAccountsDataStateChanged: boolean;
}

export interface ICancelGetIntegrationAccountsAction {
  type: IntegrationsAccountsActionTypes.SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED;
  loadingIntegrationAccountsCanceled: boolean;
}

export interface ISetIntegrationAcccountsTableProps {
  type: IntegrationsAccountsActionTypes.SET_INTEGRATION_ACCOUNTS_TABLE_PROPS;
  integrationAccountsTableState: State;
}

export interface IGetIntegrationFilteredAccountsAction {
  type: IntegrationsAccountsActionTypes.GET_INTEGRATION_FILTERED_ACCOUNTS;
}

export interface IGetIntegrationFilteredAccountsAction {
  type: IntegrationsAccountsActionTypes.GET_INTEGRATION_FILTERED_ACCOUNTS;
}

export interface IGetIntegrationAccountsCompaniesAction {
  type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS_COMPANIES;
  integrationAccountsCompanies: IIntegrationCompany[];
  loadingCompanies: boolean;
}

export interface ILinkAccountAction {
  type: IntegrationsAccountsActionTypes.LINK_ACCOUNT;
}

export interface IUnlinkAccountAction {
  type: IntegrationsAccountsActionTypes.UNLINK_ACCOUNT;
}

export type IntegrationsAccountsActions = ISetShowUnlinkedAccountsAction | IGetIntegrationAccountsAction | ICancelGetIntegrationAccountsAction | ISetIntegrationAcccountsTableProps | IGetIntegrationFilteredAccountsAction | IGetIntegrationAccountsCompaniesAction | ILinkAccountAction | IUnlinkAccountAction;

export const setShowUnlinkedAccountsAction: ActionCreator<ThunkAction<any, IAppState, null, ISetShowUnlinkedAccountsAction>> = (showUnlinkedAccounts: boolean) => (dispatch: Dispatch) => dispatch({ type: IntegrationsAccountsActionTypes.SET_SWOW_INTEGRATION_ACCOUNTS, showUnlinkedAccounts });

export const getIntegrationAccountsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationAccountsAction>> = (account: IAccount, skip: number, take: number, orderBy: string, ascending: boolean, unlinkedOnly: boolean, isLoadingFreshData?: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const currentIntegrationAccounts = getState().integrationsAccountsState.integrationAccounts;
    const currentIntegrationAccountsCount = getState().integrationsAccountsState.integrationAccountsCount;
    const currentIntegrationUnlinkedAccountsCount = getState().integrationsAccountsState.integrationUnlinkedAccountsCount;
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS,
        integrationAccounts: currentIntegrationAccounts,
        integrationAccountsCount: currentIntegrationAccountsCount,
        integrationUnlinkedAccountsCount: currentIntegrationUnlinkedAccountsCount,
        loadingIntegrationAccounts: isLoadingFreshData !== undefined && isLoadingFreshData === true,
        loadingIntegrationAccountsDataStateChanged: !(isLoadingFreshData !== undefined && isLoadingFreshData === true),
      });
      dispatch({
        type: IntegrationsAccountsActionTypes.SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED,
        loadingIntegrationAccountsCanceled: false,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const integrationAccounts: any = await mspService.loadCWIntegrationAccounts(apiUrl, account.id, skip, take, orderBy, ascending, unlinkedOnly, newCancelTokenSource.token);
      dispatch({
        type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS,
        integrationAccounts: integrationAccounts.list ? integrationAccounts.list : [],
        integrationAccountsCount: integrationAccounts.total ? integrationAccounts.total : 0,
        integrationUnlinkedAccountsCount: integrationAccounts.totalUnlinked,
        loadingIntegrationAccounts: false,
        loadingIntegrationAccountsDataStateChanged: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS,
            integrationAccounts: currentIntegrationAccounts,
            integrationAccountsCount: currentIntegrationAccountsCount,
            integrationUnlinkedAccountsCount: currentIntegrationUnlinkedAccountsCount,
            loadingIntegrationAccounts: false,
            loadingIntegrationAccountsDataStateChanged: false,
          });
        },
        () => {
          dispatch({
            type: IntegrationsAccountsActionTypes.SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED,
            loadingIntegrationAccountsCanceled: true,
          });
        },
      );
    }
  };
};

export const getIntegrationFilteredAccountsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationFilteredAccountsAction>> = (account: IAccount, forAccount: string | undefined, forCompany: string | undefined, unlinkedOnly: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const currentIntegrationAccounts = getState().integrationsAccountsState.integrationAccounts;
    const currentIntegrationAccountsCount = getState().integrationsAccountsState.integrationAccountsCount;
    const currentIntegrationUnlinkedAccountsCount = getState().integrationsAccountsState.integrationUnlinkedAccountsCount;
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS,
        integrationAccounts: currentIntegrationAccounts,
        integrationAccountsCount: currentIntegrationAccountsCount,
        integrationUnlinkedAccountsCount: currentIntegrationUnlinkedAccountsCount,
        loadingIntegrationAccounts: currentIntegrationAccounts.length > 0 ? false : true,
        loadingIntegrationAccountsDataStateChanged: currentIntegrationAccounts.length > 0 ? true : false,
      });
      dispatch({
        type: IntegrationsAccountsActionTypes.SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED,
        loadingIntegrationAccountsCanceled: false,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const integrationAccounts: any = await mspService.loadCWIntegrationFilteredAccounts(apiUrl, account.id, forAccount, forCompany, unlinkedOnly, newCancelTokenSource.token);
      dispatch({
        type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS,
        integrationAccounts: integrationAccounts.list,
        integrationAccountsCount: integrationAccounts.total,
        integrationUnlinkedAccountsCount: integrationAccounts.totalUnlinked,
        loadingIntegrationAccounts: false,
        loadingIntegrationAccountsDataStateChanged: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS,
            integrationAccounts: currentIntegrationAccounts,
            integrationAccountsCount: currentIntegrationAccountsCount,
            integrationUnlinkedAccountsCount: currentIntegrationUnlinkedAccountsCount,
            loadingIntegrationAccounts: false,
            loadingIntegrationAccountsDataStateChanged: false,
          });
        },
        () => {
          dispatch({
            type: IntegrationsAccountsActionTypes.SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED,
            loadingIntegrationAccountsCanceled: true,
          });
        },
      );
    }
  };
};

export const setIntegrationAccountsTablePropsActionToFirstPage: ActionCreator<ThunkAction<any, IAppState, null, ISetIntegrationAcccountsTableProps>> = (integrationAccountsTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    let state = getState().integrationsAccountsState.integrationAccountsTableState;
    const updatedState = { ...state, skip: 0 };
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.INTEGRATION_ACCOUNTS_UI, JSON.stringify(updatedState));
    dispatch({ type: IntegrationsAccountsActionTypes.SET_INTEGRATION_ACCOUNTS_TABLE_PROPS, integrationAccountsTableState: updatedState });
  };
};

export const setIntegrationAccountsTablePropsAction: ActionCreator<ThunkAction<any, IAppState, null, ISetIntegrationAcccountsTableProps>> = (integrationAccountsTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.INTEGRATION_ACCOUNTS_UI, JSON.stringify({ ...integrationAccountsTableState, skip: 0 }));
    dispatch({ type: IntegrationsAccountsActionTypes.SET_INTEGRATION_ACCOUNTS_TABLE_PROPS, integrationAccountsTableState });
  };
};

export const fetchIntegrationAccountsCompanies: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationAccountsCompaniesAction>> = (account: IAccount, keyword?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const { apiUrl } = state.generalState;
    const integrationAccountsCompaniesFromState = state.integrationsAccountsState.integrationAccountsCompanies;
    dispatch({
      type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS_COMPANIES,
      integrationAccountsCompanies: [],
      loadingCompanies: true,
    });

    const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
    try {
      let integrationAccountsCompanies;
      let searchResult;
      if (keyword) {
        searchResult = await mspService.loadIntegrationAccountsSearchCompanies(apiUrl, account.id, newCancelTokenSource.token, keyword);
        integrationAccountsCompanies = searchResult.companies;
      } else {
        integrationAccountsCompanies = await mspService.loadIntegrationAccountsCompanies(apiUrl, account.id, newCancelTokenSource.token);
      }
      integrationAccountsCompanies.sort(dynamicSort("name"));
      dispatch({
        type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS_COMPANIES,
        integrationAccountsCompanies: integrationAccountsCompanies,
        loadingCompanies: false,
      });
      return keyword ? searchResult : integrationAccountsCompanies;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS_COMPANIES,
          integrationAccountsCompanies: integrationAccountsCompaniesFromState,
          loadingCompanies: false,
        });
      });
    }
  };
};

export const clearIntegrationAccountsCountCompaniesAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationAccountsCompaniesAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS_COMPANIES,
      integrationAccountsCompanies: [],
    });
  };
};

export const getIntegrationAccountsCountCompaniesAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationAccountsCompaniesAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const integrationCountCompanies: any = await mspService.loadIntegrationAccountsCountCompanies(apiUrl, account.id, newCancelTokenSource.token);
      return integrationCountCompanies.count;
    } catch (err) {
      handleError(err, dispatch, () => {});
    }
  };
};

export const linkAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ILinkAccountAction>> = (accId: number, companyId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      await mspService.linkAccount(apiUrl, accId, companyId);
      dispatch({
        type: IntegrationsAccountsActionTypes.LINK_ACCOUNT,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsAccountsActionTypes.LINK_ACCOUNT,
          });
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.LinkIntegrationAccount,
      );
    }
  };
};

export const unlinkAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IUnlinkAccountAction>> = (accountId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      await mspService.unlinkAccount(apiUrl, accountId);
      dispatch({
        type: IntegrationsAccountsActionTypes.UNLINK_ACCOUNT,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsAccountsActionTypes.UNLINK_ACCOUNT,
          });
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.UnlinkIntegrationAccount,
      );
    }
  };
};
