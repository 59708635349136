import React from "react";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";

const TooltipsForEntitnlements = (props: any) => {
  let entitlementsNameElements = [];
  if (props.dataItem.entitlements !== undefined) {
    entitlementsNameElements = props.dataItem.entitlements.map((x: { description: string }, index: number) => (
      <React.Fragment key={index}>
        {x.description}
        <br />
      </React.Fragment>
    ));
  }

  let entitlementTitle = (
    <p style={{ marginBottom: "0px", marginTop: "0px" }}>
      <b>Entitlements</b>
    </p>
  );

  let entitlementContent = entitlementsNameElements.length > 0 ? entitlementsNameElements : "No entitlements";
  let entitlementText = (
    <>
      {entitlementTitle}
      {entitlementContent}
    </>
  );

  const entitlementMessage = <div style={{ whiteSpace: "pre-wrap" }}>{entitlementText}</div>;

  let tableItem = props.dataItem[props.field];
  let noValue = false;
  if (tableItem !== undefined) {
    if (tableItem.length === 0) {
      noValue = true;
    }
  }

  let tooltipComponent = (
    <td>
      <Tooltip title={entitlementMessage} disableHoverListener={noValue || props.isBa} data-testid="tooltipUserEntitlements">
        <span data-testid="userEmail">{tableItem}</span>
      </Tooltip>
    </td>
  );

  return <>{tooltipComponent}</>;
};

export default TooltipsForEntitnlements;
