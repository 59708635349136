import React, { useState, useEffect, useMemo } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from "@cuda-networks/bds-core";
import IUser from "../../../models/IUser";
import UserRole from "../../../models/UserRole";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import MspType from "../../../models/MspType";
import { loadUserEntitlementsForAccount } from "../../../actions/userActions";
import IEntitlement from "../../../models/IEntitlement";
import { useMediaQuery } from "@material-ui/core";
import RolesStep from "./DialogSteps/RolesStep";
import LoginNameStep from "./DialogSteps/LoginNameStep";
import AccountAccessStep from "./DialogSteps/AccountAccessStep";
import EntitlementsStep from "./DialogSteps/EntitlementsStep";
import { IUserRoleType, checkRequiredFieldForAddEditUser, getAccountAccess } from "../../../Utilities/usersHelper";
import { EmailValidationRule, NameValidationRule } from "../../../fieldsValidationRules";
import { enterKey, isPartnerAdmin } from "../../../utility";
import DialogStepper from "../../DialogStepper";
import { AccountAccessStepLabel, EntitlementsStepLabel, NameAndEmailStepLabel, RolesAndPrivilegesStepLabel, UserDuplicateEmailErrorMessage, buildEntitlementSelection, getAddEditUserDialogStepsNames, getCancelButtonLabel, getNextStepButtonLabel, getShowBackButton, isFinalStep, isLoadingOnEntitlementStep, shouldShowDeleteUserButton } from "../../../businessLogic/components/Users/AddEditUser/AddEditLoginUserDialog";
import IAccount from "../../../models/IAccount";
import { createAccountAccessHierachyAction, setIsViewingMspAction } from "../../../actions/loginAccountAccessActions";
import { hasAnyExpandableRows } from "../../../businessLogic/loginAccountAccess";

interface IAddEditLoginUserDialogProps {
  userParentAccount: IAccount;
  onSubmit: (user: IUser) => void;
  onCancel: () => void;
  onDelete: () => void;
  showDialog: boolean;
  isEdit: boolean;
  dialogTitle: string;
  user?: IUser;
  isActionInProgress: boolean;
  isAddFirstUser: boolean;
}

const AddEditLoginUserDialog: React.FC<IAddEditLoginUserDialogProps> = ({ userParentAccount, showDialog, onDelete, onCancel, onSubmit, isEdit, user, dialogTitle, isActionInProgress, isAddFirstUser }) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [dialogStepsNames, setDialogStepsNames] = useState([""]);
  const loadingUsers = useSelector((state: IAppState) => state.userState.loadingUsers);
  const loadingUsersExtraInfo = useSelector((state: IAppState) => state.userState.loadingUsersExtraInfo);
  const entitlements = useSelector((state: IAppState) => state.userState.entitlements);
  const duplicateEmailError = useSelector((state: IAppState) => state.userState.duplicateEmailError);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserBillingAdministration, setNewUserBillingAdministration] = useState(false);
  const [newUserUserManagement, setNewUserUserManagement] = useState(false);
  const [newSelectedRole, setNewSelectedRole] = useState<IUserRoleType>(UserRole.Unknown);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const [isEditingLoggedInUser, setIsEditingLoggedInUser] = useState(false);
  const [id, setId] = useState(0);
  const [explicitAccountId, setExplicitAccountId] = useState(0);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [entitlementsSelection, setEntitlementsSelection] = useState<IEntitlement[]>([]);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [witdh, setwitdh] = useState(680);
  const [heightForPrivilegeStep, setHeightForPrivilegeStep] = useState(440);
  const [nextStepButtonLabel, setNextStepButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [showBackButton, setShowBackButton] = useState(true);
  const loadingEntitlements = useSelector((state: IAppState) => state.userState.loadingEntitlements);
  const [isLoadingEntitlements, setIsLoadingEntitlements] = useState(loadingEntitlements);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);

  useEffect(() => {
    setDialogStepsNames(getAddEditUserDialogStepsNames(userParentAccount, isAddFirstUser, newSelectedRole));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParentAccount, isAddFirstUser, newSelectedRole]);

  useEffect(() => {
    setNextStepButtonLabel(getNextStepButtonLabel(activeStep, dialogStepsNames.length));
  }, [activeStep, dialogStepsNames]);

  useEffect(() => {
    setCancelButtonLabel(getCancelButtonLabel(activeStep, isAddFirstUser));
    setShowBackButton(getShowBackButton(activeStep, isAddFirstUser));
  }, [activeStep, isAddFirstUser]);

  useEffect(() => {
    if (loggedUser && userParentAccount.type !== MspType.BillingAggregator) {
      dispatch(loadUserEntitlementsForAccount(loggedUser.id, isEdit ? user?.explicitAccountId : userParentAccount?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, userParentAccount, isEdit, user]);

  useEffect(() => {
    setId(user ? user.id : 0);
    setExplicitAccountId(user ? user.explicitAccountId : 0);
    if (isEdit && user !== undefined) {
      setIsEditingLoggedInUser(user?.id === loggedUser.id);
      setNewUserName(user?.name);
      setNewUserEmail(user?.email);
      setNewSelectedRole(user?.role);
      setNewUserBillingAdministration(user?.billingAdministration ? user?.billingAdministration : false);
      setNewUserUserManagement(user?.userManagement ? user?.userManagement : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, user]);

  useEffect(() => {
    setEntitlementsSelection(buildEntitlementSelection(isEdit, user, entitlements));
  }, [entitlements, isEdit, user]);

  useEffect(() => {
    const isMspView = hasAnyExpandableRows(selectedAccount, mspAccounts);
    dispatch(createAccountAccessHierachyAction(isMspView, user, isEdit));
    dispatch(setIsViewingMspAction(isMspView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, user]);

  const onNameChanged = (newValue: string) => {
    setNameError("");
    setNewUserName(newValue);
  };

  useEffect(() => {
    const currentStep = dialogStepsNames[activeStep];
    setIsLoadingEntitlements(isLoadingOnEntitlementStep(currentStep, loadingEntitlements));
    // eslint-disable-next-line
  }, [loadingEntitlements, activeStep]);

  const onEmailChanged = (newValue: string) => {
    setEmailError("");
    setNewUserEmail(newValue);
  };

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");

  const handleRoleChange = (newValue: number) => {
    setRoleError("");
    setNewSelectedRole(newValue);
    setNewUserUserManagement(false);
    setNewUserBillingAdministration(false);
    if (newValue === UserRole.Admin) {
      setNewUserUserManagement(true);
      setNewUserBillingAdministration(true);
    } else if (newValue === UserRole.Finance) {
      setNewUserBillingAdministration(true);
    }
  };

  const onEntitlementsSelectionChanged = (items: IEntitlement[]) => {
    setEntitlementsSelection(items);
  };

  useEffect(() => {
    setShowDeleteButton(shouldShowDeleteUserButton(isEdit, mspAccountLoggedIn, loggedUser, user));
  }, [isEdit, mspAccountLoggedIn, loggedUser, user]);

  const handleOnOpenDropdown = (isOpen: boolean) => {
    setDropdownIsOpen(isOpen);
  };

  useEffect(() => {
    if (responsiveViewPortTriggerMin) {
      setwitdh(isPartnerAdmin(mspAccountLoggedIn) ? 900 : 830);
      setHeightForPrivilegeStep(480);
    } else {
      setwitdh(isPartnerAdmin(mspAccountLoggedIn) ? 780 : 700);
      setHeightForPrivilegeStep(420);
    }
    // eslint-disable-next-line
  }, [responsiveViewPortTriggerMin, mspAccountLoggedIn]);

  useEffect(() => {
    if (duplicateEmailError) {
      setEmailError(UserDuplicateEmailErrorMessage);
      setActiveStep(0);
    }
  }, [duplicateEmailError]);

  function getStepContent(step: number) {
    const stepName = dialogStepsNames[step];
    switch (stepName) {
      case NameAndEmailStepLabel:
        return <LoginNameStep name={newUserName} nameError={nameError} onNameChanged={onNameChanged} email={newUserEmail} emailError={emailError} onEmailChanged={onEmailChanged} isEditingLoggedInUser={isEditingLoggedInUser} />;
      case AccountAccessStepLabel:
        return <AccountAccessStep user={user} isEdit={isEdit} selectedAccount={selectedAccount} isEditingLoggedInUser={isEditingLoggedInUser} />;
      case RolesAndPrivilegesStepLabel:
        return <RolesStep userParentAccount={userParentAccount} role={newSelectedRole} roleError={roleError} handleRoleChange={handleRoleChange} isEditingLoggedInUser={isEditingLoggedInUser} isAddFirstUser={isAddFirstUser} handleOnOpenDropdown={handleOnOpenDropdown} height={heightForPrivilegeStep} isActionInProgress={isActionInProgress} />;
      case EntitlementsStepLabel:
        return <EntitlementsStep entitlements={entitlementsSelection} userParentAccount={userParentAccount} selectedRole={newSelectedRole} isEditingLoggedInUser={isEditingLoggedInUser} isAddFirstUser={isAddFirstUser} isActionInProgress={isActionInProgress} onEntitlementsSelectionChanged={onEntitlementsSelectionChanged} />;
      default:
        return "Unknown step";
    }
  }

  useMemo(() => {
    setRequiredFieldsFilled(checkRequiredFieldForAddEditUser(dialogStepsNames[activeStep], newUserName, newUserEmail, newSelectedRole));
  }, [activeStep, dialogStepsNames, newUserName, newUserEmail, newSelectedRole]);

  const handleNext = () => {
    const newUser = {
      id: id,
      email: newUserEmail.trim().toLocaleLowerCase(),
      role: newSelectedRole,
      billingAdministration: newUserBillingAdministration,
      userManagement: newUserUserManagement,
      entitlements: entitlementsSelection,
      name: newUserName.trim(),
      explicitAccountId: explicitAccountId,
      accountAccess: userParentAccount.type !== MspType.BillingAggregator ? getAccountAccess(userParentAccount) : "",
    };

    const currentStep = dialogStepsNames[activeStep];

    let isError = false;
    switch (currentStep) {
      case NameAndEmailStepLabel: {
        if (newUserName.length < 1) {
          isError = true;
          setNameError("Enter login name");
        } else {
          if (!NameValidationRule.RegularExpression.test(newUserName)) {
            isError = true;
            setNameError("Login name contains invalid characters");
          }
        }
        if (newUserEmail.length < 1) {
          isError = true;
          setEmailError("Enter email");
        } else {
          if (!EmailValidationRule.TildeRegExpression.test(newUserEmail)) {
            isError = true;
            setEmailError("Invalid email");
          } else {
            if (!EmailValidationRule.RegularExpression.test(newUserEmail)) {
              isError = true;
              setEmailError("Invalid email");
            }
          }
        }
        if (emailError.includes(UserDuplicateEmailErrorMessage)) {
          isError = true;
        }
        break;
      }
      case AccountAccessStepLabel: {
        //at least one account selected
        break;
      }
      case RolesAndPrivilegesStepLabel: {
        if (newUser.role === UserRole.Finance || newUser.role === UserRole.ReadOnly) {
          newUser.entitlements = [];
        }
        if (newUser.role === UserRole.Unknown) {
          isError = true;
          setRoleError("Select a role");
        }
        break;
      }
      case EntitlementsStepLabel: {
        if (newUser.role === UserRole.Finance || newUser.role === UserRole.ReadOnly) {
          newUser.entitlements = [];
        } else {
          newUser.entitlements = entitlementsSelection.filter((x: IEntitlement) => x.checked === true);
        }
        break;
      }
      default:
        break;
    }
    if (!isError) {
      if (isFinalStep(activeStep, dialogStepsNames.length)) {
        onSubmit(newUser);
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  function addEditUserContent() {
    return (
      <div className="addEditUser" style={{ width: witdh }}>
        <DialogTitle data-testid="addEditUserDialogTitle" id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogStepper stepsNames={dialogStepsNames} activeStep={activeStep} />
        <DialogContent>
          <div className="DialogContentDiv" style={{ padding: 15 }}>
            <Grid item xs={12}>
              {getStepContent(activeStep)}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 15, display: "flex", justifyContent: "space-between" }}>
          <div>
            {showDeleteButton && (
              <Button data-testid="deleteUserButton" variant="contained" color="secondary" type={"submit"} disabled={isActionInProgress || loadingUsers || loadingUsersExtraInfo} isLoading={loadingUsers || loadingUsersExtraInfo} onClick={onDelete}>
                DELETE
              </Button>
            )}
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button data-testid="cancelAddEditUserBtn" variant="text" size="large" disabled={isActionInProgress} onClick={onCancel}>
              {cancelButtonLabel}
            </Button>
            {showBackButton && activeStep !== 0 && (
              <Button data-testid="backAddEditUserBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleBack}>
                BACK
              </Button>
            )}
            <Button data-testid="confirmAddEditUserBtn" type={"submit"} size="large" isLoading={isActionInProgress || isLoadingEntitlements} disabled={isActionInProgress || isLoadingEntitlements || requiredFieldsFilled} onClick={() => handleNext()}>
              {nextStepButtonLabel}
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }

  return (
    <div>
      {isAddFirstUser ? (
        <Paper onKeyUp={(event: any) => (!dropdownIsOpen ? enterKey(event, handleNext) : "")} disableEscapeKeyDown={isActionInProgress} className="addEdiUserDialog" data-testid="addEdiUserDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={false}>
          {addEditUserContent()}
        </Paper>
      ) : (
        <Dialog onKeyUp={(event: any) => (!dropdownIsOpen ? enterKey(event, handleNext) : "")} disableEscapeKeyDown={isActionInProgress} className="addEdiUserDialog" data-testid="addEdiUserDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={false}>
          {addEditUserContent()}
        </Dialog>
      )}
    </div>
  );
};

export default AddEditLoginUserDialog;
