import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardContent, CircularProgress, Button } from "@cuda-networks/bds-core";
import { Backdrop } from "@material-ui/core";
import AddEditAccountDialog from "./AddEditAccount/AddEditAccountDialog";
import MspType from "../../models/MspType";
import IAccount from "../../models/IAccount";
import AccountsTable from "./AccountsTable";
import { IAppState } from "../../store/store";
import { addAccountAction, getCountriesAction, setM365AuthAction, setOwnerAccountFiltersAction, goBackToViewMspAccountsAction, filterAccountsAction, resetFiltersAction, setSelectedAccountAction, navigateToAccountAction } from "../../actions/accountActions";
import { setHasSubpartnersAction, setSelectedTabName, setSnackBarMessage } from "../../actions/generalActions";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import AddAccountButton from "./AddEditAccount/AddAccountButton";
import { findAccountToBeSelected, getSettingsForNewAcount } from "../../businessLogic/components/Accounts/AccountPanel";
import { setButtonColor } from "../../utility";
import { handleM356Auth, getAccountTypeAsTitle, areFiltersActive } from "../../Utilities/accountsHelper";
import TooltipElement from "../Users/TooltipElement";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { getOwnerAccountInfoForFiltering, shouldDisableFilter } from "../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import ActionMessageType from "../../models/ActionMessageType";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import NavigateTo from "../NavigateTo";
import DetailsTabs from "../../models/DetailsTabs";
import { useNavigate } from "react-router-dom";
import FilterAccountsByName from "./FilterAccounts/FilterAccountsByName";
import AccountFilterChips from "./FilterAccounts/AccountFilterChips";
import { UrlFilterExtractor } from "./FilterAccounts/UrlFilterExtractor";
import { getLoadedAccountOrdersAction } from "../../actions/productActions";
import IAccountOrders from "../../models/Products/IAccountOrders";
import FilterBaAccountsByName from "./FilterAccounts/FilterBaAccountsByName";
import { filtersAreActive } from "./FilterAccounts/UrlFilterParams";
import { useExpandedPartner } from "./FilterAccounts/useExpandedPartner";
import { IAccountFilters } from "../../models/IAccountFilters";
import { getAccountIdFromUrl } from "../../Utilities/UrlParser";

const AccountPanel = () => {
  const dispatch = useDispatch();
  const viewSearchResults = filtersAreActive();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingFilteredAccounts = useSelector((state: IAppState) => state.accountState.loadingFilteredAccounts);
  const expandedPartner = useExpandedPartner();
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [title, setTitle] = useState("");
  const [showFilterAccountPopover, setShowFilterAccountPopover] = useState(false);

  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const loadingAllChildrenAccountNames = useSelector((state: IAppState) => state.accountState.loadingAllChildrenAccountNames);
  const loadingOrdersForAccountId = useSelector((state: IAppState) => state.productState.loadingOrdersForAccountId);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const navigate = useNavigate();
  const location = window.location;
  const accountId = getAccountIdFromUrl();

  useEffect(() => {
    setFilterDisabled(shouldDisableFilter(loadingAccountId, loadingAllChildrenAccountNames, loadingOrdersForAccountId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAccountId, loadingAllChildrenAccountNames, loadingOrdersForAccountId]);

  const loadedAccountOrders = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getLoadedAccountOrdersAction());
      resolve(result);
    });

  useEffect(() => {
    const handlePopState = (event: any) => {
      setShowAddAccount(false);
      setShowFilterAccountPopover(false);
      loadedAccountOrders().then(result => {
        console.debug(accountId);
        parseUrlAndFilterAccounts(result);
      });
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const handleOpenDialog = () => {
    setShowAddAccount(!showAddAccount);
    if (countries.length <= 0) {
      setLoadingAdd(true);
      loadCountries().then(result => {
        setLoadingAdd(false);
      });
    }
  };

  const handleOnAddClicked = (closestParentId: number, newEntity: IAccount, linkToM365?: boolean, notes?: string) => {
    setIsActionInProgress(true);
    addNewAccount(closestParentId, newEntity, linkToM365, notes);
  };

  const handleOnCancel = () => {
    setShowAddAccount(!showAddAccount);
  };

  const addAccount = (closestParentId: number, newEntity: IAccount, notes?: string) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(addAccountAction(closestParentId, newEntity, notes));
      resolve(newAccountId);
    });

  const connectM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(setM365AuthAction(account));
      resolve(result);
    });

  const addNewAccount = (closestParentId: number, newEntity: IAccount, linkToM365?: boolean, notes?: string) => {
    addAccount(closestParentId, newEntity, notes).then(newAccountId => {
      const success = newAccountId > 0;
      const newAccount = { ...newEntity, id: newAccountId };
      if (linkToM365) {
        connectM365Auth(newAccount).then(successLink => {
          handleM356Auth(success, successLink, newAccount, newAccountId, handleAccountCreation);
        });
      } else {
        handleAccountCreation(success, newAccount, newAccountId);
      }
    });
  };

  const handleAccountCreation = (accountCreationSuccess: boolean, newAccount: IAccount, newAccountId: number) => {
    setIsActionInProgress(false);
    setShowAddAccount(!accountCreationSuccess);
    if (accountCreationSuccess) {
      if (newAccount.type === MspType.Subpartner) {
        dispatch(setHasSubpartnersAction(true));
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddSubpartner].successMessage, type: ActionMessageType.Success }));
      } else {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddAccount].successMessage, type: ActionMessageType.Success }));
      }
      dispatch(setSelectedTabName(DetailsTabs.AccountsDetails));
      const targetPath = `/accounts/${newAccountId}/details`;
      if (location.pathname !== targetPath) {
        console.debug(` navigate to new account  ${targetPath} != pathname:${location.pathname}`);
        navigate(targetPath + window.location.search);
      }
    }
  };

  const handleBackToAccounts = (event: React.SyntheticEvent) => {
    dispatch(goBackToViewMspAccountsAction(selectedAccount, expandedPartner));
  };

  const [addButtonId, setAddButtonId] = useState(0);
  const [parentOfNewAccount, setParentOfNewAccount] = useState(mspAccountLoggedIn);
  const [typeOfNewAccount, setTypeOfNewAccount] = useState(MspType.Subpartner);
  const [viewAddAccountButton, setViewAddAccountButton] = useState(false);
  const [addTitle, setAddTitle] = useState("");

  const onSelectedMspType = (newValue: MspType) => {
    setTypeOfNewAccount(newValue);
    setAddTitle("ADD " + getAccountTypeAsTitle(newValue));
  };

  useEffect(() => {
    if (viewSearchResults || mspAccountLoggedIn.type === MspType.Customer || mspAccountLoggedIn.type === MspType.BillingAggregator) {
      setViewAddAccountButton(false);
    } else {
      const { addAccountButtonType, parentOfNewAcc, mspTypeOfNewAccount, canAddAccounts, title } = getSettingsForNewAcount(mspAccountLoggedIn, expandedPartner, hasSubpartners);
      setAddButtonId(addAccountButtonType);
      setParentOfNewAccount(parentOfNewAcc);
      setTypeOfNewAccount(mspTypeOfNewAccount);
      setViewAddAccountButton(canAddAccounts);
      setAddTitle(title);
    }
  }, [hasSubpartners, mspAccountLoggedIn, expandedPartner, viewSearchResults]);

  useEffect(() => {
    const ownerAccountInfo = getOwnerAccountInfoForFiltering(mspAccountLoggedIn, expandedPartner);
    dispatch(setOwnerAccountFiltersAction(ownerAccountInfo.accountId, ownerAccountInfo.directOnly));
    setTitle(ownerAccountInfo.info);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn, expandedPartner]);

  useEffect(() => {
    parseUrlAndFilterAccounts(accountsOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterAccounts = (filters: IAccountFilters) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(filterAccountsAction(filters));
      resolve(result);
    });

  const parseUrlAndFilterAccounts = (orders: IAccountOrders[]) => {
    console.debug("Parse filters from url location ...");
    if (window.location.pathname.includes("/filters")) {
      dispatch(setSelectedAccountAction(undefined));
    }
    const parser = new UrlFilterExtractor(window.location.search, orders);
    const filters = parser.extractFilters();
    if (areFiltersActive(filters)) {
      if (filters.accountsOf) {
        const id = parseInt(filters.accountsOf);
        dispatch(setOwnerAccountFiltersAction(id, true));
      } else {
        dispatch(setOwnerAccountFiltersAction(mspAccountLoggedIn.id, false));
      }
      filterAccounts(filters).then(result => {
        const accountToBeSelected = findAccountToBeSelected(result);
        dispatch(setSelectedAccountAction(accountToBeSelected));
        if (accountToBeSelected) {
          dispatch(navigateToAccountAction(accountToBeSelected.id));
        }
      });
    } else {
      if (!window.location.pathname.includes("/accounts/")) {
        dispatch(resetFiltersAction());
      }
    }
  };

  const handleOpenFilterAccountDialog = () => {
    setShowFilterAccountPopover(true);
  };

  const handleCloseFilterAccountDialog = useCallback(() => {
    setShowFilterAccountPopover(false);
  }, []);

  const navigateToPath = (isSearch: boolean) => {
    if (isSearch) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("accountsOf");
      const url = queryParams.toString();
      return `/filters?${url}`;
    } else {
      return "/";
    }
  };

  return (
    <Card className={"AccountPanel"}>
      <CardContent>
        {isBaLoggedIn ? (
          <Grid item container xs={12}>
            <FilterBaAccountsByName disabled={filterDisabled} placeHolder={"Filter By MSP Partner Name"} />
          </Grid>
        ) : (
          <Grid item container>
            <Grid item xs={10}>
              <FilterAccountsByName data-testid="search" disabled={filterDisabled} placeHolder={title} showFilterAccountPopover={showFilterAccountPopover} onClose={handleCloseFilterAccountDialog} />
            </Grid>
            <Grid item xs={2} style={{ alignSelf: "center" }}>
              <TooltipElement title="More Filter Options">
                <Button size="small" data-testid={"filterAccountsBtn"} variant="text" startIcon={<CoreIcons.Filter />} primary onClick={handleOpenFilterAccountDialog} disabled={loadingAccountId !== undefined && loadingAccountId !== 0}>
                  Filters
                </Button>
              </TooltipElement>
            </Grid>
          </Grid>
        )}

        <Grid item container spacing={1} direction="column" style={{ paddingBottom: "10px" }}>
          <Grid item container>
            <Grid container item xs={12}>
              <AccountFilterChips disabled={filterDisabled} />
            </Grid>
            <Grid container item xs={6}>
              <Grid container>
                {expandedPartner && (
                  <Grid>
                    <NavigateTo to={navigateToPath(viewSearchResults)} text={viewSearchResults ? "View Results for all Accounts" : "all Accounts"} callback={handleBackToAccounts} testId="accountPanelLnk" enforceText={viewSearchResults} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={6} style={{ justifyContent: "flex-end" }}>
              {viewAddAccountButton && !viewSearchResults && !loadingFilteredAccounts && (
                <Grid container item style={{ justifyContent: "flex-end" }}>
                  <AddAccountButton canCreateSubPartners={mspAccountLoggedIn.canCreateSubPartners ? true : false} addButtonId={addButtonId} handleOpenDialog={handleOpenDialog} onSelectedMspType={onSelectedMspType} buttonColor={setButtonColor(selectedAccount)} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} data-testid="accountsTable">
            <div style={{ position: "relative" }}>
              <Backdrop className={"parentOpacity"} open={loadingFilteredAccounts} style={{ position: "absolute", zIndex: 1100 }}>
                <CircularProgress data-testid="loadingFilteredAccounts" size="80px" style={{ zIndex: 1101 }} />
              </Backdrop>
              <AccountsTable showExpandIcon={expandedPartner === undefined && hasSubpartners === true} />
            </div>
          </Grid>
        </Grid>
        {showAddAccount && <AddEditAccountDialog dialogStep={0} showDialog={showAddAccount} onCancel={handleOnCancel} selectedItem={parentOfNewAccount} addressToDisplay={undefined} mspType={typeOfNewAccount} isActionInProgress={isActionInProgress} isEdit={false} onSubmit={(closestParentId: number, entity: IAccount, linkToM365?: boolean, notes?: string) => handleOnAddClicked(closestParentId, entity, linkToM365, notes)} title={addTitle} loadingAddEditDialog={loadingAdd} />}
      </CardContent>
    </Card>
  );
};

export default AccountPanel;
