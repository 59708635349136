import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, LinearProgress } from "@cuda-networks/bds-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IIntegrationAccount from "../../../models/Integrations/IIntegrationAccount";
import IIntegrationCompany from "../../../models/Integrations/IIntegrationCompany";
import { IAppState } from "../../../store/store";
import { enterKey, handleBackdropClick } from "../../../utility";
import TruncatedText from "../../TruncatedText";
import { debounce } from "lodash";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import IAccount from "../../../models/IAccount";
import { clearIntegrationAccountsCountCompaniesAction, fetchIntegrationAccountsCompanies } from "../../../actions/integrations/integrationsAccountsActions";
import { cancelCurrentAction } from "../../../actions/generalActions";
import { addRefineMessageAutoCompleteOption, filterOptionsBasedOnInput, setCancelButtonLabel, shouldOpenAutocomplete, showConfirmButtonSettings } from "../../../Utilities/integrationsUtilities";
interface IIntegrationsAccountsActionDialogProps {
  onCancel: () => void;
  showIntegrationsAccountsActionDialog: boolean;
  isActionInProgress: boolean;
  selectedIntegraionAccount: IIntegrationAccount | undefined;
  action: string;
  onSubmitAction: (company?: IIntegrationCompany) => void;
  loadingCompanies: boolean;
  searchForCompanies: boolean;
}
export const COMPANIES_EXCEED_LIMIT: number = 1000;
export const AUTOCOMPLETE_DEALY_SEARCH: number = 1000;
const IntegrationsAccountsActionDialog: React.FC<IIntegrationsAccountsActionDialogProps> = ({ showIntegrationsAccountsActionDialog, onCancel, isActionInProgress, selectedIntegraionAccount, action, onSubmitAction, loadingCompanies, searchForCompanies }) => {
  const dispatch = useDispatch();
  const integrationAccountsCompanies = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccountsCompanies);
  const loadingSearchCompanies = useSelector((state: IAppState) => state.integrationsAccountsState.loadingCompanies);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [selectedOption, setSelectedOption] = useState<IIntegrationCompany | null>();
  const [companyError, setCompanyError] = useState("");
  const [showConfirmButton, setShowConfirmButton] = useState(true);
  const [buttonVariant, setButtonVariant] = useState("text");
  const [buttonLabel, setButtonLabel] = useState("CANCEL");
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);

  const [companies, setCompanies] = useState<IIntegrationCompany[]>(integrationAccountsCompanies);
  const [isInputSearchLoading, setIsInputSearchLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [exceedsCompanyLimit, setExceedsCompanyLimit] = useState(false);
  const hasCompanies = integrationAccountsCompanies.length !== 0;
  const showAutocomplete = hasCompanies || searchForCompanies;
  const showNoCompaniesMessage = !searchForCompanies && !hasCompanies;

  const handleChangeTextField = (value: any) => {
    setCompanyError("");
    if (searchForCompanies) {
      setOpenAutocomplete(true);
      setInputValue(value);
      if (value.length === 0) {
        setCompanies([]);
        setOpenAutocomplete(false);
        dispatch(clearIntegrationAccountsCountCompaniesAction());
        dispatch(cancelCurrentAction());
        debouncedSearch.cancel();
      } else {
        setIsInputSearchLoading(true);
        setOpenAutocomplete(true);
        debouncedSearch(value);
      }
    }
  };
  const searchCompanies = (acc: IAccount, keyword: string) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(fetchIntegrationAccountsCompanies(acc, keyword));
      resolve(success);
    });

  const debouncedSearch = useCallback(
    debounce(searchTerm => {
      searchCompanies(mspAccountLoggedIn, searchTerm).then(searchResult => {
        const exceedsLimit = searchResult && searchResult.count > COMPANIES_EXCEED_LIMIT;
        setExceedsCompanyLimit(exceedsLimit);
        if (searchResult) {
          setCompanies(searchResult.companies);
        }
        setIsInputSearchLoading(false);
      });
    }, AUTOCOMPLETE_DEALY_SEARCH),
    [],
  );

  const handleChangeAutoComplete = (event: any, value: any) => {
    setCompanyError("");
    setSelectedOption(value);
    if (value && searchForCompanies) {
      setInputValue(value.name);
      debouncedSearch.cancel();
    }
  };

  useEffect(() => {
    setCompanies(addRefineMessageAutoCompleteOption(integrationAccountsCompanies, searchForCompanies, exceedsCompanyLimit));
  }, [integrationAccountsCompanies, exceedsCompanyLimit, searchForCompanies]);

  const handleOpenAutocomplete = () => {
    setOpenAutocomplete(shouldOpenAutocomplete(inputValue, searchForCompanies, companies));
  };

  const handleCloseAutocomplete = () => {
    setOpenAutocomplete(false);
  };

  const integrationAccountActionContent = () => {
    let autocompleteNoOptionValue = loadingSearchCompanies || isInputSearchLoading ? "Searching..." : "No records available";
    let autocompleteOptions = loadingSearchCompanies && searchForCompanies ? [] : companies;
    let autocompleteInputValue = searchForCompanies ? inputValue : undefined;
    let autoCompleteEndorment = <>{loadingSearchCompanies ? <CircularProgress color="inherit" size={20} /> : <CoreIcons.Search />}</>;

    if (selectedIntegraionAccount) {
      if (action === "Unlink") {
        return (
          <Typography data-testid="unlinkMessage" variant="subtitle1" noWrap>
            Are you sure you want to unlink account
            <TruncatedText value={selectedIntegraionAccount.mspAccountName} bold={true} maxLength={20} />
            &nbsp;from company
            <TruncatedText value={selectedIntegraionAccount.companyName} bold={true} maxLength={20} />?
          </Typography>
        );
      } else {
        if (showAutocomplete) {
          return (
            <div>
              <Typography data-testid="linkActionLabel" variant="subtitle1" noWrap>
                Choose a ConnectWise company to link
                <TruncatedText value={selectedIntegraionAccount.mspAccountName} bold={true} maxLength={20} />?
              </Typography>
              <Autocomplete
                id="muiAutoComplete"
                data-testid="muiAutoComplete"
                disabled={isActionInProgress}
                onChange={handleChangeAutoComplete}
                noOptionsText={autocompleteNoOptionValue}
                options={autocompleteOptions}
                getOptionLabel={option => option.name}
                open={openAutocomplete}
                inputValue={autocompleteInputValue}
                onOpen={handleOpenAutocomplete}
                onClose={handleCloseAutocomplete}
                filterOptions={(options, state) => filterOptionsBasedOnInput(options, state, searchForCompanies, inputValue)}
                getOptionDisabled={option => option.identifier === "info_message"}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={autocompleteInputValue}
                    error={companyError.length > 0}
                    placeholder="Search For ConnectWise Company"
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleChangeTextField(ev.target.value)}
                    helperText={companyError}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: autoCompleteEndorment,
                    }}
                    fullWidth
                  />
                )}
              />
            </div>
          );
        } else if (showNoCompaniesMessage) {
          return (
            <Typography data-testid="linkMessage" variant="subtitle1">
              No available companies. Additional companies (not currently mapped) must be added to ConnectWise Manage to appear here.
            </Typography>
          );
        }
      }
    }
  };

  const handleOnCancelAccountsDialog = () => {
    if (loadingSearchCompanies) {
      debouncedSearch.cancel();
      dispatch(cancelCurrentAction());
    }
    onCancel();
  };

  useEffect(() => {
    const { showConfirmButton, buttonVariant } = showConfirmButtonSettings(integrationAccountsCompanies, searchForCompanies, inputValue, action);
    setShowConfirmButton(showConfirmButton);
    setButtonVariant(buttonVariant);
  }, [integrationAccountsCompanies, searchForCompanies, inputValue, action, setShowConfirmButton, setButtonVariant]);

  useEffect(() => {
    if (action === "Link") {
      setRequiredFieldsFilled(selectedOption ? false : true);
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    const label = setCancelButtonLabel(loadingCompanies, isInputSearchLoading, integrationAccountsCompanies, action);
    setButtonLabel(label);
  }, [loadingCompanies, isInputSearchLoading, integrationAccountsCompanies, action]);

  const handleConfirmAction = () => {
    let isError = false;
    if (!selectedOption && action === "Link") {
      isError = true;
      setCompanyError("Select a company");
    }

    if (!isError) {
      if (action === "Link" && selectedOption) {
        onSubmitAction(selectedOption);
      } else {
        onSubmitAction();
      }
    }
  };

  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} disableEscapeKeyDown={isActionInProgress} className="integrationAccountActionDialog" data-testid="integrationAccountActionDialog" open={showIntegrationsAccountsActionDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={"md"}>
      <div style={{ minWidth: "500px" }}>
        <DialogTitle data-testid="integrationAccountActionTitle"> {action.toUpperCase() + " ACCOUNT"} </DialogTitle>
        <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
          {loadingCompanies ? (
            <div data-testid="loadingIntegrationAccountCompanies">
              <LinearProgress />
            </div>
          ) : (
            integrationAccountActionContent()
          )}
        </DialogContent>
        <DialogActions>
          <div>
            <Button data-testid="cancelIntegrationAccountDialog" variant={buttonVariant} size="large" disabled={isActionInProgress} onClick={handleOnCancelAccountsDialog}>
              {buttonLabel}
            </Button>
            {showConfirmButton && (
              <Button data-testid="confirmIntegrationAccountDialog" primary="true" isLoading={isActionInProgress} disabled={isActionInProgress || requiredFieldsFilled} type={"submit"} onClick={handleConfirmAction}>
                CONFIRM
              </Button>
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default IntegrationsAccountsActionDialog;
