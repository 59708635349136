import { Button, Typography } from "@cuda-networks/bds-core";
import React from "react";
import TruncatedText from "../TruncatedText";

interface INewUserPageProps {
  newUserAdded: string;
  alreadyHasUsers: boolean;
  username: string;
  callback: () => void;
}

const WelcomeMessage: React.FC<INewUserPageProps> = ({ newUserAdded, alreadyHasUsers, username, callback }) => {
  const welcomeMessage = alreadyHasUsers ? (
    <div>
      <TruncatedText value={username} bold={true} maxLength={20} />, you must login to this portal with valid credentials. The login you are currently using cannot access the MSP Management portal.
      <br />
      <br />
      Logins with a valid email address are required to access the MSP Management Portal.
    </div>
  ) : (
    <div>
      <TruncatedText value={username} bold={true} maxLength={20} />, Welcome to the Barracuda MSP Management Portal!
      <br />
      <br />
      Since this is your first time here, you need to create an administrator login to begin using this web portal.
    </div>
  );

  const message =
    newUserAdded.length > 0 ? (
      <div>
        Successfully created user <TruncatedText value={newUserAdded} bold={true} maxLength={20} />. Check that mailbox to get further instructions.
      </div>
    ) : (
      welcomeMessage
    );
  return (
    <div data-testid={alreadyHasUsers ? "newUserPageHasUsers" : "newUserPage"}>
      <Typography data-testid={"welcomeMessageContent"} variant="body1" style={{ padding: "28px" }}>
        {message}
      </Typography>
      <Button data-testid={"welcomeMessageButton"} className={"center"} style={{ width: "100px", marginBottom: "10px" }} onClick={callback}>
        {newUserAdded.length > 0 || alreadyHasUsers ? "SIGN OUT" : "START"}
      </Button>
    </div>
  );
};

export default WelcomeMessage;
