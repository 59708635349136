import React from "react";
import { IDetailsTabs } from "./InfoPanel";
import ProductsTab from "./Products/ProductsTab";
import { TabPanel } from "@cuda-networks/bds-core";
import { useSelector } from "react-redux";
import { IAppState } from "../store/store";

export interface ITabContentProps {
  tabs: IDetailsTabs[];
  tabId?: string;
}

const TabContent = (params: ITabContentProps) => {
  const selectedTabName = useSelector((state: IAppState) => state.generalState.selectedTabName);

  if (params.tabId === undefined && selectedTabName !== undefined) {
    const tabObject = params.tabs.find(tab => tab.tab.label === selectedTabName);
    if (tabObject) {
      return <TabPanel>{tabObject.tab.value}</TabPanel>;
    }
  }

  const tabObject = params.tabs.find(tab => tab.routeId?.toString() === params.tabId);
  return <TabPanel>{tabObject ? tabObject.tab.value : <ProductsTab />}</TabPanel>;
};

export default TabContent;
