import { State } from "@progress/kendo-data-query";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../../models/IAccount";
import IIntegrationLog from "../../models/Integrations/IIntegrationLog";
import mspService from "../../service/mspService";
import { IAppState } from "../../store/store";
import { integrationLogsMapper } from "../../Utilities/integrationsUtilities";
import { handleError } from "../actionsErrorHandler";
import { cancelGeneralActionTokenAndCreateNew } from "../cancelAction";
import { LocalStoragePreferences, localStorageService } from "../../service/localStorageService";

export enum IntegrationsLogsActionTypes {
  GET_INTEGRATION_LOGS = "GET_INTEGRATION_LOGS",
  SET_LOAD_INTEGRATION_LOGS_CANCELED = "SET_LOAD_INTEGRATION_LOGS_CANCELED",
  SET_LOGS_TABLE_PROPS = "SET_LOGS_TABLE_PROPS",
  SET_NEW_LOGS_FILTER = "SET_NEW_LOGS_FILTER",
}

export interface IGetIntegrationLogsAction {
  type: IntegrationsLogsActionTypes.GET_INTEGRATION_LOGS;
  integrationLogs: IIntegrationLog[];
  loadingIntegrationsLogs: boolean;
}

export interface ICancelGetIntegrationLogsAction {
  type: IntegrationsLogsActionTypes.SET_LOAD_INTEGRATION_LOGS_CANCELED;
  loadingIntegrationLogsCanceled: boolean;
}

export interface ISetLogsTableProps {
  type: IntegrationsLogsActionTypes.SET_LOGS_TABLE_PROPS;
  logsTableState: State;
}

export type IntegrationsLogsActions = IGetIntegrationLogsAction | ICancelGetIntegrationLogsAction | ISetLogsTableProps;

export const getIntegrationLogsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationLogsAction>> = (accoount: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: IntegrationsLogsActionTypes.GET_INTEGRATION_LOGS,
        integrationLogs: [],
        loadingIntegrationsLogs: true,
      });
      dispatch({
        type: IntegrationsLogsActionTypes.SET_LOAD_INTEGRATION_LOGS_CANCELED,
        loadingIntegrationLogsCanceled: false,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      let integrationLogs = await mspService.loadCWIntegrationLogs(apiUrl, accoount.id, newCancelTokenSource.token, 0);
      if (integrationLogs.total > 1000) {
        integrationLogs = await mspService.loadCWIntegrationLogs(apiUrl, accoount.id, newCancelTokenSource.token, integrationLogs.total);
      }

      dispatch({
        type: IntegrationsLogsActionTypes.GET_INTEGRATION_LOGS,
        integrationLogs: integrationLogsMapper(integrationLogs.list),
        loadingIntegrationsLogs: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsLogsActionTypes.GET_INTEGRATION_LOGS,
            integrationLogs: [],
            loadingIntegrationsLogs: false,
          });
        },
        () => {
          dispatch({
            type: IntegrationsLogsActionTypes.SET_LOAD_INTEGRATION_LOGS_CANCELED,
            loadingIntegrationLogsCanceled: true,
          });
        },
      );
    }
  };
};

export const setLogsTableProps: ActionCreator<ThunkAction<any, IAppState, null, ISetLogsTableProps>> = (logsTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_LOGS_UI, JSON.stringify({ ...logsTableState, skip: 0 }));
    dispatch({ type: IntegrationsLogsActionTypes.SET_LOGS_TABLE_PROPS, logsTableState });
  };
};

export const setIntegrationLogsTablePropsActionToFirstPage: ActionCreator<ThunkAction<any, IAppState, null, ISetLogsTableProps>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    const { logsTableState } = getState().integrationsLogsState;
    logsTableState.skip = 0;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_LOGS_UI, JSON.stringify(logsTableState));
    dispatch({ type: IntegrationsLogsActionTypes.SET_LOGS_TABLE_PROPS, logsTableState });
  };
};
