import { GridColumnMenuFilterUI } from "@progress/kendo-react-grid/dist/npm/columnMenu/GridColumnMenuFilterUI";
import { GridColumnMenuFilterUIProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridColumnMenuFilterUIProps";
import React, { useEffect, useState } from "react";

const FilterCapacityInput = (props: GridColumnMenuFilterUIProps) => {
  const initialChecked = props.firstFilterProps && props.firstFilterProps.operator === "eq" && props.firstFilterProps.value === 0;
  const [naChecked, setNaChecked] = useState(initialChecked);
  useEffect(() => {
    if (naChecked) {
      props.firstFilterProps.onChange({
        value: undefined,
        operator: props.firstFilterProps.operator,
        syntheticEvent: {} as any,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naChecked]);
  const handleNaChange = (event: any) => {
    const checked = event.target.checked;
    setNaChecked(checked);
    const { secondFilterProps } = props;
    if (checked) {
      secondFilterProps.onChange({
        value: 0,
        operator: props.secondFilterProps.operators[0].operator,
        syntheticEvent: event.syntheticEvent,
      });
    } else {
      secondFilterProps.onChange({
        value: undefined,
        operator: props.secondFilterProps.operators[7].operator,
        syntheticEvent: event.syntheticEvent,
      });
    }
  };

  return (
    <div data-testid="CustomNumericCapacityFilter">
      <div>
        <div className="GridColumnMenuFilterUI">
          <div className={naChecked ? "DisableColumnFilter" : ""}>
            <GridColumnMenuFilterUI {...props} />
          </div>
        </div>
        <div style={{ marginTop: "10px", marginLeft: "13px" }}>
          <label>
            <input type="checkbox" checked={naChecked} onChange={handleNaChange} /> N/A
          </label>
        </div>
      </div>
    </div>
  );
};

export default FilterCapacityInput;
