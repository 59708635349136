import produce from "immer";
import IAccount from "../models/IAccount";
import MspType from "../models/MspType";
import IUser from "../models/IUser";
import { CheckedState } from "../models/CheckedState";

export function updateSelectionsAfterStateChange(items: IAccount[], item: IAccount | undefined, checked: boolean): IAccount[] {
  if (item === undefined) {
    return checkAllItems(items, checked);
  } else {
    if (item.type === MspType.Customer) {
      return updateSelectionsWhenChildCheckedStateChanged(items, item, checked);
    } else {
      return updateSelectionsWhenParentCheckedStateChanged(items, item, checked);
    }
  }
}

function updateSelectionsWhenChildCheckedStateChanged(items: IAccount[], item: IAccount, checked: boolean): IAccount[] {
  const parentIndex = items.findIndex(x => x.id === item.closestParentId);
  let result: IAccount[] = items;
  if (parentIndex > -1) {
    let children = result[parentIndex].accounts;
    if (children && children.length > 0) {
      const childIndex = children.findIndex(x => x.id === item.id);
      if (childIndex > -1) {
        const nextState: IAccount[] = produce(result, (draft: IAccount[]) => {
          const foundItem = draft[parentIndex].accounts;
          if (foundItem !== undefined) {
            foundItem[childIndex].checkedState = checked ? CheckedState.Checked : CheckedState.Unchecked;
          }
        });
        result = nextState;

        if (checked === true) {
          result = updateParentStateAfterChildWasChecked(result, parentIndex);
        } else {
          result = updateParentStateAfterChildWasUnChecked(result, item, parentIndex);
        }
      }
    }
    /* } else {
    //parent of customer is not expanded, this means that subpartner or partner with no subpartner is selected
    //update only item
    const childIndex = items.findIndex(x => x.id === item.id);
    if (childIndex > -1) {
      result = updateCheckStateItemInArray(result, childIndex, checked ? CheckedState.Checked : CheckedState.Unchecked);
    } */
  }
  return result;
}

function updateParentStateAfterChildWasChecked(items: IAccount[], parentIndex: number): IAccount[] {
  return updateCheckStateItemInArray(items, parentIndex, allChildrenAreChecked(items[parentIndex]) ? CheckedState.Checked : CheckedState.Indeterminate);
}

function updateCheckStateItemInArray(result: IAccount[], index: number, checked?: CheckedState, includeFutureAccounts?: boolean): IAccount[] {
  return produce(result, (draft: IAccount[]) => {
    if (checked !== undefined) {
      draft[index].checkedState = checked;
    }
    if (includeFutureAccounts !== undefined) {
      draft[index].includeFutureAccounts = includeFutureAccounts;
    }
  });
}

function updateParentStateAfterChildWasUnChecked(items: IAccount[], item: IAccount, parentIndex: number): IAccount[] {
  let result: IAccount[] = items;
  if (anyOtherChildrenAreChecked(result[parentIndex], item)) {
    result = updateCheckStateItemInArray(result, parentIndex, CheckedState.Indeterminate, false);
  } else {
    result = updateCheckStateItemInArray(result, parentIndex, CheckedState.Unchecked, false);
  }
  return result;
}

function updateSelectionsWhenParentCheckedStateChanged(items: IAccount[], item: IAccount, checked: boolean): IAccount[] {
  const index = items.findIndex(x => x.id === item.id);
  let result: IAccount[] = items;
  if (index > -1) {
    const nextStateParent: IAccount[] = produce(result, (draft: IAccount[]) => {
      draft[index] = checkItemAndChildren(draft[index], checked);
    });
    result = nextStateParent;
  }
  return result;
}

function allChildrenAreChecked(item: IAccount): boolean {
  let result = true;
  if (item.accounts && item.accounts.length > 0) {
    item.accounts.forEach(element => {
      if (element.checkedState !== CheckedState.Checked) {
        result = false;
      }
    });
  }
  return result;
}

function anyOtherChildrenAreChecked(parentItem: IAccount, item: IAccount): boolean {
  let result = false;
  if (parentItem.accounts && parentItem.accounts.length > 0) {
    parentItem.accounts.forEach(element => {
      if (element.id !== item.id && element.checkedState === CheckedState.Checked) {
        result = true;
      }
    });
  }
  return result;
}

export function getUpdatedExpandedItem(updatedAccountAccessSelection: IAccount[], expandedAccountAccessMsp: IAccount | undefined): IAccount | undefined {
  if (expandedAccountAccessMsp) {
    const index = updatedAccountAccessSelection.findIndex(x => x.id === expandedAccountAccessMsp.id);
    if (index > -1) {
      return updatedAccountAccessSelection[index];
    }
  }
  return undefined;
}

export function getUpdatedItemsToDisplay(updatedAccountAccessSelection: IAccount[], updatedExpandedAccountAccessMsp: IAccount | undefined, isViewingMsp: boolean): IAccount[] {
  if (isViewingMsp) {
    return updatedAccountAccessSelection;
  }
  if (updatedExpandedAccountAccessMsp !== undefined) {
    if (updatedExpandedAccountAccessMsp.accounts !== undefined) {
      return updatedExpandedAccountAccessMsp.accounts;
    } else {
      return [];
    }
  } else {
    return updatedAccountAccessSelection;
  }
}

export function getUpdatedSelectAll(selection: IAccount[]): CheckedState {
  return areAllItemsDisplayedSelected(selection);
}

export function getUpdatedIncludeFutureAccounts(selection: IAccount[]): boolean {
  return areAllItemsDisplayedSelectedForFutureAccounts(selection);
}

export function addChildrenAccountsToSelection(isEdit: boolean, items: IAccount[], updatedAccount: IAccount): IAccount[] {
  let result: IAccount[] = items;
  const index = items.findIndex(x => x.id === updatedAccount.id);
  if (index > -1) {
    const nextState: IAccount[] = produce(result, (draft: IAccount[]) => {
      if (isEdit) {
        draft[index] = updatedAccount;
      } else {
        if (updatedAccount.checkedState === CheckedState.Checked || updatedAccount.checkedState === CheckedState.Unchecked) {
          const checked = updatedAccount.checkedState === CheckedState.Checked;
          draft[index] = checkItemAndChildren(updatedAccount, checked);
        }
      }
    });
    result = nextState;
  }
  return result;
}

function checkAllItems(result: IAccount[] | undefined, checked: boolean): IAccount[] {
  let updated: IAccount[] = [];
  result?.forEach(element => {
    updated.push(checkItemAndChildren(element, checked));
  });
  return updated;
}

function checkFutureAccountsForAllItems(result: IAccount[], checked: boolean): IAccount[] {
  let updated: IAccount[] = [];
  result.forEach(element => {
    updated.push({ ...element, includeFutureAccounts: checked });
  });
  return updated;
}

function checkItemAndChildren(item: IAccount, checked: boolean, updateIncludeFutureAccounts?: boolean): IAccount {
  const final = produce(item, (draft: IAccount) => {
    draft.checkedState = checked ? CheckedState.Checked : CheckedState.Unchecked;
    if (!checked) {
      draft.includeFutureAccounts = false;
    } else if (updateIncludeFutureAccounts) {
      draft.includeFutureAccounts = true;
    }
    if (draft.accounts && draft.accounts.length > 0) {
      let updatedChildren: IAccount[] = [];
      draft.accounts.forEach(el => {
        updatedChildren.push({ ...el, checkedState: checked ? CheckedState.Checked : CheckedState.Unchecked });
      });
      draft.accounts = updatedChildren;
    }
  });
  return final;
}

export function getAccountAccessHierarchy(mspAccounts: IAccount[], item: IAccount | undefined, isMspView: boolean, user: IUser | undefined, isEdit: boolean): IAccount[] {
  let result: IAccount[] = [];
  if (item) {
    if (isMspView) {
      if (isEdit) {
        //future story
      } else {
        mspAccounts.forEach(element => {
          result.push(checkItemAndChildren(element, true, true));
        });
      }
    } else {
      const index = mspAccounts.findIndex(x => x.id === item.id);
      if (index > -1) {
        if (isEdit) {
          //future story
        } else {
          result = checkAllItems(mspAccounts[index].accounts, true);
        }
      }
    }
  }

  return result;
}

export function hasAnyExpandableRows(item: IAccount | undefined, mspAccounts: IAccount[]): boolean {
  let result: boolean = false;
  if (item?.type === MspType.Partner && mspAccounts.length > 1) {
    return true;
  }
  return result;
}

export function areAllItemsDisplayedSelected(itemsToDisplay: IAccount[]): CheckedState {
  let allChecked: boolean = true;
  let allUnchecked: boolean = true;
  itemsToDisplay.forEach(element => {
    if (element.checkedState !== CheckedState.Checked) {
      allChecked = false;
    }
    if (element.checkedState !== CheckedState.Unchecked) {
      allUnchecked = false;
    }
  });
  if (allChecked) {
    return CheckedState.Checked;
  } else if (allUnchecked) {
    return CheckedState.Unchecked;
  } else {
    return CheckedState.Indeterminate;
  }
}

export function areAllItemsDisplayedSelectedForFutureAccounts(itemsToDisplay: IAccount[]): boolean {
  let allChecked: boolean = true;
  itemsToDisplay.forEach(element => {
    if (element.includeFutureAccounts !== true) {
      allChecked = false;
    }
  });
  return allChecked;
}

export function updateSelectionsAfterFutureAccountsStateChange(items: IAccount[], item: IAccount | undefined, checked: boolean, isViewingMsp: boolean): IAccount[] {
  if (item === undefined) {
    if (isViewingMsp) {
      return checkFutureAccountsForAllItems(items, checked);
    }
    return items;
  }
  return updateSelectionsWhenForMspFutureAccountsStateChanged(items, item, checked);
}

function updateSelectionsWhenForMspFutureAccountsStateChanged(items: IAccount[], item: IAccount, checked: boolean): IAccount[] {
  const index = items.findIndex(x => x.id === item.id);
  let result: IAccount[] = items;
  if (index > -1) {
    result = updateCheckStateItemInArray(result, index, undefined, checked);
  }
  return result;
}
