import { findAccountById } from "../../../Utilities/accountsHelper";
import IAccount from "../../../models/IAccount";

export function findItemWithClosestParentIdInState(item: IAccount, mspAccountLoggedIn: IAccount, accountsNames: IAccount[], isBaLoggedIn: boolean, itemsToDisplay: IAccount[], mspAccounts: IAccount[]) {
  const poolOfItems = isBaLoggedIn ? [mspAccountLoggedIn, ...mspAccounts] : mspAccounts;
  const foundAccount = findAccountById(poolOfItems, accountsNames, item.id);
  if (foundAccount !== undefined) {
    if ((foundAccount.closestParentId !== undefined && foundAccount.closestParentId !== 0) || mspAccountLoggedIn.id === item.id) {
      return { foundAccount, shouldGetParent: false };
    }
    return { foundAccount, shouldGetParent: true };
  }
  return { foundAccount: undefined, shouldGetParent: true };
}
