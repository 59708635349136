import React, { useEffect, useState } from "react";
import ".././App.css";
import Grid from "@material-ui/core/Grid";
import AccountPanel from "./Accounts/AccountPanel";
import EmptyInfoPanel from "./EmptyInfoPanel";
import InfoPanel from "./InfoPanel";
import { IAppState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import IAuditUserImportAction from "../models/Products/IAuditUserImportAction";
import IntegrationsPanel from "./Integrations/IntegrationsPanel";
import DetailsTabs from "../models/DetailsTabs";
import IntegrationsTabs from "./Integrations/IntegrationsTabs";
import IntegrationTabs from "../models/Integrations/IntegrationTabs";
import IAccount from "../models/IAccount";
import IIntegration from "../models/Integrations/IIntegration";
import HelpFooter from "./HelpFooter";
import PartnerResourcesDialog from "./Accounts/PartnerResources";
import { setSnackBarMessage } from "../actions/generalActions";
import ActionMessageType from "../models/ActionMessageType";
import { removeFinishedImportAuditUsersExclusionFile } from "../actions/auditUsersActions";
import MspType from "../models/MspType";
import { setSelectedAccountAction } from "../actions/accountActions";

const MspApp = (props: any) => {
  const dispatch = useDispatch();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [isCustomer, setIsCustomer] = useState(false);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const importActions = useSelector((state: IAppState) => state.auditUsersState.importActions);
  const viewIntegrations = props.integration;
  const showPartnerResources = props.partnerResources;
  const showUserBilling = props.userBilling;
  const selectedIntegration = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const [userTabLoaded, setUserTabLoaded] = useState(false);
  const [detailsTabLoaded, setDetailTabLoaded] = useState(false);
  const [productsTabLoaded, setProductsTabLoaded] = useState(false);
  const [financeTabLoaded, setFinanceTabLoaded] = useState(false);
  const [apiCredentialsTabLoaded, setApiCredentialsTabLoaded] = useState(false);
  const [integrationAccountTabLoaded, setIntegrationAccountTabLoaded] = useState(false);
  const [integrationBillingTabLoaded, setIntegrationBillingTabLoaded] = useState(false);
  const [integrationLogsTabLoaded, setIntegrationLogsTabLoaded] = useState(false);
  const [integrationDetailsTabLoaded, setIntegrationDetailsTabLoaded] = useState(false);

  useEffect(() => {
    if (mspAccountLoggedIn.type === MspType.Customer) {
      setIsCustomer(true);
    }
    if (viewIntegrations || showPartnerResources) {
      dispatch(setSelectedAccountAction(mspAccountLoggedIn));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn]);

  useEffect(() => {
    if (importActions.length > 0) {
      const finishedActionIndex = importActions.findIndex((x: IAuditUserImportAction) => x.isRunning !== true);
      if (finishedActionIndex > -1) {
        const successMessage = `Successfully excluded users from file ${importActions[finishedActionIndex].csvFileName} for account ${importActions[finishedActionIndex].account.name}.`;
        dispatch(removeFinishedImportAuditUsersExclusionFile(importActions[finishedActionIndex].account.id));
        dispatch(setSnackBarMessage({ message: successMessage, type: ActionMessageType.Success }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importActions]);

  const onSetTabLoaded = (loaded: boolean, tab: DetailsTabs) => {
    switch (tab) {
      case DetailsTabs.Products:
        setProductsTabLoaded(loaded);
        break;
      case DetailsTabs.AccountsDetails:
        setDetailTabLoaded(loaded);
        break;
      case DetailsTabs.Users:
        setUserTabLoaded(loaded);
        break;
      case DetailsTabs.Finance:
        setFinanceTabLoaded(loaded);
        break;
      case DetailsTabs.ApiCredentials:
        setApiCredentialsTabLoaded(loaded);
        break;
    }
    if (loaded === false) {
      setIntegrationAccountTabLoaded(loaded);
      setIntegrationBillingTabLoaded(loaded);
      setIntegrationLogsTabLoaded(loaded);
      setIntegrationDetailsTabLoaded(loaded);
    }
  };

  const onSetIntegrationTabLoaded = (loaded: boolean, tab: IntegrationTabs) => {
    switch (tab) {
      case IntegrationTabs.Accounts:
        setIntegrationAccountTabLoaded(loaded);
        break;
      case IntegrationTabs.Billing:
        setIntegrationBillingTabLoaded(loaded);
        break;
      case IntegrationTabs.Logs:
        setIntegrationLogsTabLoaded(loaded);
        break;
      case IntegrationTabs.Details:
        setIntegrationDetailsTabLoaded(loaded);
        break;
    }
  };

  const displayTabs = (account: IAccount | undefined, isIntegration: boolean | undefined, integration: IIntegration | undefined) => {
    if (account === undefined) {
      return <EmptyInfoPanel message="Select an Account to See Information " />;
    }
    if (account) {
      if (isIntegration) {
        console.debug(`Account ${selectedAccount?.name} - Integrations`);

        if (integration) {
          return <IntegrationsTabs integrationAccountsTabLoaded={integrationAccountTabLoaded} integrationDetailsTabLoaded={integrationDetailsTabLoaded} integrationBillingTabLoaded={integrationBillingTabLoaded} integrationLogsTabLoaded={integrationLogsTabLoaded} setTabLoaded={onSetIntegrationTabLoaded} />;
        } else {
          return <EmptyInfoPanel message="Select an Integration to See Information " />;
        }
      }
      return <InfoPanel data-testid="infoPanel" userTabLoaded={userTabLoaded} detailsTabLoaded={detailsTabLoaded} productsTabLoaded={productsTabLoaded} financeTabLoaded={financeTabLoaded} apiCredentialsTabLoaded={apiCredentialsTabLoaded} setTabLoaded={onSetTabLoaded} showUserBilling={showUserBilling} />;
    } else {
      return <EmptyInfoPanel message="Select an Account to See Information " />;
    }
  };

  return (
    <div>
      <Grid item container spacing={3}>
        <Grid item container spacing={3} style={{ minHeight: "calc(95vh - 170px)" }}>
          {!isCustomer &&
            (showPartnerResources ? (
              <Grid item xs={12}>
                <PartnerResourcesDialog />
              </Grid>
            ) : (
              <Grid item xs={4} sm={4}>
                {viewIntegrations ? (
                  <IntegrationsPanel />
                ) : (
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <AccountPanel data-testid="accountPanel" />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          {!showPartnerResources && (
            <Grid item xs={isCustomer ? 12 : 8} sm={isCustomer ? 12 : 8}>
              {displayTabs(selectedAccount, viewIntegrations, selectedIntegration)}
            </Grid>
          )}
        </Grid>
        <HelpFooter />
      </Grid>
    </div>
  );
};

export default MspApp;
