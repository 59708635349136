import React from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@cuda-networks/bds-core";

interface IConfirmMessageDialog {
  open: boolean;
  title: React.JSX.Element;
  message: React.JSX.Element;
  onConfirm?: () => void;
  onClose: () => void;
  isActionInProgress: boolean;
}
const ConfirmMessageDialog = ({ title, message, open, onClose, onConfirm, isActionInProgress }: IConfirmMessageDialog) => {
  const onCloseDialog = (event: object, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog disableRestoreFocus disableEscapeKeyDown={isActionInProgress} data-testid="customMessageDialog" open={open} onClose={onCloseDialog}>
      <DialogTitle disableTypography data-testid="customMessageTitle">
        {title}
      </DialogTitle>
      <DialogContent>
        <div data-testid="customMessage">{message}</div>
      </DialogContent>
      <DialogActions>
        <Button disabled={isActionInProgress} data-testid="closeCustomMessage" variant={"text"} size="large" onClick={onCloseDialog} className={"dialogButtonSpacing"}>
          CANCEL
        </Button>
        <Button isLoading={isActionInProgress} data-testid="confirmCustomMessage" primary="true" type={"submit"} onClick={onConfirm} className={"dialogButtonSpacing"}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMessageDialog;
