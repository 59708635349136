enum IntegrationType {
  ConnectWise = 4,
  Autotask = 2,
}

export default IntegrationType;

export function getIntegrationNameByType(type: IntegrationType): string {
  if (type === IntegrationType.ConnectWise) {
    return "ConnectWise Manage®";
  }
  return "Autotask® PSA";
}
