import React from "react";
import { IconButton } from "@cuda-networks/bds-core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { SnackbarKey } from "notistack";

interface ICloseButtonProps {
  onCloseSnackbar(x: SnackbarKey): void;
}

const CloseButton: React.FC<ICloseButtonProps> = ({ onCloseSnackbar }) => {
  return (
    <IconButton key="clear-snackbar" data-testid="clearSnackbar" style={{ color: "white" }} onClick={onCloseSnackbar}>
      <CoreIcons.Close />
    </IconButton>
  );
};

export default CloseButton;
