enum BackupConditionStatus {
  Passed = 0,
  Running = 1,
  Warning = 2,
  Failed = 3,
  NoRecent = 4,
  Missed = 5,
  ConnectWiseNoRecent = 6,
  ConnectWiseMissesBackup = 7,
}

export default BackupConditionStatus;
