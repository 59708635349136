export enum InvoiceOptionId {
  MakeAPayment = 0,
  EmailInvoice = 1,
  ViewExclusionsList = 2,
}

export interface IInvoiceOptions {
  value: number;
  label: string;
}

export const InvoiceOptions: IInvoiceOptions[] = [
  { value: InvoiceOptionId.MakeAPayment, label: "Make a Payment" },
  { value: InvoiceOptionId.EmailInvoice, label: "Email Invoice" },
  { value: InvoiceOptionId.ViewExclusionsList, label: "View Exclusions List" },
];
