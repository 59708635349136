import React, { useState, useEffect } from "react";
import "../../table.css";
import { DataTable, DataTableColumn as Column } from "@cuda-networks/bds-core";
import { process } from "@progress/kendo-data-query";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { getButtonCount } from "../../utility";
import { useMediaQuery } from "@material-ui/core";
import IAccount from "../../models/IAccount";
import AccountCell from "./AccountCell";
import AccountCellBasic from "./AccountCellBasic";
import { setAccountRowSelectedState } from "../../Utilities/accountsHelper";

interface IAccountsTableProps {
  showExpandIcon: boolean;
  pageSize: number;
  pageNumber: number;
  isTextOnlyTable: boolean;
  items: IAccount[];
  selectedAccount: IAccount | undefined;
  hasSubpartners: boolean;
  viewSearchResults: boolean;
  loadingAccountId: number | undefined;
  onItemSelected(item: IAccount): void;
  onItemChecked(item: IAccount, checked: boolean): void;
  onPageSizeChange(value: number): void;
  onPageNumberChange(value: number): void;
  showCheckboxes: boolean;
  checkboxesDisabled: boolean;
  isBaLoggedIn: boolean;
  showInfoIcon: boolean;
  defaultPagination: number[];
}

const AccountsTableBase: React.FC<IAccountsTableProps> = ({ showExpandIcon, isTextOnlyTable, pageSize, pageNumber, items, selectedAccount, hasSubpartners, viewSearchResults, loadingAccountId, onItemSelected, onItemChecked, onPageSizeChange, onPageNumberChange, showCheckboxes, checkboxesDisabled, isBaLoggedIn, showInfoIcon, defaultPagination }) => {
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1900px)");
  const dataState = {
    skip: pageSize * (pageNumber - 1),
    take: pageSize,
    sort: [{ field: "date", dir: "asc" }],
    group: [],
    collapsedGroups: [],
    selected: 0,
    lastSelectedIndex: 0,
    columns: [
      {
        title: "name",
        field: "name",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(items, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    onPageSizeChange(e.dataState.take);
    onPageNumberChange(e.dataState.skip / e.dataState.take + 1);
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(items, e.dataState),
    });
  };

  useEffect(() => {
    let newDs;
    if (selectedAccount) {
      newDs = { ...gridState.dataState, selected: selectedAccount.id, skip: pageSize * (pageNumber - 1) };
    } else {
      newDs = { ...gridState.dataState, selected: 0, skip: pageSize * (pageNumber - 1) };
    }
    setGridState({ dataState: newDs, dataResult: process(items, newDs as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedAccount, pageNumber, pageSize]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  return (
    <DataTable
      className={"noHeader noScrollbar noBorders accountTable"}
      data={gridState.dataResult.data.map(item => ({ ...item, selected: setAccountRowSelectedState(item.id, gridState.dataState.selected), isLoading: true }))}
      filterable
      reorderable
      resizable
      // page
      pageConfig={{
        pageable: {
          pageSizes: defaultPagination,
          buttonCount: buttonCount,
        },
        skip: gridState.dataState.skip,
        take: gridState.dataState.take,
        total: gridState.dataResult.total,
      }}
      pager={gridState.dataResult.data.length > 0 && Pager}
      sortConfig={{
        sortable: true,
        sort: gridState.dataState.sort as any,
      }}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      onRowClick={() => {}}
      {...(gridState.dataState as any)}
    >
      {isTextOnlyTable ? gridState.dataState.columns.map(column => column.show && <Column key={"account" + column.field} field={column.field} title={column.title} cell={(props: any) => <AccountCellBasic onClick={onItemSelected} showCheckbox={showCheckboxes} item={props.dataItem} disabled={checkboxesDisabled} onCheck={onItemChecked} />} />) : gridState.dataState.columns.map(column => column.show && <Column key={"account" + column.field} field={column.field} title={column.title} cell={(props: any) => <AccountCell onClick={onItemSelected} displayExpandIcon={showExpandIcon && hasSubpartners && !viewSearchResults} loading={props.dataItem.id === loadingAccountId} showCheckbox={showCheckboxes} item={props.dataItem} disabled={checkboxesDisabled} onCheck={onItemChecked} isBaLoggedIn={isBaLoggedIn} showInfoIcon={showInfoIcon} />} />)}
    </DataTable>
  );
};

export default AccountsTableBase;
