import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../models/IAccount";
import { IAppState } from "../store/store";
import { addChildrenAccountsToSelection, getAccountAccessHierarchy, getUpdatedExpandedItem, getUpdatedIncludeFutureAccounts, getUpdatedItemsToDisplay, getUpdatedSelectAll, updateSelectionsAfterFutureAccountsStateChange, updateSelectionsAfterStateChange } from "../businessLogic/loginAccountAccess";
import IUser from "../models/IUser";
import { setAccountsPageNumberForAccountId } from "../businessLogic/accounts";
import { CheckedState } from "../models/CheckedState";

export enum LoginAccountAccessActionTypes {
  SET_ACCOUNTS_ACCESS_ITEM_TO_DISPLAY = "SET_ACCOUNTS_ACCESS_ITEM_TO_DISPLAY",
  SET_IS_VIEWING_MSP = "SET_IS_VIEWING_MSP",
  EXPAND_MSP = "EXPAND_MSP",
  UPDATE_ACCOUNTS_SELECTION = "UPDATE_ACCOUNTS_SELECTION",
  SET_ACCOUNT_SELECTION = "SET_ACCOUNT_SELECTION",
  SET_ACCOUNT_ACCESS_PAGE_SIZE = "SET_ACCOUNT_ACCESS_PAGE_SIZE",
  SET_ACCOUNT_ACCESS_PAGE_NUMBER = "SET_ACCOUNT_ACCESS_PAGE_NUMBER",
  CLEANUP_ACCOUNT_ACCESS_STATE = "CLEANUP_ACCOUNT_ACCESS_STATE",
}

export interface IExpandMspAction {
  type: LoginAccountAccessActionTypes.EXPAND_MSP;
  expandedAccountAccessMsp: IAccount | undefined;
  accountsAccessItemsToDisplay: IAccount[];
  isViewingMsp: boolean;
}

export interface ISetAccountAccessItemsToDisplayAction {
  type: LoginAccountAccessActionTypes.SET_ACCOUNTS_ACCESS_ITEM_TO_DISPLAY;
  accountsAccessItemsToDisplay: IAccount[];
}

export interface ISetIsViewingMsp {
  type: LoginAccountAccessActionTypes.SET_IS_VIEWING_MSP;
  isViewingMsp: boolean;
}

export interface ISetaccountAccessSelectionAction {
  type: LoginAccountAccessActionTypes.SET_ACCOUNT_SELECTION;
  accountAccessSelection: IAccount[];
  accountsAccessItemsToDisplay: IAccount[];
}

export interface IUpdateAccountsSelection {
  type: LoginAccountAccessActionTypes.UPDATE_ACCOUNTS_SELECTION;
  accountAccessSelection: IAccount[];
  accountsAccessItemsToDisplay: IAccount[];
  expandedAccountAccessMsp: IAccount | undefined;
  selectAll: CheckedState;
  includeFutureAccounts: boolean;
}

export interface ISetAccountAccessPageSize {
  type: LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_SIZE;
  accountsAccessPageSize: number;
}

export interface ISetAccountAccessPageNumber {
  type: LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_NUMBER;
  accountsAccessPageNumber: number;
}

export interface ICleanupAccountAccessState {
  type: LoginAccountAccessActionTypes.CLEANUP_ACCOUNT_ACCESS_STATE;
  expandedAccountAccessMsp: IAccount | undefined;
  accountAccessSelection: IAccount[];
  accountsAccessItemsToDisplay: IAccount[];
  accountsAccessPageSize: number;
  accountsAccessPageNumber: number;
  selectAll: CheckedState;
  includeFutureAccounts: boolean;
}

export type LoginAccountAccessAction = IExpandMspAction | ISetAccountAccessItemsToDisplayAction | ISetIsViewingMsp | IUpdateAccountsSelection | ISetaccountAccessSelectionAction | ISetAccountAccessPageSize | ISetAccountAccessPageNumber | ICleanupAccountAccessState;

export const expandMspAction: ActionCreator<ThunkAction<any, IAppState, null, IExpandMspAction>> = (accountId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountAccessSelection } = getState().loginAccountAccessState;
    const index = accountAccessSelection.findIndex(x => x.id === accountId);
    if (index > -1) {
      const expandedAccount = accountAccessSelection[index];
      dispatch({
        type: LoginAccountAccessActionTypes.EXPAND_MSP,
        expandedAccountAccessMsp: expandedAccount,
        accountsAccessItemsToDisplay: expandedAccount?.accounts ? expandedAccount?.accounts : [],
        isViewingMsp: false,
      });
    } else {
      dispatch({
        type: LoginAccountAccessActionTypes.EXPAND_MSP,
        expandedAccountAccessMsp: undefined,
        accountsAccessItemsToDisplay: [],
        isViewingMsp: false,
      });
    }
  };
};

export const setAccountAccessSelectionAction: ActionCreator<ThunkAction<any, IAppState, null, ISetaccountAccessSelectionAction>> = (accounts: IAccount[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LoginAccountAccessActionTypes.SET_ACCOUNT_SELECTION,
      accountAccessSelection: accounts,
      accountsAccessItemsToDisplay: accounts,
    });
  };
};

export const createAccountAccessHierachyAction: ActionCreator<ThunkAction<any, IAppState, null, ISetaccountAccessSelectionAction>> = (isMspView: boolean, user: IUser, isEdit: boolean) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccounts, selectedAccount } = getState().accountState;
    const accounts = getAccountAccessHierarchy(mspAccounts, selectedAccount, isMspView, user, isEdit);
    dispatch({
      type: LoginAccountAccessActionTypes.SET_ACCOUNT_SELECTION,
      accountAccessSelection: accounts,
      accountsAccessItemsToDisplay: accounts,
    });
  };
};

export const setAccountAccessToDisplayAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountAccessItemsToDisplayAction>> = (accounts: IAccount[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LoginAccountAccessActionTypes.SET_ACCOUNTS_ACCESS_ITEM_TO_DISPLAY,
      accountsAccessItemsToDisplay: accounts,
    });
  };
};

export const setIsViewingMspAction: ActionCreator<ThunkAction<any, IAppState, null, ISetIsViewingMsp>> = (isViewingMsp: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LoginAccountAccessActionTypes.SET_IS_VIEWING_MSP,
      isViewingMsp: isViewingMsp,
    });
  };
};

export const updateAccountsSelectionAction: ActionCreator<ThunkAction<any, IAppState, null, IUpdateAccountsSelection>> = (account: IAccount | undefined, checked: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountAccessSelection, expandedAccountAccessMsp, isViewingMsp } = getState().loginAccountAccessState;
    const updatedAccountAccessSelection = updateSelectionsAfterStateChange(accountAccessSelection, account, checked);

    const updatedExpandedAccountAccessMsp = getUpdatedExpandedItem(updatedAccountAccessSelection, expandedAccountAccessMsp);
    const updatedAccountsAccessItemsToDisplay = getUpdatedItemsToDisplay(updatedAccountAccessSelection, updatedExpandedAccountAccessMsp, isViewingMsp);
    const updatedSelectAll = getUpdatedSelectAll(updatedAccountAccessSelection);
    const updatedIncludeFutureAccounts = getUpdatedIncludeFutureAccounts(updatedAccountAccessSelection);
    dispatch({
      type: LoginAccountAccessActionTypes.UPDATE_ACCOUNTS_SELECTION,
      accountAccessSelection: updatedAccountAccessSelection,
      accountsAccessItemsToDisplay: updatedAccountsAccessItemsToDisplay,
      expandedAccountAccessMsp: updatedExpandedAccountAccessMsp,
      selectAll: updatedSelectAll,
      includeFutureAccounts: updatedIncludeFutureAccounts,
    });
  };
};

export const updateAccountsSelectionForFutureAccountsAction: ActionCreator<ThunkAction<any, IAppState, null, IUpdateAccountsSelection>> = (checked: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountAccessSelection, expandedAccountAccessMsp, accountsAccessItemsToDisplay, isViewingMsp, selectAll } = getState().loginAccountAccessState;
    const updatedAccountAccessSelection = updateSelectionsAfterFutureAccountsStateChange(accountAccessSelection, expandedAccountAccessMsp, checked, isViewingMsp);

    const updatedExpandedAccountAccessMsp = getUpdatedExpandedItem(updatedAccountAccessSelection, expandedAccountAccessMsp);
    let updatedAccountsAccessItemsToDisplay = accountsAccessItemsToDisplay;
    if (expandedAccountAccessMsp === undefined) {
      updatedAccountsAccessItemsToDisplay = getUpdatedItemsToDisplay(updatedAccountAccessSelection, updatedExpandedAccountAccessMsp, isViewingMsp);
    }
    const updatedIncludeFutureAccounts = getUpdatedIncludeFutureAccounts(updatedAccountAccessSelection);

    dispatch({
      type: LoginAccountAccessActionTypes.UPDATE_ACCOUNTS_SELECTION,
      accountAccessSelection: updatedAccountAccessSelection,
      accountsAccessItemsToDisplay: updatedAccountsAccessItemsToDisplay,
      expandedAccountAccessMsp: updatedExpandedAccountAccessMsp,
      selectAll: selectAll,
      includeFutureAccounts: updatedIncludeFutureAccounts,
    });
  };
};

export const addChildrenAccountsToSelectionAction: ActionCreator<ThunkAction<any, IAppState, null, IUpdateAccountsSelection>> = (isEdit: boolean, account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountAccessSelection, accountsAccessItemsToDisplay } = getState().loginAccountAccessState;
    const updatedAccountAccessSelection = addChildrenAccountsToSelection(isEdit, accountAccessSelection, account);
    dispatch({
      type: LoginAccountAccessActionTypes.SET_ACCOUNT_SELECTION,
      accountAccessSelection: updatedAccountAccessSelection,
      accountsAccessItemsToDisplay: accountsAccessItemsToDisplay,
    });
  };
};

export const setAccountAccessPageSizeAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountAccessPageSize>> = (pageSize: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_SIZE,
      accountsAccessPageSize: pageSize,
    });
  };
};

export const setAccountAccessPageNumberAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountAccessPageNumber>> = (pageNumber: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_NUMBER,
      accountsAccessPageNumber: pageNumber,
    });
  };
};

export const cleanupAccessAccountState: ActionCreator<ThunkAction<any, IAppState, null, ICleanupAccountAccessState>> = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LoginAccountAccessActionTypes.CLEANUP_ACCOUNT_ACCESS_STATE,
      expandedAccountAccessMsp: undefined,
      accountAccessSelection: [],
      accountsAccessItemsToDisplay: [],
      accountsAccessPageSize: 5,
      accountsAccessPageNumber: 1,
      selectAll: CheckedState.Checked,
      includeFutureAccounts: true,
    });
  };
};

export const navigateToAccountAccessItemAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountAccessPageNumber>> = (accountId: number) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountsAccessItemsToDisplay, accountsAccessPageSize } = getState().loginAccountAccessState;
    const accountsAccessPageNumber = setAccountsPageNumberForAccountId(accountsAccessItemsToDisplay, accountsAccessPageSize, accountId);
    dispatch({ type: LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_NUMBER, accountsAccessPageNumber });
  };
};
