import React from "react";
import TooltipElement from "./Users/TooltipElement";

const HeaderCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return (
    <div className="k-link" onClick={props.onClick}>
      <TooltipElement title={props.title}>
        <span>{props.title}</span>
      </TooltipElement>
      {props.children}
    </div>
  );
};

export default HeaderCell;
