import { CardNumberValidationRules } from "../fieldsValidationRules";
import IAccount from "../models/IAccount";
import CreditCardType from "../models/Invoices/CreditCardType";
import IExistingCreditCard from "../models/Invoices/IExistingCreditCard";
import IInvoice from "../models/Invoices/IInvoice";
import { IInvoiceOptions, InvoiceOptionId, InvoiceOptions } from "../models/Invoices/InvoiceOptions";
import InvoiceStatus from "../models/Invoices/InvoiceStatus";
import MspType from "../models/MspType";
import { dateOlderThan60Days } from "../utility";

export const CREDIT_CARD_ERROR = "The credit card provided could not be validated. This could be caused by the card being expired or inactive, or possibly insufficient funds. Please check that the credit card is in good standing and there are sufficient funds to complete the transaction and try again.";
export const PAYMENT_SUCCESS = "Payment request received. Thank you!";

export function getInvoiceStatusLabel(status: InvoiceStatus): string {
  if (status === InvoiceStatus.Pending) {
    return "Pending";
  } else if (status === InvoiceStatus.Received) {
    return "Received";
  } else {
    throw new Error("Invoice has no valid status");
  }
}

export function getCreditCardMonths(): string[] {
  return Array.from({ length: 12 }, (_, i) => (i < 9 ? ("0" + (i + 1)).toString() : (i + 1).toString()));
}

export function getCreditCardYears(): string[] {
  let yearArray = [];
  let min = new Date().getFullYear(),
    max = min + 10;
  for (let i = min; i <= max; i++) {
    yearArray.push(i.toString());
  }
  return yearArray;
}

export function isCreditCardDateExpired(card: IExistingCreditCard): boolean {
  const cardMonth = card.expirationDate.substring(0, card.expirationDate.indexOf("/"));
  const cardYear = card.expirationDate.substring(card.expirationDate.indexOf("/") + 1, card.expirationDate.length);
  const today = new Date();
  return isCardDateExpired(today, cardMonth, cardYear);
}

export function isCardDateExpired(today: Date, month: string, year: string): boolean {
  let someday = new Date();
  someday.setFullYear(parseInt(year.length > 2 ? year : today.getFullYear().toString().substring(0, 2) + year), parseInt(month) - 1, today.getDate());
  if (someday <= today) {
    return true;
  }
  return false;
}

export function isPreviousYears(today: Date, year: string): boolean {
  let someday = new Date();
  someday.setFullYear(parseInt(year.length > 2 ? year : today.getFullYear().toString().substring(0, 2) + year));
  if (someday.getFullYear() < today.getFullYear()) {
    return true;
  }
  return false;
}

export function getCardType(cardNumber: string): string {
  if (CardNumberValidationRules.VisaElectron.test(cardNumber)) {
    return CreditCardType.VisaElectron;
  } else if (CardNumberValidationRules.MasterCard.test(cardNumber)) {
    return CreditCardType.MC;
  } else if (CardNumberValidationRules.Amex.test(cardNumber)) {
    return CreditCardType.AMEX;
  } else if (CardNumberValidationRules.Discover.test(cardNumber)) {
    return CreditCardType.DISC;
  } else if (CardNumberValidationRules.Dinners.test(cardNumber)) {
    return CreditCardType.Diners;
  } else if (CardNumberValidationRules.DinersCarteBlanche.test(cardNumber)) {
    return CreditCardType.DinersCarteBlanche;
  } else if (CardNumberValidationRules.JCB.test(cardNumber)) {
    return CreditCardType.JCB;
  } else if (CardNumberValidationRules.Visa.test(cardNumber)) {
    return CreditCardType.VISA;
  } else {
    return "";
  }
}

export function obfuscateCreditCard(cardNumber: string): string {
  if (cardNumber.length > 4) {
    return "####-####-####-" + cardNumber.slice(-4);
  } else {
    return "####-####-####-" + cardNumber;
  }
}

export function setCreditCardDropdownIndex(creditCardsToDisplay: IExistingCreditCard[], selectedCreditCard: IExistingCreditCard | undefined): number {
  if (selectedCreditCard) {
    return creditCardsToDisplay.findIndex(c => c.token === selectedCreditCard.token) + 1;
  }
  return 0;
}

export const getOptionsForInvoice = (invoice: IInvoice, mspAccountLoggedIn: IAccount): IInvoiceOptions[] => {
  let options: IInvoiceOptions[] = [];
  if (invoice.status === InvoiceStatus.Pending) {
    const makePaymentOp = InvoiceOptions.find(x => x.value === InvoiceOptionId.MakeAPayment);
    if (makePaymentOp) {
      options.push(makePaymentOp);
    }
  }
  const emailInvoiceOp = InvoiceOptions.find(x => x.value === InvoiceOptionId.EmailInvoice);
  if (emailInvoiceOp) {
    options.push(emailInvoiceOp);
  }
  if (!dateOlderThan60Days(invoice.date) && mspAccountLoggedIn.type !== MspType.BillingAggregator) {
    const viewExclusionsOption = InvoiceOptions.find(x => x.value === InvoiceOptionId.ViewExclusionsList);
    if (viewExclusionsOption) {
      options.push(viewExclusionsOption);
    }
  }
  return options;
};

export function getCreditCardExpDateIndex(options: string[], date: string): number {
  return options.findIndex(option => option === date) + 1;
}
