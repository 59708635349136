import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import React from "react";
import IAccount from "../../models/IAccount";
import { enterKey, handleBackdropClick, truncate } from "../../utility";
import { Tooltip } from "@material-ui/core";

interface IExportUsageDataProps {
  onCancel: () => void;
  showExportUsageDialogDialog: boolean;
  isActionInProgress: boolean;
  onExportUsageData: () => void;
  selectedAccount: IAccount;
  emailAddress: string;
}
const ExportUsageDialog: React.FC<IExportUsageDataProps> = ({ showExportUsageDialogDialog, onCancel, onExportUsageData, isActionInProgress, selectedAccount, emailAddress }) => {
  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, onExportUsageData)} disableEscapeKeyDown={isActionInProgress} className="showUnassignDialog" data-testid="showExportUsageDialog" open={showExportUsageDialogDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
        <div style={{ width: "500px" }}>
          <DialogTitle data-testid="showExportUsageDialogTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
            Export Usage Data
          </DialogTitle>
          <DialogContent data-testid="exportUsageDialogMessage">
            <p>
              Pressing continue will send all usage data associated with{" "}
              <Tooltip title={selectedAccount.name}>
                <b>{truncate(selectedAccount.name, 20)}</b>
              </Tooltip>{" "}
              to <b>{emailAddress}</b>. It may take up to an hour for the email to be received.
            </p>
          </DialogContent>
          <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
            <Button data-testid="cancelExportUsageButton" variant="text" size="large" onClick={onCancel} disabled={isActionInProgress}>
              CANCEL
            </Button>
            <Button data-testid="continueExportUsageButton" color="primary" size="large" onClick={onExportUsageData} isLoading={isActionInProgress} disabled={isActionInProgress}>
              CONTINUE
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ExportUsageDialog;
