import { Reducer } from "redux";
import { IApiCredentialApplication, ApiCredentialActionTypes, ApiCredentialActions } from "../actions/apiCredentialActions";

export interface IApplicationState {
  applicationsToDisplay: IApiCredentialApplication[];
  loadingApplications: boolean;
  pendingAction: boolean;
}

const initialApplicationState: IApplicationState = {
  applicationsToDisplay: [],
  loadingApplications: false,
  pendingAction: false,
};

export const apiCredentialReducer: Reducer<IApplicationState, ApiCredentialActions> = (state = initialApplicationState, action: ApiCredentialActions) => {
  switch (action.type) {
    case ApiCredentialActionTypes.GET_APPLICATIONS: {
      return {
        ...state,
        applicationsToDisplay: action.applicationsToDisplay,
        loadingApplications: action.loadingApplications,
      };
    }
    case ApiCredentialActionTypes.DELETE_APPLICATION: {
      return {
        ...state,
        applicationsToDisplay: action.applicationsToDisplay,
        pendingAction: action.pendingAction,
      };
    }
    case ApiCredentialActionTypes.ADD_APPLICATION:
    case ApiCredentialActionTypes.RENEW_APPLICATION: {
      return {
        ...state,
        applicationsToDisplay: action.applicationsToDisplay,
      };
    }

    default:
      return state;
  }
};
