import React, { useEffect, useState } from "react";
import TooltipElement from "../../../Users/TooltipElement";
import DropDown from "../../../DropDown";
import { IIntegrationBillingMap, IntegrationBllingMapType } from "../../../../models/Integrations/IntegrationsBillingMap";

export function getAvailableOptionsForFamilies(item: IIntegrationBillingMap, stillAvailableItems: IIntegrationBillingMap[]): IntegrationBllingMapType[] {
  let result: (IntegrationBllingMapType | undefined)[] = stillAvailableItems.map(item => item.type).filter((value, index, self) => self.indexOf(value) === index);
  if (item.type && result.findIndex(x => x === item.type) < 0) {
    result.push(item.type);
  }
  const nonUndefined: IntegrationBllingMapType[] = [];
  result.forEach(element => {
    if (element !== undefined) {
      nonUndefined.push(element);
    }
  });
  nonUndefined.sort();
  return nonUndefined;
}

const BillingMapFamilyCell = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [ops, setOps] = useState<IntegrationBllingMapType[]>([]);

  useEffect(() => {
    if (props.rowType !== "groupHeader") {
      if (props.dataItem.type !== undefined) {
        setOps([props.dataItem.type]);
        setSelectedIndex(1);
      }
    }
  }, [props]);

  if (props.rowType === "groupHeader") {
    return null;
  }

  const onBillingMapFamilyChanged = (value: number) => {
    if (value > 0) {
      let newType = ops[value - 1];
      setSelectedIndex(value);
      props.onChange(newType);
    }
  };

  const handleOnOpen = (isOpen: boolean) => {
    if (isOpen) {
      const options = getAvailableOptionsForFamilies(props.dataItem, props.availableOrderLineItems).filter(x => x !== undefined);
      setOps(options);
      let selIndex = options.findIndex((x: IntegrationBllingMapType) => x === props.dataItem.type);
      if (selIndex > -1) {
        setSelectedIndex(selIndex + 1);
      }
    }
  };

  const setFocusClassName = () => {
    if (props.totalItems === props.dataIndex + 1 && props.dataItem.id === 0) {
      return "FocusActive";
    } else {
      return "";
    }
  };
  return (
    <td>
      <div data-testid="billingMappingFamily" className={props.disabled ? "cursorNotAllowed" : ""}>
        <TooltipElement title={props.title ? props.title : ""}>
          <DropDown className={setFocusClassName()} options={ops} selectedOptionId={selectedIndex} error={props.errorMessage} disabled={props.disabled || props.dataItem.type === IntegrationBllingMapType.IBU_PLANS} handleChange={onBillingMapFamilyChanged} onOpen={handleOnOpen} label={""} />
        </TooltipElement>
      </div>
    </td>
  );
};

export default BillingMapFamilyCell;
