import { GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";
import * as React from "react";

export const FilterDropdownInput = (props: any, data: any[]) => {
  return (
    <div data-testid="filterDropdownInput">
      <GridColumnMenuCheckboxFilter {...props} data={data} searchBox={() => null} expanded={true} />
    </div>
  );
};
