import React, { useEffect, useState } from "react";
import { Button } from "@cuda-networks/bds-core";
import IAccount from "../../models/IAccount";
import MspType from "../../models/MspType";
import { isAdmin } from "../../utility";

interface IEditAccountAddressButtonProps {
  loggedInAccount: IAccount;
  isBilling: boolean;
  handleOpenEditDialog: (isBilling: boolean) => void;
  height?: number;
  selectedAccount?: IAccount;
}

const EditAccountAddressButton: React.FC<IEditAccountAddressButtonProps> = ({ loggedInAccount, isBilling, handleOpenEditDialog, selectedAccount }) => {
  const [color, setColor] = useState("primary");
  const [showEditAddressButton, setShowEditAddressButton] = useState(false);

  useEffect(() => {
    setShowEditAddressButton(isAdmin(loggedInAccount));
  }, [loggedInAccount]);

  useEffect(() => {
    if (showEditAddressButton) {
      setColor(getEditAddressButtonColor(loggedInAccount, isBilling));
    }
  }, [loggedInAccount, isBilling, showEditAddressButton]);

  return showEditAddressButton ? (
    <Button data-testid="editAccountBtn" variant={"contained"} size={"large"} color={color} onClick={() => handleOpenEditDialog(isBilling)}>
      EDIT
    </Button>
  ) : null;
};
export default EditAccountAddressButton;

export function getEditAddressButtonColor(mspAccountLoggedIn: IAccount, isBilling: boolean): string {
  if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    return "primary";
  } else {
    if (isBilling) {
      return "secondary";
    } else {
      return "primary";
    }
  }
}
