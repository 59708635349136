import React from "react";

interface IDeleteAccountProductNameCellProps {
  name: string;
  productName: string;
}

const DeleteAccountProductNameCell: React.FC<IDeleteAccountProductNameCellProps> = ({ name, productName }) => {
  return (
    <td>
      <div>{name}</div>
      <div style={{ fontWeight: "lighter", fontSize: "10px" }}>{productName}</div>
    </td>
  );
};

export default DeleteAccountProductNameCell;
