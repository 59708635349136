import { CompanyNameValidationRule, ConnectWiseURLValidationRule, ValidCharactersValidationRule } from "../../../fieldsValidationRules";
import IIntegrationSetup from "../../../models/Integrations/IIntegrationSetup";
import { IFieldValidator } from "../../Accounts/AddEditAccount/addressValidator";

export const validateIntegrationSetup = (integrationSetup: IIntegrationSetup): IFieldValidator[] => {
  let errorFields: IFieldValidator[] = [];

  if (!CompanyNameValidationRule.RegularExpression.test(integrationSetup.company)) {
    errorFields.push({ name: "company", index: 0, error: "Invalid  Company" });
  }

  if (!ConnectWiseURLValidationRule.RegularExpression.test(integrationSetup.url)) {
    errorFields.push({ name: "url", index: 1, error: "Invalid URL " });
  }

  if (!ValidCharactersValidationRule.RegularExpression.test(integrationSetup.publicKey)) {
    errorFields.push({ name: "integrationUsername", index: 1, error: "Invalid  Public key" });
  }

  if (!ValidCharactersValidationRule.RegularExpression.test(integrationSetup.managementSolution)) {
    errorFields.push({ name: "managementSolution", index: 1, error: "Invalid  Management Solution" });
  }

  return errorFields;
};
