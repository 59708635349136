import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@cuda-networks/bds-core";
import { useDispatch } from "react-redux";
import { addApplicationAction, IApiCredentialApplication } from "../../actions/apiCredentialActions";
import { EmailValidationRule } from "../../fieldsValidationRules";
import TextboxLabel from "../TextboxLabel";
import { setSnackBarMessage } from "../../actions/generalActions";
import ActionMessageType from "../../models/ActionMessageType";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";

interface IApiCredentialsPanelDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, email: string, application: string) => void;
}

export const validation = () => {
  const validateEnableAddButton = (newName: string, newEmail: string, newApplication: string): boolean => {
    let hasErrors = false;
    if (newName.length < 1) {
      hasErrors = true;
    }
    if (newApplication.length < 1) {
      hasErrors = true;
    }
    if (newEmail.length < 1) {
      hasErrors = true;
    }
    return !hasErrors;
  };

  const validateNameInput = (input: string, setErrorCallback: (error: string) => void): boolean => {
    if (!validateInputField(input)) {
      setErrorCallback("Name contains invalid characters");
      return false;
    }
    return true;
  };

  const validateApplicationInput = (input: string, setErrorCallback: (error: string) => void): boolean => {
    if (!validateInputField(input)) {
      setErrorCallback("Application contains invalid characters");
      return false;
    }
    return true;
  };

  const validateEmailInput = (newEmail: string, setEmailErrorCallback: (error: string) => void): boolean => {
    let hasErrors = false;
    if (newEmail.length < 1) {
      setEmailErrorCallback("Enter email");
      hasErrors = true;
    } else {
      if (!EmailValidationRule.TildeRegExpression.test(newEmail)) {
        setEmailErrorCallback("Invalid email");
        hasErrors = true;
      } else {
        if (!EmailValidationRule.RegularExpression.test(newEmail)) {
          setEmailErrorCallback("Invalid email");
          hasErrors = true;
        }
      }
    }

    return !hasErrors;
  };

  function validateInputField(input: string) {
    // allows any char except unencoded < and >.
    if (input === null || typeof input === "undefined") {
      return false;
    }
    if (input.indexOf("<") !== -1 || input.indexOf(">") !== -1) {
      return false;
    }
    return true;
  }

  return { validateEnableAddButton, validateEmailInput, validateNameInput, validateApplicationInput };
};

const ApiCredentialsPanelDialog = ({ open, onClose, onSave }: IApiCredentialsPanelDialogProps) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [application, setApplication] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [applicationError, setApplicationError] = useState("");
  const [isButtonAddEnable, setButtonAddEnable] = useState(false);
  const [isActionInProgress, setActionInProgress] = useState(false);

  const addApplication = (newApplication: IApiCredentialApplication) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(addApplicationAction(newApplication));
      resolve(success);
    });
  const { validateEnableAddButton, validateEmailInput, validateNameInput, validateApplicationInput } = validation();

  const handleAdd = () => {
    let hasErrors = false;
    if (!validateNameInput(name, setNameError)) {
      hasErrors = true;
    }
    if (!validateApplicationInput(application, setApplicationError)) {
      hasErrors = true;
    }
    if (!validateEmailInput(email, setEmailError)) {
      hasErrors = true;
    }

    if (hasErrors) return false; //check all the fields before returning validation results.

    const newApplication: IApiCredentialApplication = {
      name: name,
      application: application,
      email: email,
    };

    setActionInProgress(true);
    addApplication(newApplication).then(success => {
      if (success) {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddApiCredentials].successMessage, type: ActionMessageType.Success }));
        onSave(name, email, application);
        onClose();
        resetFields();
      }
      setActionInProgress(false);
    });
    return true;
  };

  const resetFields = () => {
    setName("");
    setNameError("");
    setEmail("");
    setEmailError("");
    setApplication("");
    setApplicationError("");
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  const handleNameChange = (event: any) => {
    setNameError("");
    setName(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleApplicationChange = (event: any) => {
    setApplicationError("");
    setApplication(event.target.value);
  };

  useEffect(() => {
    if (validateEnableAddButton(name, email, application)) {
      setButtonAddEnable(true);
    } else {
      setButtonAddEnable(false);
    }
  }, [name, application, email, validateEnableAddButton]);

  return (
    <Dialog disableRestoreFocus disableBackdropClick disableEscapeKeyDown={isActionInProgress} open={open} onClose={onClose} data-testid="AddCredentialDialog">
      <DialogTitle>
        <Typography data-testid="AddCredentialDialogTitle" variant="h5" gutterBottom noWrap style={{ marginTop: "15px", fontWeight: 700 }}>
          Add API Credentials
        </Typography>
      </DialogTitle>
      <DialogContent style={{ height: "260px" }}>
        <div>
          <TextField
            inputProps={{
              maxLength: 64,
            }}
            data-testid={"AddCredentialDialogMspTextEmail"}
            disabled={isActionInProgress}
            label={<TextboxLabel label={"EMAIL"} dataTestId={"mspLabelEmail"} />}
            style={{ width: "503px", marginBottom: "15px" }}
            placeholder="email@server.com"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            error={emailError.length > 0}
            helperText={emailError}
          ></TextField>
        </div>

        <div>
          <TextField
            inputProps={{
              maxLength: 64,
            }}
            data-testid={"AddCredentialDialogMspTextName"}
            disabled={isActionInProgress}
            label={<TextboxLabel label={"NAME"} dataTestId={"mspLabelName"} maxNoOfCharacters={64} />}
            style={{ width: "503px", marginBottom: "15px" }}
            placeholder="name"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            error={nameError.length > 0}
            helperText={nameError}
          ></TextField>
        </div>
        <div>
          <TextField
            inputProps={{
              maxLength: 64,
            }}
            data-testid={"AddCredentialDialogMspTextApplication"}
            disabled={isActionInProgress}
            label={<TextboxLabel label={"APPLICATION"} dataTestId={"mspLabelApplication"} maxNoOfCharacters={64} />}
            style={{ width: "503px", marginBottom: "1px" }}
            placeholder="applicationXYZ"
            variant="outlined"
            value={application}
            onChange={handleApplicationChange}
            error={applicationError.length > 0}
            helperText={applicationError}
          ></TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button data-testid="AddCredentialDialogMspButtonCancel" onClick={handleClose} disabled={isActionInProgress} variant="text">
          Cancel
        </Button>
        <Button data-testid="AddCredentialDialogMspButtonAdd" onClick={handleAdd} loadingPosition="start" isLoading={isActionInProgress} disabled={isActionInProgress || !isButtonAddEnable} color="primary">
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiCredentialsPanelDialog;
