import React from "react";
import { Button } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MspType from "../../../models/MspType";

const options = ["ADD ACCOUNT", "ADD SUBPARTNER"];

interface IAddAccountButtonProps {
  onClick: () => void;
  onSelectedMspType: (mspType: MspType) => void;
  buttonColor: string;
}

const AddAccountSplitButton: React.FC<IAddAccountButtonProps> = ({ onClick, onSelectedMspType, buttonColor }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    onSelectedMspType(selectedIndex === 0 ? MspType.Customer : MspType.Subpartner);
    onClick();
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
    onSelectedMspType(index === 0 ? MspType.Customer : MspType.Subpartner);
    onClick();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color={buttonColor as any} ref={anchorRef} aria-label="split button">
          <Button color={buttonColor} size="large" onClick={handleClick}>
            {options[selectedIndex]}
          </Button>
          <Button data-testid="addAccountGroupBtn" color={buttonColor} size="large" aria-controls={open ? "split-button-menu" : undefined} aria-expanded={open ? "true" : undefined} aria-label="select merge strategy" aria-haspopup="menu" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem key={option} data-testid={"addAccountGroupBtn" + index} disabled={index === 2} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default AddAccountSplitButton;
