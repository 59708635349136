import React, { useEffect, useState } from "react";
import { Grid, Typography, Tooltip } from "@cuda-networks/bds-core";
import IAccount from "../models/IAccount";
import DetailsTabs from "../models/DetailsTabs";
import MspType from "../models/MspType";
import { getParentName } from "../Utilities/accountsHelper";
import { IAppState } from "../store/store";
import { useSelector } from "react-redux";

interface ITabTitleProps {
  tabType: DetailsTabs | undefined;
  item: IAccount | undefined;
  overwriteItem?: IAccount;
}

const TabTitle: React.FC<ITabTitleProps> = ({ item, tabType, overwriteItem }) => {
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [parentName, setParentName] = useState("");
  const [showSubTitle, setShowSubTitle] = useState(false);

  useEffect(() => {
    if (item !== undefined) {
      if (overwriteItem !== undefined) {
        setup(overwriteItem);
      } else {
        setup(item);
      }
    }

    function setup(acc: IAccount) {
      if (acc.type === MspType.Customer) {
        setParentName(getParentName(mspAccounts, accountsNames, acc));
        setShowSubTitle(true);
      } else {
        setShowSubTitle(false);
      }
      setName(acc.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, overwriteItem]);

  useEffect(() => {
    setTitle(getTitleForTab(tabType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabType]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} style={{ paddingBottom: "10px" }}>
        <Typography data-testid={"mspTabTitle"} variant="h5" noWrap style={{ display: "flex" }}>
          {title}&nbsp;{" "}
          <Tooltip title={name}>
            <span className="noWrap">{name}</span>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid container spacing={1} style={{ paddingBottom: "10px" }}>
        {showSubTitle && (
          <Typography data-testid={"mspTabSubtitle"} variant="h6" noWrap style={{ display: "flex" }}>
            Account of&nbsp;{" "}
            <Tooltip title={parentName}>
              <span className="noWrap">{parentName}</span>
            </Tooltip>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TabTitle;

export enum TabTitles {
  AccountDetailsTitle = "Details for",
  FinanceTitle = "Billing for",
  ProductTitle = "Products & Services List for",
  UsersTitle = "Roles & Access List for",
  AuditUsersTitle = "User Billing List for",
  ApiCredentialsTitle = "API Credentials for",
}

export function getTitleForTab(tabType: DetailsTabs | undefined): string {
  switch (tabType) {
    case DetailsTabs.AccountsDetails:
      return TabTitles.AccountDetailsTitle;
    case DetailsTabs.Finance:
      return TabTitles.FinanceTitle;
    case DetailsTabs.Products:
      return TabTitles.ProductTitle;
    case DetailsTabs.Users:
      return TabTitles.UsersTitle;
    case DetailsTabs.ApiCredentials:
      return TabTitles.ApiCredentialsTitle;
    default:
      return TabTitles.AuditUsersTitle;
  }
}
