import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@cuda-networks/bds-core";
import ProductsTable from "./ProductsTable";
import IProductFamily from "../../models/Products/IProductFamily";
import ProductFamily, { getProductTypeNameToDisplay } from "../../models/Products/ProductFamily";
import IProduct from "../../models/Products/IProduct";
import BBSProductsTable from "./BBS/BBSProductsTable";
import ProductStatusIcon from "./ProductStatusIcon";
import IntronisBackup from "./IntronisBackup";

interface IProductAccordion {
  tableKey: any;
  productFamily?: IProductFamily;
  expanded: boolean;
  handleExpandProduct: (productFamily: string, isExpanded: boolean) => void;
  handleOpenEditDialog: (e: IProduct, selectedAccountType: string) => void;
  hasIBU?: boolean;
}

const ProductAccordion: React.FC<IProductAccordion> = ({ tableKey, productFamily, expanded, handleExpandProduct, handleOpenEditDialog, hasIBU }) => {
  const handleChange = () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (hasIBU) {
      handleExpandProduct(ProductFamily.INTRONIS_BACKUP, isExpanded);
    } else {
      handleExpandProduct(productFamily!.productType, isExpanded);
    }
  };
  return (
    <div style={{ paddingBottom: "10px" }}>
      <Accordion expanded={expanded} onChange={handleChange()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div style={{ display: "inline-flex" }}>
            <Typography display="inline" style={{ fontWeight: "bold", paddingTop: "2px" }}>
              {getProductTypeNameToDisplay(hasIBU ? ProductFamily.INTRONIS_BACKUP : productFamily!.productType)}
            </Typography>
            {!hasIBU && (
              <div className="ProductFamilyErrorCount" style={{ display: "inline", paddingLeft: "9px" }}>
                <ProductStatusIcon hasPendingSerials={productFamily!.hasPendingSerials ? productFamily!.hasPendingSerials : false} hasSerials={true} noOfErrors={productFamily!.errors ? productFamily!.errors : 0} showActivatedIcon={false} showAvailableIcon={false}></ProductStatusIcon>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>{expanded && <>{hasIBU ? <IntronisBackup /> : <>{productFamily!.productType === ProductFamily.BACKUP_APPLIANCES ? <BBSProductsTable key={tableKey} productFamily={productFamily!} openEditDialog={handleOpenEditDialog} /> : <ProductsTable key={tableKey} productFamily={productFamily!} openEditDialog={handleOpenEditDialog} />}</>}</>}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProductAccordion;
