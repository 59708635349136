import axios, { CancelTokenSource } from "axios";
import { Dispatch } from "redux";
import { IAppState } from "../store/store";
import { AccountActionTypes } from "./accountActions";
import { AuditUsersActionsTypes } from "./auditUsersActions";
import { GeneralActionTypes } from "./generalActions";
import { IntegrationsActionTypes } from "./integrations/integrationsActions";
import { ProductActionTypes } from "./productActions";

export function cancelGeneralActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().generalState.cancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    cancellationTokenSource: newCancelTokenSource,
    type: GeneralActionTypes.SET_CANCEL_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelCurrentGetAddressActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.loadAddressCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadAddressCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_ADDRESS_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadPartnerAllChildrenNamesActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.allchildrenNamesCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    allchildrenNamesCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_LOAD_ALL_CHILDREN_NAMES_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadPartnerWithChildrenActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.loadCustomerParentAndItsChildrenCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadCustomerParentAndItsChildrenCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_LOAD_CUSTOMER_PARENT_AND_ITS_CHILDREN_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadPartnerChildrenActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.childrenCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    childrenCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_LOAD_CHILDREN_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadSubPartnerCustomerTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.loadSubpartnerCustomersCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadSubpartnerCustomersCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_LOAD_SUBPARTNER_CUSTOMERS_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadAuditUsersActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().auditUsersState.loadAuditUsersCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadAuditUsersCancellationTokenSource: newCancelTokenSource,
    type: AuditUsersActionsTypes.SET_CANCEL_LOAD_AUDIT_USERS_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadParentProductsActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().productState.loadParentProductsCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadParentProductsCancellationTokenSource: newCancelTokenSource,
    type: ProductActionTypes.SET_PARENT_PRODUCTS_CANCEL_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadIntegrationExtraInfoActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().integrationsState.loadIntegrationExtraInfoCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadIntegrationExtraInfoCancellationTokenSource: newCancelTokenSource,
    type: IntegrationsActionTypes.SET_CANCEL_LOAD_INTEGRATION_EXTRA_INFO_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadCurrencyActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().productState.currencyCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    currencyCancellationTokenSource: newCancelTokenSource,
    type: ProductActionTypes.SET_CANCEL_LOAD_CURRENCY_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadSupartnersWithAssignedProductsActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().productState.loadSubpartnersWithAssignedProductsCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    loadSubpartnersWithAssignedProductsCancellationTokenSource: newCancelTokenSource,
    type: ProductActionTypes.SET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS_CANCEL_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadSupartnersWithAssignedProductsActionToken(getState: () => IAppState, dispatch: Dispatch) {
  const cancelTokenSource = getState().productState.loadSubpartnersWithAssignedProductsCancellationTokenSource;
  cancelCurrent(cancelTokenSource);
  dispatch({
    loadSubpartnersWithAssignedProductsCancellationTokenSource: undefined,
    type: ProductActionTypes.SET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS_CANCEL_TOKEN,
  });
}

export function cancelFilterAccountsActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.filterAccountsCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    filterAccountsCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_FILTER_ACCOUNTS_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelFilterAccountsActionToken(getState: () => IAppState, dispatch: Dispatch) {
  const cancelTokenSource = getState().accountState.filterAccountsCancellationTokenSource;
  cancelCurrent(cancelTokenSource);
  dispatch({
    filterAccountsCancellationTokenSource: undefined,
    type: AccountActionTypes.SET_CANCEL_FILTER_ACCOUNTS_TOKEN,
  });
}

export function cancelLoadAccountInfoActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.accountInfoCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    accountInfoCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_LOAD_ACCOUNT_INFO_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadAllSerialsActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().productState.getAllSerialsCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    getAllSerialsCancellationTokenSource: newCancelTokenSource,
    type: ProductActionTypes.SET_CANCEL_GET_ALL_SERIALS_TOKEN,
  });
  return newCancelTokenSource;
}

export function cancelLoadEchoUsernameActionTokenAndCreateNew(getState: () => IAppState, dispatch: Dispatch): CancelTokenSource {
  const cancelTokenSource = getState().accountState.echoUsernameCancellationTokenSource;
  const newCancelTokenSource = cancelCurrentAndCreateNew(cancelTokenSource);
  dispatch({
    echoUsernameCancellationTokenSource: newCancelTokenSource,
    type: AccountActionTypes.SET_CANCEL_LOAD_ECHOUSERNAME_TOKEN,
  });
  return newCancelTokenSource;
}

function cancelCurrentAndCreateNew(cancelTokenSource: CancelTokenSource | undefined): CancelTokenSource {
  cancelCurrent(cancelTokenSource);
  return axios.CancelToken.source();
}

export function cancelCurrent(cancelTokenSource: CancelTokenSource | undefined) {
  if (typeof cancelTokenSource != typeof undefined) {
    cancelTokenSource?.cancel("Operation canceled due to new request.");
  }
}
