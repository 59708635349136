enum ConnectWiseFeatures {
  Accounts = 45,
  Tickets = 46,
  Billing = 47,
}

export interface ConnectWiseFeaturesValue {
  value: number;
  label: string;
}

export const ConnectWiseFeaturesValues: ConnectWiseFeaturesValue[] = [
  { value: ConnectWiseFeatures.Accounts, label: "Accounts" },
  { value: ConnectWiseFeatures.Tickets, label: "Tickets" },
  { value: ConnectWiseFeatures.Billing, label: "Billing" },
];

export default ConnectWiseFeatures;
