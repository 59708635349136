import { findAccountById } from "../Utilities/accountsHelper";
import IAccount from "../models/IAccount";
import { IUserRoleType, getRoleFromUserAccount } from "../Utilities/usersHelper";
import UserRole from "../models/UserRole";
import MspType from "../models/MspType";
import { PageRecords, PageTitles } from "./useDocumentTitle";

export function validatePath(accountId: string | undefined, location: any, mspAccountLoggedIn: IAccount, mspAccounts: IAccount[], isBaLoggedIn: boolean, accountsNames: IAccount[]): boolean {
  if (accountId !== undefined) {
    let validId = true;
    let validPath = true;

    const accountIdNo: number = Number(accountId);
    if (mspAccountLoggedIn.type === MspType.Customer) {
      if (mspAccountLoggedIn.id !== accountIdNo) {
        validId = false;
      } else {
        validPath = validatePathForAccount(location.pathname, mspAccountLoggedIn, mspAccountLoggedIn);
      }
    } else {
      const foundAccount = findAccountById(isBaLoggedIn ? [mspAccountLoggedIn, ...mspAccounts] : mspAccounts, accountsNames, accountIdNo);
      if (foundAccount === undefined) {
        validId = false;
      } else {
        validPath = validatePathForAccount(location.pathname, mspAccountLoggedIn, foundAccount);
      }
    }

    return validId && validPath;
  }
  return true;
}

function validatePathForAccount(pathname: string, mspAccountLoggedIn: IAccount, account: IAccount | undefined): boolean {
  pathname = pathname.toLowerCase().lastIndexOf("/") === pathname.length - 1 ? pathname.substring(0, pathname.length - 1) : pathname;
  if (account === undefined) {
    return true;
  }
  const validPaths: PageTitles[] = getValidPaths(mspAccountLoggedIn, account);
  return validPaths.some((element: PageTitles) => pathname === `/accounts/${account.id}/${PageRecords[element].route}`);
}

export function getValidPaths(mspAccountLoggedIn: IAccount, account: IAccount) {
  let validPaths: PageTitles[] = [];
  const userRole = getRoleFromUserAccount(mspAccountLoggedIn);
  switch (mspAccountLoggedIn.type) {
    case MspType.Customer:
      validPaths = validatePathWhenLoginIsCustomer(userRole);
      break;
    case MspType.Partner:
      validPaths = validatePathWhenLoginIsPartner(userRole, account);
      break;
    case MspType.Subpartner:
      validPaths = validatePathWhenLoginIsSubpartner(userRole, account);
      break;
    case MspType.BillingAggregator:
      validPaths = validatePathWhenLoginIsBillingAggregator(userRole, account);
      break;
  }
  return validPaths;
}

function validatePathWhenLoginIsCustomer(userRole: IUserRoleType) {
  if (userRole === UserRole.ReadOnly) {
    return [PageTitles.ProductsAndServices, PageTitles.Details];
  }
  return [];
}

function validatePathWhenLoginIsPartner(userRole: IUserRoleType, account: IAccount) {
  switch (userRole) {
    case UserRole.Admin:
      switch (account.type) {
        case MspType.Partner:
          return [PageTitles.ProductsAndServices, PageTitles.RolesAndAccess, PageTitles.Details, PageTitles.Billing, PageTitles.ApiCredentials, PageTitles.PartnerResources, PageTitles.Integrations, PageTitles.ConnectWiseAccounts, PageTitles.ConnectWiseDetails, PageTitles.ConnectWiseBilling, PageTitles.ConnectWiseTickets, PageTitles.ConnectWiseLogs];
        case MspType.Subpartner:
          return [PageTitles.ProductsAndServices, PageTitles.RolesAndAccess, PageTitles.Details];
        case MspType.Customer:
          return [PageTitles.ProductsAndServices, PageTitles.RolesAndAccess, PageTitles.Details, PageTitles.UserBilling];
      }
      break;
    case UserRole.Finance:
      switch (account.type) {
        case MspType.Partner:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.Billing, PageTitles.PartnerResources];
        case MspType.Subpartner:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
        case MspType.Customer:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
      }
      break;
    case UserRole.Tech:
    case UserRole.TechDoNotDelete:
      switch (account.type) {
        case MspType.Partner:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.PartnerResources, PageTitles.Integrations, PageTitles.ConnectWiseAccounts, PageTitles.ConnectWiseDetails, PageTitles.ConnectWiseBilling, PageTitles.ConnectWiseTickets, PageTitles.ConnectWiseLogs];
        case MspType.Subpartner:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
        case MspType.Customer:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.UserBilling];
      }
      break;
  }

  return [];
}

function validatePathWhenLoginIsSubpartner(userRole: IUserRoleType, account: IAccount) {
  switch (userRole) {
    case UserRole.Admin:
      switch (account.type) {
        case MspType.Subpartner:
          return [PageTitles.ProductsAndServices, PageTitles.RolesAndAccess, PageTitles.Details, PageTitles.ApiCredentials, PageTitles.Integrations, PageTitles.ConnectWiseAccounts, PageTitles.ConnectWiseDetails, PageTitles.ConnectWiseBilling, PageTitles.ConnectWiseTickets, PageTitles.ConnectWiseLogs];
        case MspType.Customer:
          return [PageTitles.ProductsAndServices, PageTitles.RolesAndAccess, PageTitles.Details, PageTitles.UserBilling];
      }
      break;
    case UserRole.Finance:
      switch (account.type) {
        case MspType.Subpartner:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.PartnerResources];
        case MspType.Customer:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
      }
      break;
    case UserRole.Tech:
    case UserRole.TechDoNotDelete:
      switch (account.type) {
        case MspType.Subpartner:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.PartnerResources, PageTitles.Integrations, PageTitles.ConnectWiseAccounts, PageTitles.ConnectWiseDetails, PageTitles.ConnectWiseBilling, PageTitles.ConnectWiseTickets, PageTitles.ConnectWiseLogs];
        case MspType.Customer:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.UserBilling];
      }

      break;
  }

  return [];
}

function validatePathWhenLoginIsBillingAggregator(userRole: IUserRoleType, account: IAccount) {
  switch (userRole) {
    case UserRole.BillingAggregatorAdmin:
      switch (account.type) {
        case MspType.BillingAggregator:
          return [PageTitles.ProductsAndServices, PageTitles.RolesAndAccess, PageTitles.Details, PageTitles.Billing];
        case MspType.Partner:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
      }
      break;
    case UserRole.BillingAggregatorFinance:
      switch (account.type) {
        case MspType.BillingAggregator:
          return [PageTitles.ProductsAndServices, PageTitles.Details, PageTitles.Billing];
        case MspType.Partner:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
      }
      break;
    case UserRole.BillingAggregatorTech:
      switch (account.type) {
        case MspType.BillingAggregator:
          return [PageTitles.Details];
        case MspType.Partner:
          return [PageTitles.ProductsAndServices, PageTitles.Details];
      }
      break;
  }

  return [];
}
