import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import IAccount from "../models/IAccount";

export enum PageTitles {
  Home = "Home",
  NewUser = "NewUser",
  Welcome = "Welcome",
  ServicesUnavailable = "ServicesUnavailable",
  ProductsAndServices = "ProductsAndServices",
  RolesAndAccess = "RolesAndAccess",
  Details = "Details",
  PartnerResources = "PartnerResources",
  Billing = "Billing",
  ApiCredentials = "ApiCredentials",
  UserBilling = "UserBilling",
  Integrations = "Integrations",
  ConnectWiseAccounts = "ConnectWiseAccounts",
  ConnectWiseBilling = "ConnectWiseBilling",
  ConnectWiseLogs = "ConnectWiseLogs",
  ConnectWiseDetails = "ConnectWiseDetails",
  ConnectWiseTickets = "ConnectWiseTickets",
}

export const PageRecords: Record<PageTitles, { title: string; route: string }> = {
  Home: { title: "", route: "" },
  NewUser: { title: "New User", route: "new-user" },
  Welcome: { title: "Welcome", route: "welcome" },
  ServicesUnavailable: { title: "Services Unavailable", route: "services-unavailable" },
  ProductsAndServices: { title: "Products & Services", route: "products-services" },
  RolesAndAccess: { title: "Roles & Access", route: "roles-access" },
  Details: { title: "Details", route: "details" },
  PartnerResources: { title: "Partner Resources - Details", route: "details/partner-resources" },
  Billing: { title: "Billing", route: "billing" },
  ApiCredentials: { title: "API Credentials", route: "api-credentials" },
  UserBilling: { title: "User Billing - Products & Services", route: "products-services/user-billing" },
  Integrations: { title: "Integrations", route: "integrations" },
  ConnectWiseAccounts: { title: "Accounts - ConnectWise", route: "integrations/connectwise/accounts" },
  ConnectWiseBilling: { title: "Billing - ConnectWise", route: "integrations/connectwise/billing" },
  ConnectWiseLogs: { title: "Logs - ConnectWise", route: "integrations/connectwise/logs" },
  ConnectWiseDetails: { title: "Details - ConnectWise", route: "integrations/connectwise/details" },
  ConnectWiseTickets: { title: "Tickets - ConnectWise", route: "integrations/connectwise/tickets" },
};

export function changeDocumentTitle(selectedAccountName: string | undefined, accountId: string | undefined, route: string | undefined, routeName: string, location: any, appName: string) {
  if (selectedAccountName === undefined) {
    document.title = routeName !== undefined && routeName.length > 0 ? `${routeName} - ${appName}` : appName;
    return;
  }

  if (route === undefined) {
    return;
  }

  const targetPath = `/accounts/${accountId}/${route}`;
  if (location.pathname === targetPath) {
    document.title = `${routeName} - ${selectedAccountName} - ${appName}`;
  }
}
const useDocumentTitle = (selectedAccount: IAccount | undefined, pageKey: PageTitles) => {
  const location = useLocation();
  const appName = "MSP App";
  const selectedAccountName = selectedAccount?.name;
  const accountId = selectedAccount?.id.toString();

  useEffect(() => {
    changeDocumentTitle(selectedAccountName, accountId, PageRecords[pageKey].route, PageRecords[pageKey].title, location, appName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountName, location, accountId]);
};

export default useDocumentTitle;
