import React from "react";

const ConnectWiseLogoSvgComponent = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className="connectWiseManageLogo centerVertically" style={{ width: 25, height: 23 }}>
      <path d="M1891.19,147.73c-11-34.9-40.22-32.5-71.25-25.9-131,27.89-252.2,78.68-359.79,158.11-37.43,27.63-65.38,29.3-108.11,8.87C1092.83,164.9,830.87,164.16,571.3,287.63c-45.42,21.6-75.29,18.83-115.36-10.51C345.69,196.38,221.16,145.4,74.36,117c-15.43-1.57-41.28,2.94-47.38,35-8.66,45.4,29,51.61,58.61,58.47,343.48,79.37,553.4,308.85,694.86,616,13.9,30.18,16,72.23,58.24,78,43.18,5.93,57.56-33.68,74.37-61.64,30.61-50.91,51.7-65.51,91.86-4.17,64.44,98.42,90,93.59,138.56-15.23,138.43-310.42,352.17-535,695.26-613.54C1869.93,202.72,1905,191.33,1891.19,147.73ZM1073.47,745.57c-21.48,6.11-27.12-8.87-34.89-20.07C959.09,611,959.15,611,878.65,729.36c-4.78,7-10.6,13.34-23.61,29.52C786.57,627,713.69,510.91,615.75,413.23c-29.73-29.65-14.49-41.88,13.3-54,234.37-102.23,465.45-96.91,703.72,20.9C1112.39,625.45,1104.86,649.29,1073.47,745.57Z" />
      <path d="M78.45,935.12C115,735,210,562.36,365,422.18c34.65-31.33,50.51-.21,71.79,14.75,31.73,22.3,8.93,37.7-8.17,53.12Q222.83,675.63,173.1,948.28c-5.4,29.82-18.09,56.31-54.14,56.34C91.88,1004.63,77,979.29,78.45,935.12Z" />
      <path d="M1841.75,943.08c3.55,29.42-4.25,54-35.5,59.67-36.39,6.58-50.33-17.84-57-49.41-26.32-125.19-68.85-243.86-149.83-344.86-34-42.48-73.29-80.83-110.77-120.51-13.45-14.24-36.75-27.44-8.85-46.36,20.47-13.89,35.26-54.65,70.51-23.28C1708.66,559.25,1806.3,734,1841.75,943.08Z" />
    </svg>
  );
};

export default ConnectWiseLogoSvgComponent;
