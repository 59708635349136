import { State } from "@progress/kendo-data-query";
import { Reducer } from "redux";
import { FinanceActions, FinanceActionsTypes } from "../actions/financeActions";
import IAccountsExcludedUser from "../models/Invoices/IAccountsExclusionsUser";
import IAccountsWithExclusions from "../models/Invoices/IAccountsWithExclusions";
import IApiCreditCard from "../models/Invoices/IApiCreditCard";
import IExistingCreditCard from "../models/Invoices/IExistingCreditCard";
import IInvoice from "../models/Invoices/IInvoice";
import { PaymentMethod } from "../models/Invoices/PaymentMethod";

export interface IFinanceState {
  paymentMethod: PaymentMethod;
  loadingPaymentMethod: boolean;
  invoicesToDisplay: IInvoice[];
  invoicesTableState: State;
  currency: string;
  loadingInvoices: boolean;
  loadingInvoicesCanceled: boolean;
  accountsWithExclusions: IAccountsWithExclusions[];
  loadingAccountsWithExclusions: boolean;
  loadingAccountsWithExclusionsCanceled: boolean;
  accountsWithExclusionsTableState: State;
  accountExcludedUsers: IAccountsExcludedUser[];
  loadingAccountsExcludedUsers: boolean;
  loadingAccountsExcludedUsersCanceled: boolean;
  creditCards: IApiCreditCard[];
  creditCardsToDisplay: IExistingCreditCard[];
  loadingCreditCards: boolean;
  loadingCreditCardsCanceled: boolean;
  selectedCreditCard?: IExistingCreditCard;
}

const initialProductState: IFinanceState = {
  paymentMethod: PaymentMethod.ByInvoice,
  loadingPaymentMethod: false,
  invoicesToDisplay: [],
  invoicesTableState: { sort: [{ field: "date", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  currency: "",
  loadingInvoices: false,
  loadingInvoicesCanceled: false,
  accountsWithExclusions: [],
  loadingAccountsWithExclusions: false,
  loadingAccountsWithExclusionsCanceled: false,
  accountsWithExclusionsTableState: { sort: [{ field: "accountName", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  accountExcludedUsers: [],
  loadingAccountsExcludedUsers: false,
  loadingAccountsExcludedUsersCanceled: false,
  creditCards: [],
  creditCardsToDisplay: [],
  loadingCreditCards: false,
  loadingCreditCardsCanceled: false,
  selectedCreditCard: undefined,
};

export const financeReducer: Reducer<IFinanceState, FinanceActions> = (state = initialProductState, action: FinanceActions) => {
  switch (action.type) {
    case FinanceActionsTypes.GET_PAYMENT_METHOD:
    case FinanceActionsTypes.SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        loadingPaymentMethod: action.loadingPaymentMethod,
      };
    }
    case FinanceActionsTypes.GET_INVOICES: {
      return {
        ...state,
        invoicesToDisplay: action.invoicesToDisplay,
        currency: action.currency,
        loadingInvoices: action.loadingInvoices,
      };
    }
    case FinanceActionsTypes.SET_INVOICES_TABLE_STATE: {
      return {
        ...state,
        invoicesTableState: action.invoicesTableState,
      };
    }
    case FinanceActionsTypes.SET_LOAD_INVOICES_CANCELED: {
      return {
        ...state,
        loadingInvoicesCanceled: action.loadingInvoicesCanceled,
      };
    }
    case FinanceActionsTypes.GET_ACCOUNTS_WITH_EXCLUSIONS: {
      return {
        ...state,
        loadingAccountsWithExclusions: action.loadingAccountsWithExclusions,
        accountsWithExclusions: action.accountsWithExclusions,
      };
    }
    case FinanceActionsTypes.SET_LOAD_ACCOUNTS_WITH_EXCLUSIONS_CANCELED: {
      return {
        ...state,
        loadingAccountsWithExclusionsCanceled: action.loadingAccountsWithExclusionsCanceled,
      };
    }
    case FinanceActionsTypes.SET_ACCOUNTS_WITH_EXCLUSIONS_TABLE_PROPS: {
      return {
        ...state,
        accountsWithExclusionsTableState: action.accountsWithExclusionsTableState,
      };
    }
    case FinanceActionsTypes.GET_ACCOUNTS_EXCLUDED_USERS: {
      return {
        ...state,
        accountExcludedUsers: action.accountExcludedUsers,
        loadingAccountsExcludedUsers: action.loadingAccountsExcludedUsers,
      };
    }
    case FinanceActionsTypes.SET_LOAD_ACCOUNTS_EXCLUDED_USERS_CANCELED: {
      return {
        ...state,
        loadingAccountsExcludedUsersCanceled: action.loadingAccountsExcludedUsersCanceled,
      };
    }
    case FinanceActionsTypes.SEND_EMAIL_INVOICE: {
      return {
        ...state,
      };
    }
    case FinanceActionsTypes.GET_CREDIT_CARDS: {
      return {
        ...state,
        creditCardsToDisplay: action.creditCardsToDisplay,
        loadingCreditCards: action.loadingCreditCards,
      };
    }
    case FinanceActionsTypes.SET_LOAD_CREDIT_CARDS_CANCELED: {
      return {
        ...state,
        loadingCreditCardsCanceled: action.loadingCreditCardsCanceled,
      };
    }
    case FinanceActionsTypes.SET_DEFAULT_CARD:
    case FinanceActionsTypes.SET_RECURRING_CARD:
    case FinanceActionsTypes.DELETE_CREDIT_CARD: {
      return {
        ...state,
        creditCardsToDisplay: action.creditCardsToDisplay,
      };
    }
    case FinanceActionsTypes.SET_SELECTED_CREDIT_CARD: {
      return {
        ...state,
        selectedCreditCard: action.selectedCreditCard,
      };
    }
    case FinanceActionsTypes.SET_LOADING_CREDIT_CARDS: {
      return {
        ...state,
        loadingCreditCards: action.loadingCreditCards,
      };
    }
    default:
      return state;
  }
};
