import React from "react";
import { useDispatch } from "react-redux";
import { cancelCurrentAction } from "../../actions/generalActions";
import { Button } from "@cuda-networks/bds-core";
import { Link } from "react-router-dom";

const PartnerResourcesButton = () => {
  const dispatch = useDispatch();

  const handleSetShowPartnerResources = (event: React.SyntheticEvent) => {
    dispatch(cancelCurrentAction());
  };

  // Only display for partners and sub-partners
  return (
    <div style={{ paddingTop: "4px" }}>
      <Button data-testid="partnerResourcesBtn" variant={"contained"} color={"secondary"} size={"medium"} onClick={(event: React.SyntheticEvent) => handleSetShowPartnerResources(event)} component={Link} to={"partner-resources"}>
        Partner Resources
      </Button>
    </div>
  );
};

export default PartnerResourcesButton;
