import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filterAccountsAction, resetFiltersAction, setSelectedAccountAction } from "../../../actions/accountActions";
import TextFieldWithConfirmation from "./TextFieldWithConfirmation";

interface IFilterBaAccountsByNameProps {
  disabled: boolean;
  placeHolder: string;
}

const FilterBaAccountsByName: React.FC<IFilterBaAccountsByNameProps> = ({ disabled, placeHolder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const accountName = queryParams.get("accountName");

  const [customerName, setCustomerName] = useState(accountName ?? "");

  const onHandleCancel = () => {
    setCustomerName("");
    dispatch(filterAccountsAction({ name: "" }));
    navigate("/");
  };

  const onHandleConfirm = (newValue: string) => {
    const trimmedValue = newValue.trim();
    setCustomerName(trimmedValue);

    if (trimmedValue.length > 0) {
      dispatch(setSelectedAccountAction(undefined));
      dispatch(filterAccountsAction({ name: trimmedValue }));
      navigate("/filters?accountName=" + trimmedValue);
    } else {
      dispatch(resetFiltersAction());
      navigate("/");
    }
  };

  return <TextFieldWithConfirmation dataTestId={"searchTxt"} initialValue={customerName} disabled={disabled} placeHolder={placeHolder} onConfirm={(newValue: string) => onHandleConfirm(newValue)} onCancel={() => onHandleCancel()} />;
};

export default FilterBaAccountsByName;
