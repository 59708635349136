import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { getInvoiceStatusLabel } from "../../Utilities/financeHelper";

const InvoiceStatus = (props: any) => {
  return (
    <td>
      <Grid container item>
        {getInvoiceStatusLabel(props.dataItem.status)}
      </Grid>
    </td>
  );
};

export default InvoiceStatus;
