/* eslint-disable no-unused-expressions */
/*!
 * Merchant e-Solutions Javascript Tokenization API
 * http://www.merchante-solutions.com
 *
 * V1.1 11/13/2013
 * Copyright 2012 Merchant e-Solutions
 */
export var Mes = {
  mod10: function (f) {
    var b, h, d, a, c, g;
    // eslint-disable-next-line no-sequences
    (d = !0), (a = 0), (h = (f + "").split("").reverse());
    for (c = 0, g = h.length; c < g; c++) {
      // eslint-disable-next-line no-sequences
      (b = h[c]), (b = parseInt(b, 10));
      if ((d = !d)) {
        b *= 2;
      }
      // eslint-disable-next-line no-sequences
      b > 9 && (b -= 9), (a += b);
    }
    return a % 10 === 0;
  },
  tokenize: function (d, c, a) {
    var b = Mes.getCORS(a);
    if (!b) {
      Mes.complete({ code: 1, text: "Unsupported Browser" }, a);
    } else {
      if (!Mes.valCc(d)) {
        Mes.complete({ code: 2, text: "Invalid Card Number" }, a);
      } else {
        if (!Mes.valExpiry(c)) {
          Mes.complete({ code: 3, text: "Invalid Expiry Date" }, a);
        } else {
          b.onerror = function () {
            Mes.complete({ code: 6, text: "Transmission Error" }, a);
          };
          b.onload = function () {
            if (typeof b.status != "undefined" && b.status !== 200) {
              Mes.complete({ code: 5, text: "Http code " + b.status + " recieved" }, a);
            } else {
              var e = Mes.parseJSON(b.responseText);
              // eslint-disable-next-line eqeqeq
              if (e.error_code != "000") {
                Mes.complete({ code: 4, text: "Gateway Error", gateway_text: e.auth_response_text, gateway_error: e.error_code }, a);
              } else {
                Mes.complete({ code: 0, text: "Success", token: e.transaction_id, card_number: e.card_number_truncated, hash: e.resp_hash }, a);
              }
            }
          };
          b.send("transaction_type=T&card_number=" + d + "&card_exp_date=" + c + "&resp_encoding=json&rctl_card_number_truncated=Y&rctl_resp_hash=Y");
        }
      }
    }
  },
  valCc: function (a) {
    // eslint-disable-next-line eqeqeq
    return Mes.mod10(a) && a.length != 0;
  },
  valExpiry: function (a) {
    // eslint-disable-next-line eqeqeq
    return a.length == 4;
  },
  parseJSON: function (json) {
    var result;
    if (typeof JSON !== "object") {
      // eslint-disable-next-line no-eval
      result = eval("(function(){return " + json + ";})()");
    } else {
      // eslint-disable-next-line no-undef
      result = (JSON && JSON.parse(json)) || $.parseJSON(json);
    }
    return result;
  },
  getCORS: function (a) {
    var d = null,
      b = "https://api.merchante-solutions.com/mes-api/tridentApi";
    if (typeof XMLHttpRequest != "undefined") {
      d = new XMLHttpRequest();
      if ("withCredentials" in d) {
        d.open("POST", b, true);
        d.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        d.setRequestHeader("x-requested-with", "XMLHttpRequest");
      } else {
        if (typeof XDomainRequest != "undefined") {
          // eslint-disable-next-line no-undef
          d = new XDomainRequest();
          d.onprogress = function () {};
          d.ontimeout = function () {};
          try {
            d.open("POST", b);
          } catch (c) {
            Mes.complete({ code: 7, text: c.message }, a);
            throw c;
          }
        } else {
          d = null;
        }
      }
    }
    return d;
  },
  complete: function (b, a) {
    return typeof a == "function" ? a(b) : void 0;
  },
};
