import React from "react";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { Grid, Typography } from "@cuda-networks/bds-core";
import { Tooltip } from "@material-ui/core";

const IntegrationAccountIconCell = (props: any) => {
  return (
    <td>
      <Tooltip title={props.dataItem.mspAccountName} placement="bottom-start">
        <Grid item container>
          <Grid container item xs={1} style={{ justifyContent: "flex-end", paddingRight: "11px" }}>
            <div style={{ position: "relative" }}>
              <CoreIcons.UserGroup className="center" />
            </div>
          </Grid>
          <Grid container item xs={11}>
            <Typography variant="body1" gutterBottom noWrap className={"centerVertically"}>
              {props.dataItem.mspAccountName}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    </td>
  );
};

export default IntegrationAccountIconCell;
