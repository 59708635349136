import { Reducer } from "redux";
import IAccount from "../models/IAccount";
import { LoginAccountAccessAction, LoginAccountAccessActionTypes } from "../actions/loginAccountAccessActions";
import { CheckedState } from "../models/CheckedState";

export interface ILoginAccountAccessState {
  isViewingMsp: boolean;
  accountAccessSelection: IAccount[];
  expandedAccountAccessMsp?: IAccount;
  accountsAccessItemsToDisplay: IAccount[];
  accountsAccessPageSize: number;
  accountsAccessPageNumber: number;
  selectAll: CheckedState;
  includeFutureAccounts: boolean;
}

const initialLoginAccountAccessState: ILoginAccountAccessState = {
  isViewingMsp: false,
  expandedAccountAccessMsp: undefined,
  accountsAccessItemsToDisplay: [],
  accountAccessSelection: [],
  accountsAccessPageSize: 5,
  accountsAccessPageNumber: 1,
  selectAll: CheckedState.Checked,
  includeFutureAccounts: true,
};

export const loginAccountAccessReducer: Reducer<ILoginAccountAccessState, LoginAccountAccessAction> = (state = initialLoginAccountAccessState, action: LoginAccountAccessAction) => {
  switch (action.type) {
    case LoginAccountAccessActionTypes.SET_ACCOUNTS_ACCESS_ITEM_TO_DISPLAY: {
      return {
        ...state,
        accountsAccessItemsToDisplay: action.accountsAccessItemsToDisplay,
      };
    }
    case LoginAccountAccessActionTypes.EXPAND_MSP: {
      return {
        ...state,
        expandedAccountAccessMsp: action.expandedAccountAccessMsp,
        accountsAccessItemsToDisplay: action.accountsAccessItemsToDisplay,
        isViewingMsp: action.isViewingMsp,
      };
    }
    case LoginAccountAccessActionTypes.SET_ACCOUNT_SELECTION: {
      return {
        ...state,
        accountAccessSelection: action.accountAccessSelection,
        accountsAccessItemsToDisplay: action.accountsAccessItemsToDisplay,
      };
    }
    case LoginAccountAccessActionTypes.UPDATE_ACCOUNTS_SELECTION: {
      return {
        ...state,
        accountAccessSelection: action.accountAccessSelection,
        accountsAccessItemsToDisplay: action.accountsAccessItemsToDisplay,
        expandedAccountAccessMsp: action.expandedAccountAccessMsp,
        selectAll: action.selectAll,
        includeFutureAccounts: action.includeFutureAccounts,
      };
    }
    case LoginAccountAccessActionTypes.SET_IS_VIEWING_MSP: {
      return {
        ...state,
        isViewingMsp: action.isViewingMsp,
      };
    }
    case LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_SIZE: {
      return {
        ...state,
        accountsAccessPageSize: action.accountsAccessPageSize,
      };
    }
    case LoginAccountAccessActionTypes.SET_ACCOUNT_ACCESS_PAGE_NUMBER: {
      return {
        ...state,
        accountsAccessPageNumber: action.accountsAccessPageNumber,
      };
    }
    case LoginAccountAccessActionTypes.CLEANUP_ACCOUNT_ACCESS_STATE: {
      return {
        ...state,
        expandedAccountAccessMsp: action.expandedAccountAccessMsp,
        accountAccessSelection: action.accountAccessSelection,
        accountsAccessItemsToDisplay: action.accountsAccessItemsToDisplay,
        accountsAccessPageSize: action.accountsAccessPageSize,
        accountsAccessPageNumber: action.accountsAccessPageNumber,
        selectAll: action.selectAll,
        includeFutureAccounts: action.includeFutureAccounts,
      };
    }
    default:
      return state;
  }
};
