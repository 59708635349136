import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";

const ProductLicenseName = (props: any) => {
  return (
    <td>
      <Tooltip title={props.dataItem.name} PopperProps={{ style: { zIndex: 4003 } }}>
        <Grid item container>
          <Grid container item xs={11}>
            <div className="textNoWrap">{props.dataItem.name}</div>
          </Grid>
        </Grid>
      </Tooltip>
    </td>
  );
};

export default ProductLicenseName;
