import IConnectWiseIntegrationInfo from "../../../../models/Integrations/IConnectWiseIntegrationInfo";

export function getShowUnlinkedButtonLabel(showUnlinkedAccounts: boolean, noOfUnlinkedAccounts: number): string {
  let label = "";
  if (showUnlinkedAccounts) {
    label = "SHOW ALL";
  } else {
    if (noOfUnlinkedAccounts > 0) {
      label = `UNLINKED ACCOUNTS (${noOfUnlinkedAccounts})`;
    }
  }
  return label;
}

export function getNumberOfUnlinkedAccounts(integrationUnlinkedAccountsCount: number, cwIntegrationInfo: IConnectWiseIntegrationInfo | undefined): number {
  if (integrationUnlinkedAccountsCount !== -1) {
    return integrationUnlinkedAccountsCount;
  } else if (cwIntegrationInfo !== undefined) {
    return cwIntegrationInfo.unlinkedAccounts;
  }
  return 0;
}

export function getShowViewAccountsFilterButton(showUnlinkedAccounts: boolean, noOfUnlinkedAccounts: number): boolean {
  return noOfUnlinkedAccounts > 0 || (noOfUnlinkedAccounts === 0 && showUnlinkedAccounts);
}
