import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@cuda-networks/bds-core";
import { enterKey, handleBackdropClick } from "../utility";

interface ISubmitDialogProps {
  message: string;
  onCloseMessage: () => void;
}
const ErrorMessage: React.FC<ISubmitDialogProps> = ({ message, onCloseMessage }) => {
  const [open, setOpen] = useState(message.length > 0);
  const onClose = (): any => {
    setOpen(false);
    onCloseMessage();
  };

  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onClose)} className="errorMessage" data-testid="errorMessageDialog" open={open} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onClose)}>
      <DialogTitle data-testid="deleteTitle"> {""} </DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        <div data-testid="errorMessage">{message}</div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button data-testid="closeErrorMessage" primary="true" size="large" onClick={onClose}>
            OK
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorMessage;
