import React from "react";
import { Typography } from "@cuda-networks/bds-core";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import ConfirmMessageDialog from "../ConfirmMessageDialog";

interface IApiCredentialsDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (applicationId: string | undefined) => void;
  info: {
    name: string;
    email: string;
    application: string;
    clientId: string | undefined;
  };
}

const ApiCredentialsDeleteDialog = ({ open, onClose, onConfirm, info }: IApiCredentialsDeleteDialogProps) => {
  const isPending = useSelector((state: IAppState) => state.apiCredentialApplicationState.pendingAction);

  const handleDelete = () => {
    onConfirm(info.clientId);
  };

  const handleClose = () => {
    if (!isPending) {
      onClose();
    }
  };

  const title = <Typography variant="h6">Delete Confirmation</Typography>;
  const message = (
    <>
      <Typography style={{ whiteSpace: "pre" }}>You are about to delete the following API Credentials:</Typography>
      <div style={{ marginBottom: "15px" }}></div>
      <Typography variant="body1">
        <span style={{ fontWeight: 600 }}>{info.email}</span>
      </Typography>
      <Typography variant="body1">{info.name}</Typography>
      <Typography variant="body1">Application: {info.application}</Typography>
      <div style={{ marginBottom: "15px" }}></div>
      <Typography variant="body1">Please confirm below to proceed.</Typography>
    </>
  );

  return <ConfirmMessageDialog isActionInProgress={isPending} message={message} title={title} onClose={handleClose} open={open} onConfirm={handleDelete} />;
};

export default ApiCredentialsDeleteDialog;
