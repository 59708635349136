import React from "react";

const BandwidthCell = (props: any) => {
  const stringValue = !props.item || props.item <= 0 ? "0 Mbps" : props.item * 50 + " Mbps";
  if (props.isOverage) {
    if (!props.item || props.item <= 0) {
      return props.noWrap ? <></> : <td></td>;
    }
    return props.noWrap ? <>+{stringValue}</> : <td>+{stringValue}</td>;
  } else {
    return props.noWrap ? <>{stringValue}</> : <td>{stringValue}</td>;
  }
};

export default BandwidthCell;
