import IAuditUser from "./IAuditUser";
import { State } from "@progress/kendo-data-query";

export default interface IAuditUserDisplayState {
  auditUsersToDisplay: IAuditUser[];
  auditUsersTableState: State;
  nextPageToken: string;
}

export enum AuditUserFilterByOption {
  BILLABLE_USER = 1,
  PRODUCT_LICENSES = 2,
}
