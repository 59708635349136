import { Grid } from "@cuda-networks/bds-core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountInfoAction, setSelectedAccountToFilterProductsForBA } from "../../actions/accountActions";
import { getProductsAction } from "../../actions/productActions";
import IAccount from "../../models/IAccount";
import MspType from "../../models/MspType";
import { IAppState } from "../../store/store";
import { getCurrentlySelectedPairForProductFiltering, getSelectedMspCildren, shouldShowCheckM365Button } from "../../Utilities/productsHelper";
import DropDown from "../DropDown";
import CheckM365StatusButton from "./CheckM366Status/CheckM365StatusButton";

const ProductFilters: React.FC = () => {
  const dispatch = useDispatch();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const loadingAllChildrenAccountNames = useSelector((state: IAppState) => state.accountState.loadingAllChildrenAccountNames);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const [selectedSubpartnerId, setSelectedSubpartnerId] = useState(0);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [subpartners, setSubpartners] = useState<IAccount[]>([]);
  const [customers, setCustomers] = useState<IAccount[]>([]);
  const [mspCustomers, setMspCustomers] = useState<IAccount[]>([]);
  const [shouldDisplayFilterByChildAccount, setShouldDisplayFilterByChildAccount] = useState(false);
  const [showCheckM356Button, setShowCheckM356Button] = useState(false);

  useEffect(() => {
    const { makeUpdates, displayFilters, subp, cust } = getSelectedMspCildren(mspAccountLoggedIn, selectedAccount, mspAccounts);

    setMspCustomers(cust);
    if (makeUpdates) {
      setSubpartners(subp);
      setCustomers(cust);
      setShouldDisplayFilterByChildAccount(displayFilters);
    }
  }, [mspAccountLoggedIn, selectedAccount, mspAccounts]);

  useEffect(() => {
    if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
      dispatch(getProductsAction(selectedAccountToFilterProductsForBA));
      dispatch(getAccountInfoAction(selectedAccountToFilterProductsForBA));
    } else {
      dispatch(getProductsAction(selectedAccount));
      dispatch(getAccountInfoAction(selectedAccount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, selectedAccount]);

  useMemo(() => {
    if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
      const { currentlySelectedCustomerId, currentlySelectedSubpartnerId } = getCurrentlySelectedPairForProductFiltering(selectedAccountToFilterProductsForBA, selectedAccount);
      if (customers !== undefined) {
        let custs: IAccount[] | undefined = customers;
        if (currentlySelectedSubpartnerId > 0 && subpartners && subpartners.length > 0) {
          const subpartnerIndex = subpartners.findIndex((x: IAccount) => x.id === currentlySelectedSubpartnerId);
          if (subpartnerIndex > -1) {
            setSelectedSubpartnerId(subpartnerIndex + 1);
            custs = subpartners[subpartnerIndex].accounts;
            if (custs !== undefined) {
              setCustomers(custs);
            }
          }
        }
        if (currentlySelectedCustomerId > 0 && custs && custs.length > 0) {
          const customerIndex = custs.findIndex((x: IAccount) => x.id === currentlySelectedCustomerId);
          if (customerIndex > -1) {
            setSelectedCustomerId(customerIndex + 1);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, selectedAccount, customers, subpartners]);

  useMemo(() => {
    if (selectedAccount) {
      if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
        if (selectedAccountToFilterProductsForBA.type === MspType.Subpartner) {
          if (subpartners && subpartners.length > 0) {
            const subpartnerIndex = subpartners.findIndex((x: IAccount) => x.id === selectedAccountToFilterProductsForBA.id);
            if (subpartnerIndex > -1) {
              setSelectedSubpartnerId(subpartnerIndex + 1);
              const selectedSubpartner = subpartners[subpartnerIndex];
              setSelectedCustomerId(0);
              if (selectedSubpartner.accounts !== undefined) {
                setCustomers(selectedSubpartner.accounts);
              }
            } else {
              setSelectedSubpartnerId(0);
              setSelectedCustomerId(0);
              setCustomers(mspCustomers);
            }
          }
        } else {
          if (customers && customers.length > 0) {
            const customerIndex = customers.findIndex((x: IAccount) => x.id === selectedAccountToFilterProductsForBA.id);
            if (customerIndex > -1) {
              setSelectedCustomerId(customerIndex + 1);
              const selectedCustomer = customers[customerIndex];
              dispatch(setSelectedAccountToFilterProductsForBA(selectedCustomer));
            }
          }
        }
      } else {
        setSelectedCustomerId(0);
        setSelectedSubpartnerId(0);
        setCustomers(mspCustomers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, selectedAccount]);

  const onChangedSelectedSubpartner = (selectedId: number) => {
    if (selectedId > 0) {
      setSelectedSubpartnerId(selectedId);
      if (subpartners !== undefined) {
        const selectedSubpartner = subpartners[selectedId - 1];
        dispatch(setSelectedAccountToFilterProductsForBA(selectedSubpartner));
        setCustomers(selectedSubpartner.accounts ? [...selectedSubpartner.accounts] : []);
      }
    } else {
      setSelectedSubpartnerId(0);
      dispatch(setSelectedAccountToFilterProductsForBA(selectedAccount));
    }
  };

  const onChangedSelectedCustomer = (selectedId: number) => {
    if (selectedId > 0) {
      setSelectedCustomerId(selectedId);
      if (customers !== undefined) {
        const selectedCustomer = customers[selectedId - 1];
        dispatch(setSelectedAccountToFilterProductsForBA(selectedCustomer));
      }
    } else {
      setSelectedCustomerId(0);
      if (subpartners !== undefined) {
        if (selectedSubpartnerId > 0) {
          const selectedSubpartner = subpartners[selectedSubpartnerId - 1];
          dispatch(setSelectedAccountToFilterProductsForBA(selectedSubpartner));
        } else {
          dispatch(setSelectedAccountToFilterProductsForBA(selectedAccount));
        }
      }
    }
  };

  useEffect(() => {
    setShowCheckM356Button(shouldShowCheckM365Button(selectedAccountToFilterProductsForBA, productsToDisplay));
  }, [selectedAccountToFilterProductsForBA, selectedAccount, productsToDisplay]);

  return shouldDisplayFilterByChildAccount ? (
    <Grid container item xs={12} style={{ justifyContent: "center" }} spacing={3}>
      {subpartners !== undefined && subpartners.length > 0 && (
        <Grid item xs={4}>
          <div data-testid="subpartnersDropdown">
            <DropDown
              required={false}
              firstOption={"-- Show for all -- "}
              label={"Filter by MSP Subpartner"}
              options={subpartners.map(x => x.name)}
              selectedOptionId={selectedSubpartnerId}
              error={""}
              handleChange={onChangedSelectedSubpartner}
              disabled={loadingAllChildrenAccountNames}
              onOpen={() => {
                /*nothing*/
              }}
            />
          </div>
        </Grid>
      )}
      {customers !== undefined && customers.length > 0 && (
        <Grid item xs={4}>
          <div data-testid="customersDropdown">
            <DropDown
              required={false}
              firstOption={"-- Show for all -- "}
              label={"Filter by MSP Account"}
              options={customers?.map(x => x.name)}
              selectedOptionId={selectedCustomerId}
              error={""}
              handleChange={onChangedSelectedCustomer}
              disabled={loadingAllChildrenAccountNames}
              onOpen={() => {
                /*nothing*/
              }}
            />
          </div>
          <Grid item>{showCheckM356Button && <CheckM365StatusButton accountId={selectedAccountToFilterProductsForBA?.id} />}</Grid>
        </Grid>
      )}
    </Grid>
  ) : null;
};

export default ProductFilters;
