import React from "react";
import { Typography } from "@material-ui/core";
import { IPrivilege } from "../../../../../models/IUserPrivleges";
import PrivilegeItem from "../PrivilegeItem";

interface PrivilegesSectionProps<T> {
  title: string;
  privileges: IPrivilege<T>[];
}

const PrivilegesSection: React.FC<PrivilegesSectionProps<any>> = ({ title, privileges }) => {
  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: "20px" }}>
        PRIVILEGES
      </Typography>
      {privileges.map(({ privilege, enabled }) => (
        <div key={privilege} className="privilegeItem">
          <PrivilegeItem privilege={privilege} enabled={enabled} privilegeCategory={title.replace(/\s/g, "")} />
        </div>
      ))}
    </div>
  );
};

export default PrivilegesSection;
