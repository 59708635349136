import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { getAmountToDisplay } from "../../utility";

const InvoiceAmount = (props: any) => {
  return (
    <td>
      <Grid container item>
        {getAmountToDisplay(props.dataItem.amount, props.currency)}
      </Grid>
    </td>
  );
};

export default InvoiceAmount;
