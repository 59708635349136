import React from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, LinearProgress } from "@cuda-networks/bds-core";
import { enterKey, handleBackdropClick } from "../utility";

interface ISubmitDialogProps {
  title: React.JSX.Element | undefined;
  message: React.JSX.Element | undefined;
  onSubmitDelete: () => void;
  onCancelDelete: () => void;
  showDeleteDialog: boolean;
  isActionInProgress: boolean;
  hasActiveProducts: boolean;
  loadingDeleteDialog: boolean;
}
const DeleteDialog: React.FC<ISubmitDialogProps> = ({ title, message, showDeleteDialog, onCancelDelete, onSubmitDelete, isActionInProgress, hasActiveProducts, loadingDeleteDialog }) => {
  const setEnterEvent = (e: any) => {
    if (hasActiveProducts) {
      return enterKey(e, onCancelDelete);
    } else {
      return enterKey(e, onSubmitDelete);
    }
  };
  return (
    <Dialog onKeyUp={(event: any) => setEnterEvent(event)} disableEscapeKeyDown={isActionInProgress} className="deleteDialog" data-testid="deleteDialog" open={showDeleteDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancelDelete)}>
      <DialogTitle data-testid="deleteTitle"> {title} </DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        {loadingDeleteDialog ? (
          <div data-testid="loadingDeleteMessage">
            <LinearProgress />
          </div>
        ) : (
          <div data-testid="deleteMessage">{message}</div>
        )}
      </DialogContent>
      <DialogActions>
        {hasActiveProducts ? (
          <Button data-testid="okDelete" primary="true" size="large" disabled={isActionInProgress} onClick={onCancelDelete} style={{ marginRight: 16 }}>
            OK
          </Button>
        ) : (
          <div>
            <Button data-testid="cancelDelete" variant={loadingDeleteDialog ? "contained" : "text"} size="large" disabled={isActionInProgress} onClick={onCancelDelete} className={"dialogButtonSpacing"}>
              CANCEL
            </Button>
            {!loadingDeleteDialog && (
              <Button data-testid="confirmDelete" primary="true" isLoading={isActionInProgress} disabled={isActionInProgress} type={"submit"} onClick={onSubmitDelete} className={"dialogButtonSpacing"}>
                CONFIRM
              </Button>
            )}
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
