import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { getDateToDisplay } from "../utility";

const DateCell = (props: any) => {
  return (
    <td>
      <Grid container item>
        {getDateToDisplay(props.date)}
      </Grid>
    </td>
  );
};

export default DateCell;
