import React from "react";
import { Typography } from "@material-ui/core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { setDisabledColor } from "../../../../utility";

interface PrivilegeItemProps {
  privilege: string;
  enabled: boolean;
  privilegeCategory: string;
}

const PrivilegeItem: React.FC<PrivilegeItemProps> = ({ privilege, enabled, privilegeCategory }) => {
  return (
    <div className="privilegeRow" data-testid={`user${privilegeCategory}Privileges`}>
      <div className="privilegeIconContainer">{enabled ? <CoreIcons.Checkmark className="DarkGreenColor" /> : <span className="hiddenPrivilegePlaceholder">Placeholder</span>}</div>
      <Typography variant="body2" display="inline" className={setDisabledColor("privilegeText", enabled)}>
        {privilege}
      </Typography>
    </div>
  );
};

export default PrivilegeItem;
