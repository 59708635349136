import IntegrationType from "../IntegrationType";
import IIntegration from "./IIntegration";
import IntegrationStatus from "./IntegrationStatus";

const DefaultIntegrations: IIntegration[] = [
  { type: IntegrationType.Autotask, status: IntegrationStatus.Undefined, publicKey: "", enableFeatures: "" },
  { type: IntegrationType.ConnectWise, status: IntegrationStatus.Undefined, publicKey: "", enableFeatures: "" },
];

export default DefaultIntegrations;
