import produce from "immer";
import { validateEmailAddress } from "../components/Accounts/AddEditAccount/addressValidator";
import { MAX_CSV_SIZE_TO_IMPORT } from "../config";
import IAuditUser from "../models/Products/IAuditUser";
import IAuditUserDisplayState from "../models/Products/IAuditUserDisplayState";

export function foundAuditUser(auditUsersToDisplay: IAuditUser[], searchedValue: string, nextPageToken: string): boolean {
  const searchedAuditUser = auditUsersToDisplay.find((auditUser: IAuditUser) => {
    return auditUser.user.indexOf(searchedValue) >= 0;
  });
  return searchedAuditUser || nextPageToken.length > 0 ? true : false;
}

export function updatedAuditUserToDisplayBeforeSearch(auditUser: IAuditUser, auditUserStateBeforeSearch?: IAuditUserDisplayState): any {
  if (auditUserStateBeforeSearch) {
    let nextAuditUserStateBeforeSearch = { ...auditUserStateBeforeSearch };
    let auditUsersToDisplayIndex = auditUserStateBeforeSearch.auditUsersToDisplay.findIndex((e: IAuditUser) => e.user === auditUser.user);
    if (auditUsersToDisplayIndex >= 0) {
      const newAuditUserStateBeforeSearch = produce(nextAuditUserStateBeforeSearch, draft => {
        draft.auditUsersToDisplay[auditUsersToDisplayIndex].isExcluded = auditUser.isExcluded;
      });
      return newAuditUserStateBeforeSearch;
    }
    return nextAuditUserStateBeforeSearch;
  }
}

export function searchAuditUser(auditUserEmail: string, auditUserStateBeforeSearch?: IAuditUserDisplayState): any {
  return auditUserStateBeforeSearch?.auditUsersToDisplay.filter((au: IAuditUser) => au.user.toLowerCase().startsWith(auditUserEmail.toLowerCase()));
}

export const validatedEmails = (emails: any): { validEmails: string[]; invalidEmails: string[] } => {
  let validEmails: string[] = [];
  let invalidEmails: string[] = [];
  emails.forEach((e: string) => {
    if (validateEmailAddress(e)) {
      validEmails.push(e.toLowerCase());
    } else {
      invalidEmails.push(e.toLowerCase());
    }
  });
  return { validEmails, invalidEmails };
};

export const fileHasCorrectSize = (file: any) => {
  const fsize = Math.round(file.size / 1024);
  if (fsize >= MAX_CSV_SIZE_TO_IMPORT) {
    return false;
  } else {
    return true;
  }
};

export const processFile = (content: any): { isValidCsv: boolean; users: string[] } => {
  const sep = /\r\n|\r/;
  let lines = content.split(sep);
  let users: string[] = [];
  let isValidCsv = true;
  for (let value of lines) {
    const line = value.trim().replace(/['"]+/g, "");
    const columns = line.split(",");
    if (columns.length !== 1 && columns[1].length > 0) {
      isValidCsv = false;
      break;
    } else {
      let trimmedLine = line;
      if (trimmedLine.length > 0) {
        users.push(trimmedLine);
      }
    }
  }
  if (!isValidCsv) {
    return { isValidCsv, users: [] };
  } else {
    return { isValidCsv, users };
  }
};

export const makeCSV = (content: string[]) => {
  let csv = "";
  content.forEach((item, i) => {
    let innerValue = item === null ? "" : item.toString();
    let result = innerValue.replace(/"/g, '""');
    csv += result + "\n";
  });
  return csv;
};

export function computeAllInvalidEmails(finalResult: any[]): string[] {
  let finalInvalidEmails: string[] = [];
  finalResult.forEach(emails => {
    finalInvalidEmails = [...finalInvalidEmails, ...emails];
  });
  return finalInvalidEmails;
}
