import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Grid, SwitchLabel, TextField, Typography, Tooltip } from "@cuda-networks/bds-core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import ICreditCard from "../../models/Invoices/ICreditCard";
import { getCardType, getCreditCardMonths, getCreditCardYears, setCreditCardDropdownIndex, getCreditCardExpDateIndex } from "../../Utilities/financeHelper";
import { getBrowserCountry, getDateToDisplay, isStringNullOrEmpty, setNoPoinerEvents, getAmountToDisplay } from "../../utility";
import { validateCreditCard, validateMesCreditCard } from "./creditCardValidator";
import { IFieldValidator } from "../Accounts/AddEditAccount/addressValidator";
import { setSnackBarMessage } from "../../actions/generalActions";
import TooltipElement from "../Users/TooltipElement";
import DropDown from "../DropDown";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import IInvoice from "../../models/Invoices/IInvoice";
import IApiCreditCard from "../../models/Invoices/IApiCreditCard";
import ActionMessageType from "../../models/ActionMessageType";
import CreditCardDropDown from "../CreditCardDropDown";
import ICountry from "../../models/ICountry";
import IExistingCreditCard from "../../models/Invoices/IExistingCreditCard";
import { setSelectedCreditCardAction } from "../../actions/financeActions";
import { getConfirmButtonState, getCreditCardMesSnackbarErrorMessage } from "../../businessLogic/components/Finance/CreditCardDialog";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { getSelectedCountryName } from "../Accounts/AddEditAccount/AddEditAccountDialog";

interface IManageCreditCardDialogProps {
  onCancel: () => void;
  onSubmit: (creditCard: IApiCreditCard | undefined, existingCreditCard: IExistingCreditCard | undefined) => void;
  onDelete: (creditCard: IExistingCreditCard) => void;
  showDialog: boolean;
  invoice: IInvoice | undefined;
  actionInProgress: boolean;
  currency: string;
}

const CreditCardsDialog: React.FC<IManageCreditCardDialogProps> = ({ showDialog, onCancel, onSubmit, invoice, actionInProgress, onDelete, currency }) => {
  const dispatch = useDispatch();
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const creditCardsToDisplay = useSelector((state: IAppState) => state.financeState.creditCardsToDisplay);
  const selectedCreditCard = useSelector((state: IAppState) => state.financeState.selectedCreditCard);
  const loadingCreditCards = useSelector((state: IAppState) => state.financeState.loadingCreditCards);
  const expMonthOptions = getCreditCardMonths();
  const defaultExpYearOptions = getCreditCardYears();
  const [expYearOptions, setExpYearOptions] = useState(getCreditCardYears());

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [selectedExpMonthIndex, setSelectedExpMonthIndex] = useState(0);
  const [selectedExpYearIndex, setSelectedExpYearIndex] = useState(0);
  const [selectedInvoiceCountryId, setSelectedInvoiceCountryId] = useState(0);

  const [cardNumber, setCardNumber] = useState("");
  const [cardFirstName, setCardFirstName] = useState("");
  const [cardLastName, setCardLastName] = useState("");
  const [cardCvvNumber, setCardCvvNumber] = useState("");
  const [cardZipNumber, setCardZipNumber] = useState("");
  const [isCardFromatValid, setIsCardFromatValid] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [isDefaultDisabled, setIsDefaultDisabled] = useState(false);
  const [saveCardDisabled, setSaveCardDisabled] = useState(false);
  const [validationInProgress, setValidationInProgress] = useState(false);

  const [creditCardError, setCreditCardError] = useState<IFieldValidator[]>([]);
  const [confirmBUttonTooltip, setConfirmButtonTooltip] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  useEffect(() => {
    if (invoice) {
      setSaveCardDisabled(false);
      if (saveCard) {
        if (creditCardsToDisplay.length > 0) {
          setIsDefaultDisabled(false);
        } else {
          setIsDefaultDisabled(true);
          setIsDefault(true);
        }
      } else {
        setIsDefault(false);
        setIsDefaultDisabled(true);
      }
    } else {
      setIsDefault(true);
      setSaveCard(true);
      setIsDefaultDisabled(true);
      setSaveCardDisabled(true);
    }
  }, [invoice, isDefault, saveCard, creditCardsToDisplay]);

  useEffect(() => {
    if (invoice && creditCardsToDisplay.length > 0) {
      const activeCardIndex = creditCardsToDisplay.findIndex(x => x.default === true);
      if (activeCardIndex > -1) {
        setSelectedCardIndex(activeCardIndex + 1);
        handleCreditCardChange(activeCardIndex + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardsToDisplay, invoice]);

  useEffect(() => {
    const browserLanguage = getBrowserCountry();
    const countryIndex = countries.findIndex((country: ICountry) => country.country.toLowerCase() === browserLanguage.toLowerCase());
    if (countryIndex > -1) {
      setSelectedInvoiceCountryId(countryIndex + 1);
    }
  }, [countries]);

  useEffect(() => {
    if (!selectedCreditCard && selectedCardIndex !== 0) {
      setSelectedCardIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditCard]);

  useEffect(() => {
    if (invoice && selectedCardIndex > 0 && selectedCreditCard) {
      setIsCardFromatValid(true);
    } else {
      if (!isStringNullOrEmpty(cardNumber) && !isStringNullOrEmpty(cardFirstName) && !isStringNullOrEmpty(cardLastName) && selectedExpMonthIndex !== 0 && selectedExpYearIndex !== 0 && !isStringNullOrEmpty(cardCvvNumber) && !isStringNullOrEmpty(cardZipNumber) && selectedInvoiceCountryId > 0) {
        setIsCardFromatValid(true);
      } else {
        setIsCardFromatValid(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, selectedCardIndex, selectedCreditCard, cardNumber, cardFirstName, cardLastName, selectedExpMonthIndex, selectedExpYearIndex, cardCvvNumber, cardZipNumber, selectedInvoiceCountryId]);

  useEffect(() => {
    const result = getConfirmButtonState(invoice, selectedCardIndex, selectedCreditCard, isCardFromatValid, actionInProgress, validationInProgress);
    setConfirmButtonDisabled(result.disabled);
    setConfirmButtonTooltip(result.tooltip);
  }, [invoice, selectedCardIndex, selectedCreditCard, isCardFromatValid, actionInProgress, validationInProgress]);

  const [cardNumberError, setCardNumberError] = useState("");
  const [cardFirstNameError, setCardFirstNameError] = useState("");
  const [cardLastNameError, setCardLastNameError] = useState("");
  const [cardExpMonthError, setCardExpMonthError] = useState("");
  const [cardExpMonthYear, setCardExpYearError] = useState("");
  const [cardCvvNumberError, setCardCvvNumberError] = useState("");
  const [cardZipNumberError, setCardZipNumberError] = useState("");

  useEffect(() => {
    creditCardError.forEach(value => {
      switch (value.name) {
        case "cardNumber":
          setCardNumberError(value.error);
          break;
        case "firstName":
          setCardFirstNameError(value.error);
          break;
        case "lastName":
          setCardLastNameError(value.error);
          break;
        case "expMonth":
          setCardExpMonthError(value.error);
          break;
        case "expYear":
          setCardExpYearError(value.error);
          break;
        case "cvv":
          setCardCvvNumberError(value.error);
          break;
        case "zip":
          setCardZipNumberError(value.error);
          break;
        default:
          break;
      }
    });
  }, [creditCardError]);

  useEffect(() => {
    if (selectedCreditCard) {
      let cardMonth = selectedCreditCard.expirationDate.substring(0, selectedCreditCard.expirationDate.indexOf("/"));
      let cardYear = new Date().getFullYear().toString().substring(0, 2) + selectedCreditCard.expirationDate.substring(selectedCreditCard.expirationDate.indexOf("/") + 1, selectedCreditCard.expirationDate.length);
      let yearsOptions = [...expYearOptions];
      setCardFirstName(selectedCreditCard.firstName);
      setCardLastName(selectedCreditCard.lastName);
      setSelectedExpMonthIndex(getCreditCardExpDateIndex(expMonthOptions, cardMonth));
      if (yearsOptions.indexOf(cardYear) < 0) {
        yearsOptions.unshift(cardYear);
        setExpYearOptions(yearsOptions);
      }
      setSelectedExpYearIndex(getCreditCardExpDateIndex(yearsOptions, cardYear));
    } else if (!selectedCreditCard && !actionInProgress) {
      setCardFirstName("");
      setCardLastName("");
      setSelectedExpMonthIndex(0);
      setSelectedExpYearIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditCard]);

  const handleCreditCardChange = (newValue: number) => {
    setSelectedCardIndex(newValue);
    if (newValue !== 0) {
      dispatch(setSelectedCreditCardAction(creditCardsToDisplay[newValue - 1]));
    } else {
      dispatch(setSelectedCreditCardAction(undefined));
    }
    setCardNumberError("");
    setCardFirstNameError("");
    setCardLastNameError("");
    setCardExpMonthError("");
    setCardExpYearError("");
    setCardCvvNumberError("");
    setCardZipNumberError("");
    setCardNumber("");
    setCardCvvNumber("");
    setCardZipNumber("");
  };

  const handleExpMonthChange = (newValue: number) => {
    setCardExpMonthError("");
    setCardExpYearError("");
    setSelectedExpMonthIndex(newValue);
  };

  const handleExpYearChange = (newValue: number) => {
    setCardExpMonthError("");
    setCardExpYearError("");
    setSelectedExpYearIndex(newValue);
  };

  const onOpenExpYears = () => {
    setExpYearOptions(defaultExpYearOptions);
  };

  const handleCardNumberChanged = (newValue: string) => {
    setCardNumberError("");
    setCardNumber(newValue);
  };

  const handleCardFirstNameChanged = (newValue: string) => {
    setCardFirstNameError("");
    setCardFirstName(newValue);
  };

  const handleCardLastNameChanged = (newValue: string) => {
    setCardLastNameError("");
    setCardLastName(newValue);
  };

  const handleCardCvvNumberChanged = (newValue: string) => {
    setCardCvvNumberError("");
    setCardCvvNumber(newValue);
  };

  const handleCardZipNumberChanged = (newValue: string) => {
    setCardZipNumberError("");
    setCardZipNumber(newValue);
  };

  const handleCardCountryChanged = (newValue: number) => {
    const selCountry = countries[newValue - 1];
    if (selCountry) {
      setSelectedInvoiceCountryId(newValue);
    }
  };

  const handleMakeDefaultChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsDefault(!isDefault);
  };

  const handleSaveCardChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSaveCard(!saveCard);
  };

  const handleOnConfirmCard = (creditCard: ICreditCard) => {
    setValidationInProgress(true);
    if (invoice && selectedCardIndex > 0 && selectedCreditCard) {
      onSubmit(undefined, selectedCreditCard);
      setValidationInProgress(false);
    } else {
      let errors = validateCreditCard(creditCard, selectedCreditCard);
      setCreditCardError(errors);
      if (errors.length === 0) {
        setCardNumber("");
        validateMesCreditCard(creditCard, (mesResponse: any) => {
          let response: any = {};
          if (mesResponse.code === 0) {
            const apiCreditCard: IApiCreditCard = {
              lastFourDigits: creditCard.cardNumber.substring(creditCard.cardNumber.length - 4),
              cardType: getCardType(creditCard.cardNumber),
              firstName: creditCard.firstName,
              lastName: creditCard.lastName,
              expirationDate: creditCard.month + "/" + creditCard.year.slice(-2),
              token: mesResponse.token,
              cvv: creditCard.cvv,
              zip: creditCard.zip,
              country: creditCard.country,
              isTemporary: invoice ? !saveCard : undefined,
              isDefault: isDefault,
              isRecurring: invoice ? undefined : true,
            };
            onSubmit(apiCreditCard, undefined);
            setValidationInProgress(false);
          } else {
            setValidationInProgress(false);
            setCardNumber("");
            if (mesResponse.code === 1 || mesResponse.code === 4 || mesResponse.code === 5 || mesResponse.code === 6 || mesResponse.code === 7) {
              response.error = "Unable to process your credit card. Please make sure JavaScript is enabled on your browser.";
            } else {
              response.error = mesResponse.text;
            }
            dispatch(setSnackBarMessage({ message: getCreditCardMesSnackbarErrorMessage(response.error, invoice, !!(selectedCardIndex > 0 && selectedCreditCard)), type: ActionMessageType.Error }));
          }
        });
      } else {
        setValidationInProgress(false);
      }
    }
  };

  return (
    <Dialog
      className={"ManageCreditCardDialog"}
      open={showDialog}
      maxWidth={false}
      onClose={(event: EventSource, reason: string) => {
        if (reason !== "backdropClick") {
          onCancel();
        }
      }}
      disableEscapeKeyDown={actionInProgress || validationInProgress}
    >
      <div style={{ width: "540px" }}>
        <DialogTitle data-testid="creditCardDialogTitle">{invoice ? "Make a Payment" : "Manage Credit Cards"}</DialogTitle>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Backdrop className={"parentOpacity"} open={loadingCreditCards} style={{ position: "absolute", zIndex: 0 }}>
            <CircularProgress data-testid="loadingCreditCardsCircularProgress" size="80px" style={{ zIndex: 1 }} />
          </Backdrop>
          <Grid container>
            {invoice && (
              <Grid container>
                <Grid container item xs={12} style={{ justifyContent: "end" }}>
                  <Grid container item xs={3}>
                    <Typography data-testid="invoiceNumberLbl" variant="subtitle2">
                      INVOICE NUMBER
                    </Typography>
                  </Grid>
                  <Grid container item xs={6}>
                    <Typography data-testid="invoiceNumberTxt" variant="subtitle2" gutterBottom noWrap style={{ fontWeight: "bold", marginLeft: "30px" }}>
                      {invoice.number}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} style={{ justifyContent: "end" }}>
                  <Grid container item xs={3}>
                    <Typography data-testid="invoiceAmountLbl" variant="subtitle2">
                      AMOUNT
                    </Typography>
                  </Grid>
                  <Grid container item xs={6}>
                    <Typography data-testid="invoiceAmountTxt" variant="subtitle2" gutterBottom noWrap style={{ fontWeight: "bold", marginLeft: "30px" }}>
                      {getAmountToDisplay(invoice.amount, currency)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} style={{ justifyContent: "end" }}>
                  <Grid container item xs={3}>
                    <Typography data-testid="invoicePaymentDateLbl" variant="subtitle2">
                      INVOICE DATE
                    </Typography>
                  </Grid>
                  <Grid container item xs={6}>
                    <Typography data-testid="invoicePaymentDateTxt" variant="subtitle2" gutterBottom noWrap style={{ fontWeight: "bold", marginLeft: "30px" }}>
                      {getDateToDisplay(invoice.date)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} className={"creditCardContainer"}>
              <Grid item xs={10}>
                <div data-testid="creditCardNumberDropdown">
                  <CreditCardDropDown label={"CREDIT CARD NUMBER"} options={creditCardsToDisplay} selectedOptionId={setCreditCardDropdownIndex(creditCardsToDisplay, selectedCreditCard)} error={""} disabled={actionInProgress || validationInProgress} handleChange={handleCreditCardChange} />
                </div>
              </Grid>
              {selectedCardIndex !== 0 && !invoice && (
                <Grid container item xs={2} style={{ alignItems: "end", justifyContent: "end" }}>
                  <div className={selectedCreditCard?.default ? "cursorNotAllowed" : ""}>
                    <Tooltip title={"Cannot delete active card"} disableHoverListener={!selectedCreditCard?.default} placement="bottom-start">
                      <span>
                        <Button data-testid={"creditCardDelete"} className={setNoPoinerEvents(selectedCreditCard?.default as boolean)} disabled={selectedCreditCard?.default || actionInProgress || validationInProgress} variant="contained" color="secondary" onClick={onDelete}>
                          DELETE
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </Grid>
              )}
            </Grid>
            {(selectedCardIndex === 0 || invoice === undefined) && (
              <Grid container item xs={12} className={"creditCardContainer"}>
                <Grid container item xs={12} className={"creditCardContainer"}>
                  <Grid item xs={12} container direction="column">
                    <TextField
                      data-testid="cardNumberTxt"
                      size={"small"}
                      label="CARD NUMBER"
                      value={cardNumber}
                      error={cardNumberError.length > 0}
                      disabled={actionInProgress || validationInProgress}
                      helperText={cardNumberError}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCardNumberChanged(ev.target.value)}
                      inputProps={{
                        maxLength: 64,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} className={"creditCardContainer"}>
                  <Grid item xs={5} container>
                    <Grid item xs={12} container direction="column">
                      <TextField
                        data-testid="cardFirstNameTxt"
                        size={"small"}
                        label="CARD HOLDER'S FIRST NAME"
                        value={cardFirstName}
                        error={cardFirstNameError.length > 0}
                        disabled={actionInProgress || validationInProgress}
                        helperText={cardFirstNameError}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCardFirstNameChanged(ev.target.value)}
                        inputProps={{
                          maxLength: 64,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={7} container style={{ justifyContent: "end" }}>
                    <Grid item xs={11} container direction="column">
                      <TextField
                        data-testid="cardLastNameTxt"
                        size={"small"}
                        label="CARD HOLDER'S LAST NAME"
                        value={cardLastName}
                        error={cardLastNameError.length > 0}
                        disabled={actionInProgress || validationInProgress}
                        helperText={cardLastNameError}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCardLastNameChanged(ev.target.value)}
                        inputProps={{
                          maxLength: 64,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3} className={"creditCardContainer"}>
                  <Grid item xs={3}>
                    <div data-testid="cardExpMonthDropdown">
                      <DropDown label={"EXP. MONTH"} required={false} options={expMonthOptions} selectedOptionId={selectedExpMonthIndex} error={cardExpMonthError} disabled={actionInProgress || validationInProgress} handleChange={handleExpMonthChange} />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div data-testid="cardExpYearDropdown">
                      <DropDown label={"EXP. YEAR"} required={false} options={expYearOptions} selectedOptionId={selectedExpYearIndex} error={cardExpMonthYear} disabled={actionInProgress || validationInProgress} handleChange={handleExpYearChange} onOpen={onOpenExpYears} />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      data-testid="cardCvvNumberTxt"
                      size={"small"}
                      label="CVV NUMBER"
                      value={cardCvvNumber}
                      error={cardCvvNumberError.length > 0}
                      disabled={actionInProgress || validationInProgress}
                      helperText={cardCvvNumberError}
                      style={{ width: "85px" }}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCardCvvNumberChanged(ev.target.value)}
                      inputProps={{
                        maxLength: 64,
                      }}
                    />
                    <div className={"creditCardCvvInfoIcon"} style={{ display: "inline-block", position: "relative", top: "25px", left: "8px" }}>
                      <TooltipElement title="The CVV Number Is Located On The Back Of Your Card.">
                        <CoreIcons.CreditCard data-testid="creditCardCvvInfoIcon" disabled={actionInProgress}></CoreIcons.CreditCard>
                      </TooltipElement>
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12} className={"creditCardContainer"}>
                  <Grid item xs={5} container>
                    <Grid item xs={11} container direction="column">
                      <TextField
                        data-testid="cardZipCodeTxt"
                        size={"small"}
                        label="ZIP / POSTAL CODE"
                        value={cardZipNumber}
                        error={cardZipNumberError.length > 0}
                        disabled={actionInProgress || validationInProgress}
                        helperText={cardZipNumberError}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCardZipNumberChanged(ev.target.value)}
                        inputProps={{
                          maxLength: 64,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={7} container style={{ justifyContent: "end" }}>
                    <Grid item xs={12}>
                      <div data-testid="creditCardCountryDropdown">
                        <DropDown label={"COUNTRY"} required={false} options={countries?.map(c => `${c.countryName} - ${c.country}`)} selectedOptionId={selectedInvoiceCountryId} error={""} disabled={actionInProgress || validationInProgress} handleChange={handleCardCountryChanged} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} className={"creditCardContainer"} style={{ marginBottom: "12px" }}>
                  <SwitchLabel data-testid="creditCardMakeDefaultLbl" label={"Make Card Active"} control={<Switch data-testid="creditCardMakeDefault" disabled={actionInProgress || validationInProgress || isDefaultDisabled} checked={isDefault} onChange={handleMakeDefaultChange} />} style={{ marginLeft: "15px" }} />
                  <SwitchLabel data-testid="creditCardSaveCardLbl" label={"Save Card"} control={<Switch data-testid="creditCardSaveCard" disabled={actionInProgress || validationInProgress || saveCardDisabled} checked={saveCard} onChange={handleSaveCardChange} />} style={{ marginLeft: "15px" }} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button data-testid="creditCardCancel" variant="text" onClick={onCancel} disabled={actionInProgress || validationInProgress}>
            CANCEL
          </Button>
          <div className={confirmButtonDisabled ? "cursorNotAllowed" : ""}>
            <Tooltip title={confirmBUttonTooltip} disableHoverListener={!confirmButtonDisabled} placement="bottom-start">
              <span>
                <Button
                  className={setNoPoinerEvents(confirmButtonDisabled)}
                  variant="contained"
                  data-testid="creditCardConfirm"
                  disabled={confirmButtonDisabled}
                  isLoading={actionInProgress || validationInProgress}
                  onClick={() =>
                    handleOnConfirmCard({
                      cardNumber: cardNumber.trim(),
                      firstName: cardFirstName.trim(),
                      lastName: cardLastName.trim(),
                      cvv: cardCvvNumber.trim(),
                      month: selectedExpMonthIndex > 0 ? expMonthOptions[selectedExpMonthIndex - 1] : "",
                      year: selectedExpYearIndex > 0 ? expYearOptions[selectedExpYearIndex - 1] : "",
                      zip: cardZipNumber.trim(),
                      country: getSelectedCountryName(countries, selectedInvoiceCountryId),
                    })
                  }
                >
                  {invoice ? "PAY NOW" : "CONFIRM"}
                </Button>
              </span>
            </Tooltip>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CreditCardsDialog;
