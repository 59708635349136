import React from "react";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";

const TooltipElement = (props: any) => {
  let tooltipComponent;
  if (props.tooltipType === "table") {
    const tableItem = props.dataItem[props.field];
    let noValue = false;
    if (tableItem !== undefined) {
      if (tableItem.length === 0) {
        noValue = true;
      }
    }
    tooltipComponent = (
      <td>
        <Tooltip title={tableItem !== undefined ? tableItem : ""} disableHoverListener={noValue}>
          <span>{tableItem}</span>
        </Tooltip>
      </td>
    );
  } else {
    tooltipComponent = (
      <Tooltip title={props.title} placement="bottom-start">
        <span>{props.children}</span>
      </Tooltip>
    );
  }
  return <>{tooltipComponent}</>;
};

export default TooltipElement;
