import { AddAccountButtonType } from "../../../models/AddAccountButtonType";
import IAccount from "../../../models/IAccount";
import MspType from "../../../models/MspType";
import { getAccountTypeAsTitle } from "../../../Utilities/accountsHelper";
import { getAccountIdFromUrl } from "../../../Utilities/UrlParser";

export function getSettingsForNewAcount(mspAccountLoggedIn: IAccount, expandedPartner: IAccount | undefined, hasSubpartners: boolean): { addAccountButtonType: AddAccountButtonType; parentOfNewAcc: IAccount; mspTypeOfNewAccount: MspType; canAddAccounts: boolean; title: string } {
  let addAccountButtonType: AddAccountButtonType = AddAccountButtonType.AddSubpartnerOrAccount;
  let parentOfNewAccount: IAccount | undefined = mspAccountLoggedIn;
  let typeOfNewAccount: MspType = MspType.Subpartner;
  let viewAddAccountButton = false;
  let dialogTitle: string = "";
  if (mspAccountLoggedIn.userAdminFlag) {
    viewAddAccountButton = true;
    if (expandedPartner) {
      parentOfNewAccount = expandedPartner;
      if (mspAccountLoggedIn.type === MspType.Partner) {
        if (expandedPartner.type === MspType.Partner) {
          typeOfNewAccount = MspType.Customer;
          addAccountButtonType = getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn);
          dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
        } else {
          addAccountButtonType = AddAccountButtonType.AddAccount;
          typeOfNewAccount = MspType.Customer;
          dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
        }
      } else {
        addAccountButtonType = AddAccountButtonType.AddAccount;
        typeOfNewAccount = MspType.Customer;
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      }
    } else {
      parentOfNewAccount = mspAccountLoggedIn;
      if (mspAccountLoggedIn.type === MspType.Partner) {
        if (hasSubpartners) {
          viewAddAccountButton = false;
        } else {
          typeOfNewAccount = MspType.Customer;
          addAccountButtonType = getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn);
          dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
        }
      } else {
        addAccountButtonType = AddAccountButtonType.AddAccount;
        typeOfNewAccount = MspType.Customer;
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      }
    }
  }
  return { addAccountButtonType, parentOfNewAcc: parentOfNewAccount, mspTypeOfNewAccount: typeOfNewAccount, canAddAccounts: viewAddAccountButton, title: dialogTitle };
}

function getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn: IAccount): AddAccountButtonType {
  if (mspAccountLoggedIn.canCreateSubPartners) {
    return AddAccountButtonType.AddSubpartnerOrAccount;
  } else {
    return AddAccountButtonType.AddAccount;
  }
}

export const findAccountToBeSelected = (filterAccountResult: IAccount[]): IAccount | undefined => {
  let foundAccount: IAccount | undefined = undefined;
  if (filterAccountResult) {
    const id = getAccountIdFromUrl();
    if (id !== undefined && id !== null) {
      foundAccount = filterAccountResult.find((a: IAccount) => a.id === Number(id));
      //todo - if if from url not found in filtered accounts, then we should remove the id from url
    }
  }
  return foundAccount;
};
