import React, { useState } from "react";
import { Button } from "@cuda-networks/bds-core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { getCountriesAction } from "../../actions/accountActions";
import { cancelCurrentAction, setSnackBarMessage } from "../../actions/generalActions";
import CreditCardsDialog from "./CreditCardDialog";
import IApiCreditCard from "../../models/Invoices/IApiCreditCard";
import { addCreditCard, getCreditCards, setSelectedCreditCardAction, deleteCreditCard, editCreditCard, setLoadingCreditCards } from "../../actions/financeActions";
import IAccount from "../../models/IAccount";
import ActionMessageType from "../../models/ActionMessageType";
import IExistingCreditCard from "../../models/Invoices/IExistingCreditCard";
import DeleteCreditCard from "./DeleteCreditCard";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";

interface IManageCreditCardsButton {
  disabled: boolean;
  isAddingFirstCard: boolean;
  onCancel: () => void;
  onAddFristNewCard: () => void;
  onOpenManageCreditCards: (isLoading: boolean) => void;
}

const ManageCreditCardsButton: React.FC<IManageCreditCardsButton> = ({ disabled, isAddingFirstCard, onAddFristNewCard, onCancel, onOpenManageCreditCards }) => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const loadingInvoices = useSelector((state: IAppState) => state.financeState.loadingInvoices);
  const loadingCreditCards = useSelector((state: IAppState) => state.financeState.loadingCreditCards);
  const loadingCreditCardsCanceled = useSelector((state: IAppState) => state.financeState.loadingCreditCardsCanceled);
  const selectedCreditCard = useSelector((state: IAppState) => state.financeState.selectedCreditCard);
  const [showManageCreditCard, setShowManageCreditCard] = useState(false);
  const [showDeleteCreditCard, setShowDeleteCreditCard] = useState(false);
  const [loadingManageCreditCard, setLoadingManageCreditCard] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const loadCreditCards = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const existingCreditCards = dispatch(getCreditCards(account));
      resolve(existingCreditCards);
    });

  const handleOpenManageCreditCards = () => {
    if (selectedAccount) {
      onOpenManageCreditCards(true);
      setLoadingManageCreditCard(true);
      loadCreditCards(selectedAccount).then(getCredtiCardsResult => {
        if (countries.length <= 0) {
          loadCountries().then(countriesResult => {
            setLoadingManageCreditCard(false);
            setShowManageCreditCard(!showManageCreditCard);
            onOpenManageCreditCards(false);
          });
        } else {
          setLoadingManageCreditCard(false);
          setShowManageCreditCard(!showManageCreditCard);
          onOpenManageCreditCards(false);
        }
      });
    }
  };

  const handleOnCancelShowManageCreditCard = () => {
    dispatch(setSelectedCreditCardAction(undefined));
    setShowManageCreditCard(false);
    if (isAddingFirstCard) {
      onCancel();
    }
    if (loadingCreditCards) {
      dispatch(cancelCurrentAction());
      dispatch(setLoadingCreditCards(false));
    }
  };

  const addCard = (account: IAccount, creditCard: IApiCreditCard) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(addCreditCard(account.id, creditCard));
      resolve(newAccountId);
    });

  const editCard = (account: IAccount, currentCreditCard: IExistingCreditCard, creditCard: IApiCreditCard) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(editCreditCard(account, currentCreditCard, creditCard));
      resolve(newAccountId);
    });

  const handleOnSubmitCard = (creditCard: IApiCreditCard | undefined, existingCreditCard: IExistingCreditCard | undefined) => {
    if (selectedAccount && creditCard) {
      setActionInProgress(true);
      if (selectedCreditCard) {
        editCard(selectedAccount, selectedCreditCard, creditCard).then(result => {
          setActionInProgress(false);
          dispatch(setSelectedCreditCardAction(undefined));
          if (result) {
            setShowManageCreditCard(false);
            dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.EditCreditCard].successMessage, type: ActionMessageType.Success }));
            dispatch(setSelectedCreditCardAction(undefined));
          }
        });
      } else {
        addCard(selectedAccount, creditCard).then(result => {
          setActionInProgress(false);
          if (result) {
            setShowManageCreditCard(false);
            dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddCreditCard].successMessage, type: ActionMessageType.Success }));
            if (isAddingFirstCard) {
              onAddFristNewCard();
            }
          }
        });
      }
    }
  };

  const deleteCard = (account: IAccount, creditCard: IExistingCreditCard) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(deleteCreditCard(account, creditCard));
      resolve(newAccountId);
    });

  const handleOnDeleteCreditCard = () => {
    if (selectedAccount && selectedCreditCard) {
      setDeleteInProgress(true);
      deleteCard(selectedAccount, selectedCreditCard).then(result => {
        dispatch(setSelectedCreditCardAction(undefined));
        setDeleteInProgress(false);
        setShowDeleteCreditCard(false);
        if (result) {
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.DeleteCreditCard].successMessage, type: ActionMessageType.Success }));
        }
      });
    }
  };

  const handleOnOpenDeleteDialog = () => {
    setShowDeleteCreditCard(true);
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeleteCreditCard(false);
  };

  return (
    <div>
      <Button data-testid="manageCreditCards" variant={"contained"} size={"large"} onClick={handleOpenManageCreditCards} isLoading={loadingManageCreditCard} disabled={disabled || loadingInvoices || loadingManageCreditCard || (loadingCreditCards && !loadingCreditCardsCanceled)} style={{ marginRight: "15px" }}>
        MANAGE CREDIT CARDS
      </Button>

      {(isAddingFirstCard || (showManageCreditCard && selectedAccount)) && <CreditCardsDialog showDialog={isAddingFirstCard || showManageCreditCard} onCancel={handleOnCancelShowManageCreditCard} onSubmit={handleOnSubmitCard} invoice={undefined} actionInProgress={actionInProgress} onDelete={handleOnOpenDeleteDialog} currency={""} />}
      {showDeleteCreditCard && selectedAccount && <DeleteCreditCard showDialog={showDeleteCreditCard} onCancel={handleOnCancelDeleteDialog} onDeleteCard={handleOnDeleteCreditCard} isActionInProgress={deleteInProgress} />}
    </div>
  );
};

export default ManageCreditCardsButton;
