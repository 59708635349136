import React, { useState } from "react";
import AssignedProductsTable from "./AssignedProductsTable";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress } from "@cuda-networks/bds-core";
import { enterKey, handleBackdropClick } from "../../../utility";
import { useNavigate } from "react-router-dom";

interface ISubmitDialogProps {
  onCancel: () => void;
  showDialog: boolean;
}

const AssignedProductsDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onCancel }) => {
  const navigate = useNavigate();
  const loadingSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.loadingSubpartnersWithProducts);
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectedSubpartnerLoading, setIsSelectedSubpartnerLoading] = useState(false);

  const handleGoToAccount = (accountId: number): void => {
    setIsSelectedSubpartnerLoading(true);
    navigate(`/accounts/${accountId}/products-services`);
  };

  const handleOnFocusedSearch = (isSearchbarFocused: boolean) => {
    setIsFocused(isSearchbarFocused);
  };

  return (
    <Dialog onKeyUp={(event: any) => (!isFocused ? enterKey(event, onCancel) : "")} data-testid="assignedProductsDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth="md" disableEnforceFocus>
      <DialogTitle data-testid="assignedProductsDialogTitle" style={{ backgroundColor: "primary" }}>
        Subpartners' Catalog
      </DialogTitle>
      <DialogContent>
        {loadingSubpartnersWithProducts ? (
          <div data-testid="loadingAssignedSubpartners">
            <LinearProgress />
          </div>
        ) : (
          <div data-testid="assignedProductsTable">
            <AssignedProductsTable onGoToAccount={handleGoToAccount} onFocusedSearch={handleOnFocusedSearch} showLoading={isSelectedSubpartnerLoading} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ flex: "1 0 0" }} />
        <Button data-testid="assignedProductsCloseButton" size="large" onClick={onCancel} disabled={isSelectedSubpartnerLoading}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignedProductsDialog;
