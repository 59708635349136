import { Grid, Typography } from "@cuda-networks/bds-core";
import React, { useEffect, useState } from "react";
import MspType from "../../../../models/MspType";
import EntitlementsTable from "../EntitlementsTable";
import UserRole from "../../../../models/UserRole";
import IAccount from "../../../../models/IAccount";
import IEntitlement from "../../../../models/IEntitlement";
import { IUserRoleType } from "../../../../Utilities/usersHelper";

interface IEntitlementsStepProps {
  userParentAccount: IAccount | undefined;
  selectedRole: IUserRoleType;
  entitlements: IEntitlement[];
  isEditingLoggedInUser: boolean;
  isAddFirstUser: boolean;
  isActionInProgress: boolean;
  onEntitlementsSelectionChanged: (items: IEntitlement[]) => void;
}

const EntitlementsStep: React.FC<IEntitlementsStepProps> = ({ userParentAccount, selectedRole, entitlements, onEntitlementsSelectionChanged, isEditingLoggedInUser, isAddFirstUser, isActionInProgress }) => {
  const [showEntitlements, setShowEntitlements] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setShowEntitlements(userParentAccount?.type !== MspType.Customer && userParentAccount?.type !== MspType.BillingAggregator && selectedRole !== UserRole.Finance);
  }, [userParentAccount, selectedRole]);

  useEffect(() => {
    setDisabled(isActionInProgress || isEditingLoggedInUser || isAddFirstUser);
  }, [isActionInProgress, isEditingLoggedInUser, isAddFirstUser]);

  return (
    <div data-testid="addEditLoginEntitlementsStep">
      <Grid item container spacing={3}>
        {showEntitlements && (
          <Grid container item xs={12}>
            <Grid item xs={12} container direction="column">
              <Grid item>
                <Typography variant="subtitle2" gutterBottom>
                  ENTITLEMENTS
                </Typography>
              </Grid>
              <Grid item>
                <div data-testid={"entitlementsTable"}>
                  <EntitlementsTable selectionChanged={onEntitlementsSelectionChanged} userEntitlements={entitlements} disabled={disabled} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default EntitlementsStep;
