import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../store/store";
import { AccountActionTypes, ISetDisplayItemsAction } from "./accountActions";
import { IAccountFilters } from "../models/IAccountFilters";
import { areFiltersActive, computeItemsToFilter, filterResultsByType, filterSmbByName, getFilterMethod, getNamesForFilteredAccounts, mapParentAccountNameToFilterResults, searchAccount } from "../Utilities/accountsHelper";
import { cancelCurrent, cancelFilterAccountsActionTokenAndCreateNew } from "./cancelAction";
import { dynamicSort } from "../utility";
import mspService from "../service/mspService";
import { handleError } from "./actionsErrorHandler";
import IAccount from "../models/IAccount";

export const filterAccountsFromFiltersPopupAction: ActionCreator<ThunkAction<any, IAppState, null, ISetDisplayItemsAction>> = (newFilters: IAccountFilters) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccounts } = getState().accountState;
    let results = await filterAccountsFromFiltersPopup(getState, dispatch, newFilters);
    if (results !== undefined && results.length > 0) {
      results.sort(dynamicSort("name"));
      results = mapParentAccountNameToFilterResults(results, mspAccounts);
    }
    return results;
  };
};

export async function filterAccountsFromFiltersPopup(getState: () => IAppState, dispatch: Dispatch<any>, filters: IAccountFilters) {
  const cancelTokenSource = getState().accountState.filterAccountsCancellationTokenSource;
  cancelCurrent(cancelTokenSource);
  const { mspAccountLoggedIn } = getState().generalState;
  const { accountsNames } = getState().accountState;
  const { mspAccounts } = getState().accountState;
  const { filterChildrenOfAccountId, directChildrenOnly } = getState().accountState;
  const itemsToFilter = computeItemsToFilter(mspAccountLoggedIn, accountsNames, mspAccounts, filterChildrenOfAccountId, directChildrenOnly);
  if (areFiltersActive(filters)) {
    const filterMethod = getFilterMethod(filters);
    if (!filterMethod.filterByName && !filterMethod.filterByExtraParams) {
      return itemsToFilter;
    } else {
      if (filterMethod.filterByExtraParams) {
        if (filterChildrenOfAccountId) {
          if (filterMethod.filterByName) {
            return await filterByNameAndExtraParams(getState, dispatch, filterChildrenOfAccountId, filters, directChildrenOnly, itemsToFilter);
          } else {
            return await filterOnlyByExtraParams(getState, dispatch, filterChildrenOfAccountId, filters, itemsToFilter, directChildrenOnly);
          }
        }
        return itemsToFilter;
      } else {
        dispatch({
          type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
          loadingFilteredAccounts: false,
        });
        cancelCurrent(cancelTokenSource);
        return filterOnlyByName(getState, filters.name, itemsToFilter);
      }
    }
  }
  dispatch({
    type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
    loadingFilteredAccounts: false,
  });
  return itemsToFilter;
}

async function filterByNameAndExtraParams(getState: () => IAppState, dispatch: Dispatch, accountId: number, filters: IAccountFilters | undefined, filterOnlyDirectAccounts: boolean | undefined, itemsToFilter: IAccount[]) {
  if (filters?.name !== undefined) {
    const { apiUrl } = getState().generalState;
    const { accountsNames } = getState().accountState;

    try {
      dispatch({
        type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
        loadingFilteredAccounts: true,
      });
      const newCancelTokenSource = cancelFilterAccountsActionTokenAndCreateNew(getState, dispatch);
      const results = await mspService.filterAccounts(apiUrl, accountId, filters, filterOnlyDirectAccounts, newCancelTokenSource.token);
      const resultNameUpdated = getNamesForFilteredAccounts(results, accountsNames);
      dispatch({
        type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
        loadingFilteredAccounts: false,
      });
      if (resultNameUpdated.length > 0) {
        return filterSmbByName(resultNameUpdated, filters.name);
      } else {
        return [];
      }
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
            loadingFilteredAccounts: false,
          });
        },
        () => {},
      );
      return undefined;
    }
  }
}

async function filterOnlyByExtraParams(getState: () => IAppState, dispatch: Dispatch, accountId: number, filters: IAccountFilters, itemsToFilter: IAccount[], filterOnlyDirectAccounts: boolean | undefined) {
  try {
    dispatch({
      type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
      loadingFilteredAccounts: true,
    });
    const newCancelTokenSource = cancelFilterAccountsActionTokenAndCreateNew(getState, dispatch);
    const { apiUrl } = getState().generalState;
    const { accountsNames } = getState().accountState;
    const results = await mspService.filterAccounts(apiUrl, accountId, filters, filterOnlyDirectAccounts, newCancelTokenSource.token);
    dispatch({
      type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
      loadingFilteredAccounts: false,
    });
    return getNamesForFilteredAccounts(results, accountsNames);
  } catch (err) {
    handleError(
      err,
      dispatch,
      () => {
        dispatch({
          type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
          loadingFilteredAccounts: false,
        });
      },
      () => {},
    );
    return undefined;
  }
}

function filterOnlyByName(getState: () => IAppState, name: string | undefined, itemsToFilter: IAccount[]): IAccount[] {
  if (name !== undefined) {
    const { isBaLoggedIn } = getState().generalState;
    const { mspAccountLoggedIn } = getState().generalState;
    const { mspAccounts } = getState().accountState;

    const result: IAccount[] = isBaLoggedIn ? searchAccount(mspAccountLoggedIn, mspAccounts, name) : filterSmbByName(itemsToFilter, name);
    const filteredResults = filterResultsByType(result, mspAccountLoggedIn);
    filteredResults.sort(dynamicSort("name"));
    return filteredResults;
  }
  return itemsToFilter;
}
