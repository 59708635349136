export function parseUrl(path: string, pattern: string): Record<string, string> {
  // Split the pattern and the path into segments
  const patternSegments = pattern.split("/").filter(Boolean);
  const pathSegments = path.split("/")?.filter(Boolean);

  // Initialize an empty object to hold the parameters
  const params: Record<string, string> = {};

  // Iterate over the pattern segments
  patternSegments.forEach((segment: string, index: number) => {
    // If the segment starts with ":", it's a dynamic parameter
    if (segment.startsWith(":")) {
      const paramName: string = segment.slice(1); // Remove the ":" to get the parameter name
      params[paramName] = pathSegments[index]; // Map the parameter name to the corresponding value
    }
  });

  return params;
}

export function getParamsFromUrl(): Record<string, string> {
  const pattern = "/accounts/:accountId/:tabId";
  const params = parseUrl(window.location.pathname, pattern);
  return params;
}

export function getAccountIdFromUrl(): string {
  const params = getParamsFromUrl();
  return params.accountId;
}
