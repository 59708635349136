import React from "react";

const IntegrationPlanNameCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  if (props.dataItem.canceled && props.dataItem.canceled === true) {
    return <td className="DarkRedColor">Canceled Service/Plan</td>;
  } else {
    return <td>{props.dataItem.subname}</td>;
  }
};

export default IntegrationPlanNameCell;
