import { NO_VALID_ROLE_ERROR_CODE } from "../actions/actionsErrorHandler";
import { getErrorMessage } from "../utility";

export class NoValidRoleError extends Error {
  errorMessage: string = "";
  errorCode: string | undefined = "";

  constructor(error: any) {
    super();
    const errorM = getErrorMessage(error.data?.errors);
    this.errorMessage = errorM.errorMessage;
    this.errorCode = NO_VALID_ROLE_ERROR_CODE;
  }
}
