import { Typography, Grid, Alert } from "@cuda-networks/bds-core";
import React, { useEffect, useState } from "react";
import { getAccountDisplayType } from "../../../Utilities/accountsHelper";
import ActionMessageType from "../../../models/ActionMessageType";
import MspType from "../../../models/MspType";

interface IDeleteAccountWithNoSerialsProps {
  type: string;
}

const DeleteAccountWithNoSerials: React.FC<IDeleteAccountWithNoSerialsProps> = ({ type }) => {
  const [typeDisplay, setTypeDisplay] = useState("");

  useEffect(() => {
    setTypeDisplay(getAccountDisplayType(type).toLowerCase());
  }, [type]);

  return (
    <div className="AccountIcons" data-testId="deleteAccountWithNoSerialsMessage">
      <Grid item spacing={3} style={{ paddingBottom: "15px" }}>
        <Alert severity={ActionMessageType.Warning}>
          <Typography gutterBottom>Caution! This deletion is permanent and cannot be undone.</Typography>
          <Typography gutterBottom>
            This action deletes the {typeDisplay} from your accounts list and all associated {typeDisplay}-level logins.
            {type === MspType.Subpartner && <Typography gutterBottom>All direct accounts of this {typeDisplay} and the associated account-level logins will be deleted.</Typography>}
          </Typography>
        </Alert>
      </Grid>
      <Grid item spacing={3}>
        <Typography gutterBottom>
          Click the <b>Delete</b> button below to proceed.
        </Typography>
      </Grid>
    </div>
  );
};

export default DeleteAccountWithNoSerials;
