enum CreditCardType {
  VISA = "VISA",
  JCB = "JCB",
  DinersCarteBlanche = "Diners - Carte Blanche",
  Diners = "Diners",
  DISC = "DISC",
  AMEX = "AMEX",
  MC = "MC",
  VisaElectron = "Visa Electron",
}

export default CreditCardType;
