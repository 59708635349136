import React from "react";
import { isSecureEdgeServiceSku } from "../../Utilities/productsHelper";

const UserDisplayCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  if (!isSecureEdgeServiceSku(props.dataItem.sku)) {
    return <td>{props.item.users}</td>;
  } else {
    return <td>N/A</td>;
  }
};

export default UserDisplayCell;
