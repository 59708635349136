import React from "react";
import Capacity from "./Capacity";

const CapacityCell = (props: any) => {
  const { rowType, capacity } = props;
  if (rowType === "groupHeader") {
    return null;
  }
  return (
    <td>
      <Capacity capacity={capacity} />
    </td>
  );
};

export default CapacityCell;
