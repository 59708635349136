import React, { useEffect, useState } from "react";
import { Grid, Typography, Alert } from "@cuda-networks/bds-core";
import DeleteAccountSerialsTable, { IDeleteAccountSerial } from "./DeleteAccountSerialsTable";
import { getAccountDisplayType } from "../../../Utilities/accountsHelper";
import ActionMessageType from "../../../models/ActionMessageType";
import MspType from "../../../models/MspType";

interface IDeleteAccountWithSerialsProps {
  serials: IDeleteAccountSerial[];
  type: string;
}

const DeleteAccountWithSerials: React.FC<IDeleteAccountWithSerialsProps> = ({ serials, type }) => {
  const [typeDisplay, setTypeDisplay] = useState("");

  useEffect(() => {
    setTypeDisplay(getAccountDisplayType(type).toLowerCase());
  }, [type]);

  return (
    <div>
      <Grid item container spacing={3} data-testid="deleteAccountWithActivatedSerialsMessage">
        <Grid container item xs={12}>
          <Grid item xs={12} container direction="column">
            <Grid item spacing={3} style={{ paddingBottom: "15px" }}>
              <Alert severity={ActionMessageType.Warning}>
                <Typography gutterBottom>Caution! This deletion is permanent and cannot be undone.</Typography>
                <Typography gutterBottom>
                  This action deletes the {typeDisplay} from your accounts list and all associated {typeDisplay}-level logins.
                </Typography>
                {type === MspType.Subpartner && <Typography gutterBottom>All direct accounts of this {typeDisplay} and the associated account-level logins will be deleted.</Typography>}
                <Typography gutterBottom>The {typeDisplay}’s activated products/services that will be deleted are listed below.</Typography>
              </Alert>
            </Grid>
            <Grid item spacing={3} style={{ paddingBottom: "10px" }}>
              <Typography gutterBottom>
                Click the <b>Delete</b> button below to proceed.
              </Typography>
            </Grid>
            <Grid item spacing={3}>
              <div className="deleteAccountSerialsTable" data-testid={"deleteAccountSerialsTable"}>
                <DeleteAccountSerialsTable serials={serials} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeleteAccountWithSerials;
