import { LinearProgress } from "@cuda-networks/bds-core";
import React, { useEffect, useState } from "react";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersForAccountsFilteringAction } from "../../../actions/productActions";
import useWhyDidYouUpdate from "./useWhyDidYouUpdate";
import { getOrders } from "../../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";

interface IFilterAccountListDialogProps {
  textFieldWidth: number;
  children?: React.ReactNode;
}

const FilterAccountsPopoverLoader = (props: IFilterAccountListDialogProps) => {
  const { children } = props;
  const dispatch = useDispatch();
  const loadingOrdersForAccountId = useSelector((state: IAppState) => state.productState.loadingOrdersForAccountId);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const filterChildrenOfAccountId = useSelector((state: IAppState) => state.accountState.filterChildrenOfAccountId);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(loadingOrdersForAccountId > 0);
    const filterOwnerId = filterChildrenOfAccountId ?? mspAccountLoggedIn.id;
    if (loadingOrdersForAccountId === 0) {
      if (accountsOrders.length > 0) {
        const currentOrders = getOrders(accountsOrders, filterChildrenOfAccountId);
        if (currentOrders === undefined) {
          dispatch(getOrdersForAccountsFilteringAction(filterOwnerId));
        } else {
          if (filterChildrenOfAccountId !== mspAccountLoggedIn.id) {
            const currentOrders = getOrders(accountsOrders, mspAccountLoggedIn.id);
            if (currentOrders === undefined) {
              dispatch(getOrdersForAccountsFilteringAction(mspAccountLoggedIn.id));
            }
          }
        }
      } else {
        dispatch(getOrdersForAccountsFilteringAction(filterOwnerId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOrdersForAccountId, accountsOrders, filterChildrenOfAccountId]);

  useWhyDidYouUpdate("FilterAccountsPopoverLoader", props);

  return (
    <>
      {showLoading ? (
        <div style={{ width: props.textFieldWidth - 35 }} data-testid="loadingFilterDialog">
          <LinearProgress />
        </div>
      ) : (
        <div data-testid="loadedFilterDialog">{children}</div>
      )}
    </>
  );
};

export default React.memo(FilterAccountsPopoverLoader);
