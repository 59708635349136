import { useMemo } from "react";
import IAccount from "../../../models/IAccount";
import { UrlFilterExtractor } from "./UrlFilterExtractor";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import MspType from "../../../models/MspType";
import { filtersAreActive } from "./UrlFilterParams";
import { getAccountIdFromUrl } from "../../../Utilities/UrlParser";

export const useExpandedPartner = () => {
  const accountId = getAccountIdFromUrl();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);

  const expandedPartner = useMemo(() => {
    return isPartnerExpanded(window.location.search, accountId, mspAccounts, accountsNames, mspAccountLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, accountId, mspAccounts, accountsNames, mspAccountLoggedIn]);

  return expandedPartner;
};

export const isPartnerExpanded = (url: string, accountId: string | undefined, mspParentAccounts: IAccount[], allAccounts: IAccount[], mspAccountLoggedIn: IAccount): IAccount | undefined => {
  if (mspAccountLoggedIn.type !== MspType.Partner || (mspAccountLoggedIn.type === MspType.Partner && mspParentAccounts.length === 1)) {
    return undefined;
  }

  if (filtersAreActive()) {
    const filters = new UrlFilterExtractor(url, []).extractFilters();
    if (filters.accountsOf) {
      const accId = parseInt(filters.accountsOf);
      if (accId) {
        if (mspAccountLoggedIn.id === accId) {
          return mspAccountLoggedIn;
        }
        const account = mspParentAccounts.find(account => account.id === accId);
        return account as IAccount;
      }
    }
  } else {
    if (accountId) {
      const accId = parseInt(accountId);
      if (accId) {
        if (mspAccountLoggedIn.id === accId) {
          return mspAccountLoggedIn;
        }
        const account = allAccounts.find(account => account.id === accId);
        if (account) {
          switch (account.type) {
            case MspType.Partner:
            case MspType.Subpartner:
            case MspType.BillingAggregator:
              return account as IAccount;

            case MspType.Customer:
              const parent = mspParentAccounts.find(acc => acc.id === account?.closestParentId);
              return parent as IAccount;
          }
        }
      }
    }
  }

  return undefined;
};
