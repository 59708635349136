import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, DataTable, DataTableColumn as Column } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { process } from "@progress/kendo-data-query";
import { useMediaQuery } from "@material-ui/core";
import IAccount from "../../../models/IAccount";
import { getButtonCount, handleBackdropClick } from "../../../utility";
import ProductLicenseName from "./ProductLicenseName";
import IProductLicenseList from "../../../models/ProductLicenseList";

interface ISubmitDialogProps {
  onCancel: () => void;
  showDialog: boolean;
  userName: string;
  account: IAccount | undefined;
  initLoading: boolean;
  productLicenseList: IProductLicenseList;
}

const ProductLicensesDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onCancel, userName, account, initLoading, productLicenseList }) => {
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const dataState = {
    skip: 0,
    take: 10,
    group: [],
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "Name",
        field: "name",
        show: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process([], dataState as any),
  });

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const dataStateChange = (e: any): void => {
    if (productLicenseList.productLicenses.length > 0) {
      setGridState({
        dataState: { ...dataState, ...e.dataState },
        dataResult: process(productLicenseList.productLicenses, e.dataState),
      });
    }
  };

  useEffect(() => {
    if (!initLoading) {
      setGridState({
        dataState: {
          skip: 0,
          take: 10,
          group: [],
          collapsedGroups: [],
          selectedItem: "any",
          lastSelectedIndex: 0,
          columns: [
            {
              title: "Name",
              field: "name",
              show: true,
            },
          ],
        },
        dataResult: { data: productLicenseList.productLicenses.slice(0, 10), total: productLicenseList.totalCount },
      });
    }
  }, [initLoading, productLicenseList]);

  const ProductLicenseCell = (props: any) => <ProductLicenseName {...props} />;

  return (
    <Dialog className="m365AuthLinkDialog" data-testid="productLicensesDialog" onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} open={showDialog} maxWidth={false} style={{ zIndex: 4002 }}>
      <div className="" style={{ width: "500px" }}>
        <DialogTitle data-testid="productLicensesDialogTitle" id="alert-dialog-title">
          {userName}
        </DialogTitle>
        <DialogContent>
          <div className="DialogContentDiv" style={{ padding: 15 }}>
            <Grid container item xs={12} direction="row">
              <Grid container item xs={12} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                {initLoading && <CircularProgress data-testid="productLicensesLoadingProgress" />}
              </Grid>
            </Grid>
            {!initLoading && (
              <div>
                <DataTable
                  className={"ProductLicenseTable noScrollbar noBorders"}
                  data={gridState.dataResult}
                  resizable
                  pageConfig={{
                    pageable: {
                      buttonCount: buttonCount,
                    },
                    skip: 0,
                    take: 10,
                    total: gridState.dataResult.total,
                  }}
                  sortConfig={{
                    sortable: true,
                  }}
                  pager={gridState.dataResult.data.length > 0 && Pager}
                  onDataStateChange={dataStateChange}
                  {...(gridState.dataState as any)}
                >
                  <Column key={"productLicense"} field={"name"} cell={ProductLicenseCell} title={"BILLABLE LICENSES"} minResizableWidth={30} resizable={false} headerClassName={"m365domainHeader"} />
                </DataTable>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Grid container item xs={12} direction="row" alignItems={"center"} justifyContent={"center"}>
            <Button data-testid="productLicensesCloseBtn" variant="contained" color="primary" size="large" onClick={onCancel}>
              CLOSE
            </Button>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProductLicensesDialog;
