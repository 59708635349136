import React, { useState, useEffect } from "react";
import { Grid, IconButton, Button, Typography, Tooltip } from "@cuda-networks/bds-core";
import { Refresh, FilterNone, Delete } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { renewApplicationAction, deleteApplicationAction } from "../../actions/apiCredentialActions";
import { setSnackBarMessage } from "../../actions/generalActions";
import ActionMessageType from "../../models/ActionMessageType";
import ConfirmMessageDialog from "../ConfirmMessageDialog";
import ApiCredentialsDeleteDialog from "./ApiCredentialsDeleteDialog";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";

interface IApiCredentialsItemProps {
  nameParam: string;
  emailParam: string;
  applicationParam: string;
  clientIdParam?: string;
  clientSecretParam?: string;
  justAdded?: boolean;
  showSecret?: boolean;
}

const ApiCredentialsItem: React.FC<IApiCredentialsItemProps> = ({ nameParam, emailParam, applicationParam, clientIdParam, clientSecretParam, justAdded, showSecret }: IApiCredentialsItemProps) => {
  const dispatch = useDispatch();
  const [openRenew, setRenewOpen] = useState(false);
  const [copySecretButtonDisabled, setCopySecretButtonDisabled] = useState(true);
  const [isRenewActionInProgress, setRenewIsActionInProgress] = useState(false);

  let title = <Typography variant="h6">Renew Client Application</Typography>;
  let message = (
    <div>
      <Typography variant="body1">
        Are you sure you want to renew client application <span style={{ fontWeight: 600 }}>{emailParam}</span> ?
      </Typography>

      <br />
      <Typography variant="body1">Renewing these credentials invalidates API credentials currently in use by other platforms. Please confirm this action.</Typography>
    </div>
  );
  useEffect(() => {
    setCopySecretButtonDisabled(!justAdded && !showSecret);
  }, [justAdded, showSecret]);

  const onCopyClientId = () => {
    navigator.clipboard.writeText(clientIdParam + "");
    dispatch(setSnackBarMessage({ message: "Client ID has been added to your clipboard", type: ActionMessageType.Info }));
  };

  const onCopyClientSecret = () => {
    navigator.clipboard.writeText(clientSecretParam + "");
    dispatch(setSnackBarMessage({ message: "Client Secret has been added to your clipboard", type: ActionMessageType.Info }));
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteApplication = async (applicationId: string | undefined) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(deleteApplicationAction(applicationId));
      resolve(success);
    });

  const deleteCallback = (applicationId: string | undefined) => {
    deleteApplication(applicationId).then(success => {
      if (success) {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.DeleteApiCredentials].successMessage, type: ActionMessageType.Success }));
      }
    });
  };

  const renewApplication = () =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(renewApplicationAction(clientIdParam));
      resolve(success);
    });

  const onRenew = () => {
    setRenewOpen(true);
  };

  const closeRenew = () => {
    setRenewOpen(false);
  };

  const renewCallback = () => {
    setRenewIsActionInProgress(true);
    renewApplication().then(success => {
      setRenewIsActionInProgress(false);
      if (success) {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.RenewApiCredentials].successMessage, type: ActionMessageType.Success }));
      }
    });
  };

  return (
    <div style={{ margin: "20px 0px 10px 0", padding: "10px 10px 12px 12px", borderRadius: "5px", backgroundColor: "whitesmoke" }}>
      <Grid container item style={{ width: "20", height: "100%", justifyContent: "flex-end", alignItems: "flex-start" }}>
        <Tooltip title="Delete">
          <IconButton onClick={handleOpenDeleteDialog} style={{ padding: "2px" }} data-testid="apiCredentialDeleteButton">
            <Delete />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container style={{ display: "flex", overflow: "hidden" }} data-testid="apiCredentialsItem">
        <Grid item xs style={{ minWidth: "200px", flexDirection: "column", textAlign: "left", paddingTop: "10px" }}>
          <Tooltip title={emailParam} placement="bottom-start">
            <div style={{ display: "inline-block", maxWidth: "100%" }}>
              <Typography
                data-testid="apiCredentialsItemEmail"
                noWrap
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {emailParam}
              </Typography>
            </div>
          </Tooltip>
          <div />
          <Tooltip title={nameParam} placement="bottom-start">
            <div style={{ display: "inline-block", maxWidth: "100%" }}>
              <Typography
                data-testid="apiCredentialsItemName"
                noWrap
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {nameParam}
              </Typography>
            </div>
          </Tooltip>
          <div />
          <Tooltip title={applicationParam} placement="bottom-start">
            <div style={{ display: "inline-block", maxWidth: "100%" }}>
              <Typography
                data-testid="apiCredentialsItemApplication"
                noWrap
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Application: {applicationParam}
              </Typography>
            </div>
          </Tooltip>
        </Grid>
        {/* Empty grid item for spacing */}
        <Grid item style={{ minWidth: "40px" }} />
        <Grid item style={{ padding: "10px 30px 0px 0px" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", fontSize: "16px" }}>
            <Typography
              data-testid="apiCredentialsItemClientId"
              gutterBottom
              noWrap
              style={{
                marginTop: "4px",
                marginRight: "15px",
                minWidth: "100px",
                fontSize: "16px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Client Id :
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                border: "none",
                paddingTop: "5px",
                overflow: "hidden",
                textAlign: "left",
                width: "290px",
                marginRight: "15px",
              }}
            >
              {clientIdParam || ""}
            </Typography>

            <Tooltip title="Copy Client Id into clipboard">
              <IconButton data-testid="apiCredentialsItemClientIdButton" onClick={onCopyClientId} style={{ padding: "2px" }}>
                <FilterNone />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <Typography
              data-testid="apiCredentialsItemClientSecret"
              gutterBottom
              noWrap
              style={{
                marginTop: "4px",
                marginRight: "15px",
                minWidth: "100px",
                fontSize: "16px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Client Secret :
            </Typography>

            <div style={{ marginTop: "7px", marginRight: "15px", width: "290px" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  border: "none",
                  minHeight: "20px",
                  textAlign: "left",
                  overflow: "hidden",
                }}
              >
                {clientSecretParam || "****************************************"}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  marginBottom: "5px",
                  textAlign: "left",
                  width: "290px",
                }}
              >
                Renew for Client Secret
              </Typography>
            </div>

            <Tooltip title="Copy Client Secret into clipboard">
              <IconButton data-testid="apiCredentialsItemClientSecretButton" onClick={onCopyClientSecret} style={{ padding: "2px" }} disabled={copySecretButtonDisabled}>
                <FilterNone />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <Grid item style={{ display: "flex", alignItems: "flex-start", marginTop: "21px" }}>
        <Button startIcon={<Refresh style={{ transform: "scale(0.8)" }} />} data-testid="apiCredentialRenewButton" color="primary" variant="outlined" onClick={onRenew} style={{ width: "102px", height: "30px", fontSize: "12px", fontWeight: 600 }}>
          RENEW
        </Button>
        <div style={{ width: "10px" }}></div>
      </Grid>
      <ConfirmMessageDialog isActionInProgress={isRenewActionInProgress} message={message} title={title} onClose={closeRenew} open={openRenew} onConfirm={renewCallback} />
      <ApiCredentialsDeleteDialog info={{ email: emailParam, name: nameParam, application: applicationParam, clientId: clientIdParam }} open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog} onConfirm={deleteCallback} />
    </div>
  );
};

export default ApiCredentialsItem;
