import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import React from "react";
import IProduct from "../../../models/Products/IProduct";
import ISerial from "../../../models/Products/ISerial";
import { enterKey, handleBackdropClick } from "../../../utility";
import { IMigrationObject } from "../ProductsTab";

interface IShowMigrateConfirmationDialog {
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  onSubmit: (product: IProduct, serial: ISerial, changeToProduct: IProduct) => void;
  migrationOption: IMigrationObject | undefined;
}
const ShowMigrateConfirmationDialog: React.FC<IShowMigrateConfirmationDialog> = ({ showDialog, onCancel, onSubmit, isActionInProgress, migrationOption }) => {
  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, onSubmit)} disableEscapeKeyDown={isActionInProgress} data-testid="migrationConfirmationDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
        <div style={{ width: "500px" }}>
          <DialogTitle data-testid="migrationConfirmationTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
            Change Service
          </DialogTitle>
          <DialogContent data-testid="migrationConfirmationMessage">
            <p>
              Changing from <b>{migrationOption?.orderLineItem.subname}</b> to <b>{migrationOption?.lineItem.subname}</b>. <br />
              <br />
              Please confirm to proceed or cancel to select a different license to change.
            </p>
          </DialogContent>
          <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
            <Button data-testid="migrationConfirmationCancelationButton" variant="text" size="large" onClick={onCancel} disabled={isActionInProgress}>
              CANCEL
            </Button>
            <Button data-testid="migrationConfirmationConfirmationButton" color="primary" size="large" onClick={onSubmit} isLoading={isActionInProgress} disabled={isActionInProgress}>
              CONFIRM
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ShowMigrateConfirmationDialog;
