import { getErrorMessage } from "../utility";

export class ApiError extends Error {
  errorMessage: string = "";
  errorCode: string | undefined = "";

  constructor(error: any) {
    super();
    const errorM = getErrorMessage(error.data?.errors);
    this.errorMessage = errorM.errorMessage;
    this.errorCode = errorM.errorCode;
  }
}
