import React, { useEffect, useState } from "react";
import { DataTable, DataTableColumn as Column, Pager, Typography, CircularProgress } from "@cuda-networks/bds-core";
import { State, process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, useMediaQuery } from "@material-ui/core";
import { IAppState } from "../../../store/store";
import { dataStateHasChanged, filterHasChanged, getButtonCount } from "../../../utility";
import { filterContainInput, getColorForColumn } from "../../TableHelpers";
import { filtersPresent, getCwAccountIntegrationStatus, getIntegrationAccountsActionName, getIntegrationFiltering, getIntegrationSorting, isFilterOn } from "../../../Utilities/integrationsUtilities";
import ButtonCommandCell from "../../ButtonCommandCell";
import { Refresh } from "@material-ui/icons";
import { getIntegrationAccountsAction, getIntegrationFilteredAccountsAction, setIntegrationAccountsTablePropsAction } from "../../../actions/integrations/integrationsAccountsActions";
import IntegrationAccountIconCell from "./IntegrationAccountIconCell";
import IIntegrationAccount from "../../../models/Integrations/IIntegrationAccount";
import { resetPastPlansAction } from "../../../actions/integrations/integrationsBillingActions";
import CustomMessageDialog from "../../CustomMessageDialog";
import IntegrationAccountStatus from "../../../models/Integrations/IntegrationAccountStatus";
import TooltipElement from "../../Users/TooltipElement";
import { setSnackBarMessage } from "../../../actions/generalActions";
import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";
import ActionMessageType from "../../../models/ActionMessageType";
import { PAGE_SIZES } from "../../../models/TableConfig";

interface IIntegrationAccountsProps {
  openIntegrationAccountsActionDialog: (integrationAccount: IIntegrationAccount) => void;
}

const IntegrationsAccountsTable: React.FC<IIntegrationAccountsProps> = ({ openIntegrationAccountsActionDialog }) => {
  const dispatch = useDispatch();
  const integrationAccounts = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccounts);
  const integrationAccountsCount = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccountsCount);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const selectedIntegration = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const tableState = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccountsTableState);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const loadingIntegrationAccountsDataStateChanged = useSelector((state: IAppState) => state.integrationsAccountsState.loadingIntegrationAccountsDataStateChanged);
  const showUnlinkedAccounts = useSelector((state: IAppState) => state.integrationsAccountsState.showUnlinkedAccounts);
  const [buttonCount, setButtonCount] = useState(10);
  const [showResetPastPlansDialog, setShowResetPastPlansDialog] = useState(false);
  const [selectedCwAccountId, setSelectedCwAccountId] = useState(0);
  const [showTableLoading, setShowTableLoading] = useState(false);
  const resetPastPlansMessage: React.JSX.Element = (
    <div>
      <Typography variant={"body1"}>Are you sure you would like to reset all past plans? This will stop the integration from attempting to adjust the agreement additions created for plans to which this account was previously linked down to zero.</Typography>
      <br />
      <Typography variant={"body1"}>Please ensure that all past agreement additions on this company’s agreement are adjusted to zero before resetting past plans.</Typography>
    </div>
  );

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "MSP ACCOUNT",
        field: "mspAccountName",
        show: true,
        filter: "text",
        filtrable: true,
      },
      {
        title: "COMPANY (CONNECTWISE)",
        field: "companyName",
        show: true,
        filter: "text",
        filtrable: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: { data: integrationAccounts, total: integrationAccountsCount },
  });

  useEffect(() => {
    setShowTableLoading(loadingIntegrationAccountsDataStateChanged);
    // eslint-disable-next-line
  }, [loadingIntegrationAccountsDataStateChanged]);

  useEffect(() => {
    if (selectedIntegration) {
      const integrationAccountsToDisplay = integrationAccounts.map(account => ({
        ...account,
        companyName: account.companyName.length > 0 ? account.companyName : "-",
      }));
      computeDataResult(tableState.filter, gridState.dataState, integrationAccountsToDisplay, integrationAccountsCount);
    }
    // eslint-disable-next-line
  }, [integrationAccounts, integrationAccountsCount, gridState.dataState]);

  useEffect(() => {
    const newDataState = { ...dataState, skip: tableState.skip, take: tableState.take, sort: tableState.sort, filter: tableState.filter };
    computeDataResult(tableState.filter, newDataState, integrationAccounts, integrationAccountsCount);
    // eslint-disable-next-line
  }, [tableState, integrationAccounts, integrationAccountsCount]);

  const computeDataResult: any = (filter: any, newDataState: any, accList: IIntegrationAccount[], integrationAccountsCount: number) => {
    if ((loadingIntegrationAccountsDataStateChanged && accList.length > newDataState.take) || isFilterOn(filter)) {
      const newData = process(accList, newDataState);
      setGridState({
        dataState: newDataState,
        dataResult: newData,
      });
    } else {
      setGridState({
        dataState: newDataState,
        dataResult: { data: accList, total: integrationAccountsCount },
      });
    }
  };

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const getIntegrationAccountsPage = (take: number, skip: number, sortBy: string | undefined, ascending: boolean | undefined) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getIntegrationAccountsAction(selectedAccount, skip, take, sortBy, ascending, showUnlinkedAccounts));
      resolve(result);
    });

  const getFilteredIntegrationAccounts = (forAccount: string | undefined, forCompany: string | undefined) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getIntegrationFilteredAccountsAction(selectedAccount, forAccount, forCompany, showUnlinkedAccounts));
      resolve(result);
    });

  const dataStateChange = (e: any, pastPlanReseted?: boolean): void => {
    if (dataStateHasChanged(e.dataState, tableState) || pastPlanReseted) {
      if (isFilterOn(e.dataState.filter)) {
        if (filterHasChanged(tableState.filter, e.dataState.filter) || pastPlanReseted) {
          updateAccounts();
        } else {
          const newDataState = { ...dataState, ...e.dataState };
          const newData = process(integrationAccounts, newDataState);
          dispatch(setIntegrationAccountsTablePropsAction({ take: newDataState.take, skip: newDataState.skip, sort: newDataState.sort, filter: newDataState.filter }));
          setGridState({
            dataState: newDataState,
            dataResult: newData,
          });
        }
      } else {
        updateAccounts();
      }
    }

    function updateAccounts() {
      const filterBy = getIntegrationFiltering(e.dataState.filter);
      if (filtersPresent(filterBy)) {
        getFilteredAccounts(filterBy);
      } else {
        getAccountsPage();
      }
    }

    function getFilteredAccounts(filterBy: { forAccount: string | undefined; forCompany: string | undefined }) {
      getFilteredIntegrationAccounts(filterBy.forAccount, filterBy.forCompany).then(result => {
        updateState(result, 0);
      });
    }

    function getAccountsPage() {
      const { sortBy, asc } = getIntegrationSorting(e.dataState.sort);
      getIntegrationAccountsPage(e.dataState.take, e.dataState.skip, sortBy, asc).then(result => {
        updateState(result, e.dataState.skip);
      });
    }

    function updateState(result: any, skip: number) {
      if (result) {
        const newDataState = { ...dataState, skip: skip, take: e.dataState.take, sort: e.dataState.sort, filter: e.dataState.filter };
        dispatch(setIntegrationAccountsTablePropsAction({ take: newDataState.take, skip: newDataState.skip, sort: newDataState.sort, filter: newDataState.filter }));
        setGridState({
          dataState: newDataState,
          dataResult: { data: result, total: integrationAccountsCount },
        });
      }
    }
  };

  const refreshPastPlansAction = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(resetPastPlansAction(selectedAccount, accountId));
      resolve(result);
    });

  const refreshPastPlans = () => {
    setShowResetPastPlansDialog(false);
    setShowTableLoading(true);
    refreshPastPlansAction(selectedCwAccountId).then(result => {
      const newDataState = { ...dataState, skip: tableState.skip, take: tableState.take, sort: tableState.sort, filter: tableState.filter };
      dataStateChange({ dataState: newDataState }, result);
      if (result) {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.ResetPastPlans].successMessage, type: ActionMessageType.Success }));
      }
    });
  };

  const onResetPastPlansClicked = (props: any) => {
    setSelectedCwAccountId(props.id);
    setShowResetPastPlansDialog(true);
  };

  const onCancelPastPlansClicked = () => {
    setSelectedCwAccountId(0);
    setShowResetPastPlansDialog(false);
  };

  const IntgrationStatus = (props: any) => {
    if (selectedIntegration) {
      return (
        <td id="integrationStatus">
          <Typography>{getCwAccountIntegrationStatus(props.dataItem.status, selectedIntegration?.type)}</Typography>
        </td>
      );
    }
  };

  const IntegrationAccountIcon = (props: any) => <IntegrationAccountIconCell {...props} accountName={props.dataItem.mspAccountName} />;

  const rowRender = (trElement: React.DetailedReactHTMLElement<any, HTMLElement>, props: any) => {
    const notActive = props.dataItem.status !== IntegrationAccountStatus.Linked;
    if (notActive) {
      return React.cloneElement(trElement, { className: trElement.props.className + " DarkRedColor" }, trElement.props.children);
    }
    return React.cloneElement(trElement, trElement.props.children);
  };

  const enterIntegrationAccountAction = (props: any): void => {
    setSelectedCwAccountId(props.id);
    if (props.status === IntegrationAccountStatus.ResetPastPlans) {
      onResetPastPlansClicked(props);
    } else {
      openIntegrationAccountsActionDialog(props);
    }
  };

  const ActionsCell = (props: any) => <ButtonCommandCell {...props} id={"integrationActionButton"} dataTestId={"integrationAccountActions"} action={enterIntegrationAccountAction} isOutlined={false} color={"secondary"} label={getIntegrationAccountsActionName(props.dataItem)} disabled={false} startIcon={props.dataItem.status === IntegrationAccountStatus.ResetPastPlans ? <Refresh /> : null} />;
  const TooltipTable = (props: any, tooltipType: any) => <TooltipElement {...props} tooltipType={"table"} />;
  return (
    <div style={{ position: "relative" }}>
      <Backdrop className={"parentOpacity"} open={showTableLoading} style={{ position: "absolute", zIndex: 4000 }}>
        <CircularProgress data-testid="loadingIntegrationAccountsPage" size="80px" style={{ zIndex: 4001 }} />
      </Backdrop>
      <DataTable
        className={"userTable noScrollbar noBorders"}
        data={gridState.dataResult}
        resizable
        filter={gridState.dataState.filter}
        // page
        pageConfig={{
          pageable: {
            pageSizes: PAGE_SIZES,
            buttonCount: buttonCount,
          },
          skip: tableState.skip,
          take: tableState.take,
          total: integrationAccountsCount,
        }}
        // sort
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        rowRender={rowRender}
        pager={integrationAccountsCount > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
      >
        <Column key={"integrationAccount"} title={"MSP ACCOUNT"} cell={IntegrationAccountIcon} field={"mspAccountName"} sortable={true} minResizableWidth={30} resizable={true} columnMenu={filterContainInput} headerClassName={getColorForColumn("mspAccountName", gridState.dataState as State)} />
        <Column key={"integrationCompany"} title={"COMPANY (CONNECTWISE)"} cell={TooltipTable} field={"companyName"} sortable={true} minResizableWidth={30} resizable={true} columnMenu={showUnlinkedAccounts ? null : filterContainInput} headerClassName={getColorForColumn("companyName", gridState.dataState as State)} />
        <Column key={"integrationStatus" + gridState.dataState.columns.length} title={"STATUS"} cell={IntgrationStatus} field={"status"} width={150} sortable={true} resizable={false} headerClassName={getColorForColumn("status", gridState.dataState as State)} />
        <Column key={"integrationActions" + gridState.dataState.columns.length} title={"ACTIONS"} cell={ActionsCell} field={"action"} width={200} sortable={false} resizable={false} />
      </DataTable>
      {showResetPastPlansDialog && <CustomMessageDialog message={resetPastPlansMessage} showConfirm={true} onConfirm={refreshPastPlans} onCloseMessage={onCancelPastPlansClicked} title={"Reset Past Plans"}></CustomMessageDialog>}
    </div>
  );
};

export default IntegrationsAccountsTable;
