import * as React from "react";

declare global {
  interface Window {
    newrelic: any;
  }
}

export const ErrorHandler = (error: Error, info: { componentStack: string }) => {
  //log to an error logging client
  //window.newrelic.noticeError(error);
};

function ErrorFallback(error: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre className="DarkRedColor">{error.message}</pre>
    </div>
  );
}

export default ErrorFallback;
