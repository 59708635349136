enum ConnectWiseLogType {
  Information = 7,
  Warning = 8,
  Error = 9,
}

export interface ConnectWiseLogTypeValue {
  value: number;
  label: string;
}

export const ConnectWiseLogTypeValues: ConnectWiseLogTypeValue[] = [
  { value: ConnectWiseLogType.Information, label: "Information" },
  { value: ConnectWiseLogType.Warning, label: "Warning" },
  { value: ConnectWiseLogType.Error, label: "Error" },
];

export default ConnectWiseLogType;
