import produce from "immer";
import IIntegration from "../models/Integrations/IIntegration";
import IntegrationStatus from "../models/Integrations/IntegrationStatus";
import IntegrationType from "../models/IntegrationType";

export function removeIntegrationsFromState(integrations: IIntegration[], integrationId: number): IIntegration[] {
  const integrationIndex = integrations.findIndex(value => value.id === integrationId);
  if (integrationIndex < 0) {
    throw new Error(`No integration with id ${integrationId}`);
  }
  return produce(integrations, (draft: IIntegration[]) => {
    draft[integrationIndex].id = undefined;
    draft[integrationIndex].enableFeatures = "";
    draft[integrationIndex].publicKey = "";
    draft[integrationIndex].status = IntegrationStatus.Undefined;
  });
}

export function addIntegrationsFromState(integrations: IIntegration[], integrationSetupId: number): IIntegration[] {
  const nextStateMspAccounts: IIntegration[] = produce(integrations, (draft: IIntegration[]) => {
    let integrationIndex = draft.findIndex(e => e.type === IntegrationType.ConnectWise);
    if (integrationIndex >= 0) draft[integrationIndex].id = integrationSetupId;
  });

  return nextStateMspAccounts;
}
