import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Back } from "@cuda-networks/bds-core/dist/Icons/Core";
import { Typography } from "@cuda-networks/bds-core";
import { Link } from "react-router-dom";

interface IBackToProperties {
  text: string;
  callback: Function;
  testId?: string;
  className?: string;
  variant?: string;
  enforceText?: boolean;
  disable?: boolean;
  to: string;
}

const NavigateTo = ({ testId, text, callback, className, variant, enforceText, disable, to }: IBackToProperties) => {
  testId = testId ?? `backTo${text.replace(/\s/g, "")}`;
  variant = variant ?? "body1";

  const muiLinkStyle = "MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary";

  return (
    <Link data-testid={testId} to={to} className={muiLinkStyle} onClick={(event: React.SyntheticEvent) => callback(event)} style={{ visibility: disable ? "hidden" : "visible" }}>
      <Grid container item>
        <Grid item>
          <Back />
        </Grid>
        <Grid item>
          <Typography variant={variant} gutterBottom>
            {enforceText !== undefined && enforceText === true ? text : `Back to ${text}`}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default NavigateTo;
