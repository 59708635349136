import React from "react";
import * as FeedBackIcons from "@cuda-networks/bds-core/dist/Icons/Feedback";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SerialStatus from "../../models/Products/SerialStatus";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import { getSerialStatusIconTooltip } from "../../businessLogic/components/Products/SerialStatusIcon";
import CustomColors from "../../models/CustomColors";

export const getSerialStatusIcon = (status: string): React.JSX.Element | null => {
  switch (status) {
    case SerialStatus.ACTIVE:
      return (
        <div data-testid="activatedIcon">
          <FeedBackIcons.Success className="DarkGreenColor"></FeedBackIcons.Success>
        </div>
      );
    case SerialStatus.AVAILABLE:
      return (
        <div data-testid="availableIcon">
          <PowerSettingsNewIcon htmlColor={"gray"}></PowerSettingsNewIcon>
        </div>
      );
    case SerialStatus.ACTIVATION_FAILED:
    case SerialStatus.PROVISION_FAILED:
      return (
        <div data-testid="errorIcon">
          <FeedBackIcons.Critical htmlColor={CustomColors.DarkRed}></FeedBackIcons.Critical>
        </div>
      );
    case SerialStatus.SSG_PENDING:
    case SerialStatus.PENDING:
      return (
        <div data-testid="pendingIcon">
          <HourglassEmptyIcon htmlColor={"gray"}></HourglassEmptyIcon>
        </div>
      );
    default:
      return null;
  }
};

interface ISerialStatusIconProps {
  isBBS: boolean;
  status: string;
}

const SerialStatusIcon: React.FC<ISerialStatusIconProps> = ({ isBBS, status }) => {
  return (
    <Tooltip title={getSerialStatusIconTooltip(status, isBBS)}>
      <div className={"ProductStatusIcon"}>{getSerialStatusIcon(status)}</div>
    </Tooltip>
  );
};
export default SerialStatusIcon;
