import React from "react";
import { CircularProgress, Box } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import IAccount from "../../models/IAccount";
import { getAccountDisplayType, getAccountDisplayTypeWithParent } from "../../Utilities/accountsHelper";
import AccountIcon from "./AccountIcon";
import MspType from "../../models/MspType";
import { Typography } from "@material-ui/core";
import CheckBoxWithIndeterminateState from "../CheckBoxWithIndeterminateState";

interface IAccountCellProps {
  showCheckbox: boolean;
  showInfoIcon: boolean;
  item: IAccount;
  loading: boolean;
  displayExpandIcon: boolean;
  disabled: boolean;
  onClick: (item: IAccount) => void;
  onCheck: (item: IAccount, checked: boolean) => void;
  isBaLoggedIn: boolean;
}

const AccountCell: React.FC<IAccountCellProps> = ({ item, loading, disabled, showCheckbox, showInfoIcon, displayExpandIcon, onClick, onCheck, isBaLoggedIn }) => {
  const onHandleChange = (checked: boolean): void => {
    onCheck(item, checked);
  };
  const textWeightStyle = getTextWeight(item.type, isBaLoggedIn);

  const handleOnClick = () => {
    onClick(item);
  };

  return (
    <td>
      <Grid item container>
        {showCheckbox && (
          <Grid container item xs={1}>
            {showCheckbox && <CheckBoxWithIndeterminateState size="small" disabled={disabled} onCheck={onHandleChange} checkedState={item.checkedState} />}
          </Grid>
        )}
        <Grid container item xs={1} onClick={handleOnClick} style={{ cursor: "pointer" }}>
          <Tooltip title={getAccountDisplayType(item.type)}>
            <div className={"centerVertically"} style={{ position: "relative", paddingRight: "10px" }}>
              <AccountIcon entityType={item.type}></AccountIcon>
            </div>
          </Tooltip>
        </Grid>
        <Grid style={{ lineHeight: "20px", cursor: "pointer" }} container item xs={9} className="AccountAccessCell AccountCellGrid" onClick={handleOnClick}>
          <Typography noWrap className="AccountCellContent AccountAccessCellLayout">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={item.name}>
                <Box data-testid="accountName" variant="body1" className="noWrap" style={{ ...textWeightStyle, flex: 1, minWidth: 0 }}>
                  {item.name}
                </Box>
              </Tooltip>
              {showInfoIcon && (
                <Tooltip title={"Automatically Include Future Accounts"}>
                  <div data-testid="accountInfoIcon" className="AccountIcons AccountCellCenterVertically AccountAccessUpdateIcons">
                    {item.includeFutureAccounts && <CoreIcons.Update />}
                  </div>
                </Tooltip>
              )}
            </div>
            <Box data-testid="accountType" variant="body2" style={{ fontWeight: "light", fontSize: "12px" }}>
              {getAccountDisplayTypeWithParent(item)}
            </Box>
          </Typography>
        </Grid>
        <Grid container item xs={1} className={"centerVertically AccountAccessGridXs"} style={{ display: "grid", justifyContent: "flex-end" }}>
          {loading && <CircularProgress data-testid="loadingSubpartner" size="25px" />}
        </Grid>
        <Grid container item xs={1} className="AccountIcons AccountAccessGridXs" style={{ marginBottom: "auto", marginTop: "auto", cursor: "pointer" }}>
          {item.type !== MspType.Customer && displayExpandIcon && <CoreIcons.PageNext />}
        </Grid>
      </Grid>
    </td>
  );
};

export default AccountCell;
export function getTextWeight(type: string, isBaAccountLoggedIn: boolean) {
  let result = { fontWeight: "medium" };
  if (isBaAccountLoggedIn) {
    if (type === MspType.BillingAggregator) {
      result = { fontWeight: "bold" };
    }
  } else {
    if (type === MspType.Partner) {
      result = { fontWeight: "bold" };
    } else if (type === MspType.Subpartner) {
      result = { fontWeight: "600" };
    }
  }
  return result;
}
