import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";
import IExistingCreditCard from "../../../models/Invoices/IExistingCreditCard";
import IInvoice from "../../../models/Invoices/IInvoice";
import { isCreditCardDateExpired } from "../../../Utilities/financeHelper";

export function getConfirmButtonState(invoice: IInvoice | undefined, selectedCardIndex: number, selectedCreditCard: IExistingCreditCard | undefined, isCardFromatValid: boolean, actionInProgress: boolean, validationInProgress: boolean) {
  let result = { tooltip: "", disabled: false };
  if (invoice && selectedCardIndex > 0 && selectedCreditCard) {
    if (isCreditCardDateExpired(selectedCreditCard)) {
      result.disabled = true;
      result.tooltip = "Cannot pay with expired card";
    } else {
      result.disabled = !isCardFromatValid || actionInProgress || validationInProgress;
    }
  } else {
    result.disabled = !isCardFromatValid || actionInProgress || validationInProgress;
  }
  return result;
}

export function getCreditCardMesSnackbarErrorMessage(mesError: any, invoice: IInvoice | undefined, isEdit: boolean): string {
  const actionType = invoice ? ActionTypes.PayInvoice : isEdit ? ActionTypes.EditCreditCard : ActionTypes.AddCreditCard;
  return `${ActionMessages[actionType].failMessage} ${mesError}`;
}
