import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { OpenInBrowser } from "@cuda-networks/bds-core/dist/Icons/Core";
import { Card, Link, CardContent, Typography, CardHeader } from "@cuda-networks/bds-core";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, { PageTitles } from "../../Utilities/useDocumentTitle";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import NavigateTo from "../NavigateTo";
import { getAccountIdFromUrl } from "../../Utilities/UrlParser";

const PartnerResourcesDialog = () => {
  const navigate = useNavigate();
  const accountId = getAccountIdFromUrl();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);

  useDocumentTitle(selectedAccount, PageTitles.PartnerResources);

  const navigateTo = `/accounts/${accountId}/details`;
  const handleBackToAccounts = (event: React.SyntheticEvent) => {
    navigate(`/accounts/${accountId}/details`);
  };

  interface IResourceItemProps {
    text: string;
    url: string;
    description: string;
  }

  const ResourceItem = ({ text, url, description }: IResourceItemProps) => {
    return (
      <Grid container item xs={6}>
        <Grid container item spacing={1}>
          <Grid item>
            <Link href={url} target="_blank">
              <Typography variant="subtitle1" gutterBottom>
                {text}
              </Typography>
            </Link>{" "}
          </Grid>
          <Grid item>
            <OpenInBrowser />
          </Grid>
        </Grid>
        <Grid item>
          <div style={{ paddingBottom: "50px" }}>
            <Typography variant="subtitle2" gutterBottom>
              {description}
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item>
      <Grid container style={{ paddingBottom: "10px" }} spacing={1}>
        <NavigateTo to={navigateTo} text="Account Details" callback={handleBackToAccounts} />
      </Grid>
      <Grid item container xs={12}>
        <Card style={{ width: "100%" }}>
          <CardHeader data-testid="partnerResourcesPanelTitle" title="Partner Resources" style={{ textAlign: "left" }} />
          <CardContent>
            <Grid container spacing={1} style={{ paddingLeft: "20px" }}>
              <Grid item container spacing={2} direction="row">
                <ResourceItem text="Autotask Integration Guide" url="https://campus.barracuda.com/product/echoplatform/doc/93197017/autotask-echoplatform-integration" description="Details on how to get the most out of our Autotask integration." />
                <ResourceItem text="Best Practices Partner Guide" url="https://campus.barracuda.com/product/echoplatform/doc/86545557/best-practices-partner-guide" description="Comprehensive guide to Barracuda MSP best practices, including DR planning." />
                <ResourceItem text="ConnectWise Integration Guide" url="https://campus.barracuda.com/product/MSPPM/doc/98227561/connectwise-manage-integrations" description="Details on how to get the most out of our ConnectWise integration." />
                <ResourceItem text="MSP Global Price List" url="https://status.barracudamsp.com/resources/data-sheets/pr-list.pdf" description="Access a list of Barracuda MSP Pricing for all products." />
                <ResourceItem text="Portal REST API Interactive Guide" url="https://api.intronis.com/portal-api.html" description="Interactive Guide for the ECHO Platform REST API." />
                <ResourceItem text="Re-brandable Marketing Materials" url="https://insiders.barracudamsp.com/users/sign_in" description="A collection of marketing material templates." />
                <ResourceItem text="Release Notes" url="https://status.barracudamsp.com/resources/data-sheets/Release_Notes.pdf" description="Guide to important product information." />
                <ResourceItem text="Solarwinds MSP N-central Integration Guide" url="https://status.barracudamsp.com/resources/documentation/Intronis-Backup-Agent-SolarwindsMSP-N-central-Integration-Guide.pdf" description="Details on how to get the most of our Solarwinds integration." />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PartnerResourcesDialog;
