import { IUserRoleType } from "../../../../Utilities/usersHelper";
import { NO_VALID_ROLE_ERROR_CODE, RESSELLER_ERROR_CODE, USER_DUPLICATE_EMAIL } from "../../../../actions/actionsErrorHandler";
import { ApiError } from "../../../../errors/ApiError";
import { NoValidRoleError } from "../../../../errors/NoValidRoleError";
import { CheckedState } from "../../../../models/CheckedState";
import IAccount from "../../../../models/IAccount";
import { IAccountAccessItem } from "../../../../models/IAccountAccessItem";
import IEntitlement from "../../../../models/IEntitlement";
import ILoggedUser from "../../../../models/ILoggedUser";
import IUser from "../../../../models/IUser";
import MspType from "../../../../models/MspType";
import UserRole, { UserRoleValues } from "../../../../models/UserRole";
import { isAdmin } from "../../../../utility";
export const NameAndEmailStepLabel = "Name & Email";
export const AccountAccessStepLabel = "Account Access";
export const RolesAndPrivilegesStepLabel = "Roles & Privileges";
export const EntitlementsStepLabel = "Entitlements";
export const UserDuplicateEmailErrorMessage = "This email address is already linked to another login";

export function buildEntitlementSelection(isEdit: boolean, user: IUser | undefined, entitlements: IEntitlement[]): IEntitlement[] {
  let result: IEntitlement[] = [];
  if (entitlements.length > 0) {
    if (isEdit && user !== undefined) {
      entitlements.forEach((en: IEntitlement) => {
        let found = false;
        const userEntitlements = user.entitlements;
        userEntitlements?.forEach((userEn: IEntitlement) => {
          if (userEn.service_id === en.service_id) {
            found = true;
          }
        });
        result.push({ ...en, checked: found });
      });
      return result;
    } else {
      result = entitlements.map((en: IEntitlement) => ({ ...en, checked: true }));
    }
  }
  return result;
}

export function shouldShowDeleteUserButton(isEdit: boolean, mspAccountLoggedIn: IAccount, loggedUser: ILoggedUser, user: IUser | undefined): boolean {
  if (!isEdit) {
    return false;
  }
  if (loggedUser.id === user?.id) {
    return false;
  }
  return isAdmin(mspAccountLoggedIn);
}

export function getAddEditUserDialogStepsNames(account: IAccount | undefined, isAddFirstUser: boolean, userRole: IUserRoleType): string[] {
  let steps: string[] = [];
  if (isAddFirstUser) {
    steps = [NameAndEmailStepLabel, RolesAndPrivilegesStepLabel, EntitlementsStepLabel];
  } else {
    switch (account?.type) {
      case MspType.BillingAggregator:
      case MspType.Customer: {
        steps = [NameAndEmailStepLabel, RolesAndPrivilegesStepLabel];
        break;
      }
      case MspType.Partner:
      case MspType.Subpartner: {
        steps = [NameAndEmailStepLabel, RolesAndPrivilegesStepLabel, EntitlementsStepLabel];
        break;
      }
      default:
        steps = [];
    }
  }

  if (userRole === UserRole.Finance) {
    steps = steps.filter(step => step !== EntitlementsStepLabel);
  }

  return steps;
}

export const isFinalStep = (currentStep: number, stepsLength: number): boolean => {
  return currentStep === stepsLength - 1;
};

export const getNextStepButtonLabel = (stepNumber: number, stepsLength: number): string => {
  if (isFinalStep(stepNumber, stepsLength)) {
    return "CONFIRM";
  } else {
    return "NEXT";
  }
};

export const getCancelButtonLabel = (stepNumber: number, isFirstUser: boolean): string => {
  if (getShowBackButton(stepNumber, isFirstUser)) {
    return "CANCEL";
  }
  return "BACK";
};

export const getShowBackButton = (stepNumber: number, isFirstUser: boolean): boolean => {
  if (isFirstUser && stepNumber === 0) {
    return false;
  }
  return true;
};

export const isUserDuplicateEmailError = (error: ApiError): boolean => {
  return error?.message !== undefined && error?.errorMessage !== undefined && error?.errorMessage !== "" && error.errorMessage.includes(USER_DUPLICATE_EMAIL);
};

export const isResellerLoginError = (error: ApiError): boolean => {
  return error.errorCode !== undefined && error.errorCode === RESSELLER_ERROR_CODE;
};

export const isKnownErrorAfterLogin = (error: ApiError | NoValidRoleError): boolean => {
  return error.errorCode === RESSELLER_ERROR_CODE || error.errorCode === NO_VALID_ROLE_ERROR_CODE;
};
export const hasNoValidRole = (roles: string[]): boolean => {
  if (roles.length === 0) {
    return true;
  }
  const validAuthDbValues = new Set(UserRoleValues.map(role => role.authDbValue));
  return !roles.some(role => validAuthDbValues.has(role));
};

export const getApiErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case RESSELLER_ERROR_CODE:
      return "Please use a login that is specifically designated as an MSP instead of a Reseller.";
    case NO_VALID_ROLE_ERROR_CODE:
      return "Please use a login that has a valid role.";
    default:
      return "";
  }
};

export const isLoadingOnEntitlementStep = (currentStep: string, loadingEntitlements: boolean): boolean => {
  if (currentStep === EntitlementsStepLabel) {
    return loadingEntitlements;
  } else {
    return false;
  }
};

export function buildCustomAccountList(account: IAccount, accountAccessSelection: IAccount[], selectAll: CheckedState, includeFutureAccounts: boolean): IAccountAccessItem[] {
  let result: IAccountAccessItem[] = [];
  if (selectAll === CheckedState.Checked) {
    return [{ accountId: account.id, futureAccountAccess: includeFutureAccounts }];
  }
  if (selectAll === CheckedState.Indeterminate) {
    accountAccessSelection.forEach(element => {
      if (element.type === MspType.Partner || element.type === MspType.Subpartner) {
        if (element.checkedState === CheckedState.Checked) {
          result.push({ accountId: element.id, futureAccountAccess: element.includeFutureAccounts !== undefined ? element.includeFutureAccounts : false });
        } else if (element.checkedState === CheckedState.Indeterminate) {
          element.accounts?.forEach(childElement => {
            if (childElement.checkedState === CheckedState.Checked) {
              result.push({ accountId: childElement.id, futureAccountAccess: false });
            }
          });
        }
      } else {
        if (element.type === MspType.Customer) {
          if (element.checkedState === CheckedState.Checked) {
            result.push({ accountId: element.id, futureAccountAccess: false });
          }
        }
      }
    });
  }

  return result;
}
