import React from "react";
import { Stepper, StepLabel, Step } from "@cuda-networks/bds-core";

interface IDialogStepperProps {
  stepsNames: string[];
  activeStep: number;
}

const DialogStepper: React.FC<IDialogStepperProps> = ({ stepsNames, activeStep }) => {
  return (
    <Stepper activeStep={activeStep}>
      {stepsNames.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default DialogStepper;
