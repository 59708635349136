import React from "react";
import { isSecureEdgeServiceSku } from "../../Utilities/productsHelper";
import BandwidthCell from "./Serials/BandwidthCell";

const ContractDisplayCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  if (isSecureEdgeServiceSku(props.dataItem.sku)) {
    if (!props.item.contract) {
      return <td></td>;
    }
    return <BandwidthCell {...props} item={props.item.contract} />;
  } else {
    return <td>{props.item.contract}</td>;
  }
};

export default ContractDisplayCell;
