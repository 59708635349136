import { CardContent, Tooltip, Typography, Grid, Card } from "@cuda-networks/bds-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelCurrentAction } from "../../actions/generalActions";
import { setCancelLoadIntegrationExtraInfoTokenAction } from "../../actions/integrations/integrationsActions";
import IntegrationsTable from "./IntegrationsTable";

import NavigateTo from "../NavigateTo";
import useDocumentTitle, { PageTitles } from "../../Utilities/useDocumentTitle";
import { IAppState } from "../../store/store";
import { getAccountIdFromUrl } from "../../Utilities/UrlParser";

const IntegrationsPanel = () => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const integrationsTitle = "Manage Integrations";
  const accountId = getAccountIdFromUrl();

  useDocumentTitle(selectedAccount, PageTitles.Integrations);

  const navigateTo = `/accounts/${accountId}/products-services`;

  const handleBackToProducts = () => {
    dispatch(setCancelLoadIntegrationExtraInfoTokenAction());
    dispatch(cancelCurrentAction());
  };

  return (
    <Card className={"IntegrationPanel"}>
      <CardContent>
        <Grid item container spacing={1} direction="column" style={{ paddingBottom: "10px" }}>
          <Grid item container>
            <Grid container item xs={9}>
              <Grid container style={{ paddingBottom: "10px", paddingRight: "40px" }}>
                <Tooltip title={""}>
                  <Typography data-testid="integrationsPanelTitle" variant="h4" noWrap>
                    {integrationsTitle}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid container>
                <NavigateTo to={navigateTo} text={"Products & Services"} callback={handleBackToProducts} testId="integrationsPanelLnk" />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} data-testid="integrationsTable">
            <IntegrationsTable />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IntegrationsPanel;
