import React from "react";
import CheckM365Status from "./CheckM356Status";

const CheckM365StatusButtonCommandCell = (props: any) => {
  if (props.rowType === "groupHeader" || props.accountId === undefined) {
    return null;
  } else
    return (
      <td id={props.id}>
        <CheckM365Status accountId={props.accountId} />
      </td>
    );
};

export default CheckM365StatusButtonCommandCell;
