import { Link } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../table.css";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { IAppState } from "../../../store/store";
import { getBilledUsersAction } from "../../../actions/auditUsersActions";

const AuditUsersTablePager = (props: any) => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const nextPageToken = useSelector((state: IAppState) => state.auditUsersState.nextPageToken);
  const searchedAuditUserEmail = useSelector((state: IAppState) => state.auditUsersState.searchedAuditUserEmail);
  const pages = useSelector((state: IAppState) => Math.ceil(state.auditUsersState.auditUsersToDisplay.length / state.auditUsersState.auditUsersTableState.take!));
  const filterByOptionId = useSelector((state: IAppState) => state.auditUsersState.filterByOptionId);

  const onHandleLoadMore = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(getBilledUsersAction(selectedAccount, filterByOptionId, searchedAuditUserEmail, nextPageToken));
  };

  return (
    <div>
      {selectedAccount && selectedAccount.m365AuthLinked === 1 && nextPageToken.length > 0 ? (
        <div className="AuditUserLoadMorePager">
          <Pager {...props} info={false} />
          <div className="AuditUserLoadMoreLink">
            <label data-testid="auditUserLoadMoreLabel" style={{ color: "grey" }}>
              {pages} Loaded pages{" "}
            </label>
            <Link
              data-testid="auditUserLoadMoreLink"
              href="#"
              onClick={(event: React.SyntheticEvent) => {
                onHandleLoadMore(event);
              }}
              variant="body1"
            >
              (Load More)
            </Link>
          </div>
        </div>
      ) : (
        <Pager {...props} />
      )}
    </div>
  );
};

export default AuditUsersTablePager;
