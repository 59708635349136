enum SerialStatus {
  ACTIVE = "Active",
  AVAILABLE = "Available",
  SSG_PENDING = "Activation Pending",
  PROVISION_FAILED = "Provision Failed",
  ACTIVATION_FAILED = "Activation Failed",
  RMA_ISSUED = "RMA Issued",
  CANCELED = "Canceled",
  RMA_NEEDED = "RMA Needed",
  RMA_REQUESTED = "RMA Requested",
  PENDING = "Pending",
}

export default SerialStatus;
