import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@cuda-networks/bds-core";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@cuda-networks/bds-core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { redirectWithSso } from "../../utility";
import IAccount from "../../models/IAccount";
import { useDispatch, useSelector } from "react-redux";
import { getAccountEchoUserNameAction } from "../../actions/accountActions";
import MspType from "../../models/MspType";
import { IAppState } from "../../store/store";

const IntronisBackup = () => {
  const dispatch = useDispatch();
  const echoUrl = useSelector((state: IAppState) => state.generalState.echoUrl);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [showGoToEchoLink, setShowGoToEchoLink] = useState(true);
  const loadAccountEchoUserName = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getAccountEchoUserNameAction(account));
      resolve(result);
    });

  const handleOnClick = () => {
    if (selectedAccount) {
      if (selectedAccount.type === MspType.Customer) {
        loadAccountEchoUserName(selectedAccount).then(result => {
          if (result) {
            redirectWithSso(echoUrl, mspAccountLoggedIn, selectedAccount, result);
          }
        });
      } else {
        redirectWithSso(echoUrl, mspAccountLoggedIn, selectedAccount);
      }
    }
  };

  useEffect(() => {
    if (mspAccountLoggedIn?.type === MspType.BillingAggregator) {
      setShowGoToEchoLink(false);
    } else {
      setShowGoToEchoLink(true);
    }
  }, [mspAccountLoggedIn]);

  return (
    <div style={{ width: "100%" }}>
      <Card variant="outlined" elevation={0}>
        <CardContent>
          <Grid item container direction="column">
            <Grid item>
              <Typography data-testid="IntronisBackupLabel" variant="subtitle2" style={{ color: "#8a8a8a", padding: 11 }}>
                Your Intronis Backup service is currently being managed in the ECHOplatform.
              </Typography>
            </Grid>
            {showGoToEchoLink && (
              <Grid item>
                <Button data-testid="IntronisBackupGoToECHOplatform" variant={"contained"} color={"secondary"} onClick={handleOnClick} startIcon={<CoreIcons.Launch fontSize="inherit" id="intronisBackup" />}>
                  GO TO ECHOplatform
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default IntronisBackup;
