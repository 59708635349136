import { CancelTokenSource } from "axios";
import { Reducer } from "redux";
import { AccountActionTypes, AccountActions } from "../actions/accountActions";
import IAccount from "../models/IAccount";
import IAccountAddress from "../models/IAccountAddress";
import ICountry from "../models/ICountry";
import IUser from "../models/IUser";

export interface IAccountState {
  loadingAddress: boolean;
  mspAccounts: IAccount[];
  selectedAccount?: IAccount;
  loadedAccount?: IAccount;
  itemsToDisplay: IAccount[];
  accountsPageSize: number;
  accountsPageNumber: number;
  users: IUser[];
  countries: ICountry[];
  states: string[];
  addEditError: string;
  addressToDisplay?: IAccountAddress;
  loadAddressCancellationTokenSource?: CancelTokenSource;
  prevSelectedAccount?: IAccount;
  lastUsageSyncTime: string;
  filterAccountsCancellationTokenSource?: CancelTokenSource;
  loadingFilteredAccounts: boolean;
  loadingAccountId?: number;
  loadingAllChildrenAccountNames: boolean;
  accountsNames: IAccount[];
  loadSubpartnerCustomersCancellationTokenSource?: CancelTokenSource;
  loadCustomerParentAndItsChildrenCancellationTokenSource?: CancelTokenSource;
  selectedAccountToFilterProductsForBA?: IAccount;
  childrenCancellationTokenSource?: CancelTokenSource;
  allchildrenNamesCancellationTokenSource?: CancelTokenSource;
  loadingM356StatusForAccountId?: number;
  loadingAccountM365Auth?: boolean;
  filterChildrenOfAccountId?: number;
  directChildrenOnly?: boolean;
  hasIBU: boolean;
  accountInfoCancellationTokenSource?: CancelTokenSource;
  loadingAccountInfo: boolean;
  notes?: string;
  echoUserName?: string;
  loadingEchoUserName: boolean;
  echoUsernameCancellationTokenSource?: CancelTokenSource;
}

const initialAccountState: IAccountState = {
  loadingAddress: false,
  selectedAccount: undefined,
  itemsToDisplay: [],
  mspAccounts: [],
  countries: [],
  states: [],
  accountsPageSize: 10,
  accountsPageNumber: 1,
  users: [],
  addEditError: "",
  addressToDisplay: undefined,
  loadAddressCancellationTokenSource: undefined,
  prevSelectedAccount: undefined,
  lastUsageSyncTime: "",
  loadingAllChildrenAccountNames: false,
  accountsNames: [],
  loadingAccountM365Auth: false,
  loadingFilteredAccounts: false,
  hasIBU: false,
  loadingAccountInfo: false,
  loadingEchoUserName: false,
};

export const accountReducer: Reducer<IAccountState, AccountActions> = (state = initialAccountState, action: AccountActions) => {
  switch (action.type) {
    case AccountActionTypes.ADD_ACCOUNT:
    case AccountActionTypes.EDIT_ACCOUNT:
    case AccountActionTypes.DELETE_ACCOUNT: {
      return {
        ...state,
        mspAccounts: action.mspAccounts,
        itemsToDisplay: action.itemsToDisplay,
      };
    }
    case AccountActionTypes.SET_DISPLAY_ITEMS: {
      return {
        ...state,
        itemsToDisplay: action.itemsToDisplay,
      };
    }
    case AccountActionTypes.SET_LOADING_ACCOUNT_INFO: {
      return {
        ...state,
        loadingAccountId: action.loadingAccountId,
      };
    }
    case AccountActionTypes.SET_CANCEL_LOAD_SUBPARTNER_CUSTOMERS_TOKEN: {
      return {
        ...state,
        loadSubpartnerCustomersCancellationTokenSource: action.loadSubpartnerCustomersCancellationTokenSource,
      };
    }
    case AccountActionTypes.SET_CANCEL_LOAD_CUSTOMER_PARENT_AND_ITS_CHILDREN_TOKEN: {
      return {
        ...state,
        loadCustomerParentAndItsChildrenCancellationTokenSource: action.loadCustomerParentAndItsChildrenCancellationTokenSource,
      };
    }
    case AccountActionTypes.LOAD_ADDRESS: {
      return {
        ...state,
        loadingAddress: action.loadingAddress,
        addressToDisplay: action.addressToDisplay,
      };
    }
    case AccountActionTypes.LOAD_COUNTRIES:
    case AccountActionTypes.SET_STATES:
    case AccountActionTypes.LOAD_STATES: {
      return {
        ...state,
        countries: action.countries,
      };
    }
    case AccountActionTypes.SET_PAGE_SIZE: {
      return {
        ...state,
        accountsPageSize: action.accountsPageSize,
      };
    }
    case AccountActionTypes.SET_PAGE_NUMBER: {
      return {
        ...state,
        accountsPageNumber: action.accountsPageNumber,
      };
    }
    case AccountActionTypes.SET_SELECTED_ACCOUNT: {
      return {
        ...state,
        selectedAccount: action.selectedAccount,
      };
    }
    case AccountActionTypes.FETCH_M365_AUTH: {
      return {
        ...state,
        selectedAccount: action.selectedAccount,
        loadingAccountM365Auth: action.loadingAccountM365Auth,
      };
    }
    case AccountActionTypes.SET_LOADING_ACCOUNT_M365_AUTH: {
      return {
        ...state,
        loadingAccountM365Auth: action.loadingAccountM365Auth,
      };
    }
    case AccountActionTypes.FETCH_M365_AUTH_FOR_ACC: {
      return {
        ...state,
        loadingM356StatusForAccountId: action.loadingM356StatusForAccountId,
        accountsNames: action.accountsNames,
      };
    }
    case AccountActionTypes.SET_LOADED_ACCOUNT: {
      return {
        ...state,
        loadedAccount: action.loadedAccount,
      };
    }
    case AccountActionTypes.SET_MSP_ACCOUNTS: {
      return {
        ...state,
        itemsToDisplay: action.itemsToDisplay,
        mspAccounts: action.mspAccounts,
      };
    }
    case AccountActionTypes.SET_CANCEL_ADDRESS_TOKEN: {
      return {
        ...state,
        loadAddressCancellationTokenSource: action.loadAddressCancellationTokenSource,
      };
    }
    case AccountActionTypes.GO_TO_ACCOUNT_ACTION: {
      return {
        ...state,
        selectedAccount: action.selectedAccount,
        itemsToDisplay: action.itemsToDisplay,
        accountsPageNumber: action.accountsPageNumber,
      };
    }
    case AccountActionTypes.SET_PREV_SELECTED_ACCOUNT: {
      return {
        ...state,
        prevSelectedAccount: action.prevSelectedAccount,
      };
    }
    case AccountActionTypes.SET_LAST_USAGE_SYNC_TIME: {
      return {
        ...state,
        lastUsageSyncTime: action.lastUsageSyncTime,
      };
    }
    case AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES: {
      return {
        ...state,
        loadingAllChildrenAccountNames: action.loadingAllChildrenAccountNames,
        accountsNames: action.accountsNames,
      };
    }
    case AccountActionTypes.SET_SELECTED_ACCOUNT_TO_FILTER_PRODUCTS_FOR_BA: {
      return {
        ...state,
        selectedAccountToFilterProductsForBA: action.selectedAccountToFilterProductsForBA,
      };
    }
    case AccountActionTypes.SET_CANCEL_LOAD_CHILDREN_TOKEN: {
      return {
        ...state,
        childrenCancellationTokenSource: action.childrenCancellationTokenSource,
      };
    }
    case AccountActionTypes.SET_CANCEL_LOAD_ALL_CHILDREN_NAMES_TOKEN: {
      return {
        ...state,
        allchildrenNamesCancellationTokenSource: action.allchildrenNamesCancellationTokenSource,
      };
    }
    case AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS: {
      return {
        ...state,
        loadingFilteredAccounts: action.loadingFilteredAccounts,
      };
    }
    case AccountActionTypes.SET_CANCEL_FILTER_ACCOUNTS_TOKEN: {
      return {
        ...state,
        filterAccountsCancellationTokenSource: action.filterAccountsCancellationTokenSource,
      };
    }
    case AccountActionTypes.SET_FITLER_CHILDREN_OF_ACCOUNT_ID: {
      return {
        ...state,
        filterChildrenOfAccountId: action.filterChildrenOfAccountId,
        directChildrenOnly: action.directChildrenOnly,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_INFO: {
      return {
        ...state,
        hasIBU: action.hasIBU,
        loadingAccountInfo: action.loadingAccountInfo,
        notes: action.notes,
      };
    }
    case AccountActionTypes.SET_CANCEL_LOAD_ACCOUNT_INFO_TOKEN: {
      return {
        ...state,
        accountInfoCancellationTokenSource: action.accountInfoCancellationTokenSource,
      };
    }
    case AccountActionTypes.LOAD_ACCOUNT_ECHO_USERNAME: {
      return {
        ...state,
        echoUserName: action.echoUserName,
        loadingEchoUserName: action.loadingEchoUserName,
      };
    }
    case AccountActionTypes.SET_CANCEL_LOAD_ECHOUSERNAME_TOKEN: {
      return {
        ...state,
        echoUsernameCancellationTokenSource: action.echoUsernameCancellationTokenSource,
      };
    }
    default:
      return state;
  }
};
