import React from "react";
import DetailsTabs from "../models/DetailsTabs";
import { IDetailsTabs } from "./InfoPanel";
import ProductsTab from "./Products/ProductsTab";
import AccountDetailsTab from "./Accounts/AccountDetailsTab";
import FinanceTab from "./Finance/FinanceTab";
import ApiCredentialsTab from "./ApiCredentials/ApiCredentialsTab";
import UsersTab from "./Users/UsersTab";
import IAccount from "../models/IAccount";
import { getValidPaths } from "../Utilities/pathValidator";
import { PageTitles } from "../Utilities/useDocumentTitle";

export const ProductsTabItem: IDetailsTabs = {
  id: 0,
  routeId: "products-services",
  tab: {
    label: DetailsTabs.Products,
    value: <ProductsTab />,
  },
};

export const UsersTabItem: IDetailsTabs = {
  id: 1,
  routeId: "roles-access",
  tab: {
    label: DetailsTabs.Users,
    value: <UsersTab />,
  },
};

export const AccountDetailsTabItem: IDetailsTabs = {
  id: 2,
  routeId: "details",
  tab: {
    label: DetailsTabs.AccountsDetails,
    value: <AccountDetailsTab />,
  },
};

export const FinanceTabItem: IDetailsTabs = {
  id: 3,
  routeId: "billing",
  tab: {
    label: DetailsTabs.Finance,
    value: <FinanceTab />,
  },
};

export const ApiCredentialsTabItem: IDetailsTabs = {
  id: 4,
  routeId: "api-credentials",
  tab: {
    label: DetailsTabs.ApiCredentials,
    value: <ApiCredentialsTab />,
  },
};

export function getDisplayedTabs(mspAccountLoggedIn: IAccount, selectedAccount: IAccount | undefined): IDetailsTabs[] {
  if (selectedAccount === undefined) {
    return [];
  }
  const validPaths = getValidPaths(mspAccountLoggedIn, selectedAccount);
  let tabs: IDetailsTabs[] = [];
  validPaths.forEach(element => {
    switch (element) {
      case PageTitles.ProductsAndServices:
        tabs.push(ProductsTabItem);
        break;
      case PageTitles.RolesAndAccess:
        tabs.push(UsersTabItem);
        break;
      case PageTitles.Details:
        tabs.push(AccountDetailsTabItem);
        break;
      case PageTitles.Billing:
        tabs.push(FinanceTabItem);
        break;
      case PageTitles.ApiCredentials:
        tabs.push(ApiCredentialsTabItem);
        break;
    }
  });
  return tabs;
}
