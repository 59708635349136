import { Dispatch } from "redux";
import { IDeleteAccountSerial } from "../../../components/Accounts/DeleteAccount/DeleteAccountSerialsTable";
import IOrder from "../../../models/Products/IOrder";
import ISerial from "../../../models/Products/ISerial";
import SerialStatus from "../../../models/Products/SerialStatus";
import mspService from "../../../service/mspService";
import { IAppState } from "../../../store/store";
import ProductFamily from "../../../models/Products/ProductFamily";
import { updateProductIdsRepo } from "../../../actions/productActions";
import { nameIsBBS } from "../../../Utilities/productsHelper";

function productDataIsCached(serials: ISerial[], cachedProducts: IOrder[]): boolean {
  return serials.every((serial: ISerial) => {
    const index = cachedProducts.findIndex(x => x.lineItemId === serial.orderLineItemId);
    if (index < 0) {
      return false;
    }
    return true;
  });
}

async function getDeleteSerialsWithProductInfo(serials: ISerial[], cachedProducts: IOrder[], accountId: number, getState: () => IAppState, dispatch: Dispatch): Promise<IDeleteAccountSerial[]> {
  if (productDataIsCached(serials, cachedProducts)) {
    return getDeleteSerials(serials, cachedProducts);
  } else {
    const { apiUrl } = getState().generalState;
    const ordersResult = await mspService.loadOrders(apiUrl, accountId, undefined, false);
    updateProductIdsRepo(getState, ordersResult, dispatch);
    return getDeleteSerials(serials, ordersResult);
  }
}

export async function filtersSerialsToDelete(serials: ISerial[], cachedProducts: IOrder[], statuses: SerialStatus[], accountId: number, getState: () => IAppState, dispatch: Dispatch): Promise<IDeleteAccountSerial[]> {
  const filteredSerials = filterByStatus(serials, statuses);
  if (filteredSerials.length > 0) {
    return await getDeleteSerialsWithProductInfo(filteredSerials, cachedProducts, accountId, getState, dispatch);
  }
  return [];
}

export function filterByStatus(serials: ISerial[], statuses: SerialStatus[]): ISerial[] {
  return serials.filter(x => statuses.indexOf(x.status as SerialStatus) > -1);
}

export function filterDeleteAccountSerialByStatus(serials: IDeleteAccountSerial[], statuses: SerialStatus[]): IDeleteAccountSerial[] {
  return serials.filter(x => statuses.indexOf(x.status as SerialStatus) > -1);
}

export function getDeleteSerials(serials: ISerial[], ordersResult: any): IDeleteAccountSerial[] {
  let result: IDeleteAccountSerial[] = [];
  serials.forEach((serial: ISerial) => {
    const prodIndex = ordersResult.findIndex((x: { lineItemId: string | undefined }) => x.lineItemId === serial.orderLineItemId);
    if (prodIndex > -1) {
      const { model, name, productName, productFamily } = getDeleteAccountsDisplayData(ordersResult[prodIndex]);
      result.push({ serialNumber: serial.serial, bccProductId: serial.bccProductId, model: model, name: name, productName: productName, status: serial.status, productFamily, orderLineItemId: serial.orderLineItemId });
    } else {
      result.push({ serialNumber: serial.serial, bccProductId: serial.bccProductId, model: "", name: "", productName: "", status: serial.status, productFamily: "" });
    }
  });
  return result;
}

function getDeleteAccountsDisplayData(order: IOrder): { model: string; name: string; productName: string; productFamily: string } {
  if (nameIsBBS(order.familyName)) {
    return { model: order.prodSku, name: order.description, productName: "", productFamily: ProductFamily.BACKUP_APPLIANCES };
  }
  if (order.familyName.includes(ProductFamily.ESSENTIALS_SERIVICES) || order.familyName.includes(ProductFamily.CONTENT_SHIELD) || order.familyName.includes(ProductFamily.SECUREEDGE_SERVICES)) {
    return { model: "N/A", name: order.description?.length > 0 ? order.description : order.bundleName, productName: order.bundleName, productFamily: order.familyName };
  }
  return { model: "N/A", name: order.description?.length > 0 ? order.description : order.prodSku, productName: order.familyName, productFamily: "" };
}

export async function computeSerialsForAccountToDelete(serials: ISerial[], accountId: number, getState: () => IAppState, dispatch: Dispatch) {
  let result: IDeleteAccountSerial[] = [];
  if (serials.length > 0) {
    const { allProductIdsRepo } = getState().productState;
    const pendingSerials = await filtersSerialsToDelete(serials, allProductIdsRepo, [SerialStatus.SSG_PENDING, SerialStatus.PENDING], accountId, getState, dispatch);
    if (pendingSerials.length > 0) {
      return pendingSerials;
    } else {
      const activatedSerials = await filtersSerialsToDelete(serials, allProductIdsRepo, [SerialStatus.ACTIVATION_FAILED, SerialStatus.ACTIVE, SerialStatus.PROVISION_FAILED, SerialStatus.AVAILABLE], accountId, getState, dispatch);
      if (activatedSerials.length > 0) {
        result = activatedSerials;
      }
    }
  }
  const { hasIBU } = getState().accountState;
  if (hasIBU) {
    result.push({ serialNumber: "N/A", bccProductId: 0, model: "N/A", name: ProductFamily.INTRONIS_BACKUP, productName: "", status: SerialStatus.ACTIVE, productFamily: ProductFamily.INTRONIS_BACKUP });
  }
  return result;
}
