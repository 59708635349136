import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { TextField, Typography } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import DropDown from "../../DropDown";
import ICountry, { IState } from "../../../models/ICountry";
import { isStringNullOrEmpty } from "../../../utility";
import { useDispatch } from "react-redux";
import { getStatesAction } from "../../../actions/accountActions";
import { disableStateZipPostalCode, getStateLable, isUK, requiredZipPostalCode } from "../../../Utilities/accountsHelper";
import TextboxLabel from "../../TextboxLabel";

interface IFieldValidator {
  name: string;
  error: string;
  index: number;
}

interface IAddressDialogProps {
  countries: ICountry[];
  isBilling: boolean;
  company: string;
  name: string;
  city: string;
  countryId: number;
  email: string;
  phone: string;
  stateId: number;
  customState: string;
  street: string;
  street2?: string;
  street3?: string;
  street4?: string;
  zip: string;
  errors: IFieldValidator[];
  onCompanyChanged: (newValue: any) => void;
  onNameChanged: (newValue: any) => void;
  onStreetChanged: (newValue: any) => void;
  onStreet2Changed: (newValue: any) => void;
  onStreet3Changed: (newValue: any) => void;
  onStreet4Changed: (newValue: any) => void;
  onCityChanged: (newValue: any) => void;
  onZipChanged: (newValue: any) => void;
  onPhoneChanged: (newValue: any) => void;
  onEmailChanged: (newValue: any) => void;
  onCountryIdChanged: (newValue: any) => void;
  onCustomStateChanged: (newValue: any) => void;
  onStateIdChanged: (newValue: any) => void;
  disabled: boolean;
  handleLoadingStates: (newValue: boolean) => void;
  onOpenDropDown: (isOpen: boolean) => void;
  scrollToElement?: IFieldValidator;
}

const AddEditAddressDialog: React.FC<IAddressDialogProps> = ({ company, countries, isBilling, city, countryId, email, name, phone, stateId, customState, street, street2, street3, street4, zip, errors, onCompanyChanged, onNameChanged, onStreetChanged, onStreet2Changed, onStreet3Changed, onStreet4Changed, onCityChanged, onCountryIdChanged, onZipChanged, onPhoneChanged, onEmailChanged, onStateIdChanged, onCustomStateChanged, disabled, handleLoadingStates, onOpenDropDown, scrollToElement }) => {
  const dispatch = useDispatch();
  const [address2Visibility, setAddress2Visibility] = useState(false);
  const [address3Visibility, setAddress3Visibility] = useState(false);
  const [address4Visibility, setAddress4Visibility] = useState(false);
  const [stateLabel, setStateLabel] = useState<React.ReactNode>("STATE");
  const [stateOptions, setStateOptions] = useState<string[]>([]);
  const isEmailDisabled = isBilling && !isStringNullOrEmpty(email);
  const [isCountryEmpty, setIsCountryEmpty] = useState(false);
  const [isZipPostalCodeRequired, setIsZipPostalCodeRequired] = useState(false);
  const useStyles = makeStyles(theme => ({
    popper: {
      zIndex: 9000,
    },
  }));
  let classes = useStyles();

  const getStates = (countryIndex: number): IState[] => {
    if (countries && countries.length > countryIndex) {
      if (countries[countryIndex - 1]) {
        return countries[countryIndex - 1].states;
      }
    }
    return [];
  };

  const [nameError, setNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [streetError, setStreetError] = useState("");
  const [street2Error, setStreet2Error] = useState("");
  const [street3Error, setStreet3Error] = useState("");
  const [street4Error, setStreet4Error] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [zipError, setZipError] = useState("");
  const [stateError, setStateError] = useState("");
  const [customStateError, setCustomStateError] = useState("");

  const nameRef = useRef<any>();
  const companyRef = useRef<any>();
  const emailRef = useRef<any>();
  const streetRef = useRef<any>();
  const phoneRef = useRef<any>();
  const cityRef = useRef<any>();
  const countryRef = useRef<any>();
  const zipRef = useRef<any>();
  const stateRef = useRef<any>();

  const [states, setStates] = useState<IState[]>([]);

  const [loadingStates, setLoadingStates] = useState(false);

  useEffect(() => {
    handleLoadingStates(loadingStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStates]);

  useEffect(() => {
    if (!isStringNullOrEmpty(street4)) {
      setAddress2Visibility(true);
      setAddress3Visibility(true);
      setAddress4Visibility(true);
    } else if (!isStringNullOrEmpty(street3)) {
      setAddress3Visibility(true);
      setAddress2Visibility(true);
    } else if (!isStringNullOrEmpty(street2)) {
      setAddress2Visibility(true);
    }
  }, [street2, street3, street4]);

  useEffect(() => {
    let currentStates = getStates(countryId);
    setStates(currentStates);
    if (currentStates && currentStates.length > 0 && !loadingStates) {
      if (isUK(countries[countryId - 1].countryName)) {
        setStateOptions(currentStates.map(s => s.stateName));
      } else {
        setStateOptions(currentStates.map(s => s.state));
      }
    }
    handleStateChanged(stateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, countryId]);

  useEffect(() => {
    if (countryId > 0 && countries.length >= countryId) {
      let countryName = countries[countryId - 1].countryName;
      let stateLable = getStateLable(countryName, true).toUpperCase();
      if (countryName !== "United States" && countryName !== "Canada") {
        setStateLabel(
          <>
            {stateLable}
            <span className="AccountLengthInfo" data-testid="accountStateLengthInfo" style={{ fontWeight: "normal" }}>
              {" "}
              Optional
            </span>
          </>,
        );
      } else {
        setStateLabel(stateLable);
      }
    }
  }, [countries, countryId]);

  useEffect(() => {
    if (scrollToElement) {
      switch (scrollToElement.name) {
        case "name":
          nameRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "company":
          companyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "street":
          streetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "email":
          emailRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "phone":
          phoneRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "city":
          cityRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "country":
          countryRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "zip":
          zipRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "state":
          stateRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        default:
          break;
      }
    }
  }, [scrollToElement]);

  useEffect(() => {
    errors.forEach(value => {
      switch (value.name) {
        case "name":
          setNameError(value.error);
          break;
        case "company":
          setCompanyError(value.error);
          break;
        case "street":
          setStreetError(value.error);
          break;
        case "street2":
          setStreet2Error(value.error);
          break;
        case "street3":
          setStreet3Error(value.error);
          break;
        case "street4":
          setStreet4Error(value.error);
          break;
        case "email":
          setEmailError(value.error);
          break;
        case "phone":
          setPhoneError(value.error);
          break;
        case "city":
          setCityError(value.error);
          break;
        case "country":
          setCountryError(value.error);
          break;
        case "zip":
          setZipError(value.error);
          break;
        case "state":
          setStateError(value.error);
          break;
        case "customState":
          setCustomStateError(value.error);
          break;
        default:
          break;
      }
    });
  }, [errors]);

  const handleNameChanged = (newValue: string) => {
    setNameError("");
    onNameChanged(newValue);
  };

  const handleCompanyChanged = (newValue: string) => {
    setCompanyError("");
    onCompanyChanged(newValue);
  };

  const handleEmailChanged = (newValue: string) => {
    setEmailError("");
    onEmailChanged(newValue);
  };

  const handleStreetChanged = (newValue: string) => {
    setStreetError("");
    onStreetChanged(newValue);
  };

  const handleStreet2Changed = (newValue: string) => {
    setStreet2Error("");
    onStreet2Changed(newValue);
  };

  const handleStreet3Changed = (newValue: string) => {
    setStreet3Error("");
    onStreet3Changed(newValue);
  };

  const handleStreet4Changed = (newValue: string) => {
    setStreet4Error("");
    onStreet4Changed(newValue);
  };

  const handlePhoneChanged = (newValue: string) => {
    setPhoneError("");
    onPhoneChanged(newValue);
  };

  const loadStates = (countryCode: string) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getStatesAction(countryCode));
      resolve(newAccountId);
    });

  const handleCountryChanged = (newValue: number) => {
    setCountryError("");
    const selCountry = countries[newValue - 1];
    if (selCountry) {
      setLoadingStates(true);
      loadStates(selCountry.country).then(result => {
        setLoadingStates(false);
        setStates(result);
        onCountryIdChanged(newValue);
        handleStateChanged(0);
      });
      setZipError("");
    }
  };

  const handleCityChanged = (newValue: string) => {
    setCityError("");
    onCityChanged(newValue);
  };

  const handleZipChanged = (newValue: string) => {
    setZipError("");
    onZipChanged(newValue);
  };

  const handleStateChanged = (newValue: number) => {
    setStateError("");
    onStateIdChanged(newValue);
  };

  const handleCustomStateChanged = (newValue: string) => {
    setCustomStateError("");
    onCustomStateChanged(newValue);
  };

  const handleOnOpenDropdown = (isOpen: boolean) => {
    onOpenDropDown(isOpen);
  };

  useEffect(() => {
    setIsCountryEmpty(disableStateZipPostalCode(countryId));
  }, [countryId]);

  useEffect(() => {
    if (countryId > 0 && countries.length >= countryId) {
      setIsZipPostalCodeRequired(requiredZipPostalCode(countries[countryId - 1].countryName));
    }
  }, [countries, countryId]);

  return (
    <div style={{ paddingTop: "15px" }}>
      <Typography data-testid="addressInfoTitle" variant="h6" gutterBottom style={{ padding: "10px" }}>
        {isBilling ? "BILLING INFORMATION" : "CONTACT INFORMATION"}
      </Typography>
      <Grid container className="AddEditDialogContainer">
        <Grid item xs={12} sm={6}>
          <Grid container direction="column">
            <Grid item xs={11}>
              <TextField data-testid="accountNameTxt" fullWidth label={<TextboxLabel label={isBilling ? "BILLING NAME" : "CONTACT NAME"} dataTestId={"accountNameTxtLengthInfo"} maxNoOfCharacters={64} />} size="small" value={name} error={nameError.length > 0} ref={nameRef} disabled={disabled} helperText={nameError} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleNameChanged(ev.target.value)} inputProps={{ maxLength: 64 }} style={{ marginBottom: "10px" }} />
            </Grid>
            <Grid item xs={11}>
              <Tooltip title={isEmailDisabled ? "To change your Billing Address, please contact the Barracuda MSP Billing Department" : ""} classes={{ popper: classes.popper }}>
                <TextField data-testid="accountEmailTxt" fullWidth label="EMAIL" size="small" value={email} error={emailError.length > 0} ref={emailRef} disabled={disabled || isEmailDisabled} helperText={emailError} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleEmailChanged(ev.target.value)} inputProps={{ maxLength: 64 }} className="AccountAddEditTextField" />
              </Tooltip>
            </Grid>
            <Grid item xs={11}>
              {!isBilling && <TextField data-testid="accountCompanyTxt" fullWidth label={<TextboxLabel label={"COMPANY NAME"} dataTestId={"accountCompanyTxtLengthInfo"} maxNoOfCharacters={64} />} size="small" value={company} error={companyError.length > 0} ref={companyRef} disabled={disabled} helperText={companyError} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCompanyChanged(ev.target.value)} inputProps={{ maxLength: 64 }} className="AccountAddEditTextField" />}
            </Grid>
            <Grid item xs={11}>
              <TextField
                data-testid="accountPhoneTxt"
                label="PHONE NUMBER"
                size={"small"}
                value={phone}
                fullWidth
                error={phoneError.length > 0}
                ref={phoneRef}
                disabled={disabled}
                helperText={phoneError}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handlePhoneChanged(ev.target.value)}
                inputProps={{
                  maxLength: 100,
                }}
                className="AccountAddEditTextField"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container direction="column">
            <Grid container>
              <Grid item xs={11}>
                <TextField data-testid="accountAddressTxt" label="ADDRESS" size="small" fullWidth value={street} error={streetError.length > 0} ref={streetRef} disabled={disabled} helperText={streetError} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreetChanged(ev.target.value)} inputProps={{ maxLength: 32 }} style={{ marginBottom: "10px" }} />
              </Grid>
              {!address2Visibility && (
                <Grid item xs={1} className="AddAddressBtn">
                  <IconButton data-testid="addAddress2Btn" aria-label="add" size="small" disabled={disabled} onClick={() => setAddress2Visibility(true)} style={{ marginTop: "2%" }}>
                    <CoreIcons.Add />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            {address2Visibility && (
              <Grid container>
                <Grid item xs={11}>
                  <TextField
                    data-testid="accountAddress2Txt"
                    label={<TextboxLabel label={"ADDRESS 2"} dataTestId={"accountAddress2TxtLengthInfo"} optional={true} />}
                    fullWidth
                    size={"small"}
                    value={street2}
                    error={street2Error.length > 0}
                    ref={streetRef}
                    disabled={disabled}
                    helperText={street2Error}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreet2Changed(ev.target.value)}
                    inputProps={{
                      maxLength: 32,
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                </Grid>
                {address2Visibility && !address3Visibility && (
                  <Grid item xs={1} className="AddAddressBtn">
                    <IconButton data-testid="addAddress3Btn" aria-label="add" size={"small"} disabled={disabled} onClick={() => setAddress3Visibility(true)} style={{ marginTop: "2%" }}>
                      <CoreIcons.Add></CoreIcons.Add>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
            {address3Visibility && (
              <Grid container>
                <Grid item xs={11}>
                  <TextField
                    data-testid="accountAddress3Txt"
                    label={<TextboxLabel label={"ADDRESS 3"} dataTestId={"accountAddress3TxtLengthInfo"} optional={true} />}
                    fullWidth
                    size={"small"}
                    value={street3}
                    error={street3Error.length > 0}
                    ref={streetRef}
                    disabled={disabled}
                    helperText={street3Error}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreet3Changed(ev.target.value)}
                    inputProps={{
                      maxLength: 32,
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                </Grid>
                {address3Visibility && !address4Visibility && (
                  <Grid item xs={1} className="AddAddressBtn">
                    <IconButton data-testid="addAddress4Btn" aria-label="add" size={"small"} disabled={disabled} onClick={() => setAddress4Visibility(true)} style={{ marginTop: "2%" }}>
                      <CoreIcons.Add></CoreIcons.Add>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
            {address4Visibility && (
              <Grid item xs={11}>
                <TextField
                  data-testid="accountAddress4Txt"
                  label={<TextboxLabel label={"ADDRESS 4"} dataTestId={"accountAddress4TxtLengthInfo"} optional={true} />}
                  fullWidth
                  size={"small"}
                  value={street4}
                  error={street4Error.length > 0}
                  ref={streetRef}
                  disabled={disabled}
                  helperText={street4Error}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreet4Changed(ev.target.value)}
                  inputProps={{
                    maxLength: 32,
                  }}
                  className="AccountAddEditTextField"
                />
              </Grid>
            )}
            <Grid item xs={11}>
              <TextField
                data-testid="accountCityTxt"
                label="CITY"
                size={"small"}
                value={city}
                fullWidth
                error={cityError.length > 0}
                ref={cityRef}
                disabled={disabled}
                helperText={cityError}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCityChanged(ev.target.value)}
                inputProps={{
                  maxLength: 32,
                }}
                className="AccountAddEditTextField"
              />
            </Grid>
            <Grid item xs={11}>
              <div className="accountCountryDropdown" data-testid="accountCountryDropdown" ref={countryRef} style={{ marginBottom: "10px" }}>
                <DropDown label={"COUNTRY"} required={false} options={countries?.map(c => `${c.countryName} - ${c.country}`)} selectedOptionId={countryId} error={countryError} disabled={disabled} handleChange={handleCountryChanged} onOpen={handleOnOpenDropdown} />
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={11}>
                {states && states.length > 0 ? (
                  <div className="accountStateDropdown" data-testid="accountStateDropdown" ref={stateRef} style={{ marginBottom: "10px" }}>
                    <DropDown label={stateLabel} required={false} options={stateOptions} selectedOptionId={stateId} error={stateError} handleChange={handleStateChanged} disabled={disabled || loadingStates} onOpen={handleOnOpenDropdown} />
                  </div>
                ) : (
                  <TextField data-testid="accountStateTxt" label={<TextboxLabel label={"STATE"} dataTestId={"accountCustomStateLengthInfo"} optional={true} />} size={"small"} value={customState} error={customStateError.length > 0} ref={stateRef} helperText={customStateError} disabled={disabled || isCountryEmpty || loadingStates} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCustomStateChanged(ev.target.value)} inputProps={{ maxLength: 32 }} style={{ marginBottom: "10px" }} fullWidth />
                )}
              </Grid>
              <Grid item xs={1} className="LoadingAccountStates">
                {loadingStates && (
                  <div style={{ paddingLeft: "5px" }}>
                    <CircularProgress data-testid="loadingAccountStates" size="22px" />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <TextField
                data-testid="accountZipTxt"
                label={<TextboxLabel label={"ZIP/POSTAL CODE"} dataTestId={"accountZipPostalCodeLengthInfo"} optional={!isZipPostalCodeRequired} />}
                size={"small"}
                value={zip}
                fullWidth
                error={zipError.length > 0}
                ref={zipRef}
                disabled={disabled || isCountryEmpty}
                helperText={zipError}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleZipChanged(ev.target.value)}
                inputProps={{
                  maxLength: 10,
                }}
                style={{ marginRight: "16px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditAddressDialog;
