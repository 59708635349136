import IAccount from "../../../../models/IAccount";
import { findAccountById } from "../../../../Utilities/accountsHelper";

export function isEditSerialDisabled(accountId: number, allMspAccount: IAccount[], accountsNames: IAccount[]): boolean {
  return findAccountById(allMspAccount, accountsNames, accountId) === undefined;
}

export function getEditSerialTitle(accountId: number, allMspAccount: IAccount[], accountsNames: IAccount[]): string {
  if (findAccountById(allMspAccount, accountsNames, accountId) === undefined) {
    return `Account ID ${accountId} does not have MSP type`;
  }
  return "";
}
