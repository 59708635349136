import { CancelTokenSource } from "axios";
import { Reducer } from "redux";
import { IntegrationsActions, IntegrationsActionTypes } from "../../actions/integrations/integrationsActions";
import DefaultIntegrations from "../../models/Integrations/DefaultIntegrations";
import IConnectWiseIntegrationInfo from "../../models/Integrations/IConnectWiseIntegrationInfo";
import IIntegration from "../../models/Integrations/IIntegration";

export interface IIntegrationsState {
  integrationsLoaded: boolean;
  integrations: IIntegration[];
  selectedIntegration?: IIntegration;
  selectedIntegrationsTabName: string | undefined;
  loadedIntegration?: IIntegration;
  cwIntegrationInfo?: IConnectWiseIntegrationInfo;
  loadingIntegrations: boolean;
  loadingConnectWiseIntegrationInfo: boolean;
  loadingConnectWiseIntegrationsCanceled?: boolean;
  loadIntegrationExtraInfoCancellationTokenSource?: CancelTokenSource;
}

const initialIntegrationState: IIntegrationsState = {
  integrationsLoaded: false,
  integrations: DefaultIntegrations,
  selectedIntegrationsTabName: "",
  loadingIntegrations: false,
  loadingConnectWiseIntegrationInfo: false,
};

export const integrationsReducer: Reducer<IIntegrationsState, IntegrationsActions> = (state = initialIntegrationState, action: IntegrationsActions) => {
  switch (action.type) {
    case IntegrationsActionTypes.SET_SELECTED_INTEGRATION: {
      return {
        ...state,
        selectedIntegration: action.selectedIntegration,
      };
    }
    case IntegrationsActionTypes.SET_LOADED_INTEGRATION: {
      return {
        ...state,
        loadedIntegration: action.loadedIntegration,
      };
    }
    case IntegrationsActionTypes.SET_SELECTED_INTEGRATIONS_TAB: {
      return {
        ...state,
        selectedIntegrationsTabName: action.selectedIntegrationsTabName,
      };
    }
    case IntegrationsActionTypes.GET_INTEGRATIONS: {
      return {
        ...state,
        integrations: action.integrations,
        loadingIntegrations: action.loadingIntegrations,
        integrationsLoaded: action.integrationsLoaded,
      };
    }
    case IntegrationsActionTypes.SET_GET_INTEGRATIONS_CANCELED: {
      return {
        ...state,
        loadingIntegrationsCanceled: action.loadingIntegrationsCanceled,
      };
    }
    case IntegrationsActionTypes.GET_CONNECTWISE_INTEGRATION_INFO: {
      return {
        ...state,
        cwIntegrationInfo: action.cwIntegrationInfo,
        loadingConnectWiseIntegrationInfo: action.loadingConnectWiseIntegrationInfo,
      };
    }
    case IntegrationsActionTypes.SET_CANCEL_LOAD_INTEGRATION_EXTRA_INFO_TOKEN: {
      return {
        ...state,
        loadIntegrationExtraInfoCancellationTokenSource: action.loadIntegrationExtraInfoCancellationTokenSource,
      };
    }
    case IntegrationsActionTypes.SET_GET_CONNECTWISE_INTEGRATION_INFO_CANCELED: {
      return {
        ...state,
        loadingConnectWiseIntegrationsCanceled: action.loadingConnectWiseIntegrationsCanceled,
      };
    }
    case IntegrationsActionTypes.ADD_INTEGRATION:
    case IntegrationsActionTypes.DELETE_INTEGRATION: {
      return {
        ...state,
        integrations: action.integrations,
        selectedIntegration: action.selectedIntegration,
        loadedIntegration: action.loadedIntegration,
      };
    }
    case IntegrationsActionTypes.EDIT_INTEGRATION: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
