import React, { useState, useEffect } from "react";
import { Card, Tabs, TabPanel, Tab } from "@cuda-networks/bds-core";
import { useDispatch, useSelector } from "react-redux";
import IntegrationTabs from "../../models/Integrations/IntegrationTabs";
import { IAppState } from "../../store/store";
import { getDetailsTab } from "../../utility";
import { TabProps } from "@cuda-networks/bds-core/dist/Tabs";
import IntegrationsAccountsTab from "./Accounts/IntegrationsAccountsTab";
import IntegrationsBillingTab from "./Billing/IntegrationsBillingTab";
import IntegrationsLogsTab from "./Logs/IntegrationsLogsTab";
import IntegrationsDetailsTab from "./Details/IntegrationsDetailsTab";
import { loadConnectWiseIntegrationInfoAction, setLoadedIntegrationAction, setSelectedIntegrationsTabNameAction } from "../../actions/integrations/integrationsActions";
import { getIntegrationAccountsAction, getIntegrationFilteredAccountsAction, setIntegrationAccountsTablePropsActionToFirstPage, setShowUnlinkedAccountsAction } from "../../actions/integrations/integrationsAccountsActions";
import { getIntegrationLogsAction } from "../../actions/integrations/integrationsLogsActions";
import IntegrationStatus from "../../models/Integrations/IntegrationStatus";
import { getIntegrationBillingMapsAction } from "../../actions/integrations/integrationsBillingActions";
import IntegrationsTicketsTab from "./Tickets/IntegrationsTicketsTab";
import { getIntegrationFiltering, getIntegrationSorting } from "../../Utilities/integrationsUtilities";
import { Link, useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import IntegrationType from "../../models/IntegrationType";
import IIntegration from "../../models/Integrations/IIntegration";

export interface IDetailsTabs {
  id: number;
  routeId: string;
  tab: TabProps;
}

export interface IIntegrationTabsProps {
  integrationAccountsTabLoaded: boolean;
  integrationDetailsTabLoaded: boolean;
  integrationBillingTabLoaded: boolean;
  integrationLogsTabLoaded: boolean;
  setTabLoaded: (loaded: boolean, tab: IntegrationTabs) => void;
}

const IntegrationsTabs: React.FC<IIntegrationTabsProps> = ({ integrationAccountsTabLoaded, integrationDetailsTabLoaded, integrationBillingTabLoaded, integrationLogsTabLoaded, setTabLoaded }) => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const selectedIntegration = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const loadedIntegration = useSelector((state: IAppState) => state.integrationsState.loadedIntegration);
  const selectedIntegrationsTabName = useSelector((state: IAppState) => state.integrationsState.selectedIntegrationsTabName);
  const loadingIntegrationAccountsCanceled = useSelector((state: IAppState) => state.integrationsAccountsState.loadingIntegrationAccountsCanceled);
  const loadingIntegrationLogsCanceled = useSelector((state: IAppState) => state.integrationsLogsState.loadingIntegrationLogsCanceled);
  const loadingIntegrationBillingMapCanceled = useSelector((state: IAppState) => state.integrationsBillingMapState.loadingIntegrationBillingMapCanceled);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const { tabId, integrationId } = useParams();
  const integrationAccountsTableState = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccountsTableState);
  const alltabs: IDetailsTabs[] = [
    {
      id: 0,
      routeId: "accounts",
      tab: {
        label: IntegrationTabs.Accounts,
        value: <IntegrationsAccountsTab />,
      },
    },
    {
      id: 1,
      routeId: "billing",
      tab: {
        label: IntegrationTabs.Billing,
        value: <IntegrationsBillingTab />,
      },
    },
    {
      id: 2,
      routeId: "logs",
      tab: {
        label: IntegrationTabs.Logs,
        value: <IntegrationsLogsTab />,
      },
    },
    {
      id: 3,
      routeId: "details",
      tab: {
        label: IntegrationTabs.Details,
        value: <IntegrationsDetailsTab />,
      },
    },
    {
      id: 4,
      routeId: "tickets",
      tab: {
        label: IntegrationTabs.Tickets,
        value: <IntegrationsTicketsTab />,
      },
    },
  ];

  const isConnectWiseActive = (selectedIntegration?: IIntegration): boolean => {
    if (selectedIntegration === undefined) return false;
    return selectedIntegration.status === IntegrationStatus.Active || selectedIntegration.status === IntegrationStatus.NewLogs;
  };

  const [tabs, setTabs] = useState(alltabs);

  const selectedDetailTab = getDetailsTab(tabs, selectedIntegrationsTabName);
  const [selectedTab, setSelectedTab] = useState(selectedDetailTab);
  const handleChange: any = (_e: React.SyntheticEvent, newValue: any) => {
    let resultValue = tabs.filter(x => x.id === newValue)[0];
    if (resultValue !== null && resultValue !== undefined) {
      setSelectedTab(resultValue);
      dispatch(setSelectedIntegrationsTabNameAction(resultValue.tab.label));
    }
  };

  const handleChangeByTabName2: any = (_e: React.SyntheticEvent, newValue: any) => {
    let resultValue = tabs.filter(x => x.routeId?.toString() === newValue)[0];
    if (resultValue !== null) {
      setSelectedTab(resultValue);
      dispatch(setSelectedIntegrationsTabNameAction(resultValue.tab.label));
    }
  };

  useEffect(() => {
    if (tabId && tabs) handleChangeByTabName2(null, tabId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId]);

  const [tabObject, setTabObject] = useState(selectedDetailTab);

  useEffect(() => {
    setTabs(alltabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration]);

  useEffect(() => {
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].id = i;
    }
  }, [tabs]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedIntegration) {
      const anyTabsAlreadyLoaded = integrationAccountsTabLoaded || integrationDetailsTabLoaded || integrationBillingTabLoaded || integrationLogsTabLoaded;
      if (!anyTabsAlreadyLoaded) {
        let selectedTabName;
        let defaultTab;
        switch (selectedIntegration.status) {
          case IntegrationStatus.Active:
            selectedTabName = "accounts";
            defaultTab = IntegrationTabs.Accounts;
            break;
          case IntegrationStatus.NewLogs:
            selectedTabName = "logs";
            defaultTab = IntegrationTabs.Logs;
            break;
          default:
            selectedTabName = "details";
            defaultTab = IntegrationTabs.Details;
        }
        setSelectedTab(getDetailsTab(tabs, defaultTab));

        if (tabId !== undefined && isConnectWiseActive(selectedIntegration)) {
          selectedTabName = tabId;
        }

        navigateToPage(selectedTabName);
      } else {
        navigateToPage(selectedTab.routeId);
      }
    }

    function navigateToPage(selectedTab: string) {
      let integrationArg = integrationId;
      if (integrationId === undefined) {
        if (selectedIntegration?.type === IntegrationType.ConnectWise) {
          integrationArg = "connectwise";
        } else {
          integrationArg = selectedIntegration?.id! + "";
        }
      }

      const targetPath = `/accounts/${selectedAccount?.id}/integrations/${integrationArg}/${selectedTab}`;

      if (location.pathname !== targetPath) {
        console.debug(`navigate to targetPath:  ${targetPath} != pathname:${location.pathname}`);

        if (location.pathname.endsWith("/integrations")) {
          navigate(targetPath, { replace: true });
        } else {
          navigate(targetPath);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration, integrationAccountsTabLoaded, integrationDetailsTabLoaded, integrationBillingTabLoaded, integrationLogsTabLoaded]);

  useEffect(() => {
    setSelectedTab(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegrationsTabName]);

  useEffect(() => {
    setTabObject(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedIntegration && selectedTab && selectedAccount) {
      let integrationHasChanged = false;
      if (loadedIntegration?.id !== selectedIntegration.id) {
        integrationHasChanged = true;
        dispatch(setLoadedIntegrationAction(selectedIntegration));
        setTabLoaded(false, IntegrationTabs.Accounts);
        setTabLoaded(false, IntegrationTabs.Billing);
        setTabLoaded(false, IntegrationTabs.Logs);
        setTabLoaded(false, IntegrationTabs.Details);
      }
      if (selectedTab.tab.label === IntegrationTabs.Accounts && isConnectWiseActive(selectedIntegration)) {
        if (integrationHasChanged || !integrationAccountsTabLoaded || loadingIntegrationAccountsCanceled) {
          setTabLoaded(true, IntegrationTabs.Accounts);

          const filters = getIntegrationFiltering(integrationAccountsTableState.filter);
          if (filters.forAccount === undefined && filters.forCompany === undefined) {
            const take = integrationAccountsTableState.take !== undefined ? integrationAccountsTableState.take : 10;
            const { sortBy, asc } = getIntegrationSorting(integrationAccountsTableState.sort);

            //used for filtered account values
            dispatch(getIntegrationAccountsAction(selectedAccount, 0, take, sortBy !== undefined ? sortBy : "mspAccountName", asc !== undefined ? asc : true, false, true));
          } else {
            //used for default account values
            dispatch(getIntegrationFilteredAccountsAction(selectedAccount, filters.forAccount, filters.forCompany, false));
          }

          dispatch(setShowUnlinkedAccountsAction(false));
          dispatch(setIntegrationAccountsTablePropsActionToFirstPage({}));
        }
      } else if (selectedTab.tab.label === IntegrationTabs.Billing && isConnectWiseActive(selectedIntegration)) {
        if (integrationHasChanged || !integrationBillingTabLoaded || loadingIntegrationBillingMapCanceled) {
          setTabLoaded(true, IntegrationTabs.Billing);
          dispatch(getIntegrationBillingMapsAction(selectedAccount));
        }
      } else if (selectedTab.tab.label === IntegrationTabs.Logs && isConnectWiseActive(selectedIntegration)) {
        if (integrationHasChanged || !integrationLogsTabLoaded || loadingIntegrationLogsCanceled) {
          setTabLoaded(true, IntegrationTabs.Logs);
          dispatch(getIntegrationLogsAction(mspAccountLoggedIn));
        }
      } else if (selectedTab.tab.label === IntegrationTabs.Details) {
        if ((integrationHasChanged || !integrationDetailsTabLoaded || loadingIntegrationLogsCanceled) && selectedIntegration.id !== undefined) {
          setTabLoaded(true, IntegrationTabs.Details);
          dispatch(loadConnectWiseIntegrationInfoAction());
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedIntegration, integrationAccountsTabLoaded, integrationLogsTabLoaded, integrationBillingTabLoaded, integrationDetailsTabLoaded, integrationAccountsTableState]);

  return (
    <Card>
      <Tabs variant="scrollable" value={selectedTab?.id !== undefined ? selectedTab?.id : 0} onChange={handleChange} indicatorColor="primary" textColor="primary">
        {tabs.map((t, index) => (
          <Tab component={Link} to={`/accounts/${selectedAccount?.id}/integrations/connectwise/${t.routeId}`} key={"integrationTab" + index} data-testid={"integrationTab" + t.tab?.label?.toString().toLocaleLowerCase().replace(/\s/g, "")} label={t.tab?.label} />
        ))}
      </Tabs>
      <TabPanel>{tabObject?.tab.value}</TabPanel>
    </Card>
  );
};

export default IntegrationsTabs;
