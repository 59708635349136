import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, Typography } from "@cuda-networks/bds-core";
import SetupBillingTable from "./SetupBillingTable";
import { IAppState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { IIntegrationBillingMap, IntegrationBllingMapType } from "../../../../models/Integrations/IntegrationsBillingMap";
import { EchoOnlyOption, getAvailableBillingMapsFamilies, getBillingMapList, hasIBUBillingMapFamily } from "../../../../Utilities/integrationsBillingHelper";
import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import UsageType from "../../../../models/Integrations/UsageType";
import { adjustSetupBillingTablePageAfterAddDeleteAction } from "../../../../actions/integrations/integrationsBillingActions";
import { getAvailableItemsAfterAddingRemovedByEditItem, getCurrentAvailableOrderLineItemsAfterChangePlan, getCurrentAvailableOrderLineItemsAfterDeleteItem, getCurrentBillingMapsAfterAddChangeBundle, getCurrentBillingMapsAfterAddChangeFamily, getCurrentBillingMapsAfterAddChangeLevel, getCurrentBillingMapsAfterAddChangePlan, getCurrentBillingMapsAfterAddNewItem, getCurrentBillingMapsAfterDeleteItem } from "../../../../businessLogic/components/Integrations/Billing/SetupBilling/SetupIntegrationBillingDialog";
import { handleBackdropClick, setNoPoinerEvents } from "../../../../utility";
import { IFieldValidator } from "../../../Accounts/AddEditAccount/addressValidator";
import { validateSetupBilling } from "./setupBillingValidator";

interface ISetupIntegrationBillingDialogProps {
  showSetupBilling: boolean;
  onSubmit: (billingMapsList: IIntegrationBillingMap[]) => void;
  onCancel: () => void;
  actionInProgress: boolean;
}

const SetupIntegrationBillingDialog: React.FC<ISetupIntegrationBillingDialogProps> = ({ showSetupBilling, onCancel, onSubmit, actionInProgress }) => {
  const dispatch = useDispatch();
  const integrationBillingMaps = useSelector((state: IAppState) => state.integrationsBillingMapState.integrationBillingMaps);
  const integrationBillingMapOrderlineItems = useSelector((state: IAppState) => state.integrationsBillingMapState.integrationBillingMapOrderlineItems);
  const integrationBillingUsageType = useSelector((state: IAppState) => state.integrationsBillingMapState.integrationBillingUsageType);
  const [availableOrderLineItems, setAvailableOrderLineItems] = useState<IIntegrationBillingMap[]>([]);
  const [billingMapsList, setBillingMapsList] = useState<IIntegrationBillingMap[]>([]);
  const [setupErrors, setSetupErrors] = useState<IFieldValidator[]>([]);

  useEffect(() => {
    const availableItems = getAvailableBillingMapsFamilies(integrationBillingMaps, integrationBillingMapOrderlineItems);
    setAvailableOrderLineItems(availableItems);
    const items = getBillingMapList(integrationBillingMaps);
    setBillingMapsList(items);
    let canceledProductsError = validateSetupBilling(items);
    if (canceledProductsError.length > 0) {
      setSetupErrors(canceledProductsError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationBillingMaps, integrationBillingMapOrderlineItems]);

  const onAddBillingMapping = (): void => {
    const newBillingMapsList = getCurrentBillingMapsAfterAddNewItem(billingMapsList);
    setBillingMapsList(newBillingMapsList);
    dispatch(adjustSetupBillingTablePageAfterAddDeleteAction(newBillingMapsList.length, false));
  };

  const onDeleteItem = (item: IIntegrationBillingMap, index: number): void => {
    const newBillingMapsList = getCurrentBillingMapsAfterDeleteItem(billingMapsList, item);
    setBillingMapsList(newBillingMapsList);
    clearSetupError(index, "");
    setAvailableOrderLineItems(getCurrentAvailableOrderLineItemsAfterDeleteItem(availableOrderLineItems, item));
    dispatch(adjustSetupBillingTablePageAfterAddDeleteAction(newBillingMapsList.length, true));
  };

  const clearSetupError = (index: number, errorName: string) => {
    let newSetupErrors = [...setupErrors];
    if (errorName.length > 0) {
      let newIndex = newSetupErrors.findIndex(e => e.name === errorName && e.index === index);
      if (newIndex > -1) {
        newSetupErrors.splice(newIndex, 1);
        setSetupErrors(newSetupErrors);
      }
    } else {
      newSetupErrors = newSetupErrors.filter(e => e.index !== index);
      setSetupErrors(newSetupErrors);
    }
  };

  const onChangeFamily = (currentMap: IIntegrationBillingMap, newFamily: IntegrationBllingMapType, index: number): void => {
    clearSetupError(index, "family");
    setBillingMapsList(getCurrentBillingMapsAfterAddChangeFamily(billingMapsList, currentMap, newFamily));
    setAvailableOrderLineItems(getAvailableItemsAfterAddingRemovedByEditItem(availableOrderLineItems, currentMap));
  };

  const onChangeBundle = (currentMap: IIntegrationBillingMap, newBundle: string, index: number): void => {
    clearSetupError(index, "bundle");
    setBillingMapsList(getCurrentBillingMapsAfterAddChangeBundle(billingMapsList, currentMap, newBundle));
    setAvailableOrderLineItems(getAvailableItemsAfterAddingRemovedByEditItem(availableOrderLineItems, currentMap));
  };

  const onChangePlan = (currentMap: IIntegrationBillingMap, newPlan: string, index: number): void => {
    clearSetupError(index, "plan");
    setBillingMapsList(getCurrentBillingMapsAfterAddChangePlan(billingMapsList, availableOrderLineItems, currentMap, newPlan));
    setAvailableOrderLineItems(getCurrentAvailableOrderLineItemsAfterChangePlan(availableOrderLineItems, currentMap, newPlan));
  };

  const onChangeLevel = (currentMap: IIntegrationBillingMap, newLevel: string, index: number): void => {
    clearSetupError(index, "level");
    setBillingMapsList(getCurrentBillingMapsAfterAddChangeLevel(billingMapsList, currentMap, newLevel));
  };

  const handleOnConfirm = () => {
    let errors = validateSetupBilling(billingMapsList);
    setSetupErrors(errors);
    if (errors.length === 0) {
      onSubmit(billingMapsList);
    }
  };

  return (
    <Dialog data-testid="setupBillingDialog" fullWidth open={showSetupBilling} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={"lg"}>
      <DialogTitle data-testid="billingSetupTitle">BILLING SETUP</DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        {hasIBUBillingMapFamily(integrationBillingMaps) && (
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Tooltip title={EchoOnlyOption}>
                <span>
                  <Typography data-testid="IntronisBackupUsageValue" className={"cursorNotAllowed"} style={{ fontWeight: "bold", color: "#B2B2B2" }}>
                    Intronis Backup Usage Value
                  </Typography>
                  <Typography data-testid="IntronisBackupUsageValueDescription" className={"cursorNotAllowed"} style={{ color: "#B2B2B2" }}>
                    Determine if you would like to update total usage for each account or only the additional usage over the account's plan size.
                  </Typography>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={integrationBillingUsageType}>
                <FormControlLabel data-testid="UpdateTotalUsage" value={UsageType.UpdateTotalUsage} disabled control={<Radio />} label="Update Total Usage" />
                <FormControlLabel data-testid="UpdateAdditionalUsage" value={UsageType.UpdateAdditionalUsage} disabled control={<Radio />} label="Update Additional Usage" />
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} style={{ marginBottom: "15px" }}>
          <div className={availableOrderLineItems.length === 0 ? "cursorNotAllowed" : ""}>
            <Tooltip title={"No more Products/Services to map"} disableHoverListener={availableOrderLineItems.length !== 0} placement="bottom-start">
              <span>
                <Button data-testid="AddBillingMappingButton" primary="true" size="large" onClick={onAddBillingMapping} className={setNoPoinerEvents(availableOrderLineItems.length === 0)} disabled={availableOrderLineItems.length === 0}>
                  ADD BILLING MAPPING
                </Button>
              </span>
            </Tooltip>
          </div>
        </Grid>
        <Grid data-testid="integrationsSetupBillingTable" item xs={12}>
          <SetupBillingTable availableOrderLineItems={availableOrderLineItems} items={billingMapsList} onDeleteItem={onDeleteItem} onChangePlan={onChangePlan} onChangeFamily={onChangeFamily} onChangeBundle={onChangeBundle} onChangeLevel={onChangeLevel} errors={setupErrors} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancelSetupBillingButton" variant={"text"} size="large" onClick={onCancel} disabled={actionInProgress} className={"dialogButtonSpacing"}>
          CANCEL
        </Button>
        <Button data-testid="confirmSetupBillingButton" isLoading={actionInProgress} primary="true" disabled={actionInProgress} type={"submit"} onClick={handleOnConfirm} className={"dialogButtonSpacing"}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetupIntegrationBillingDialog;
