import SvgInfo from "@cuda-networks/bds-core/dist/Icons/Feedback/Info";
import React from "react";
import { getAccountUsageInfoIconTooltip } from "../../businessLogic/components/Accounts/AccountUsageInfoIcon";
import TooltipElement from "../Users/TooltipElement";

const UsersHeaderCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return (
    <div className="k-link" onClick={props.onClick}>
      <div style={{ display: "inline-flex", height: "17px" }}>
        <TooltipElement title={props.title}>
          <span>{props.title}</span>
        </TooltipElement>
        {props.children}
        {props.showM365Icon && (
          <div data-testid="m365ProdUsersInfoIcon" className={"headerUserColumnIcon"}>
            <TooltipElement title={getAccountUsageInfoIconTooltip}>
              <SvgInfo color={"secondary"}></SvgInfo>
            </TooltipElement>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersHeaderCell;
