import React, { useEffect, useState } from "react";
import "../../table.css";
import { DataTable, DataTableColumn as Column, CircularProgress } from "@cuda-networks/bds-core";
import { process, State } from "@progress/kendo-data-query";
import IUser from "../../models/IUser";
import ButtonCommandCell from "../ButtonCommandCell";
import IconUserCell from "./IconUserCell";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { getAccountUsersExtraInfoAction, setSelectedUserAction, setTableProps } from "../../actions/userActions";
import TooltipElement from "./TooltipElement";
import { getUserIconType } from "./User";
import TooltipsForEntitnlements from "./TooltipsForEntitnlements";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { canAddEditUser, getButtonCount } from "../../utility";
import IAccount from "../../models/IAccount";
import { Backdrop, useMediaQuery } from "@material-ui/core";
import { filterTextInput, getColorForColumn } from "../TableHelpers";
import { getEditUserIsDisabled, getEditUserTooltip, getLdapMfaStatuses } from "../../Utilities/usersHelper";
import MspType from "../../models/MspType";
import HeaderCell from "../HeaderCell";
import { PAGE_SIZES } from "../../models/TableConfig";

interface IUsersTableProps {
  openEditDialog: (user: IUser) => void;
}

const UsersTable: React.FC<IUsersTableProps> = ({ openEditDialog }) => {
  const dispatch = useDispatch();
  const users = useSelector((state: IAppState) => state.userState.usersToDisplay);
  const loadingUsersExtraInfo = useSelector((state: IAppState) => state.userState.loadingUsersExtraInfo);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const tableState = useSelector((state: IAppState) => state.userState.tableState);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const isNotBaLoggedIn = mspAccountLoggedIn.type !== MspType.BillingAggregator;
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");

  const [buttonCount, setButtonCount] = useState(10);
  const [currentlySelectedAccountId, setCurrentlySelectedAccountId] = useState(0);
  const [showActionColumn, setshowActionColumn] = useState(false);
  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "LOGIN",
        field: "email",
        show: true,
        filter: "text",
        filtrable: true,
      },
      {
        title: "ROLE",
        field: "role",
        show: false,
        filter: "text",
        filtrable: false,
      },
      {
        title: "ROLE",
        field: "displayRole",
        show: true,
        filter: "text",
        filtrable: false,
      },
      {
        title: "ACCOUNT ACCESS",
        field: "accountAccess",
        show: isNotBaLoggedIn,
        filter: "text",
        filtrable: true,
      },
      {
        title: "BCC MFA ENABLED",
        field: "displayMfaStatus",
        show: isNotBaLoggedIn,
        filter: "text",
        filtrable: false,
      },
      {
        title: "LDAP ENABLED",
        field: "displayLdpaStatus",
        show: isNotBaLoggedIn,
        filter: "text",
        filtrable: false,
      },
      {
        title: "ACTIONS",
        field: "actions",
        show: false, //custom add
        filter: "text",
        filtrable: false,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(users, dataState as any),
  });

  useEffect(() => {
    if (selectedAccount?.type === MspType.BillingAggregator) {
      const ds = { ...gridState.dataState };
      ds.columns.forEach(column => {
        if (column.field === "accountAccess" || column.field === "displayLdpaStatus" || column.field === "displayMfaStatus") {
          column.show = false;
        }
      });
      setGridState({
        dataState: ds,
        dataResult: process(users, ds as any),
      });
    }
    // eslint-disable-next-line
  }, [selectedAccount]);

  useEffect(() => {
    const usersToDisplay = users.map(user => ({
      ...user,
      displayRole: user.roleDisplay,
      displayLdpaStatus: getLdapMfaStatuses(user).ldapStatus,
      displayMfaStatus: getLdapMfaStatuses(user).mfaStatus,
    }));
    dispatch(setTableProps({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
    const usersToDisplaySubset = process(usersToDisplay, gridState.dataState as any);
    usersToDisplaySubset.data.forEach((element: IUser) => {
      if (element.role === undefined && (element.errorWhileLoading === undefined || element.errorWhileLoading.length === 0)) {
        if (selectedAccount) {
          getExtraInfoForUsers(selectedAccount, usersToDisplaySubset.data).then(success => {
            if (success) {
              setGridState({
                dataState: { ...dataState },
                dataResult: usersToDisplaySubset,
              });
            }
          });
        }
      }
    });
    setGridState({ dataState: gridState.dataState, dataResult: usersToDisplaySubset });
    // eslint-disable-next-line
  }, [users, gridState.dataState]);

  const getExtraInfoForUsers = (selectedAcc: IAccount, displayUsers: IUser[]) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getAccountUsersExtraInfoAction(selectedAcc, displayUsers));
      resolve(result);
    });

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount != null) {
      if (currentlySelectedAccountId !== selectedAccount?.id) {
        setCurrentlySelectedAccountId(selectedAccount.id);
        if (selectedAccount.id !== 0) {
          const ds = { ...gridState.dataState, skip: 0 };
          setGridState({
            dataState: ds,
            dataResult: process(users, ds as any),
          });
        }
      }
    }
  }, [currentlySelectedAccountId, gridState.dataState, selectedAccount, users]);

  useEffect(() => {
    setshowActionColumn(canAddEditUser(mspAccountLoggedIn));
  }, [mspAccountLoggedIn]);

  const dataStateChange = (e: any): void => {
    if (selectedAccount) {
      const displayUsers = process(users, e.dataState);
      getExtraInfoForUsers(selectedAccount, displayUsers.data).then(success => {
        if (success) {
          setGridState({
            dataState: { ...gridState.dataState, ...e.dataState },
            dataResult: displayUsers,
          });
        }
      });
    }
  };

  const enterEdit = (user: IUser): void => {
    dispatch(setSelectedUserAction(user));
    openEditDialog(user);
  };

  const getUserIconId = (user: IUser): number => {
    if (selectedAccount) {
      return getUserIconType(mspAccountLoggedIn.id, mspAccountLoggedIn.type, selectedAccount.id, selectedAccount.type, selectedAccount.closestParentId, user.explicitAccountId);
    }
    return 0;
  };

  const Header = (props: any) => <HeaderCell {...props} />;
  const EditUserCell = (props: any) => <ButtonCommandCell {...props} dataTestId={"editUserButton"} action={enterEdit} isOutlined={false} color={"secondary"} label={"EDIT"} disabled={getEditUserIsDisabled(props.dataItem)} title={getEditUserTooltip(props.dataItem)} />;
  const UserCell = (props: any) => <IconUserCell userIconId={getUserIconId(props.dataItem)} />;
  const TooltipTable = (props: any, tooltipType: any) => <TooltipElement {...props} tooltipType={"table"} />;
  const TooltipEntitlement = (props: any) => <TooltipsForEntitnlements {...props} isBa={selectedAccount?.type === MspType.BillingAggregator} />;

  useEffect(() => {
    if (tableState.take && gridState.dataResult.total >= tableState.take) {
      if (tableState.skip === gridState.dataResult.total) {
        const ds = { ...gridState.dataState, skip: tableState.skip - tableState.take, take: tableState.take, filter: tableState.filter };
        setGridState({
          dataState: ds,
          dataResult: process(users, ds as any),
        });
      }
    }
    // eslint-disable-next-line
  }, [gridState.dataResult.total]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const renderTooltipForEntitlements = (field: string) => {
    if (field === "email") {
      return TooltipEntitlement;
    } else {
      return TooltipTable;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Backdrop className={"parentOpacity"} open={loadingUsersExtraInfo} style={{ position: "absolute", zIndex: 4000 }}>
        <CircularProgress data-testid="loadingUsersExtraInfo" size="80px" style={{ zIndex: 4001 }} />
      </Backdrop>
      <DataTable
        className={`userTable noScrollbar noBorders${isNotBaLoggedIn ? " resizeRoleColumnWidth" : ""}`}
        data={gridState.dataResult}
        resizable
        filter={gridState.dataState.filter}
        // page
        pageConfig={{
          pageable: {
            pageSizes: PAGE_SIZES,
            buttonCount: buttonCount,
          },
          skip: tableState.skip,
          take: tableState.take,
          total: gridState.dataResult.total,
        }}
        // sort
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
      >
        <Column key={"entitlement0"} title={" "} cell={UserCell} width={30} sortable={false} resizable={false} />
        {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"user" + idx} field={column.field} title={column.title} sortable={column.field === "email"} cell={renderTooltipForEntitlements(column.field)} minResizableWidth={30} resizable={true} columnMenu={column.field === "email" && filterTextInput} headerClassName={getColorForColumn(column.field, gridState.dataState as State)} headerCell={Header} />)}
        {showActionColumn ? <Column key={"entitlement" + gridState.dataState.columns.length} title={"ACTIONS"} cell={EditUserCell} width={120} sortable={false} resizable={false} headerCell={Header} /> : null}
      </DataTable>
    </div>
  );
};

export default UsersTable;
