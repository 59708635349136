import { LevelValidationRule } from "../../../../fieldsValidationRules";
import { IIntegrationBillingMap } from "../../../../models/Integrations/IntegrationsBillingMap";
import { IFieldValidator } from "../../../Accounts/AddEditAccount/addressValidator";

export const validateSetupBilling = (billingMapsList: IIntegrationBillingMap[]): IFieldValidator[] => {
  let errorFields: IFieldValidator[] = [];
  billingMapsList.forEach((bill: IIntegrationBillingMap, index: number) => {
    if (bill.canceled) {
      if (bill.name.length === 0) {
        errorFields.push({ name: "bundle", index: index, error: "Product canceled" });
      }
      if (bill.subname.length === 0) {
        errorFields.push({ name: "plan", index: index, error: "Unique ID/Plan canceled" });
      }
    } else {
      if (bill.type === undefined || bill.type?.length === 0) {
        errorFields.push({ name: "family", index: index, error: "Select a type" });
      }
      if (bill.name.length === 0) {
        errorFields.push({ name: "bundle", index: index, error: "Select a Product/Service" });
      }
      if (bill.subname.length === 0) {
        errorFields.push({ name: "plan", index: index, error: "Select a Unique ID" });
      }
      if (bill.level) {
        if (!LevelValidationRule.RegularExpression.test(bill.level)) {
          errorFields.push({ name: "level", index: index, error: "Level contains invalid characters." });
        }
      } else {
        errorFields.push({ name: "level", index: index, error: "Enter level" });
      }
    }
  });

  return errorFields;
};
