import { IPastPlanPurge } from "./IPastPlanPurge";

export enum IntegrationBllingMapType {
  ESSENTIALS_SERIVICES = "Email Protection",
  CONTENT_SHIELD_PLUS = "Content Shield Plus",
  IBU_PLANS = "Intronis Backup",
}

export interface IIntegrationBillingMapFamily {
  type: IntegrationBllingMapType;
  maps: IIntegrationBillingMap[];
}

export interface IIntegrationBillingMap {
  id: number;
  level?: string;
  name: string;
  subname: string;
  type?: IntegrationBllingMapType;
  canceled?: boolean;
}

export interface IIntegrationBillingMapOption {
  id: number;
  text: string;
}

export interface IIntegrationBillingMaps {
  billingMap: IIntegrationBillingMap[];
  usageType: number;
  pastPlanPurge: IPastPlanPurge[];
  essentialsMap: IIntegrationBillingMap[];
  contentShieldPlusMap: IIntegrationBillingMap[];
}

export function getIntegrationBillingTypeNameToDisplay(familyName: IntegrationBllingMapType): string {
  switch (familyName) {
    case IntegrationBllingMapType.ESSENTIALS_SERIVICES:
      return "Email Protection";
    case IntegrationBllingMapType.CONTENT_SHIELD_PLUS:
      return "Content Shield Plus";
    case IntegrationBllingMapType.IBU_PLANS:
      return "Intronis Backup";
    default:
      return "";
  }
}
