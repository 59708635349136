import React, { useEffect } from "react";
import "../../table.css";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../store/store";
import { setAccountsPageSizeAction, setAccountsPageNumberAction, goToAccountIdAction, goBackToViewMspAccountsAction, onSelectAccountItemAction } from "../../actions/accountActions";
import { setIntegrationsAction, setLoadedIntegrationAction, setSelectedIntegrationAction, setSelectedIntegrationsTabNameAction } from "../../actions/integrations/integrationsActions";
import DefaultIntegrations from "../../models/Integrations/DefaultIntegrations";
import { setShowUnlinkedAccountsAction } from "../../actions/integrations/integrationsAccountsActions";
import { Dispatch } from "redux";
import AccountsTableWithoutState from "./AccountsTableBase";
import { useNavigate } from "react-router-dom";
import { setIntegrationLogsTablePropsActionToFirstPage } from "../../actions/integrations/integrationsLogsActions";
import { setIntegrationBillingTablePropsActionToFirstPage } from "../../actions/integrations/integrationsBillingActions";
import useDocumentTitle, { PageTitles } from "../../Utilities/useDocumentTitle";
import { getInitialAccountTabs } from "../../Utilities/useAccountTabs";
import { PAGE_SIZES } from "../../models/TableConfig";
import { filtersAreActive } from "./FilterAccounts/UrlFilterParams";
import { useExpandedPartner } from "./FilterAccounts/useExpandedPartner";
import { getAccountIdFromUrl } from "../../Utilities/UrlParser";

interface IAccountsTableProps {
  showExpandIcon: boolean;
}

const AccountsTable: React.FC<IAccountsTableProps> = ({ showExpandIcon }) => {
  const dispatch = useDispatch();
  const pageSize = useSelector((state: IAppState) => state.accountState.accountsPageSize);
  const pageNumber = useSelector((state: IAppState) => state.accountState.accountsPageNumber);
  const items = useSelector((state: IAppState) => state.accountState.itemsToDisplay);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const expandedPartner = useExpandedPartner();
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const viewSearchResults = filtersAreActive();
  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const selectedTabName = useSelector((state: IAppState) => state.generalState.selectedTabName);
  const accountId = getAccountIdFromUrl();
  const location = window.location;
  const navigate = useNavigate();

  useDocumentTitle(selectedAccount, PageTitles.Home);

  useEffect(() => {
    if (accountId !== undefined) {
      dispatch(goToAccountIdAction(accountId, viewSearchResults));
    } else {
      if (location.pathname === "/") {
        dispatch(goBackToViewMspAccountsAction(selectedAccount, expandedPartner));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const onItemSelected = (e: any): void => {
    const item = e;
    let enableLoading = true;
    if (selectedAccount !== undefined && selectedAccount !== null) {
      if (item.id === selectedAccount.id) {
        enableLoading = false;
      }
    } else if (item.id === loadingAccountId) {
      enableLoading = false;
    }

    if (enableLoading) {
      dispatch(onSelectAccountItemAction(item));
      const response = getInitialAccountTabs(item, mspAccountLoggedIn, selectedTabName);
      if (item.id !== undefined) {
        const targetPath = `/accounts/${item.id}/${response?.initialTab}`;
        if (location.pathname !== targetPath) {
          console.debug(`navigate to account: ${targetPath}`);
          navigate(targetPath + location.search);
        }
      }
    }
  };

  const onPageNumberChange = (value: number): void => {
    dispatch(setAccountsPageNumberAction(value));
  };

  const onPageSizeChange = (value: number): void => {
    dispatch(setAccountsPageSizeAction(value));
  };

  return <AccountsTableWithoutState isTextOnlyTable={false} showExpandIcon={showExpandIcon} pageSize={pageSize} pageNumber={pageNumber} items={items} selectedAccount={selectedAccount} hasSubpartners={hasSubpartners} viewSearchResults={viewSearchResults} loadingAccountId={loadingAccountId} onItemSelected={onItemSelected} onPageSizeChange={onPageSizeChange} onPageNumberChange={onPageNumberChange} showCheckboxes={false} checkboxesDisabled={false} isBaLoggedIn={isBaLoggedIn} onItemChecked={() => {}} showInfoIcon={false} defaultPagination={PAGE_SIZES} />;
};

export default AccountsTable;

export function resetCw(dispatch: Dispatch<any>) {
  dispatch(setIntegrationsAction(DefaultIntegrations));
  dispatch(setSelectedIntegrationAction(undefined));
  dispatch(setLoadedIntegrationAction(undefined));
  dispatch(setIntegrationLogsTablePropsActionToFirstPage());
  dispatch(setSelectedIntegrationsTabNameAction(undefined));
  dispatch(setShowUnlinkedAccountsAction(false));
  dispatch(setIntegrationBillingTablePropsActionToFirstPage());
}
