import React from "react";
import { Grid, Paper, Button, Typography } from "@cuda-networks/bds-core";
import BarracudaMspLogo from "../assets/barracudaMSPLogo.jpg";
import { logout } from "../utility";
import { ApiError } from "../errors/ApiError";
import TruncatedText from "./TruncatedText";
import { getApiErrorMessage, isKnownErrorAfterLogin } from "../businessLogic/components/Users/AddEditUser/AddEditLoginUserDialog";
import { NoValidRoleError } from "../errors/NoValidRoleError";

interface IErrorAfterLoginProps {
  apiError: ApiError | NoValidRoleError;
}

const ErrorAfterLogin: React.FC<IErrorAfterLoginProps> = ({ apiError }) => {
  const message =
    apiError.errorCode && isKnownErrorAfterLogin(apiError) ? (
      <div>
        <TruncatedText value={apiError.errorMessage} bold={true} maxLength={20} />, the login you are currently using does not have permission to access the MSP Management portal.
        <br />
        <br />
        {getApiErrorMessage(apiError.errorCode)}
      </div>
    ) : (
      <div></div>
    );

  return (
    <div data-testid={"errorAfterLogin"} className="mspBackground">
      <Grid container xs={12} className="mspBackgroundContainer">
        <Paper elevation={3} style={{ width: "460px" }}>
          <img src={BarracudaMspLogo} alt="Icon Layers" style={{ width: "100%", padding: "26px 26px 0px 26px" }} className="center" />
          <div>
            <Typography data-testid={"welcomeMessageContent"} variant="body1" style={{ padding: "28px" }}>
              {message}
            </Typography>
            <Button data-testid={"welcomeMessageButton"} className={"center"} style={{ width: "100px", marginBottom: "10px" }} onClick={logout}>
              SIGN OUT
            </Button>
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

export default ErrorAfterLogin;
