import { State } from "@progress/kendo-data-query";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { deleteCreditCardFromState, updateCardsWithDefault, updateCardsWithRecurring } from "../businessLogic/finances";
import IAccount from "../models/IAccount";
import IAccountsExcludedUser from "../models/Invoices/IAccountsExclusionsUser";
import IAccountsWithExclusions from "../models/Invoices/IAccountsWithExclusions";
import IApiCreditCard from "../models/Invoices/IApiCreditCard";
import ICreditCardPayment from "../models/Invoices/ICreditCardPayment";
import IExistingCreditCard from "../models/Invoices/IExistingCreditCard";
import IInvoice from "../models/Invoices/IInvoice";
import { PaymentMethod } from "../models/Invoices/PaymentMethod";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { getDateForApiCall } from "../utility";
import { handleError } from "./actionsErrorHandler";
import { cancelGeneralActionTokenAndCreateNew } from "./cancelAction";
import { ActionTypes } from "./ActionTypes";
import { LocalStoragePreferences, localStorageService } from "../service/localStorageService";

export enum FinanceActionsTypes {
  GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD",
  SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
  GET_INVOICES = "GET_INVOICES",
  SET_INVOICES_TABLE_STATE = "SET_INVOICES_TABLE_STATE",
  SET_LOAD_INVOICES_CANCELED = "SET_LOAD_INVOICES_CANCELED",
  GET_ACCOUNTS_WITH_EXCLUSIONS = "GET_ACCOUNTS_WITH_EXCLUSIONS",
  SET_LOAD_ACCOUNTS_WITH_EXCLUSIONS_CANCELED = "SET_LOAD_ACCOUNTS_WITH_EXCLUSIONS_CANCELED",
  SET_ACCOUNTS_WITH_EXCLUSIONS_TABLE_PROPS = "SET_ACCOUNTS_WITH_EXCLUSIONS_TABLE_PROPS",
  GET_ACCOUNTS_EXCLUDED_USERS = "GET_ACCOUNTS_EXCLUDED_USERS",
  SET_LOAD_ACCOUNTS_EXCLUDED_USERS_CANCELED = "SET_LOAD_ACCOUNTS_EXCLUDED_USERS_CANCELED",
  SEND_EMAIL_INVOICE = "SEND_EMAIL_INVOICE",
  ADD_CREDIT_CARD = "ADD_CREDIT_CARD",
  GET_CREDIT_CARDS = "GET_CREDIT_CARDS",
  SET_LOAD_CREDIT_CARDS_CANCELED = "SET_LOAD_CREDIT_CARDS_CANCELED",
  SET_DEFAULT_CARD = "SET_DEFAULT_CARD",
  SET_RECURRING_CARD = "SET_RECURRING_CARD",
  DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD",
  SET_SELECTED_CREDIT_CARD = "SET_SELECTED_CREDIT_CARD",
  EDIT_CREDIT_CARD = "EDIT_CREDIT_CARD",
  PAY_INVOICE = "PAY_INVOICE",
  SET_LOADING_CREDIT_CARDS = "SET_LOADING_CREDIT_CARDS",
}

export interface IGetPaymentMethod {
  type: FinanceActionsTypes.GET_PAYMENT_METHOD;
  paymentMethod: number;
  loadingPaymentMethod: boolean;
}

export interface ISetPaymentMethod {
  type: FinanceActionsTypes.SET_PAYMENT_METHOD;
  paymentMethod: number;
  loadingPaymentMethod: boolean;
}

export interface IGetInvoicesAction {
  type: FinanceActionsTypes.GET_INVOICES;
  invoicesToDisplay: IInvoice[];
  currency: string;
  loadingInvoices: boolean;
}

export interface ISetInvoicesTableState {
  type: FinanceActionsTypes.SET_INVOICES_TABLE_STATE;
  invoicesTableState: State;
}

export interface ISetLoadingInvoicesCanceledAction {
  type: FinanceActionsTypes.SET_LOAD_INVOICES_CANCELED;
  loadingInvoicesCanceled: boolean;
}

export interface IGetAccountsWithExclusionsAction {
  type: FinanceActionsTypes.GET_ACCOUNTS_WITH_EXCLUSIONS;
  loadingAccountsWithExclusions: boolean;
  accountsWithExclusions: IAccountsWithExclusions[];
}

export interface ISetLoadingAccountsWithExclusionsCanceledAction {
  type: FinanceActionsTypes.SET_LOAD_ACCOUNTS_WITH_EXCLUSIONS_CANCELED;
  loadingAccountsWithExclusionsCanceled: boolean;
}

export interface ISetAccountsWithExclusionsTableProps {
  type: FinanceActionsTypes.SET_ACCOUNTS_WITH_EXCLUSIONS_TABLE_PROPS;
  accountsWithExclusionsTableState: State;
}

export interface IGetAccountsExcludedUsersAction {
  type: FinanceActionsTypes.GET_ACCOUNTS_EXCLUDED_USERS;
  loadingAccountsExcludedUsers: boolean;
  accountExcludedUsers: IAccountsExcludedUser[];
}

export interface ISetLoadingAccountExcludedUsersCanceledAction {
  type: FinanceActionsTypes.SET_LOAD_ACCOUNTS_EXCLUDED_USERS_CANCELED;
  loadingAccountsExcludedUsersCanceled: boolean;
}

export interface ISendEmailInvoiceAction {
  type: FinanceActionsTypes.SEND_EMAIL_INVOICE;
}
export interface IAddCreditCard {
  type: FinanceActionsTypes.ADD_CREDIT_CARD;
}

export interface IGetCreditCardsAction {
  type: FinanceActionsTypes.GET_CREDIT_CARDS;
  creditCardsToDisplay: IExistingCreditCard[];
  loadingCreditCards: boolean;
}

export interface ISetLoadingCreditCardsCanceledAction {
  type: FinanceActionsTypes.SET_LOAD_CREDIT_CARDS_CANCELED;
  loadingCreditCardsCanceled: boolean;
}

export interface ISetDefaultCardAction {
  type: FinanceActionsTypes.SET_DEFAULT_CARD;
  creditCardsToDisplay: IExistingCreditCard[];
}

export interface ISetRecurringCardAction {
  type: FinanceActionsTypes.SET_RECURRING_CARD;
  creditCardsToDisplay: IExistingCreditCard[];
}

export interface IDeleteCreditCardAction {
  type: FinanceActionsTypes.DELETE_CREDIT_CARD;
  creditCardsToDisplay: IExistingCreditCard[];
}

export interface ISetSelectCreditCardAction {
  type: FinanceActionsTypes.SET_SELECTED_CREDIT_CARD;
  selectedCreditCard: IExistingCreditCard;
}

export interface IEditCreditCardAction {
  type: FinanceActionsTypes.EDIT_CREDIT_CARD;
}
export interface IPayInvoice {
  type: FinanceActionsTypes.PAY_INVOICE;
}

export interface ISetLoadingCreditCards {
  type: FinanceActionsTypes.SET_LOADING_CREDIT_CARDS;
  loadingCreditCards: boolean;
}

export type FinanceActions = IGetPaymentMethod | ISetPaymentMethod | IGetInvoicesAction | ISetInvoicesTableState | ISetLoadingInvoicesCanceledAction | IGetAccountsWithExclusionsAction | ISetLoadingAccountsWithExclusionsCanceledAction | ISetAccountsWithExclusionsTableProps | IGetAccountsExcludedUsersAction | ISetLoadingAccountExcludedUsersCanceledAction | ISendEmailInvoiceAction | IGetCreditCardsAction | ISetLoadingCreditCardsCanceledAction | IAddCreditCard | ISetDefaultCardAction | ISetRecurringCardAction | IDeleteCreditCardAction | ISetSelectCreditCardAction | IEditCreditCardAction | IPayInvoice | ISetLoadingCreditCards;

export const getPaymentMethod: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetPaymentMethod>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: FinanceActionsTypes.GET_PAYMENT_METHOD,
        loadingPaymentMethod: true,
        paymentMethod: PaymentMethod.ByInvoice,
      });
      const result = await mspService.loadPaymentMethod(apiUrl, account.id);
      dispatch({
        type: FinanceActionsTypes.GET_PAYMENT_METHOD,
        loadingPaymentMethod: false,
        paymentMethod: result.paymentMethod,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.GET_PAYMENT_METHOD,
            loadingPaymentMethod: false,
            paymentMethod: PaymentMethod.ByInvoice,
          });
        },
        () => {},
      );
    }
  };
};

export const setPaymentMethod: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetPaymentMethod>> = (account: IAccount, newPaymentMethod: PaymentMethod) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { paymentMethod } = getState().financeState;
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: FinanceActionsTypes.SET_PAYMENT_METHOD,
        paymentMethod: paymentMethod,
        loadingPaymentMethod: true,
      });
      await mspService.editPaymentMethod(apiUrl, account.id, newPaymentMethod);
      dispatch({
        type: FinanceActionsTypes.SET_PAYMENT_METHOD,
        paymentMethod: newPaymentMethod,
        loadingPaymentMethod: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_PAYMENT_METHOD,
            paymentMethod: paymentMethod,
            loadingPaymentMethod: false,
          });
        },
        () => {},
        true,
        ActionTypes.SetPaymentMethod,
      );
    }
  };
};

export const getInvoices: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetInvoicesAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: FinanceActionsTypes.GET_INVOICES,
        invoicesToDisplay: [],
        currency: "",
        loadingInvoices: true,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const result = await mspService.loadInvoices(apiUrl, account.id, newCancelTokenSource.token);
      dispatch({
        type: FinanceActionsTypes.SET_LOAD_INVOICES_CANCELED,
        loadingInvoicesCanceled: false,
      });
      const finalInvoices = result.invoices.map((invoice: IInvoice) => ({
        ...invoice,
        date: new Date(invoice.date.toString()),
      }));

      dispatch({
        type: FinanceActionsTypes.GET_INVOICES,
        invoicesToDisplay: finalInvoices,
        currency: result.currencyCode,
        loadingInvoices: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.GET_INVOICES,
            invoicesToDisplay: [],
            currency: "",
            loadingInvoices: false,
          });
        },
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_LOAD_INVOICES_CANCELED,
            loadingInvoicesCanceled: true,
          });
        },
      );
    }
  };
};

export const setInvoicesTableStateOnFirstPage: ActionCreator<ThunkAction<any, IAppState, null, ISetInvoicesTableState>> = (invoicesTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    const state = getState().financeState.invoicesTableState;
    invoicesTableState.skip = 0;
    invoicesTableState.take = state.take;
    invoicesTableState.sort = state.sort;
    invoicesTableState.filter = state.filter;

    dispatch({ type: FinanceActionsTypes.SET_INVOICES_TABLE_STATE, invoicesTableState });
    localStorageService.setItem(id.toString(), LocalStoragePreferences.FINANCES_UI, JSON.stringify({ ...invoicesTableState, skip: 0 }));
  };
};

export const setInvoicesTableState: ActionCreator<ThunkAction<any, IAppState, null, ISetInvoicesTableState>> = (invoicesTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    dispatch({ type: FinanceActionsTypes.SET_INVOICES_TABLE_STATE, invoicesTableState });
    localStorageService.setItem(id.toString(), LocalStoragePreferences.FINANCES_UI, JSON.stringify({ ...invoicesTableState, skip: 0 }));
  };
};

export const getAccountsWithExclusions: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAccountsWithExclusionsAction>> = (accountId: number, invoice: IInvoice) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: FinanceActionsTypes.GET_ACCOUNTS_WITH_EXCLUSIONS,
        accountsWithExclusions: [],
        loadingAccountsWithExclusions: true,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const isoDate = getDateForApiCall(invoice.date);
      const accountsWithExclusions = await mspService.loadAccountsWithExclusions(apiUrl, accountId, isoDate, newCancelTokenSource.token);
      dispatch({
        type: FinanceActionsTypes.SET_LOAD_ACCOUNTS_WITH_EXCLUSIONS_CANCELED,
        loadingAccountsWithExclusionsCanceled: false,
      });
      dispatch({
        type: FinanceActionsTypes.GET_ACCOUNTS_WITH_EXCLUSIONS,
        accountsWithExclusions: accountsWithExclusions.accounts,
        loadingAccountsWithExclusions: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.GET_ACCOUNTS_WITH_EXCLUSIONS,
            accountsWithExclusions: [],
            loadingAccountsWithExclusions: false,
          });
        },
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_LOAD_ACCOUNTS_WITH_EXCLUSIONS_CANCELED,
            loadingAccountsWithExclusions: true,
          });
        },
      );
    }
  };
};

export const setAccountsWithExclusionsTableProps: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountsWithExclusionsTableProps>> = (accountsWithExclusionsTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.EXCLUSION_LIST_UI, JSON.stringify({ ...accountsWithExclusionsTableState, skip: 0 }));
    dispatch({ type: FinanceActionsTypes.SET_ACCOUNTS_WITH_EXCLUSIONS_TABLE_PROPS, accountsWithExclusionsTableState });
  };
};

export const getAccountExcludedUsers: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAccountsExcludedUsersAction>> = (accountId: number, invoice: IInvoice, skip: number, take: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: FinanceActionsTypes.GET_ACCOUNTS_EXCLUDED_USERS,
        accountExcludedUsers: [],
        loadingAccountsExcludedUsers: true,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const isoDate = getDateForApiCall(invoice.date);
      const response = await mspService.loadAccountExcludedUsers(apiUrl, accountId, isoDate, skip, take, newCancelTokenSource.token);
      dispatch({
        type: FinanceActionsTypes.SET_LOAD_ACCOUNTS_EXCLUDED_USERS_CANCELED,
        loadingAccountsExcludedUsersCanceled: false,
      });
      dispatch({
        type: FinanceActionsTypes.GET_ACCOUNTS_EXCLUDED_USERS,
        accountExcludedUsers: response.users,
        loadingAccountsExcludedUsers: false,
      });
      return response.users;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.GET_ACCOUNTS_EXCLUDED_USERS,
            accountExcludedUsers: [],
            loadingAccountsExcludedUsers: false,
          });
        },
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_LOAD_ACCOUNTS_EXCLUDED_USERS_CANCELED,
            loadingAccountsExcludedUsersCanceled: true,
          });
        },
      );
      return undefined;
    }
  };
};

export const sendEmailInvoiceAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISendEmailInvoiceAction>> = (accountId: number, invoiceNumber: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.sendEmailInvoice(apiUrl, accountId, invoiceNumber);
      dispatch({
        type: FinanceActionsTypes.SEND_EMAIL_INVOICE,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* do nothing */
        },
        () => {
          /* do nothing */
        },
        true,
        ActionTypes.SendEmailInvoice,
      );
      return false;
    }
  };
};

export const addCreditCard: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddCreditCard>> = (accountId: number, creditCard: IApiCreditCard) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      await mspService.addCreditCard(apiUrl, accountId, creditCard);
      dispatch({
        type: FinanceActionsTypes.ADD_CREDIT_CARD,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          //nothing
        },
        () => {
          //nothing
        },
        true,
        ActionTypes.AddCreditCard,
      );
      return false;
    }
  };
};

export const getCreditCards: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetCreditCardsAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: FinanceActionsTypes.GET_CREDIT_CARDS,
        creditCardsToDisplay: [],
        loadingCreditCards: true,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const result = await mspService.loadCreditCards(apiUrl, account.id, newCancelTokenSource.token);
      const sortedCards = result.creditCards.sort((a: IExistingCreditCard, b: IExistingCreditCard) => +b.default - +a.default);
      dispatch({
        type: FinanceActionsTypes.GET_CREDIT_CARDS,
        creditCardsToDisplay: sortedCards,
        loadingCreditCards: false,
      });
      dispatch({
        type: FinanceActionsTypes.SET_LOAD_CREDIT_CARDS_CANCELED,
        loadingCreditCardsCanceled: false,
      });
      return sortedCards;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.GET_CREDIT_CARDS,
            creditCardsToDisplay: [],
            loadingCreditCards: false,
          });
        },
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_LOAD_CREDIT_CARDS_CANCELED,
            loadingCreditCardsCanceled: true,
          });
        },
      );
    }
  };
};

export const setDefaultCard: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetDefaultCardAction>> = (account: IAccount, creditCardToken: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const { creditCardsToDisplay } = getState().financeState;
      await mspService.setDefaultCard(apiUrl, account.id, creditCardToken);
      const nexStateCards = updateCardsWithDefault(creditCardsToDisplay, creditCardToken);

      dispatch({
        type: FinanceActionsTypes.SET_DEFAULT_CARD,
        creditCardsToDisplay: nexStateCards,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_DEFAULT_CARD,
            creditCardsToDisplay: getState().financeState.creditCardsToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.SetPaymentMethod,
      );
      return false;
    }
  };
};

export const setRecurringCard: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetRecurringCardAction>> = (account: IAccount, creditCardToken: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const { creditCardsToDisplay } = getState().financeState;
      await mspService.setRecurringCard(apiUrl, account.id, creditCardToken);
      const nexStateCards = updateCardsWithRecurring(creditCardsToDisplay, creditCardToken);

      dispatch({
        type: FinanceActionsTypes.SET_RECURRING_CARD,
        creditCardsToDisplay: nexStateCards,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: FinanceActionsTypes.SET_RECURRING_CARD,
            creditCardsToDisplay: getState().financeState.creditCardsToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.SetPaymentMethod,
      );
      return false;
    }
  };
};

export const deleteCreditCard: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeleteCreditCardAction>> = (account: IAccount, creditCard: IExistingCreditCard) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { creditCardsToDisplay } = getState().financeState;
    const { apiUrl } = getState().generalState;
    try {
      await mspService.deleteCreditCard(apiUrl, account.id, creditCard.token);
      const nexStateCards = deleteCreditCardFromState(creditCardsToDisplay, creditCard);
      dispatch({
        type: FinanceActionsTypes.DELETE_CREDIT_CARD,
        creditCardsToDisplay: nexStateCards,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        async () => {
          dispatch({
            type: FinanceActionsTypes.SET_LOADING_CREDIT_CARDS,
            loadingCreditCards: true,
          });
          const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
          const result = await mspService.loadCreditCards(apiUrl, account.id, newCancelTokenSource.token);
          const sortedCards = result.creditCards.sort((a: IExistingCreditCard, b: IExistingCreditCard) => +b.default - +a.default);
          dispatch({
            type: FinanceActionsTypes.SET_LOADING_CREDIT_CARDS,
            loadingCreditCards: false,
          });
          dispatch({
            type: FinanceActionsTypes.DELETE_CREDIT_CARD,
            creditCardsToDisplay: sortedCards,
          });
        },
        () => {
          //nothing
        },
        true,
        ActionTypes.DeleteCreditCard,
      );
      return false;
    }
  };
};

export const setSelectedCreditCardAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectCreditCardAction>> = (selectedCreditCard: IExistingCreditCard) => (dispatch: Dispatch) => dispatch({ type: FinanceActionsTypes.SET_SELECTED_CREDIT_CARD, selectedCreditCard });

export const setLoadingCreditCards: ActionCreator<ThunkAction<any, IAppState, null, ISetLoadingCreditCards>> = (loadingCreditCards: boolean) => (dispatch: Dispatch) => dispatch({ type: FinanceActionsTypes.SET_LOADING_CREDIT_CARDS, loadingCreditCards });

export const editCreditCard: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IEditCreditCardAction>> = (account: IAccount, creditCard: IExistingCreditCard, apiCreditCard: IApiCreditCard) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;

      await mspService.deleteCreditCard(apiUrl, account.id, creditCard.token);
      await mspService.addCreditCard(apiUrl, account.id, apiCreditCard);

      dispatch({
        type: FinanceActionsTypes.EDIT_CREDIT_CARD,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          //nothing
        },
        () => {
          //nothing
        },
        true,
        ActionTypes.EditCreditCard,
      );
      return false;
    }
  };
};

export const payInvoiceAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IPayInvoice>> = (accountId: number, invoiceNumber: string, creditCardPayment: ICreditCardPayment) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      await mspService.payInvoice(apiUrl, accountId, invoiceNumber, creditCardPayment);
      dispatch({
        type: FinanceActionsTypes.PAY_INVOICE,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          //nothing
        },
        () => {
          //nothing
        },
        true,
        ActionTypes.PayInvoice,
      );
      return false;
    }
  };
};
