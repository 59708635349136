import React from "react";
import * as FeedbackIcons from "@cuda-networks/bds-core/dist/Icons/Feedback";
import TooltipElement from "../Users/TooltipElement";

const IconUnassignedCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  return props.dataItem.unassigned === true ? (
    <td id="unassignedRow">
      <TooltipElement placement="bottom-start" title="Cannot be activated to additional accounts">
        <FeedbackIcons.Info data-testid="unassignedIcon" />
      </TooltipElement>
    </td>
  ) : (
    <td></td>
  );
};

export default IconUnassignedCell;
