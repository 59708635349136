import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@cuda-networks/bds-core";
import { enterKey, handleBackdropClick } from "../utility";

interface ICustomMessageDialog {
  message: React.JSX.Element | undefined;
  showConfirm?: boolean;
  onConfirm?: () => void;
  onCloseMessage: () => void;
  title?: string;
}
const CustomMessageDialog: React.FC<ICustomMessageDialog> = ({ message, onCloseMessage, showConfirm, onConfirm, title }) => {
  const [open, setOpen] = useState(message ? true : false);
  const onClose = (): any => {
    setOpen(false);
    onCloseMessage();
  };

  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onClose)} data-testid="customMessageDialog" open={open} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onClose)}>
      <DialogTitle data-testid="customMessageTitle">{title}</DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        <div data-testid="customMessage">{message}</div>
      </DialogContent>
      <DialogActions>
        {showConfirm ? (
          <div>
            <Button data-testid="closeCustomMessage" variant={"text"} size="large" onClick={onClose} className={"dialogButtonSpacing"}>
              CANCEL
            </Button>
            <Button data-testid="confirmCustomMessage" primary="true" type={"submit"} onClick={onConfirm} className={"dialogButtonSpacing"}>
              CONFIRM
            </Button>
          </div>
        ) : (
          <Button data-testid="closeCustomMessage" primary="true" size="large" onClick={onClose} style={{ marginRight: 16 }}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomMessageDialog;
