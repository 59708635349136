enum UserPrivileges {
  UserManagement = "User Management",
  BillingAdministration = "Billing Administration",
  None = "None",
}
export default UserPrivileges;

export enum UserPrivilegeAccount {
  View = "View",
  EditPartnerContactInfo = "Edit Partner Contact Info.",
  CreateAccounts = "Create Accounts",
  EditAccounts = "Edit Accounts",
  DeleteAccounts = "Delete Accounts",
  CreateSubpartners = "Create Subpartners",
  EditSubpartners = "Edit Subpartners",
  DeleteSubpartners = "Delete Subpartners",
}

export enum UserPrivilegeMSP {
  View = "View",
  EditBABillingInfo = "Edit BA Billing Info.",
  EditMSPContactInfo = "Edit MSP Contact Info.",
}

export enum UserPrivilegeRolesAccess {
  View = "View",
  CreateLogins = "Create Logins",
  EditLogins = "Edit Logins",
  DeleteLogins = "Delete Logins",
}

export enum UserPrivilegeFinance {
  View = "View",
  ManageCreditCards = "Manage Credit Cards",
  PayInvoices = "Pay Invoices",
}

export enum UserPrivilegeProductServices {
  View = "View",
  AssignProductsToSubpartner = "Assign Products To Subpartner",
  AccessSubpartnerCatalogs = "Access Subpartners' Catalog",
  ActivateProductsToAccount = "Activate Products To Account",
  DeactivateProducts = "Deactivate Products",
  AccessUserBillingList = "Access User Billing List",
  ExportUsageData = "Export Usage Data",
  ManageIntegrations = "Manage Integrations",
}

export enum UserPrivilegeApiCredentials {
  View = "View",
  Generate = "Generate",
  Renew = "Renew",
  Delete = "Delete",
}

export enum UserPrivilegeBAProductServices {
  View = "View",
  ExportUsageData = "Export Usage Data",
  FilterProductsOfMSPBySubpartner = "Filter products of MSP by Subpartner",
  FilterProductsOfMSPByAccount = "Filter products of MSP by Account",
}

export enum UserSubpartnerPrivilegeAccount {
  View = "View",
  EditSubpartnerContactInfo = "Edit Subpartner Contact Info.",
  CreateAccounts = "Create Accounts",
  EditAccounts = "Edit Accounts",
  DeleteAccounts = "Delete Accounts",
}

export enum UserSubpartnerPrivilegeProductServices {
  View = "View",
  ActivateProductsToAccount = "Activate Products To Account",
  DeactivateProducts = "Deactivate Products",
  AccessUserBillingList = "Access User Billing List",
  ExportUsageData = "Export Usage Data",
  ManageIntegrations = "Manage Integrations",
}
