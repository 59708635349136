import { Typography } from "@cuda-networks/bds-core";
import React from "react";
import { useSelector } from "react-redux";
import useDocumentTitle, { PageTitles } from "../../../Utilities/useDocumentTitle";
import { IAppState } from "../../../store/store";

const IntegrationsTicketsTab: React.FC = () => {
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  useDocumentTitle(selectedAccount, PageTitles.ConnectWiseTickets);

  return (
    <div style={{ height: 550 }}>
      <Typography data-testid="ticketingFeatureTxt" variant="body1" style={{ position: "relative", top: "235px", color: "darkgray" }}>
        The Ticketing feature is available only on the ECHOplatform and currently features only Intronis Backup ticketing. Please visit the ECHOplatform to access this feature.
      </Typography>
    </div>
  );
};
export default IntegrationsTicketsTab;
