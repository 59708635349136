import React, { useState, useEffect } from "react";
import { DataTable } from "@cuda-networks/bds-core";
import { process } from "@progress/kendo-data-query";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import "../../../table.css";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { getButtonCount } from "../../../utility";
import { useMediaQuery } from "@material-ui/core";
import { setTablePropsForDeleteAccountSerial } from "../../../actions/productActions";
import DeleteAccountProductNameCell from "./DeleteAccountProductNameCell";

export interface IDeleteAccountSerial {
  bccProductId: number;
  name: string;
  productName: string;
  model: string;
  serialNumber: string;
  status: string;
  productFamily: string;
  orderLineItemId?: string;
}
interface IDeleteAccountSerialsTableProps {
  serials: IDeleteAccountSerial[];
}

const ProductName = (props: any) => <DeleteAccountProductNameCell name={props.dataItem.name} productName={props.dataItem.productName} />;

const DeleteAccountSerialsTable: React.FC<IDeleteAccountSerialsTableProps> = ({ serials }) => {
  const dispatch = useDispatch();
  const tableState = useSelector((state: IAppState) => state.productState.deleteAccountSerialTableState);

  const [buttonCount, setButtonCount] = useState(15);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState?.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "Activated Products/Services",
        field: "productName",
        show: false,
        filter: "text",
      },
      {
        title: "SERIAL NUMBER",
        field: "serialNumber",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(serials, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    if (serials && serials.length > 0) {
      setGridState({
        dataState: { ...dataState, ...e.dataState },
        dataResult: process(serials, e.dataState),
      });
    }
  };

  useEffect(() => {
    if (serials) {
      dispatch(setTablePropsForDeleteAccountSerial({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip }));
      setGridState({ dataState: gridState.dataState, dataResult: process(serials, gridState.dataState as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataState, serials]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  return (
    <DataTable
      className={"userTable noScrollbar noBorders"}
      data={gridState.dataResult}
      resizable
      // page
      pageConfig={{
        pageable: {
          pageSizes: [5, 10, 15],
          buttonCount: buttonCount,
        },
        skip: gridState.dataState.skip,
        take: gridState.dataState.take,
        total: gridState.dataResult.total,
      }}
      pager={gridState.dataResult.data.length > 0 && Pager}
      // sort
      sortConfig={{
        sortable: true,
        sort: gridState.dataState.sort as any,
      }}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      {...(gridState.dataState as any)}
    >
      <Column key={"deleteAccountProduct"} field={"productName"} title={"ACTIVATED PRODUCTS/SERVICES"} cell={ProductName} minResizableWidth={30} sortable />
      {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"deleteAccount" + column.field} field={column.field} title={column.title} width={180} sortable />)}
    </DataTable>
  );
};

export default DeleteAccountSerialsTable;
