import React, { useState, useRef, useEffect } from "react";
import { TextField, Grid, Button } from "@cuda-networks/bds-core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { isStringNullOrEmpty } from "../../../utility";

interface ITextFieldWithConfirmationProps {
  initialValue: string | undefined;
  disabled: boolean;
  placeHolder: string;
  onConfirm(value: string): void;
  onCancel(): void;
  dataTestId?: string;
  errorMessage?: string;
}

const TextFieldWithConfirmation: React.FC<ITextFieldWithConfirmationProps> = ({ initialValue, disabled, placeHolder, onConfirm, onCancel, dataTestId, errorMessage }) => {
  const textFieldRef = useRef<HTMLDivElement | null>(null);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialValue ?? "");
  const [applyEnabled, setApplyEnabled] = useState(!isStringNullOrEmpty(initialValue) && initialValue !== value);
  const [removeEnabled, setRemoveEnabled] = useState(!isStringNullOrEmpty(initialValue));
  const [visiblePlaceholder, setVisiblePlaceholder] = useState(placeHolder);

  const handleConfirm = (e: any) => {
    e.stopPropagation();
    const newValue = value.trim();
    onConfirm(newValue);
    setApplyEnabled(false);
    setRemoveEnabled(newValue.length > 0);
  };

  const handleCancel = () => {
    setValue("");
    onCancel();
    setApplyEnabled(false);
    setRemoveEnabled(false);
    setVisiblePlaceholder(placeHolder);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trimStart();
    setValue(newValue);
    setApplyEnabled(!isStringNullOrEmpty(newValue));
    if (newValue.length === 0) {
      const queryParams = new URLSearchParams(window.location.search);
      const accountName = queryParams.get("accountName");
      if (!isStringNullOrEmpty(accountName)) {
        setVisiblePlaceholder('Active MSP name filter: "' + accountName + '"');
      } else {
        setVisiblePlaceholder(placeHolder);
      }
    }
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      const queryParams = new URLSearchParams(window.location.search);
      const accountName = queryParams.get("accountName");

      setValue(accountName ?? "");
      if (isStringNullOrEmpty(accountName)) {
        setRemoveEnabled(false);
        setVisiblePlaceholder(placeHolder);
      } else {
        setRemoveEnabled(true);
        setVisiblePlaceholder('Active MSP name filter: "' + initialValue + '"');
      }
      setApplyEnabled(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onConfirm(value.trim());
        setApplyEnabled(false);
        setRemoveEnabled(value.length > 0);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirm, value]);

  return (
    <Grid item container>
      <Grid item xs={8}>
        <TextField ref={textFieldRef} inputRef={inputFieldRef} data-testid={dataTestId} fullWidth style={{ backgroundColor: "white", zIndex: 300 }} placeholder={visiblePlaceholder} size={"large"} disabled={disabled} value={value} onChange={handleInputChange} error={errorMessage && errorMessage?.length > 0} helperText={errorMessage} />
      </Grid>
      <Grid item xs={2} style={{ alignSelf: "center" }}>
        <Button size="small" data-testid={dataTestId + "ApplyValue"} variant="text" startIcon={<CoreIcons.Filter />} primary="true" onClick={handleConfirm} disabled={!applyEnabled}>
          Apply
        </Button>
      </Grid>
      <Grid item xs={2} style={{ alignSelf: "center" }}>
        <Button size="small" data-testid={dataTestId + "RemoveValue"} variant="text" startIcon={<CoreIcons.Close />} primary="true" onClick={handleCancel} disabled={!removeEnabled}>
          <div style={{ display: "block", textAlign: "left" }}>Remove</div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default TextFieldWithConfirmation;
