import React from "react";
import { Link } from "@cuda-networks/bds-core";

interface IActionLinkProps {
  onActionLinkClick(): void;
}

const ActionLink: React.FC<IActionLinkProps> = ({ onActionLinkClick }) => {
  return (
    <Link key="view-details" data-testid="snackbarViewDetails" style={{ color: "white", cursor: "pointer" }} onClick={onActionLinkClick}>
      View Details
    </Link>
  );
};

export default ActionLink;
