import { Grid, Checkbox } from "@cuda-networks/bds-core";
import React from "react";

interface IConfirmationCheckboxProps {
  message: React.JSX.Element;
  confirmed: boolean;
  onConfirmChanged: (value: boolean) => void;
}

const ConfirmationCheckbox: React.FC<IConfirmationCheckboxProps> = ({ message, confirmed, onConfirmChanged }) => {
  const onHandleCheckConfirm = (checked: boolean) => {
    onConfirmChanged(checked);
  };

  return (
    <Grid container>
      <Grid item className={"DeleteSerialCheckboxGrid"} xs={1}>
        <Checkbox data-testid="confirmCheckbox" size="medium" checked={confirmed} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onHandleCheckConfirm(ev.currentTarget.checked)} />
      </Grid>
      <Grid item xs={11}>
        {message}
      </Grid>
    </Grid>
  );
};

export default ConfirmationCheckbox;
