import React from "react";
import { TextField } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { getAccountTypeFromValue } from "../../../Utilities/accountsHelper";

interface IAddEditAccountDisplayNameProps {
  accountName: string;
  accountNameError: string;
  title: string;
  isActionInProgress: boolean;
  onAccountNameChanged: (value: string) => void;
}

const AddEditAccountDisplayName: React.FC<IAddEditAccountDisplayNameProps> = ({ accountName, accountNameError, title, isActionInProgress, onAccountNameChanged }) => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} container direction="column" style={{ padding: "10px" }}>
        <TextField
          size={"small"}
          data-testid="accountName"
          label={
            <>
              {getAccountTypeFromValue(title) + " DISPLAY NAME"}
              <span className="AccountLengthInfo" data-testid="accountNameLengthInfo" style={{ fontWeight: "normal" }}>
                {" "}
                (64 Maximum Characters)
              </span>
            </>
          }
          value={accountName}
          error={accountNameError.length > 0}
          disabled={isActionInProgress}
          helperText={accountNameError}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAccountNameChanged(ev.target.value)}
          inputProps={{
            maxLength: 64,
          }}
          className="AccountDisplayNameTextField"
        />
      </Grid>
    </Grid>
  );
};

export default AddEditAccountDisplayName;
