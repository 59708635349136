import React from "react";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { UserIconType } from "./User";
import TooltipElement from "./TooltipElement";

interface IconUserCellProps {
  userIconId: number;
}

const IconUserCell: React.FC<IconUserCellProps> = ({ userIconId }) => {
  const renderUserIcon = () => {
    switch (userIconId) {
      case UserIconType.PartnerUser:
        return (
          <TooltipElement title="Partner User">
            <div data-testid="partnerUser">
              <CoreIcons.Account className="center" />
            </div>
          </TooltipElement>
        );
      case UserIconType.SubpartnerUser:
        return (
          <TooltipElement title="Subpartner User">
            <div data-testid="subpartnerUser">
              <CoreIcons.SupervisedUserCircle className="center" />
            </div>
          </TooltipElement>
        );
      case UserIconType.SmbUser:
        return (
          <TooltipElement title="Account User">
            <div data-testid="customerUser">
              <CoreIcons.Accessibility className="center" />
            </div>
          </TooltipElement>
        );
      case UserIconType.BaUser:
        return (
          <TooltipElement title="Billing Aggregator User">
            <div data-testid="billingAggregatorUser">
              <CoreIcons.SupervisedUserCircle className="center" />
            </div>
          </TooltipElement>
        );
      default:
        return null;
    }
  };

  return <td style={{ padding: "0", alignContent: "center" }}>{renderUserIcon()}</td>;
};

export default IconUserCell;
