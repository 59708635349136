import React, { useState } from "react";
import { getSerialStatusIcon } from "../SerialStatusIcon";
import SerialStatus from "../../../models/Products/SerialStatus";
import { GridColumnMenuItem, GridColumnMenuItemContent, GridColumnMenuItemGroup, GridColumnMenuProps } from "@progress/kendo-react-grid";
import { getSerialStatusLabel } from "../../../utility";

interface IBBSFilterStatusDropdown extends GridColumnMenuProps {
  statuses: any;
  onReset: () => void;
  onColumnsSubmit: (event: any, updatedStatus: any) => void;
}
const BBSFilterStatusDropdown = (props: IBBSFilterStatusDropdown) => {
  let options = [getSerialStatusIcon(SerialStatus.ACTIVE), getSerialStatusIcon(SerialStatus.ACTIVATION_FAILED), getSerialStatusIcon(SerialStatus.SSG_PENDING), getSerialStatusIcon(SerialStatus.AVAILABLE), getSerialStatusIcon(SerialStatus.PENDING), getSerialStatusIcon(SerialStatus.PROVISION_FAILED)];
  const [statuses, setStatuses] = useState(props.statuses);
  const [updatedStatuses, setUpdatedStatuses] = useState([] as any);
  const onToggleColumn = (id: number) => {
    const newStatuses = statuses.map((status: any, idx: any) => {
      let updatedStatus = [];
      if (idx === id) {
        updatedStatus.push({ ...status, show: !status.show });
        setUpdatedStatuses([...updatedStatuses, ...updatedStatus]);
        return { ...status, show: !status.show };
      } else {
        return status;
      }
    });
    setStatuses(newStatuses);
  };

  const onReset = (event: any) => {
    event.preventDefault();
    const newStatuses = props.statuses.map((status: any) => {
      return {
        ...status,
        show: false,
      };
    });
    setStatuses(newStatuses);
    props.onColumnsSubmit(newStatuses, []);
    props.onReset();
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    props.onColumnsSubmit(statuses, updatedStatuses);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  return (
    <div className="filterStatusDropdown k-filter-menu-container" data-testid="filterStatusDropdown">
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem title={"FILTER"} iconClass={"k-i-filter"} onClick={() => null} />
        <GridColumnMenuItemContent show={true} />
      </GridColumnMenuItemGroup>
      <div className={"k-column-list-wrapper"}>
        <form onSubmit={onSubmit} onReset={onReset}>
          <div className={"k-column-list"} style={{ maxHeight: "400px" }}>
            {statuses.map((status: any, idx: any) => (
              <div key={idx} className={"k-column-list-item"} style={{ display: "inline-flex" }}>
                <span style={{ justifySelf: "flex-start" }}>
                  <input
                    id={`column-visiblity-show-${idx}`}
                    className="k-checkbox"
                    type="checkbox"
                    checked={status.show}
                    onClick={() => {
                      onToggleColumn(idx);
                    }}
                  />
                  <label className="k-checkbox-label">{status.bbsOrderStatus === SerialStatus.SSG_PENDING ? getSerialStatusLabel(SerialStatus.SSG_PENDING) : status.bbsOrderStatus}</label>
                </span>
                <span style={{ justifySelf: "flex-end" }}>{options[idx]}</span>
              </div>
            ))}
          </div>
          <div className={"k-actions k-hstack k-justify-content-stretch"}>
            <button type={"reset"} className={"k-button"}>
              CLEAR
            </button>
            <button className={"k-button k-primary"}>FILTER</button>
          </div>
        </form>
      </div>
      {/*   <List>
        {options.map(value => (
          <ListItem style={{ paddingBottom: "0px", paddingTop: "0px", height: "28px" }}>{value}</ListItem>
        ))}
      </List> */}
    </div>
  );
};

export default BBSFilterStatusDropdown;
