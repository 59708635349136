import React from "react";
import SerialStatusIcon from "../SerialStatusIcon";

const SerialStatusCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return (
    <td>
      <SerialStatusIcon status={props.status} isBBS={props.isBBS}></SerialStatusIcon>
    </td>
  );
};

export default SerialStatusCell;
