import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../table.css";
import { DataTable, DataTableColumn as Column, CircularProgress } from "@cuda-networks/bds-core";
import { process } from "@progress/kendo-data-query";
import { IAppState } from "../../store/store";
import IntegrationsCell from "./IntegrationsCell";
import { loadIntegrationsAction, setLoadedIntegrationAction, setSelectedIntegrationAction } from "../../actions/integrations/integrationsActions";
import Backdrop from "@material-ui/core/Backdrop";
import IntegrationType from "../../models/IntegrationType";
import { useParams } from "react-router-dom";

const IntegrationsTable = () => {
  const dispatch = useDispatch();
  const integrationsLoaded = useSelector((state: IAppState) => state.integrationsState.integrationsLoaded);
  const items = useSelector((state: IAppState) => state.integrationsState.integrations);
  const selectedItem = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const loadingIntegrations = useSelector((state: IAppState) => state.integrationsState.loadingIntegrations);
  const { integrationId } = useParams();

  useEffect(() => {
    if (integrationsLoaded === false) return;
    if (integrationId !== undefined && integrationId.toLocaleLowerCase().localeCompare("connectwise") === 0) {
      const item = items.filter(x => x.type === IntegrationType.ConnectWise);
      dispatch(setSelectedIntegrationAction(item[0]));
      dispatch(setLoadedIntegrationAction(item[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, dispatch, integrationsLoaded]);

  const dataState = {
    skip: 0,
    take: 10,
    sort: [],
    group: [],
    collapsedGroups: [],
    selected: -1,
    lastSelectedIndex: 0,
    columns: [
      {
        title: "name",
        field: "s",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(items, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(items, e.dataState),
    });
  };

  const rowClick = (item: any): void => {
    setGridState({
      dataState: { ...dataState, selected: item.id },
      dataResult: process(items, dataState as any),
    });
  };

  const onClick = (e: any): void => {
    const item = e.dataItem;
    if (selectedItem === undefined || item.id !== selectedItem.id) {
      if (item.type === IntegrationType.ConnectWise) {
        dispatch(setSelectedIntegrationAction(item));
        dispatch(setLoadedIntegrationAction(item));
      }
    }
  };

  useEffect(() => {
    let newDs;
    if (selectedItem && selectedItem.id) {
      newDs = { ...gridState.dataState, selected: selectedItem.id };
    } else {
      newDs = { ...gridState.dataState, selected: 0 };
    }
    setGridState({ dataState: newDs, dataResult: process(items, newDs as any) });
    // eslint-disable-next-line
  }, [items, selectedItem]);

  useEffect(() => {
    if (integrationsLoaded !== undefined && integrationsLoaded === false) {
      dispatch(loadIntegrationsAction());
    }
    // eslint-disable-next-line
  }, [integrationsLoaded]);

  const IntegrationsCellCommand = (props: any) => <IntegrationsCell {...props} onClick={onClick} displayAccountIcon={true} displayExpandIcon={true} loading={loadingIntegrations} />;

  return (
    <div style={{ position: "relative" }}>
      <Backdrop className={"parentOpacity"} open={loadingIntegrations} style={{ position: "absolute", zIndex: 4000 }}>
        <CircularProgress data-testid="loadingIntegrations" size="40px" style={{ zIndex: 4001 }} />
      </Backdrop>
      <DataTable className={"noHeader noScrollbar noBorders"} data={gridState.dataResult.data.map(item => ({ ...item, selected: item.id === gridState.dataState.selected, isLoading: true }))} filterable reorderable resizable onDataStateChange={dataStateChange} selectedField="selected" onRowClick={rowClick} {...(gridState.dataState as any)}>
        {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"item" + idx} field={column.field} title={column.title} cell={IntegrationsCellCommand} />)}
      </DataTable>
    </div>
  );
};

export default IntegrationsTable;
