import React, { useMemo } from "react";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { computeChipsNamesFromFilters, removeFilterFromState } from "../../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import { filterAccountsAction } from "../../../actions/accountActions";
import FilterAccountsOptions from "../../../models/FilterAccountsOptions";
import { Chip } from "@cuda-networks/bds-core";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlFilterParams } from "./UrlFilterParams";
import { UrlFilterExtractor } from "./UrlFilterExtractor";

interface IAccountFilterChipsProps {
  disabled: boolean;
}

const AccountFilterChips: React.FC<IAccountFilterChipsProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const filters = new UrlFilterExtractor(window.location.search, accountsOrders).extractFilters();
  const location = useLocation();
  const navigate = useNavigate();

  const parser = useMemo(() => new UrlFilterExtractor(location.search, accountsOrders), [location.search, accountsOrders]);
  const extractedFilters = useMemo(() => parser.extractFilters(), [parser]);
  const filterNames = useMemo(() => computeChipsNamesFromFilters(extractedFilters), [extractedFilters]);

  const onChipDelete = (name: string) => {
    if (!disabled) {
      dispatch(filterAccountsAction(removeFilterFromState(filters, name)));

      const url = new UrlFilterParams(location.search, accountsOrders ?? []);
      const params = url.delete(name);

      let targetPath = "";

      const checkParams = new URLSearchParams(params);

      if (Array.from(checkParams.entries()).length === 0) {
        targetPath = "/";
      } else if (Array.from(checkParams.entries()).length === 1 && checkParams.toString().includes("accountsOf")) {
        const urlParams = new URLSearchParams(location.search);
        const accountsOfValue = urlParams.get("accountsOf");

        if (accountsOfValue) {
          targetPath = `/accounts/${accountsOfValue}/products-services`;
        }
      } else {
        targetPath = location.pathname + "?" + params;
      }

      if (location.pathname !== targetPath) {
        console.debug(` navigate to updated path : ${targetPath}`);
        navigate(targetPath);
      }
    }
  };

  return (
    <div style={{ padding: "10px" }} data-testid="chipsSection" aria-disabled={disabled}>
      {filterNames.map((value, index) => (
        <Chip style={disabled ? { cursor: "not-allowed" } : {}} data-testid={"chipElement" + index} key={"FilteringChip" + value} label={value === FilterAccountsOptions.LoginUserAssociated ? "Associated with: " + filters?.associatedLogin : value} onDelete={() => onChipDelete(value)} disabled={disabled} skipFocusWhenDisabled={true} />
      ))}
    </div>
  );
};

export default React.memo(AccountFilterChips);
