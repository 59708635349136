import { Typography } from "@cuda-networks/bds-core";
import React from "react";
import { getAccountDisplayType } from "../../../Utilities/accountsHelper";

interface IDeleteAccountConfirmationMessageProps {
  type: string;
}

const DeleteAccountConfirmationMessage: React.FC<IDeleteAccountConfirmationMessageProps> = ({ type }) => {
  return (
    <div className="AccountIcons" data-testid="deleteAccountConfirmationMessage">
      <Typography variant="subtitle2" gutterBottom>
        I am aware that this deletion is permanent and cannot be undone.
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        All account-level logins and associated user data with this {getAccountDisplayType(type).toLowerCase()} will be deleted from all products and services.
      </Typography>
    </div>
  );
};

export default DeleteAccountConfirmationMessage;
