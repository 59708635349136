import SvgInfo from "@cuda-networks/bds-core/dist/Icons/Feedback/Info";
import React from "react";
import { getSecureEdgeServiceSizeInfoIconToolTip } from "../../businessLogic/components/Products/SecureEdgeServiceSizeInfoIcon";
import TooltipElement from "../Users/TooltipElement";

const SizeHeaderCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return (
    <div className="k-link" onClick={props.onClick}>
      <div style={{ display: "inline-flex", height: "17px" }}>
        <TooltipElement title={props.title}>
          <span>{props.title}</span>
        </TooltipElement>
        {props.children}
        {
          <div data-testid="SizeInfoIcon" className={"headerSizeColumnIcon"}>
            <TooltipElement title={getSecureEdgeServiceSizeInfoIconToolTip}>
              <SvgInfo color={"secondary"}></SvgInfo>
            </TooltipElement>
          </div>
        }
      </div>
    </div>
  );
};
export default SizeHeaderCell;
