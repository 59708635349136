import React, { useState, useEffect } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, LinearProgress } from "@cuda-networks/bds-core";
import { isFinalStep } from "../../../businessLogic/components/Users/AddEditUser/AddEditLoginUserDialog";
import IAccount from "../../../models/IAccount";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { cancelCurrentAction } from "../../../actions/generalActions";
import { handleBackdropClick, truncate } from "../../../utility";
import { getAccountDisplayType } from "../../../Utilities/accountsHelper";
import DeleteAccountWithSerials from "./DeleteAccountWithSerials";
import DeleteAccountWithNoSerials from "./DeleteAccountWithNoSerials";
import { IDeleteAccountSerial } from "./DeleteAccountSerialsTable";
import DeleteAccountWithPendingSerials from "./DeleteAccountWithPendingSerials";
import ConfirmationCheckbox from "../../ConfirmationCheckbox";
import DeleteAccountConfirmationMessage from "./DeleteAccountConfirmationMessage";
import { getAllSerialsForAccount } from "../../../actions/productActions";
import SerialStatus from "../../../models/Products/SerialStatus";

export const confirmDeleteAccountWithSerials = "I am aware that this deletion is permanent and cannot be undone. <br/> All  account-level logins and associated user data with this account will be deleted from all products and services.";

interface IDeleteAccountDialogProps {
  account: IAccount;
  onSubmit: (deleteAccountSerials: IDeleteAccountSerial[] | undefined) => void;
  onCancel: () => void;
  actionInProgress: boolean;
}

const DeleteAccountDialog: React.FC<IDeleteAccountDialogProps> = ({ account, onSubmit, onCancel, actionInProgress }) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const allDeleteAccountSerials = useSelector((state: IAppState) => state.productState.allDeleteAccountSerials);
  const deleteAccountSerialsToDisplay = useSelector((state: IAppState) => state.productState.deleteAccountSerialsToDisplay);
  const loadingDeleteAccountSerials = useSelector((state: IAppState) => state.productState.loadingDeleteAccountSerials);
  const [dialogStepsNumber, setDialogStepsNumber] = useState(1);
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [deleteButtonLabel, setDeleteButtonLabel] = useState("");
  const [title, setTitle] = useState("");
  const [withWarning, setWithWarning] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [hasPendingSerials, setHasPendingSerials] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [serials, setSerials] = useState<IDeleteAccountSerial[]>([]);

  const getAllSerials = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getAllSerialsForAccount(accountId));
      resolve(result);
    });

  useEffect(() => {
    getAllSerials(account.id).then(result => {
      if (!result) {
        onCancel();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    return () => {
      dispatch(getAllSerialsForAccount(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTitle(getDeleteAccountDialogTitle(withWarning, activeStep, account));
  }, [account, withWarning, activeStep]);

  useEffect(() => {
    if (loadingDeleteAccountSerials === false) {
      if (deleteAccountSerialsToDisplay !== undefined) {
        const hasPend = deleteAccountSerialsToDisplay.length > 0 && (deleteAccountSerialsToDisplay[0].status === SerialStatus.PENDING || deleteAccountSerialsToDisplay[0].status === SerialStatus.SSG_PENDING);
        setHasPendingSerials(hasPend);
        setShowDeleteButton(!hasPend);
        setWithWarning(deleteAccountSerialsToDisplay.length > 0);
        setSerials(deleteAccountSerialsToDisplay);
      }
    } else {
      setHasPendingSerials(false);
      setWithWarning(false);
      setShowDeleteButton(false);
    }
  }, [deleteAccountSerialsToDisplay, loadingDeleteAccountSerials]);

  useEffect(() => {
    if (loadingDeleteAccountSerials === false) {
      setDialogStepsNumber(withWarning ? 2 : 1);
      if (withWarning && activeStep === 1) {
        setDeleteButtonDisabled(!confirmed);
      } else {
        setDeleteButtonDisabled(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withWarning, activeStep, loadingDeleteAccountSerials, confirmed]);

  useEffect(() => {
    const { cancelButtonLabel, deleteButtonLabel } = getDeleteAccountDialogButtonsLabels(hasPendingSerials, withWarning, activeStep);
    setCancelButtonLabel(cancelButtonLabel);
    if (deleteButtonLabel.length > 0) {
      setDeleteButtonLabel(deleteButtonLabel);
    }
  }, [hasPendingSerials, withWarning, activeStep]);

  const onConfirmChanged = (value: boolean) => {
    setConfirmed(value);
    setDeleteButtonDisabled(value);
  };

  const refreshSerials = () => {
    getAllSerials(account.id).then(result => {
      if (!result) {
        onCancel();
      }
    });
  };

  function getStepContent(step: number, withWarning: boolean) {
    if (hasPendingSerials) {
      return <DeleteAccountWithPendingSerials serials={serials} type={account.type} onRefreshSerials={refreshSerials} />;
    } else if (withWarning) {
      switch (step) {
        case 0:
          return <DeleteAccountWithSerials serials={serials} type={account.type} />;
        case 1:
          return <ConfirmationCheckbox message={<DeleteAccountConfirmationMessage type={account.type} />} onConfirmChanged={value => onConfirmChanged(value)} confirmed={confirmed} />;
      }
    } else {
      return <DeleteAccountWithNoSerials type={account.type} />;
    }
  }

  const handleNext = () => {
    if (isFinalStep(activeStep, dialogStepsNumber)) {
      onSubmit(allDeleteAccountSerials);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleCancel = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      if (loadingDeleteAccountSerials) {
        dispatch(cancelCurrentAction());
      }
      onCancel();
    }
  };

  return (
    <Dialog open={true} data-testid="deleteAccountDialog" onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)}>
      <DialogTitle data-testid="deleteAccountTitle" id="alert-dialog-title">
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>

      <DialogContent>
        {loadingDeleteAccountSerials ? (
          <div data-testid="loadingDeleteMessage">
            <LinearProgress />
          </div>
        ) : (
          <div className="DialogContentDiv" style={{ padding: 8 }}>
            <Grid item xs={12}>
              {getStepContent(activeStep, withWarning)}
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: 15 }}>
        <Button data-testid="cancelDeleteAccountButton" variant="text" size="large" onClick={() => handleCancel()} disabled={actionInProgress}>
          {cancelButtonLabel}
        </Button>
        {showDeleteButton && (
          <Button data-testid="deleteAccountButton" type={"submit"} onClick={() => handleNext()} disabled={deleteButtonDisabled || actionInProgress} isLoading={actionInProgress}>
            {deleteButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountDialog;

export function getDeleteAccountDialogButtonsLabels(hasPendingSerials: boolean, withWarning: boolean, activeStep: number): { cancelButtonLabel: string; deleteButtonLabel: string } {
  let cancelButtonLabel: string = "";
  let deleteButtonLabel: string = "";
  if (hasPendingSerials) {
    cancelButtonLabel = "CANCEL";
  } else {
    if (withWarning) {
      if (activeStep === 0) {
        cancelButtonLabel = "CANCEL";
        deleteButtonLabel = "DELETE";
      } else {
        cancelButtonLabel = "BACK";
        deleteButtonLabel = "CONFIRM";
      }
    } else {
      cancelButtonLabel = "CANCEL";
      deleteButtonLabel = "DELETE";
    }
  }
  return { cancelButtonLabel, deleteButtonLabel };
}

export function getDeleteAccountDialogTitle(withWarning: boolean, activeStep: number, account: IAccount) {
  return (withWarning && activeStep === 1 ? "Confirm Deletion of " : "Delete ") + getAccountDisplayType(account.type) + " " + truncate(account.name, 30);
}
