import React from "react";
import TooltipElement from "../../../Users/TooltipElement";
import { setNoPoinerEvents } from "../../../../utility";
import { IconButton } from "@cuda-networks/bds-core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { setBillingSetupDisableTooltipMessage } from "../../../../Utilities/integrationsBillingHelper";

const DeleteBillingMapCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  return (
    <td>
      <div data-testid="deleteBillingMapping" className={props.disabled ? "cursorNotAllowed" : ""} style={{ textAlign: "end" }}>
        <TooltipElement title={setBillingSetupDisableTooltipMessage(props.title, props.dataItem.type)}>
          <IconButton aria-label="delete" disabled={props.disabled} onClick={() => props.onClick()} className={setNoPoinerEvents(props.disabled)}>
            <CoreIcons.Delete />
          </IconButton>
        </TooltipElement>
      </div>
    </td>
  );
};

export default DeleteBillingMapCell;
