/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { TextField, IconButton } from "@cuda-networks/bds-core";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { useDispatch, useSelector } from "react-redux";
import FilterAccountsPopoverLoader from "./FilterAccountsPopoverLoader";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { filterAccountsAction } from "../../../actions/accountActions";
import useWhyDidYouUpdate from "./useWhyDidYouUpdate";
import FilterAccountsPopover from "./FilterAccountsPopover";
import { UrlFilterExtractor } from "./UrlFilterExtractor";
import { IAppState } from "../../../store/store";

interface IFilterAccountsByNameProps {
  disabled: boolean;
  placeHolder: string;
  showFilterAccountPopover: boolean;
  onClose: () => void;
}

const FilterAccountsByName: React.FC<IFilterAccountsByNameProps> = props => {
  const { disabled, placeHolder, showFilterAccountPopover, onClose } = props;
  const dispatch = useDispatch();
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const existingFilters = new UrlFilterExtractor(window.location.search, accountsOrders).extractFilters();
  const [showClearButton, setShowClearButton] = useState(false);

  const [panelOpen, setPanelOpen] = useState(false);

  const textFieldRef = useRef<HTMLDivElement | null>(null);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const accountName = queryParams.get("accountName");

  const [customerName, setCustomerName] = useState(accountName ?? "");
  const [customerNameBounce, setCustomerNameBounce] = useState(accountName ?? "");
  const [textFieldWidth, setTextFieldWidth] = useState(0);
  const [nameFilterInProgress, setNameFilterInProgress] = useState(false);

  const handleClose = useCallback(() => {
    setPanelOpen(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelCallback = useCallback(() => {
    setPanelOpen(false);
    onClose();

    const queryParams = new URLSearchParams(window.location.search);
    const accountName = queryParams.get("accountName");

    setCustomerName(accountName ?? "");
    setCustomerNameBounce(accountName ?? "");
    localStorage.setItem("currentFilterName", accountName ?? "");

    checkClearButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFilters?.name]);

  const onApplyCallback = useCallback(() => {
    setPanelOpen(false);
    onClose();
    checkClearButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchClear = useCallback(() => {
    setPanelOpen(false);
    onClose();
    setCustomerName("");
    setCustomerNameBounce("");
    localStorage.setItem("currentFilterName", "");

    checkClearButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkClearButton = () => {
    setShowClearButton(accountName?.length! > 0);
  };

  const renderClearSearchButton = () => {
    let adornment = null;
    if (showClearButton === true) {
      adornment = (
        <InputAdornment position="end">
          <IconButton data-testid={"clearSearchAccounts"} onClick={onHandleClearSearch} disabled={disabled}>
            <CoreIcons.Close></CoreIcons.Close>
          </IconButton>
        </InputAdornment>
      );
    }
    return adornment;
  };

  const onHandleClearSearch = (e: any) => {
    e.stopPropagation();
    setCustomerName("");
    setCustomerNameBounce("");
    localStorage.setItem("currentFilterName", "");
    setShowClearButton(false);
    if (!panelOpen && !showFilterAccountPopover) {
      onResetNameFilters();
      //url update
      existingFilters.name = "";
      dispatch(filterAccountsAction(existingFilters));
      queryParams.delete("accountName");
      if (Array.from(queryParams.entries()).length === 1 && queryParams.toString().includes("accountsOf")) {
        const urlParams = new URLSearchParams(window.location.search);
        const accountsOfValue = urlParams.get("accountsOf");

        if (accountsOfValue) {
          navigate(`/accounts/${accountsOfValue}/products-services`);
        }
      } else {
        if (Array.from(queryParams.entries()).length === 0) {
          navigate("/");
        } else {
          navigate("/filters?" + queryParams.toString());
        }
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (panelOpen === false) {
      setPanelOpen(true);
    }
    const value = e.target.value.trimStart();
    setCustomerName(value);
    setShowClearButton(value.length > 0);
    setNameFilterInProgress(true);
    debouncedCustomerName(value);
  };

  const debouncedCustomerName = useCallback(
    debounce((criteria: string) => {
      setCustomerNameBounce(criteria);
      localStorage.setItem("currentFilterName", criteria);
      setNameFilterInProgress(false);
    }, 500),
    [],
  );

  const onResetNameFilters = () => {
    setCustomerName("");
    setCustomerNameBounce("");
    localStorage.setItem("currentFilterName", "");
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      const accountName = extractAccountNameFromURLSearchParams();
      setCustomerName(accountName ?? "");
      setCustomerNameBounce(accountName ?? "");
      setPanelOpen(false);
      setShowClearButton(accountName?.length! > 0);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    setShowClearButton(customerName.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "/" && event.ctrlKey) {
        event.preventDefault();
        if (inputFieldRef.current) {
          inputFieldRef.current.focus();
          setPanelOpen(true);
        }
      }

      if (event.key === "Escape") {
        if (document.getElementById("modal")) {
          onCancelCallback();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const setFocus = useCallback(() => {
    if (document.activeElement !== inputFieldRef.current) {
      debouncedSetFocus(""); //wait some time before setting the focus on text field.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSetFocus = debounce(async criteria => {
    inputFieldRef.current?.focus();
  }, 500);

  useEffect(() => {
    const updateTextFieldWidth = () => {
      if (textFieldRef.current) {
        setTextFieldWidth(textFieldRef.current.offsetWidth);
      }
    };

    updateTextFieldWidth();
    window.addEventListener("resize", updateTextFieldWidth);

    return () => {
      window.removeEventListener("resize", updateTextFieldWidth);
    };
  }, []);

  const extractAccountNameFromURLSearchParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("accountName");
  };
  useWhyDidYouUpdate("FilterAccountsByName", props);

  return (
    <>
      <TextField
        ref={textFieldRef}
        inputRef={inputFieldRef}
        data-testid="searchTxt"
        fullWidth
        style={{
          backgroundColor: "white",
          zIndex: 122,
        }}
        InputProps={{
          endAdornment: <div style={{ display: "inline-block" }}>{renderClearSearchButton()}</div>,
        }}
        placeholder={placeHolder}
        size={"large"}
        disabled={disabled}
        value={customerName}
        onClick={() => setPanelOpen(true)}
        onChange={handleInputChange}
        tooltip={"Search /"}
      />
      {(panelOpen || showFilterAccountPopover) && (
        <div
          id="modal"
          data-testid="modalSearchAccounts"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            zIndex: 121,
          }}
        >
          <div
            ref={panelRef}
            data-testid="modalSearchAccountsPanel"
            style={{
              position: "absolute",
              top: textFieldRef.current ? textFieldRef.current.getBoundingClientRect().bottom + 5 + window.scrollY : 0,
              left: textFieldRef.current ? textFieldRef.current.getBoundingClientRect().left + window.scrollX : 0,
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "16px",
              zIndex: 123,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <FilterAccountsPopoverLoader textFieldWidth={textFieldWidth}>
              <FilterAccountsPopover onSearchClear={onSearchClear} existingFilters={existingFilters} actionInProgress={nameFilterInProgress} setActionInProgress={setNameFilterInProgress} onClose={handleClose} newFilterByName={customerNameBounce} setInitialFocus={setFocus} onCancel={onCancelCallback} onApply={onApplyCallback} />
            </FilterAccountsPopoverLoader>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(FilterAccountsByName);
