import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import React, { useState } from "react";
import { enterKey, handleBackdropClick } from "../../../utility";
import IProduct from "../../../models/Products/IProduct";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import BackTo from "../../BackTo";
import ConfirmationCheckbox from "../../ConfirmationCheckbox";
import DeleteSerialConfirmationMessage from "./DeleteSerialConfirmationMessage";

interface ISubmitDeactivateDialogProps {
  onBack: () => void;
  onCancel: () => void;
  onSubmitDeleteSerial: () => void;
  showDeleteSerialDialog: boolean;
  isActionInProgress: boolean;
  selectedProduct: IProduct | undefined;
}
const ShowDeleteSerialDialog: React.FC<ISubmitDeactivateDialogProps> = ({ showDeleteSerialDialog, onBack, onSubmitDeleteSerial, isActionInProgress, selectedProduct, onCancel }) => {
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const onHandleCheckDeleteSerial = (value: boolean) => {
    setConfirmed(value);
    setDeleteButtonDisabled(value);
  };
  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onBack)} disableEscapeKeyDown={isActionInProgress} className="deactivateDialog" data-testid="deleteSerialDialog" open={showDeleteSerialDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onBack)}>
      <DialogTitle data-testid="deleteSerialTitle"> {"Confirm Deletion of " + selectedProduct?.serial} </DialogTitle>
      <DialogContent>
        <div style={{ width: "530px" }}>
          <Grid container alignItems="center" direction="row" style={{ paddingBottom: "10px" }}>
            <Grid item xs={12} style={{ padding: "0px 6px 6px 6px" }}>
              <BackTo
                text={`Edit ${selectedProduct?.subname}`}
                callback={(e: any) => {
                  e.preventDefault();
                  if (!isActionInProgress) {
                    onBack();
                  }
                }}
                testId="goBackToEditSerial"
                disable={isActionInProgress}
              />
            </Grid>
            <ConfirmationCheckbox message={<DeleteSerialConfirmationMessage />} onConfirmChanged={value => onHandleCheckDeleteSerial(value)} confirmed={confirmed} />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button variant="text" data-testid="closeDeleteSerialDialog" size="large" onClick={onCancel} disabled={isActionInProgress}>
            CANCEL
          </Button>
          <Button data-testid="confirmDeleteSerial" primary="true" isLoading={isActionInProgress} disabled={isActionInProgress || !deleteButtonDisabled} type={"submit"} onClick={onSubmitDeleteSerial}>
            CONFIRM
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ShowDeleteSerialDialog;
