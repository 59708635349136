import React, { useEffect, useState } from "react";
import { LinearProgress, Button, Typography } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import IntegrationsBillingAccordion from "./IntegrationsBillingAccordion";
import IAccount from "../../../models/IAccount";
import { IIntegrationBillingMap, IIntegrationBillingMapFamily, IntegrationBllingMapType } from "../../../models/Integrations/IntegrationsBillingMap";
import SetupIntegrationBillingDialog from "./SetupBilling/SetupIntegrationBillingDialog";
import { setBillingMapsAction, setBillingMapsExpandedStatusAction, setIntegrationSetupBillingTablePageNumberAction } from "../../../actions/integrations/integrationsBillingActions";
import ActionMessageType from "../../../models/ActionMessageType";
import { setSnackBarMessage } from "../../../actions/generalActions";
import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";
import useDocumentTitle, { PageTitles } from "../../../Utilities/useDocumentTitle";

export const getIntegrationsBillingTabContent = (integrationBillingMaps: IIntegrationBillingMapFamily[], expandedStatus: Record<IntegrationBllingMapType, boolean>, selectedAccount: IAccount | undefined, expandBillingMapsHandler: (integrationBillingMapFamilyType: IntegrationBllingMapType, isExpanded: boolean) => void, integrationBillingMapOrderlineItems: IIntegrationBillingMap[]): React.JSX.Element | React.JSX.Element[] => {
  if (integrationBillingMaps && integrationBillingMaps.length > 0) {
    const acc = integrationBillingMaps?.map((item, idx) => {
      return (
        <div key={"baccordionDiv" + idx} data-testid={"billingMapFamily" + idx}>
          <IntegrationsBillingAccordion key={"baccordion" + idx} tableKey={item.type + idx} integrationBillingMapFamily={item} handleExpandBillingMaps={expandBillingMapsHandler} expanded={expandedStatus[item.type]} />
        </div>
      );
    });
    return acc;
  } else if (integrationBillingMapOrderlineItems.length > 0 && integrationBillingMaps.length === 0) {
    return <Typography data-testid={"noBillingMapsMessage"}>Billing is not yet set up. Click Billing Setup to add product mappings.</Typography>;
  } else {
    return <Typography data-testid={"noBillingMapsMessage"}>No Product/Service available to map.</Typography>;
  }
};

export const shouldShowSetupBilling = (integrBillingMapOrderlineItems: IIntegrationBillingMap[], integrBillingMaps: IIntegrationBillingMapFamily[]): boolean => {
  const existingEssOrCs = integrBillingMaps?.filter(x => x.type === IntegrationBllingMapType.ESSENTIALS_SERIVICES || x.type === IntegrationBllingMapType.CONTENT_SHIELD_PLUS) || [];
  return integrBillingMapOrderlineItems.length > 0 || existingEssOrCs.length > 0;
};

const IntegrationsBillingTab: React.FC = () => {
  const dispatch = useDispatch();
  const loadingIntegrationBilling = useSelector((state: IAppState) => state.integrationsBillingMapState.loadingIntegrationBillingMap);
  const integrationBillingMaps = useSelector((state: IAppState) => state.integrationsBillingMapState.integrationBillingMaps);
  const integrationBillingMapOrderlineItems = useSelector((state: IAppState) => state.integrationsBillingMapState.integrationBillingMapOrderlineItems);
  const expandedBillingMapStatus = useSelector((state: IAppState) => state.integrationsBillingMapState.expandedBillingMapStatus);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const [accordions, setAccodions] = useState<React.JSX.Element | React.JSX.Element[]>();
  const [showSetupBilling, setShowSetupBilling] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  useDocumentTitle(selectedAccount, PageTitles.ConnectWiseBilling);

  const handleExpandBillingMaps = (integrationBillingMapFamilyType: IntegrationBllingMapType, isExpanded: boolean) => {
    dispatch(setBillingMapsExpandedStatusAction(integrationBillingMapFamilyType, isExpanded));
  };

  useEffect(() => {
    if (!loadingIntegrationBilling) {
      const tabContent = getIntegrationsBillingTabContent(integrationBillingMaps, expandedBillingMapStatus, selectedAccount, handleExpandBillingMaps, integrationBillingMapOrderlineItems);
      setAccodions(tabContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationBillingMaps, expandedBillingMapStatus, selectedAccount, integrationBillingMapOrderlineItems]);

  const setIntegrationBillingMaps = (account: IAccount, billingMapsList: IIntegrationBillingMap[]) =>
    new Promise<any>((resolve, reject) => {
      const response = dispatch(setBillingMapsAction(account, billingMapsList));
      resolve(response);
    });

  const handleOpenSetupBilling = () => {
    setShowSetupBilling(true);
  };

  const onSubmitSetupBillng = (billingMapsList: IIntegrationBillingMap[]) => {
    if (selectedAccount) {
      setActionInProgress(true);
      setIntegrationBillingMaps(selectedAccount, billingMapsList).then(result => {
        if (result) {
          setShowSetupBilling(false);
          resetSetupBillingPagination();
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.SetBillingMaps].successMessage, type: ActionMessageType.Success }));
        }
        setActionInProgress(false);
      });
    }
  };

  const onCancelSetupBilling = () => {
    resetSetupBillingPagination();
    setShowSetupBilling(false);
  };

  const resetSetupBillingPagination = () => {
    dispatch(setIntegrationSetupBillingTablePageNumberAction(1));
  };

  return (
    <div className={"IntegrationsBillingTab"}>
      <Grid item container spacing={3} direction="column">
        {shouldShowSetupBilling(integrationBillingMapOrderlineItems, integrationBillingMaps) && (
          <Grid item container>
            <Grid container item xs={12} justifyContent="flex-end">
              <div>
                <Button data-testid={"SetupBillingButton"} size={"large"} style={{ marginRight: "15px" }} onClick={handleOpenSetupBilling} disabled={loadingIntegrationBilling}>
                  BILLING SETUP
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {loadingIntegrationBilling ? (
            <div data-testid={"loadingIntegrationBilling"}>
              <LinearProgress />
            </div>
          ) : (
            accordions
          )}
        </Grid>
      </Grid>
      {showSetupBilling && <SetupIntegrationBillingDialog showSetupBilling={showSetupBilling} onSubmit={onSubmitSetupBillng} onCancel={onCancelSetupBilling} actionInProgress={actionInProgress} />}
    </div>
  );
};
export default IntegrationsBillingTab;
