import React from "react";
import { Button } from "@cuda-networks/bds-core";
import TooltipElement from "./Users/TooltipElement";
import { setNoPoinerEvents } from "../utility";

const ButtonCommandCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }
  return (
    <td id={props.id}>
      <div className={props.disabled ? "cursorNotAllowed" : ""}>
        <TooltipElement title={props.title ? props.title : ""}>
          <Button data-testid={props.dataTestId} disabled={props.disabled} className={setNoPoinerEvents(props.disabled)} variant={props.isOutlined ? "outlined" : "contained"} color={props.color} onClick={() => props.action(props.dataItem)} isLoading={props.isLoading} startIcon={props.startIcon}>
            {props.label}
          </Button>
        </TooltipElement>
      </div>
    </td>
  );
};

export default ButtonCommandCell;
