import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Link } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import IIntegrationSetup from "../../../models/Integrations/IIntegrationSetup";
import IntegrationType from "../../../models/IntegrationType";
import { validateIntegrationSetup } from "./integrationSetupValidator";
import { IFieldValidator } from "../../Accounts/AddEditAccount/addressValidator";
import { handleBackdropClick, isStringNullOrEmpty } from "../../../utility";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import TextboxLabel from "../../TextboxLabel";

interface IAddEditIntegrationDialogProps {
  onSubmit: (setupIntegration: IIntegrationSetup) => void;
  onCancel: () => void;
  showDialog: boolean;
  isLoadingDelete: boolean;
  isActionInProgress: boolean;
  isEdit: boolean;
  title: string;
  onDelete?: () => void;
}

const AddEditIntegrationDialog: React.FC<IAddEditIntegrationDialogProps> = ({ showDialog, onCancel, onSubmit, isActionInProgress, isEdit, onDelete, isLoadingDelete, title }) => {
  const cwIntegrationInfo = useSelector((state: IAppState) => state.integrationsState.cwIntegrationInfo);
  const [integrationCompanyName, setIntegrationCompanyName] = useState("");
  const [integrationUrl, setIntegrationUrl] = useState("");
  const [integrationPublicKey, setIntegrationPublicKey] = useState("");
  const [integrationPrivateKey, setIntegrationPrivateKey] = useState("");
  const [integrationManagementSolution, setIntegrationManagementSolution] = useState("");
  const [isSetupValid, setIsSetupValid] = useState(true);

  const [integrationCompanyNameError, setIntegrationCompanyNameError] = useState("");
  const [integrationUrlError, setIntegrationUrlError] = useState("");
  const [integrationPublicKeyError, setIntegrationPublicKeyError] = useState("");
  const [integrationPrivateKeyError, setIntegrationPrivateKeyError] = useState("");
  const [integrationManagementSolutionError, setIntegrationManagementSolutionError] = useState("");

  const [integrationSetupError, setIntegrationSetupError] = useState<IFieldValidator[]>([]);

  const handleCompanyNameChanged = (newValue: string) => {
    setIntegrationCompanyNameError("");
    setIntegrationCompanyName(newValue);
  };

  const handleUrlChanged = (newValue: string) => {
    setIntegrationUrlError("");
    setIntegrationUrl(newValue);
  };

  const handlePublicKeyChanged = (newValue: string) => {
    setIntegrationPublicKeyError("");
    setIntegrationPublicKey(newValue);
  };

  const handlePrivateKeyChanged = (newValue: string) => {
    setIntegrationPrivateKeyError("");
    setIntegrationPrivateKey(newValue);
  };

  const handleManagementSolutionChanged = (newValue: string) => {
    setIntegrationManagementSolutionError("");
    setIntegrationManagementSolution(newValue);
  };

  const showDeleteButton = () => {
    let button = null;
    if (isEdit) {
      button = (
        <Button data-testid="deleteIntegrationBtn" variant="contained" color="secondary" type={"submit"} isLoading={isLoadingDelete} disabled={isActionInProgress || isLoadingDelete} onClick={onDelete}>
          DELETE
        </Button>
      );
    }
    return button;
  };

  useEffect(() => {
    if (isEdit && cwIntegrationInfo) {
      setIntegrationCompanyName(cwIntegrationInfo.companyName);
      setIntegrationUrl(cwIntegrationInfo.url);
      setIntegrationPublicKey(cwIntegrationInfo.publicKey);
      setIntegrationManagementSolution(cwIntegrationInfo.managementSolution);
    }
    // eslint-disable-next-line
  }, [cwIntegrationInfo]);

  useEffect(() => {
    integrationSetupError.forEach(value => {
      switch (value.name) {
        case "company":
          setIntegrationCompanyNameError(value.error);
          break;
        case "url":
          setIntegrationUrlError(value.error);
          break;
        case "integrationUsername":
          setIntegrationPublicKeyError(value.error);
          break;
        case "managementSolution":
          setIntegrationManagementSolutionError(value.error);
          break;
        default:
          break;
      }
    });
  }, [integrationSetupError]);

  useEffect(() => {
    if (!isStringNullOrEmpty(integrationCompanyName) && !isStringNullOrEmpty(integrationUrl) && !isStringNullOrEmpty(integrationPublicKey) && !isStringNullOrEmpty(integrationPrivateKey) && !isStringNullOrEmpty(integrationManagementSolution)) {
      setIsSetupValid(false);
    } else {
      setIsSetupValid(true);
    }
  }, [integrationCompanyName, integrationUrl, integrationPublicKey, integrationPrivateKey, integrationManagementSolution]);

  const onConfirm = () => {
    let setupIntegration: IIntegrationSetup = {
      publicKey: integrationPublicKey,
      privateKey: integrationPrivateKey,
      company: integrationCompanyName,
      managementSolution: integrationManagementSolution,
      url: integrationUrl,
      integrationType: IntegrationType.ConnectWise,
    };
    let errors = validateIntegrationSetup(setupIntegration);
    setIntegrationSetupError(errors);
    if (errors.length === 0) {
      onSubmit(setupIntegration);
    }
  };

  return (
    <Dialog className="addEditIntegrationDialog" disableEscapeKeyDown={isActionInProgress} data-testid="addEditIntegrationDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={"sm"} style={{ zIndex: 4002 }}>
      <DialogTitle data-testid="addEditIntegrationDialogTitle" id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <div className="DialogContentDiv">
          <Grid container item xs={12} className={"integrationContainer"}>
            <Grid item xs={12}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="companyNameIntegrationTxt"
                  size={"small"}
                  label={<TextboxLabel label={"COMPANY NAME"} dataTestId={"companyNameIntegrationTxtLengthInfo"} maxNoOfCharacters={64} />}
                  value={integrationCompanyName}
                  error={integrationCompanyNameError.length > 0}
                  disabled={isActionInProgress}
                  helperText={integrationCompanyNameError}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCompanyNameChanged(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={"integrationContainer"}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="urlIntegrationTxt"
                  size={"small"}
                  label="URL (EX. TEST.SERVICE.COM)"
                  value={integrationUrl}
                  error={integrationUrlError.length > 0}
                  disabled={isActionInProgress}
                  helperText={integrationUrlError}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleUrlChanged(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={"integrationContainer"}>
              <Typography data-testid="integrationInfoTxt" variant="subtitle2" gutterBottom style={{ fontWeight: 600 }}>
                {"This key pair is obtained from the Members API Key section of the ConnectWise Manage portal."}
              </Typography>
            </Grid>
            <Grid item xs={12} className={"integrationContainer"}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="publicKeyIntegrationTxt"
                  size={"small"}
                  label="PUBLIC KEY"
                  value={integrationPublicKey}
                  error={integrationPublicKeyError.length > 0}
                  disabled={isActionInProgress}
                  helperText={integrationPublicKeyError}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handlePublicKeyChanged(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={"integrationContainer"}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="privateKeyIntegrationTxt"
                  size={"small"}
                  label="PRIVATE KEY"
                  value={integrationPrivateKey}
                  error={integrationPrivateKeyError.length > 0}
                  disabled={isActionInProgress}
                  type={"password"}
                  helperText={integrationPrivateKeyError}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handlePrivateKeyChanged(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={"integrationContainer"}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="managementSolutionIntegrationTxt"
                  size={"small"}
                  label="MANAGEMENT SOLUTION"
                  value={integrationManagementSolution}
                  error={integrationManagementSolutionError.length > 0}
                  disabled={isActionInProgress}
                  helperText={integrationManagementSolutionError}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleManagementSolutionChanged(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 15 }} className={"integrationContainer"}>
        {showDeleteButton()}
        <Link data-testid="downloadGuideIntegrationBtn" variant="body2" target="_blank" href="https://campus.barracuda.com/product/MSPPM/doc/98227570/setting-up-connectwise-manage-integrations/">
          LINK TO CONNECTWISE INTEGRATION ARTICLES
        </Link>
        <div style={{ flex: "1 0 0" }} />
        <Button data-testid="cancelAddEditIntegrationBtn" variant="text" size="large" disabled={isActionInProgress} onClick={onCancel}>
          CANCEL
        </Button>
        <Button data-testid="confirmAddEditIntegrationBtn" type={"submit"} isLoading={isActionInProgress && !isLoadingDelete} disabled={isActionInProgress || isSetupValid} onClick={onConfirm}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditIntegrationDialog;
