import React from "react";
import Grid from "@material-ui/core/Grid";
import * as FeedbackIcons from "@cuda-networks/bds-core/dist/Icons/Feedback";
import TooltipElement from "./Users/TooltipElement";
import { Typography } from "@cuda-networks/bds-core";
import { setCapacityTextValue } from "../Utilities/productsHelper";
interface ICapacityProps {
  capacity: number | undefined;
}

const Capacity: React.FC<ICapacityProps> = ({ capacity }) => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={capacity ? 12 : 5}>
        <Typography data-testid="bbsCapacityValue" variant="body2">
          {setCapacityTextValue(capacity, "N/A")}
        </Typography>
      </Grid>
      {!capacity && (
        <Grid item xs={7}>
          <TooltipElement placement="bottom-start" title="Capacity not available for legacy appliances">
            <FeedbackIcons.Info data-testid="bbsCapacityIcon" style={{ display: "flex", color: "dimgray" }} />
          </TooltipElement>
        </Grid>
      )}
    </Grid>
  );
};

export default Capacity;
