export enum LocalStoragePreferences {
  PRODUCTS_UI_ESS = "products.ui.ess",
  PRODUCTS_UI_CS = "products.ui.cs",
  PRODUCTS_UI_BBS = "products.ui.bbs",
  PRODUCTS_UI_SE = "products.ui.se",
  FINANCES_UI = "finances.ui",
  ACCOUNTS_UI = "accounts.pageSize",
  INTEGRATION_ACCOUNTS_UI = "integration.accounts.ui",
  SUBPARTNER_CATALOG_UI = "subpartner.catalog.ui",
  ROLESANDACCESS_UI = "rolesandaccess.ui",
  AUDITUSERS_UI = "auditusers.ui",
  INTEGRATIONS_BILLING_IBU_UI = "integrations.billing.ibu.ui",
  INTEGRATIONS_BILLING_ESS_UI = "integrations.billing.ess.ui",
  INTEGRATIONS_BILLING_CS_UI = "integrations.billing.cs.ui",
  INTEGRATIONS_LOGS_UI = "integrations.logs.ui",
  INTEGRATIONS_BILLING_SETUP = "integrations.billing.setup",
  SERIALS_UI = "products.ui.serials",
  EXCLUSION_LIST_UI = "finances.ui.exclusion",
  ENTITLEMENTS_UI = "rolesandaccess.ui.entitlements",
  DELETE_ACCOUNTS_SERIALS_UI = "products.delete.account.serials.ui",
  EXCLUDED_USERS_UI = "finances.ui.exclusion.users",
}

export const localStorageService = {
  getAll(userId: string) {
    let accountsPageSize, setupBillingMapTablePageSize, invoicesTableState, essTableState, bbsTableState, csTableState, seTableState, integrationAccountsTableState, tableState, auditUsersTableState, csBillingMapTableState, essBillingMapTableState, ibuBillingMapTableState, logsTableState, serialTableState, accountsWithExclusionsTableState, entitlementsTableState, deleteAccountSerialTableState;

    accountsPageSize = getLocalStoragePreferences(userId, LocalStoragePreferences.ACCOUNTS_UI, 10);
    setupBillingMapTablePageSize = getLocalStoragePreferences(userId, LocalStoragePreferences.INTEGRATIONS_BILLING_SETUP, 10);
    invoicesTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.FINANCES_UI, { sort: [{ field: "date", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    essTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.PRODUCTS_UI_ESS, { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    bbsTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.PRODUCTS_UI_BBS, { sort: [{ field: "nameAndModel", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    csTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.PRODUCTS_UI_CS, { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0 });
    seTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.PRODUCTS_UI_SE, { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    integrationAccountsTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.INTEGRATION_ACCOUNTS_UI, { sort: [{ field: "mspAccountName", dir: "asc" }], take: 10, skip: 0 });
    tableState = getLocalStoragePreferences(userId, LocalStoragePreferences.ROLESANDACCESS_UI, { sort: [{ field: "email", dir: "asc" }], take: 10, skip: 0 });
    auditUsersTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.AUDITUSERS_UI, { sort: [], take: 10, skip: 0 });
    csBillingMapTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.INTEGRATIONS_BILLING_CS_UI, { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    essBillingMapTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.INTEGRATIONS_BILLING_ESS_UI, { sort: [{ field: "subname", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    ibuBillingMapTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.INTEGRATIONS_BILLING_IBU_UI, { sort: [{ field: "name", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    logsTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.INTEGRATIONS_LOGS_UI, { sort: [{ field: "date", dir: "desc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    serialTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.SERIALS_UI, { sort: [{ field: "accountName", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    accountsWithExclusionsTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.EXCLUSION_LIST_UI, { sort: [{ field: "accountName", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } });
    entitlementsTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.ENTITLEMENTS_UI, { take: 15, skip: 0 });
    deleteAccountSerialTableState = getLocalStoragePreferences(userId, LocalStoragePreferences.DELETE_ACCOUNTS_SERIALS_UI, { sort: [{ field: "productName", dir: "asc" }], take: 15, skip: 0 });
    return { accountsPageSize, setupBillingMapTablePageSize, invoicesTableState, essTableState, bbsTableState, csTableState, seTableState, integrationAccountsTableState, tableState, auditUsersTableState, csBillingMapTableState, essBillingMapTableState, ibuBillingMapTableState, logsTableState, serialTableState, accountsWithExclusionsTableState, entitlementsTableState, deleteAccountSerialTableState };
  },

  getItem(userId: string, category: LocalStoragePreferences, defaultValue: any = {}) {
    return getLocalStoragePreferences(userId, category, defaultValue);
  },
  setItem(userId: string, category: LocalStoragePreferences, content: string) {
    localStorage.setItem(buildPreferenceId(userId, category), content);
  },
};
const shouldConvertFilterDates = (key: any) => {
  const isIntegrationsLogsUI = key.includes(LocalStoragePreferences.INTEGRATIONS_LOGS_UI);
  const isFinancesUI = key.includes(LocalStoragePreferences.FINANCES_UI);
  return isIntegrationsLogsUI || isFinancesUI;
};

const getLocalStorageItemOrDefault = (key: any, defaultValue: any) => {
  let currentValue;

  try {
    currentValue = JSON.parse(localStorage.getItem(key) || defaultValue);

    if (shouldConvertFilterDates(key) && currentValue.filter) {
      currentValue.filter = convertFilterDates(currentValue.filter);
    }
  } catch (error) {
    currentValue = defaultValue;
  }

  return currentValue;
};

const getLocalStoragePreferences = (userId: string, prefKey: string, defaultValue: any) => {
  const key = buildPreferenceId(userId, prefKey);
  return getLocalStorageItemOrDefault(key, defaultValue);
};

const buildPreferenceId = (userId: string, prefKey: string) => {
  return `preferences.${prefKey}.${userId}`;
};

function convertFilterDates(filter: any) {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  function convertDateString(filterObj: any) {
    if (filterObj.hasOwnProperty("value") && typeof filterObj.value === "string" && isoDateRegex.test(filterObj.value)) {
      filterObj.value = new Date(filterObj.value);
    }
  }
  filter.filters.forEach((firstLevelFilter: any) => {
    firstLevelFilter.filters.forEach((secondLevelFilter: any) => {
      convertDateString(secondLevelFilter);
    });
  });
  return filter;
}
