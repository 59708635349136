import produce from "immer";
import IExistingCreditCard from "../models/Invoices/IExistingCreditCard";

export function deleteCreditCardFromState(creditCardsToDisplay: IExistingCreditCard[], creditCard: IExistingCreditCard) {
  const cardToDeleteIndex = creditCardsToDisplay.findIndex(value => value.token === creditCard.token);
  if (cardToDeleteIndex < 0) {
    throw new Error(`No matching credit card found.`);
  }
  const nextStateCreditCards = produce(creditCardsToDisplay, draft => {
    draft.splice(cardToDeleteIndex, 1);
  });
  return nextStateCreditCards;
}

export function updateCardsWithRecurring(creditCardsToDisplay: IExistingCreditCard[], creditCardToken: string) {
  const cardToUpdateIndex = creditCardsToDisplay.findIndex(value => value.token === creditCardToken);
  if (cardToUpdateIndex < 0) {
    throw new Error(`No matching credit card found.`);
  }
  return produce(creditCardsToDisplay, draft => {
    draft[cardToUpdateIndex].recurring = true;
  });
}

export function updateCardsWithDefault(creditCardsToDisplay: IExistingCreditCard[], creditCardToken: string) {
  const cardToUpdateIndex = creditCardsToDisplay.findIndex(value => value.token === creditCardToken);
  if (cardToUpdateIndex < 0) {
    throw new Error(`No matching credit card found.`);
  }
  return produce(creditCardsToDisplay, draft => {
    draft[cardToUpdateIndex].default = true;
  });
}
