import { Grid, TextField } from "@cuda-networks/bds-core";
import React from "react";
import { setAddEditUserDialogWidth } from "../../../../Utilities/usersHelper";
import TextboxLabel from "../../../TextboxLabel";

interface ILoginNameStepProps {
  name: string;
  nameError: string;
  onNameChanged: (value: string) => void;
  email: string;
  emailError: string;
  onEmailChanged: (value: string) => void;
  isEditingLoggedInUser: boolean;
}

const LoginNameStep: React.FC<ILoginNameStepProps> = ({ name, nameError, onNameChanged, email, emailError, onEmailChanged, isEditingLoggedInUser }) => {
  return (
    <div data-testid="addEditLoginNameEmailStep">
      <Grid item container spacing={3}>
        <Grid container item xs={setAddEditUserDialogWidth("name")}>
          <Grid item xs={12} container direction="column">
            <TextField
              data-testid="userNameTxt"
              size={"small"}
              label={<TextboxLabel label={"LOGIN NAME"} dataTestId={"userNameLengthInfo"} maxNoOfCharacters={64} />}
              value={name}
              error={nameError.length > 0}
              helperText={nameError}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onNameChanged(ev.target.value)}
              inputProps={{
                maxLength: 64,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={setAddEditUserDialogWidth("email")}>
          <Grid item xs={12} container direction="column">
            <TextField
              data-testid="userEmailTxt"
              label="EMAIL"
              size={"small"}
              value={email}
              error={emailError.length > 0}
              disabled={isEditingLoggedInUser}
              helperText={emailError}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onEmailChanged(ev.target.value)}
              inputProps={{
                maxLength: 64,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginNameStep;
