import React from "react";

interface ITextboxLabelProps {
  label: string;
  dataTestId: string;
  optional?: boolean;
  maxNoOfCharacters?: number;
}
const TextboxLabel: React.FC<ITextboxLabelProps> = ({ label, dataTestId, optional, maxNoOfCharacters }) => {
  return (
    <div>
      {label}
      <span className="AccountLengthInfo" data-testid={dataTestId} style={{ fontWeight: "normal" }}>
        {optional ? " Optional" : ""} {maxNoOfCharacters ? " (" + maxNoOfCharacters + " Maximum Characters)" : ""}
      </span>
    </div>
  );
};

export default TextboxLabel;
