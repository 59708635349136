import { Typography } from "@cuda-networks/bds-core";
import React from "react";

const DeleteSerialConfirmationMessage = () => {
  return (
    <div className="AccountIcons" data-testid="deleteSerialMessage">
      <Typography variant="subtitle2" gutterBottom>
        I am aware that deleting this serial number is a permanent action that cannot be undone. All usage data associated with this serial number will be deleted.
      </Typography>
    </div>
  );
};

export default DeleteSerialConfirmationMessage;
