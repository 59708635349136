import axios from "axios";
import { API_ULR_BCC_TOKEN, API_URL_CHECKUSER, API_URL_GENERATE_TOKEN, API_URL_GET_USER, API_URL_REFRESH_TOKEN } from "./service/clientAPI";
import { logout, stripTrailingSlash } from "./utility";
import env from "@beam-australia/react-env";

export class TokenStorage {
  private static readonly AUTH_URL = env("AUTH_URL");
  private static readonly CLIENT_ID = env("CLIENT_ID");
  private static readonly SESSION_STORAGE_BCC_ACCESS_TOKEN = "bcc_access_token";
  private static readonly SESSION_STORAGE_BCC_REFRESH_TOKEN = "bcc_refresh_token";
  private static readonly SESSION_STORAGE_ACCESS_TOKEN = "access_token";
  private static readonly SESSION_STORAGE_REFRESH_TOKEN = "refresh_token";
  private static readonly SESSION_STORAGE_BCC_ID_TOKEN = "bcc_id_token";
  private static API_URL = "";
  private static USER_ID = 0;

  public static setApiUrl(url: string): void {
    this.API_URL = url;
  }

  public static setUserId(id: number): void {
    this.USER_ID = id;
  }

  public static getApiUrl(): string {
    return this.API_URL;
  }

  public static getUserId(): number {
    return this.USER_ID;
  }

  public static storeBccAccessToken(token: string): void {
    sessionStorage.setItem(TokenStorage.SESSION_STORAGE_BCC_ACCESS_TOKEN, token);
  }

  public static storeBccIdToken(token: string): void {
    sessionStorage.setItem(TokenStorage.SESSION_STORAGE_BCC_ID_TOKEN, token);
  }

  public static storeEchoV3AccessToken(token: string): void {
    sessionStorage.setItem(TokenStorage.SESSION_STORAGE_ACCESS_TOKEN, token);
  }

  public static storeEchoV3RefreshToken(token: string): void {
    sessionStorage.setItem(TokenStorage.SESSION_STORAGE_REFRESH_TOKEN, token);
  }

  public static storeBccRefreshToken(refreshToken: string): void {
    sessionStorage.setItem(TokenStorage.SESSION_STORAGE_BCC_REFRESH_TOKEN, refreshToken);
  }

  public static clear(): void {
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_BCC_ACCESS_TOKEN);
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_BCC_REFRESH_TOKEN);
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_ACCESS_TOKEN);
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_REFRESH_TOKEN);
    sessionStorage.removeItem(TokenStorage.SESSION_STORAGE_BCC_ID_TOKEN);
  }

  public static getBccRefreshToken(): string | null {
    return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_BCC_REFRESH_TOKEN);
  }

  public static getBccAccessToken(): string | null {
    return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_BCC_ACCESS_TOKEN);
  }

  public static getEchoV3RefreshToken(): string | null {
    return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_REFRESH_TOKEN);
  }

  public static getEchoV3AccessToken(): string | null {
    return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_ACCESS_TOKEN);
  }

  public static getBccIdToken(): string | null {
    return sessionStorage.getItem(TokenStorage.SESSION_STORAGE_BCC_ID_TOKEN);
  }

  public static getAccessTokenForApiCall(url: string | undefined) {
    if (url === undefined) {
      return "";
    }
    if (this.shouldUseBccToken(url)) {
      const bccToken = this.getBccAccessToken();
      if (bccToken !== undefined && bccToken !== null && bccToken !== "") {
        return bccToken;
      }
    }
    return this.getEchoV3AccessToken();
  }

  public static shouldUseBccToken(url: string): boolean {
    const urlStripped = stripTrailingSlash(url);
    return urlStripped.endsWith(API_ULR_BCC_TOKEN) || urlStripped.endsWith(API_URL_CHECKUSER) || urlStripped.endsWith(API_URL_GENERATE_TOKEN) || urlStripped.endsWith(API_URL_GET_USER);
  }

  public static refreshToken(url: string): Promise<string> {
    if (this.shouldUseBccToken(url)) {
      return this.refreshBccAccessToken();
    } else {
      return this.refreshEchoV3AccessToken();
    }
  }

  public static refreshEchoV3AccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.getEchoV3RefreshToken()) {
        axios
          .post(`${this.API_URL}/${API_URL_REFRESH_TOKEN}`, {
            refreshToken: this.getEchoV3RefreshToken(),
            userId: this.USER_ID,
          })
          .then(response => {
            if (response && response.data && response.data.accessToken) {
              this.storeEchoV3AccessToken(response.data.accessToken);
              this.storeEchoV3RefreshToken(response.data.refreshToken);
              resolve(response.data.accessToken);
            } else {
              reject("no new access token");
              logout();
            }
          })
          .catch(error => {
            reject(error);
            logout();
          })
          .finally(() => {});
      } else {
        reject("no refresh token");
        logout();
      }
    });
  }

  public static refreshBccAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.getBccRefreshToken()) {
        axios
          .post(`${TokenStorage.AUTH_URL}/${API_ULR_BCC_TOKEN}`, {
            refresh_token: this.getBccRefreshToken(),
            client_id: TokenStorage.CLIENT_ID,
            grant_type: "refresh_token",
            scope: "offline_access",
          })
          .then(response => {
            if (response && response.data && response.data.access_token) {
              this.storeBccAccessToken(response.data.access_token);
              this.storeBccRefreshToken(response.data.refresh_token);
              resolve(response.data.access_token);
            } else {
              reject("no new access token");
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject("no refresh token");
      }
    });
  }
}
