import { IIntegrationBillingMap, IntegrationBllingMapType } from "../../../../../models/Integrations/IntegrationsBillingMap";

export function getCurrentBillingMapsAfterAddNewItem(billingMapsList: IIntegrationBillingMap[]): IIntegrationBillingMap[] {
  let newBillingMapsList = [...billingMapsList];
  newBillingMapsList.push({ id: 0, name: "", subname: "" });
  return newBillingMapsList;
}

export function getCurrentBillingMapsAfterDeleteItem(billingMapsList: IIntegrationBillingMap[], item: IIntegrationBillingMap): IIntegrationBillingMap[] {
  const index = billingMapsList.findIndex(x => x.id === item.id);
  if (index > -1) {
    let newBillingMapsList = [...billingMapsList];
    newBillingMapsList.splice(index, 1);
    return newBillingMapsList;
  }
  return billingMapsList;
}

export function getCurrentAvailableOrderLineItemsAfterDeleteItem(availableOrderLineItems: IIntegrationBillingMap[], item: IIntegrationBillingMap): IIntegrationBillingMap[] {
  if (item.subname !== "") {
    return [...availableOrderLineItems, item];
  }
  return availableOrderLineItems;
}

export function getCurrentBillingMapsAfterAddChangeFamily(billingMapsList: IIntegrationBillingMap[], item: IIntegrationBillingMap, newFamily: IntegrationBllingMapType): IIntegrationBillingMap[] {
  const index = billingMapsList.findIndex(x => x === item);
  if (index > -1) {
    let newBillingMapsList = [...billingMapsList];
    newBillingMapsList[index] = { ...item, type: newFamily, name: "", subname: "", ...(item.canceled && { canceled: false }) };
    return newBillingMapsList;
  }
  return billingMapsList;
}

export function getCurrentBillingMapsAfterAddChangeBundle(billingMapsList: IIntegrationBillingMap[], item: IIntegrationBillingMap, newBundle: string): IIntegrationBillingMap[] {
  const index = billingMapsList.findIndex(x => x === item);
  if (index > -1) {
    let newBillingMapsList = [...billingMapsList];
    newBillingMapsList[index] = { ...item, name: newBundle, subname: "", ...(item.canceled && { canceled: false }) };
    return newBillingMapsList;
  }
  return billingMapsList;
}

export function getCurrentBillingMapsAfterAddChangePlan(billingMapsList: IIntegrationBillingMap[], availableOrderLineItems: IIntegrationBillingMap[], item: IIntegrationBillingMap, newPlan: string): IIntegrationBillingMap[] {
  const currentMapIndex = billingMapsList.findIndex(x => x === item);
  if (currentMapIndex > -1) {
    const orderLineItemIndex = availableOrderLineItems.findIndex(x => x.type === item.type && x.name === item.name && x.subname === newPlan);
    if (orderLineItemIndex > -1) {
      let newBillingMapsList = [...billingMapsList];
      newBillingMapsList[currentMapIndex] = { ...item, subname: newPlan, id: availableOrderLineItems[orderLineItemIndex].id };
      return newBillingMapsList;
    }
    return billingMapsList;
  }
  return billingMapsList;
}

export function getCurrentBillingMapsAfterAddChangeLevel(billingMapsList: IIntegrationBillingMap[], item: IIntegrationBillingMap, newLevel: string): IIntegrationBillingMap[] {
  const index = billingMapsList.findIndex(x => x.id === item.id);
  if (index > -1) {
    let newBillingMapsList = [...billingMapsList];
    if (item.subname !== "") {
      newBillingMapsList[index] = { ...item, level: newLevel };
    }
    return newBillingMapsList;
  }
  return billingMapsList;
}

export function getCurrentAvailableOrderLineItemsAfterChangePlan(availableOrderLineItems: IIntegrationBillingMap[], item: IIntegrationBillingMap, newPlan: string): IIntegrationBillingMap[] {
  const newMappedOrderLineItemIndex = availableOrderLineItems.findIndex(x => x.type === item.type && x.name === item.name && x.subname === newPlan);
  if (newMappedOrderLineItemIndex > -1) {
    let newAvailableOrderLineItems = [...availableOrderLineItems];
    newAvailableOrderLineItems.splice(newMappedOrderLineItemIndex, 1);
    return getAvailableItemsAfterAddingRemovedByEditItem(newAvailableOrderLineItems, item);
  }
  return availableOrderLineItems;
}

export function getAvailableItemsAfterAddingRemovedByEditItem(availableOrderLineItems: IIntegrationBillingMap[], item: IIntegrationBillingMap): IIntegrationBillingMap[] {
  let newAvailableOrderLineItems = [...availableOrderLineItems];
  if (item.subname !== "") {
    newAvailableOrderLineItems.push({ type: item.type, name: item.name, subname: item.subname, id: item.id });
  }
  return newAvailableOrderLineItems;
}
