import produce from "immer";
import IAccount from "../models/IAccount";
import MspType from "../models/MspType";
import IAccountProducts from "../models/Products/IAccountProducts";
import IOrder from "../models/Products/IOrder";
import IProduct from "../models/Products/IProduct";
import IProductFamily from "../models/Products/IProductFamily";
import IProductSkuInfo from "../models/Products/IProductSkuInfo";
import ISerial from "../models/Products/ISerial";
import { getOrderSummary, IOrderSummary } from "../models/Products/OrderSummary";
import ProductFamily, { getProductTypeName } from "../models/Products/ProductFamily";
import SerialStatus from "../models/Products/SerialStatus";
import { findAccountById } from "../Utilities/accountsHelper";
import { getProductDisplayInfo, IProductDisplayInfo, nameIsBBS, nameIsCS, nameIsESS, productIsBBS } from "../Utilities/productsHelper";
import { getFinalProduct } from "../utility";
import { IDeleteAccountSerial } from "../components/Accounts/DeleteAccount/DeleteAccountSerialsTable";

export function computeProductOrder(order: IOrder, serials: ISerial[], skuInfo: IProductSkuInfo, mspAccounts: IAccount[]) {
  const pendingSerials = filterSerialsByState(serials, [SerialStatus.SSG_PENDING, SerialStatus.PENDING]);
  const orderSummary: IOrderSummary = { finalSerials: serials, totalUsers: 0, totalErrors: 0, hasPendingSerials: pendingSerials.length > 0 };
  const displayInfo: IProductDisplayInfo = getProductDisplayInfo(order, orderSummary, mspAccounts);
  const name = getProductTypeName(order.familyName);
  return {
    id: order.lineItemId,
    type: name,
    name: displayInfo.name,
    subname: displayInfo.subname,
    sku: order.bundleSku,
    serial: displayInfo.serial,
    serials: serials,
    subPartnerId: order.subPartnerId,
    status: order.status,
    unassigned: order.unassigned,
    bbsOrderStatus: displayInfo.bbsOrderStatus,
  };
}

export function computeProductOrderWithDetails(order: IOrder, serials: ISerial[] | undefined, mspAccounts: IAccount[]) {
  const orderSummary: IOrderSummary = getOrderSummary(serials, order.familyName);
  const displayInfo: IProductDisplayInfo = getProductDisplayInfo(order, orderSummary, mspAccounts);
  const name = getProductTypeName(order.familyName);
  const seSize = order.familyName.includes(ProductFamily.SECUREEDGE_SERVICES) ? orderSummary.totalUsers : undefined;
  return {
    id: order.lineItemId,
    type: name,
    name: displayInfo.name,
    subname: displayInfo.subname,
    contract: order.noOfUnits,
    serials: orderSummary.finalSerials,
    users: orderSummary.totalUsers,
    size: seSize,
    status: order.status,
    overages: displayInfo.overages,
    noOfErrors: orderSummary.totalErrors,
    sku: order.bundleSku,
    serial: displayInfo.serial,
    account: displayInfo.account,
    nameAndModel: displayInfo.nameAndModel,
    subPartnerId: order.subPartnerId,
    unassigned: order.unassigned,
    hasPendingSerials: orderSummary.hasPendingSerials,
    bbsOrderStatus: displayInfo.bbsOrderStatus,
    services: order.services,
    partnerSkuId: order.partnerSkuId,
    price: order.price,
    overageRate: order.overageRate,
    effectiveDate: order.effectiveDate,
    endDate: order.endDate,
    provisionable: order.provisionable,
  };
}

export function getFinalProductsForAccount(products: IProduct[], accountType: string) {
  const finalProductsForAccount: IProduct[] = products.filter(f => {
    if (productIsBBS(f)) {
      if (accountType === MspType.Customer) {
        return f.serials.length > 0 && !serialHasRmaStatus(f.serials[0]);
      } else {
        return f.serials.length > 0;
      }
    } else if (accountType === MspType.Customer) {
      return f.serials.length > 0;
    } else {
      if (accountType === MspType.Subpartner && f.unassigned) {
        return f.serials.length > 0;
      } else {
        return f.serials;
      }
    }
  });
  const result = getFinalProduct(finalProductsForAccount);
  result.sort((a, b) => b.productType.localeCompare(a.productType));
  return result;
}

export function filterSerialsByState(serials: ISerial[], statuses: SerialStatus[]): ISerial[] {
  let matchedSerials: ISerial[] = [];
  statuses.forEach(status => {
    serials.forEach(serial => {
      if (serial.status === status) {
        matchedSerials.push(serial);
      }
    });
  });
  return matchedSerials;
}

export function serialHasRmaStatus(serial: ISerial): boolean {
  return serial.status === SerialStatus.RMA_ISSUED || serial.status === SerialStatus.RMA_NEEDED || serial.status === SerialStatus.RMA_REQUESTED;
}

export function getSerialsWithAccountId(serials: ISerial[], mspAccounts: IAccount[], accountNames: IAccount[] | undefined): ISerial[] {
  const filteredSerials = serials.filter((serial: ISerial) => serial.status !== SerialStatus.CANCELED);
  const finalResult = filteredSerials.map((serial: ISerial) => ({
    ...serial,
    accountName: findAccountById(mspAccounts, accountNames, serial.accountId)?.name,
  }));
  return finalResult;
}

export const getAccountProductFromAccountProducts = (product: IProduct | undefined, accountId: number, accountsProducts: IAccountProducts[]): IProduct | undefined => {
  const indexes = getProductFromAccountProductsIndexes(product, accountId, accountsProducts);
  if (indexes.productFound) {
    return accountsProducts[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex];
  } else {
    return undefined;
  }
};

export const getProductFromAccountProductsIndexes = (product: IProduct | undefined, accountId: number, accountsProducts: IAccountProducts[]) => {
  let accountProductIndex = -1;
  let accountProductFamilyIndex = -1;
  let productIndex = -1;
  let productFound = false;
  accountProductIndex = accountsProducts.findIndex((x: IAccountProducts) => x.accountId === accountId);
  if (accountProductIndex >= 0) {
    accountProductFamilyIndex = accountsProducts[accountProductIndex].productFamilies.findIndex((x: IProductFamily) => product?.type.includes(x.productType));
    if (accountProductFamilyIndex > -1) {
      productIndex = accountsProducts[accountProductIndex].productFamilies[accountProductFamilyIndex].products.findIndex((x: IProduct) => x.id === product?.id);
    }
  }
  if (accountProductIndex > -1 && accountProductFamilyIndex > -1 && productIndex > -1) {
    productFound = true;
  }
  return { productFound, accountProductIndex, accountProductFamilyIndex, productIndex };
};

export function updateSerialStatusForParentProduct(product: IProduct, account: IAccount, accountsProducts: IAccountProducts[], serialToDisplay: ISerial, status: string): IAccountProducts[] {
  const indexes = getProductFromAccountProductsIndexes(product, account.closestParentId, accountsProducts);
  if (indexes.productFound) {
    const productSerialIndex = accountsProducts[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials.findIndex((s: ISerial) => s.serial === serialToDisplay.serial);
    if (productSerialIndex > -1) {
      const nextStateAccountProducts: IAccountProducts[] = produce(accountsProducts, (draft: IAccountProducts[]) => {
        draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials[productSerialIndex].status = status;
      });
      return nextStateAccountProducts;
    }
  }
  return accountsProducts;
}

export function updateBBSProductForParentAccountForAfterAssignUnassign(product: IProduct, account: IAccount, accountsProducts: IAccountProducts[], isAssigning: boolean): IAccountProducts[] {
  const indexes = getProductFromAccountProductsIndexes(product, account.closestParentId, accountsProducts);
  if (indexes.productFound) {
    const nextStateAccountProducts: IAccountProducts[] = produce(accountsProducts, (draft: IAccountProducts[]) => {
      draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].subPartnerId = isAssigning ? account.id : null;
      draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials[0].status = SerialStatus.AVAILABLE; //maybe not needed
    });
    return nextStateAccountProducts;
  }
  return accountsProducts;
}

export function updateBBSProductForParentAccountForAfterActivateDeactivate(product: IProduct, account: IAccount, accountsProducts: IAccountProducts[], isActivating: boolean, updatedStatusAfterActivation: string, updatingImmediateParent: boolean): IAccountProducts[] {
  const indexes = getProductFromAccountProductsIndexes(product, account.closestParentId, accountsProducts);
  if (indexes.productFound) {
    const nextStateAccountProducts: IAccountProducts[] = produce(accountsProducts, (draft: IAccountProducts[]) => {
      draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials[0].status = isActivating ? updatedStatusAfterActivation : SerialStatus.AVAILABLE;
      if (updatingImmediateParent || !isActivating) {
        draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials[0].accountId = isActivating ? account.id : 0;
      }
    });
    return nextStateAccountProducts;
  }
  return accountsProducts;
}

export function updateBBSProductsInStateAfterDeactivateSerial(accountsProducts: IAccountProducts[], product: IProduct, selectedAccount: IAccount, mspAccounts: IAccount[], mspAccountLoggedIn: IAccount) {
  let nextStateAccountProducts = accountsProducts;
  if (productIsBBS(product)) {
    nextStateAccountProducts = updateBBSProductForParentAccountForAfterActivateDeactivate(product, selectedAccount, accountsProducts, false, "", true);
    const selectedAccountParent = mspAccounts.find(e => e.id === selectedAccount.closestParentId);
    if (selectedAccountParent?.type === MspType.Subpartner && mspAccountLoggedIn.type === MspType.Partner) {
      nextStateAccountProducts = updateBBSProductForParentAccountForAfterActivateDeactivate(product, selectedAccountParent, nextStateAccountProducts, false, "", false);
    }
  }
  return nextStateAccountProducts;
}

export function updateESSCSProductForParentAccountForAfterCancelSerial(product: IProduct, account: IAccount, accountsProducts: IAccountProducts[], bccProductId: number): IAccountProducts[] {
  const indexes = getProductFromAccountProductsIndexes(product, account.closestParentId, accountsProducts);
  if (indexes.productFound) {
    const serialIndex = accountsProducts[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials.findIndex(serial => serial.bccProductId === bccProductId);
    if (serialIndex !== -1) {
      const nextStateAccountProducts: IAccountProducts[] = produce(accountsProducts, (draft: IAccountProducts[]) => {
        draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products[indexes.productIndex].serials.splice(serialIndex, 1);
      });
      return nextStateAccountProducts;
    }
    return accountsProducts;
  }

  return accountsProducts;
}

export function removeAccountSerialsFromState(account: IAccount, deleteAccountSerials: IDeleteAccountSerial[] | undefined, accountsProducts: IAccountProducts[], mspAccounts: IAccount[]) {
  if (deleteAccountSerials === undefined || deleteAccountSerials.length === 0) {
    return undefined;
  }
  let nextStateAccountsProducts: IAccountProducts[] = [...accountsProducts];
  const accountOrdersIndex = nextStateAccountsProducts.findIndex(x => x.accountId === account.id);
  if (accountOrdersIndex > -1) {
    nextStateAccountsProducts.splice(accountOrdersIndex, 1);
  }
  const selectedAccountParent = mspAccounts.find(e => e.id === account.closestParentId);
  if (selectedAccountParent) {
    nextStateAccountsProducts = remoteAccountSerialsFromProductsParentState(nextStateAccountsProducts, deleteAccountSerials, selectedAccountParent, true);
    const selectedAccountParent2 = mspAccounts.find(e => e.id === selectedAccountParent.closestParentId);
    if (selectedAccountParent2) {
      nextStateAccountsProducts = remoteAccountSerialsFromProductsParentState(nextStateAccountsProducts, deleteAccountSerials, selectedAccountParent2, false);
    }
  }
  return nextStateAccountsProducts;
}

export function remoteAccountSerialsFromProductsParentState(products: IAccountProducts[], deleteAccountSerials: IDeleteAccountSerial[], account: IAccount, isDirectChild: boolean): IAccountProducts[] {
  let result: IAccountProducts[] = [...products];

  const accountOrdersIndex = result.findIndex(x => x.accountId === account.id);
  if (accountOrdersIndex > -1) {
    deleteAccountSerials?.forEach((accountSerial: IDeleteAccountSerial) => {
      const prodFamIndex = result[accountOrdersIndex].productFamilies.findIndex(x => x.productType.includes(accountSerial.productFamily));
      if (prodFamIndex > -1) {
        const prodIndex = result[accountOrdersIndex].productFamilies[prodFamIndex].products.findIndex(x => x.id === accountSerial.orderLineItemId);
        if (prodIndex > -1) {
          const serialIndex = result[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].serials.findIndex(x => x.bccProductId === accountSerial.bccProductId);
          if (serialIndex > -1) {
            if (nameIsBBS(accountSerial.productFamily)) {
              if (account.type === MspType.Subpartner) {
                result = produce(result, (draft: IAccountProducts[]) => {
                  draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].serials[serialIndex].accountId = 0;
                  draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].serials[serialIndex].status = SerialStatus.AVAILABLE;
                  draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].account = undefined;
                });
              } else {
                result = produce(result, (draft: IAccountProducts[]) => {
                  draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].serials[serialIndex].accountId = 0;
                  draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].serials[serialIndex].status = SerialStatus.AVAILABLE;
                  if (isDirectChild) {
                    draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].subPartnerId = null;
                  }
                  draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].account = undefined;
                });
              }
            } else if (nameIsESS(accountSerial.productFamily) || nameIsCS(accountSerial.productFamily)) {
              result = produce(result, (draft: IAccountProducts[]) => {
                draft[accountOrdersIndex].productFamilies[prodFamIndex].products[prodIndex].serials.splice(serialIndex, 1);
              });
            }
          }
        }
      }
    });
  }
  return result;
}

/* export function updateProducts(currentProducts: IProductFamily[], accountId: number, updatedSerials: IDeleteAccountSerial[]) {
  return currentProducts;
}
 */
