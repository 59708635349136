import { CardCvvValidationRule, CardNameValidationRule, CardNumberValidationRules } from "../../fieldsValidationRules";
import ICreditCard from "../../models/Invoices/ICreditCard";
import { getCardType, isCardDateExpired, isPreviousYears } from "../../Utilities/financeHelper";
import { IFieldValidator } from "../Accounts/AddEditAccount/addressValidator";
import { Mes } from "../../mes.min.js";
import IExistingCreditCard from "../../models/Invoices/IExistingCreditCard";

export const validateCreditCard = (creditCard: ICreditCard, selectedCreditCard: IExistingCreditCard | undefined): IFieldValidator[] => {
  let errorFields: IFieldValidator[] = [];

  errorFields.push(...cardNumberValidation(creditCard.cardNumber, selectedCreditCard));
  if (!CardNameValidationRule.RegularExpression.test(creditCard.firstName)) {
    errorFields.push({ name: "firstName", index: 0, error: "First name contains invalid characters" });
  }

  if (!CardNameValidationRule.RegularExpression.test(creditCard.lastName)) {
    errorFields.push({ name: "lastName", index: 0, error: "Last name contains invalid characters" });
  }

  let today = new Date();
  if (isPreviousYears(today, creditCard.year)) {
    errorFields.push({ name: "expYear", index: 0, error: "Expired" });
  } else {
    if (isCardDateExpired(today, creditCard.month, creditCard.year)) {
      errorFields.push({ name: "expMonth", index: 0, error: "Expired" });
    }
  }

  if (!CardCvvValidationRule.RegularExpression.test(creditCard.cvv)) {
    errorFields.push({ name: "cvv", index: 0, error: "Invalid" });
  }

  return errorFields;
};

function cardNumberValidation(cardNumber: string, selectedCreditCard: IExistingCreditCard | undefined): IFieldValidator[] {
  let errorFields: IFieldValidator[] = [];
  if (!CardNumberValidationRules.RegularExpression.test(cardNumber) || getCardType(cardNumber) === "") {
    errorFields.push({ name: "cardNumber", index: 0, error: "Invalid card number" });
  } else if (selectedCreditCard) {
    if (cardNumber.substring(cardNumber.length - 4) !== selectedCreditCard.lastFourDigits) {
      errorFields.push({ name: "cardNumber", index: 0, error: "Card Number does not match" });
    }
  }
  return errorFields;
}

export const validateMesCreditCard = (creditCard: ICreditCard, handleMesResponse: any) => {
  const expDate = creditCard.month + creditCard.year.slice(-2);
  Mes.tokenize(creditCard.cardNumber, expDate, handleMesResponse);
};
