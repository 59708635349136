import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import React from "react";
import SerialsTable from "./SerialsTable";
import { enterKey, handleBackdropClick, setNoPoinerEvents } from "../../../utility";

interface ISubmitDialogProps {
  onCancel: () => void;
  showDialog: boolean;
  onGoToAccount: (accountId: number) => void;
  dialogTitle: string | undefined;
  onShowUnassignedDialog: () => void;
  showUnassignButton: boolean;
  disableUnassignButton?: boolean;
  isSecureEdgeEdgeService: boolean;
}
const ShowSerialsDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onCancel, onGoToAccount, dialogTitle, onShowUnassignedDialog, showUnassignButton, disableUnassignButton, isSecureEdgeEdgeService }) => {
  const handleGoToAccount = (e: any): void => {
    onGoToAccount(e);
  };

  const renderUnassignButton = () => {
    let button = (
      <Button data-testid="unassignButton" size="medium" color="secondary" disabled={disableUnassignButton} className={disableUnassignButton ? setNoPoinerEvents(disableUnassignButton) : ""} onClick={onShowUnassignedDialog}>
        UNASSIGN
      </Button>
    );
    if (showUnassignButton) {
      if (disableUnassignButton) {
        return <Tooltip title="Service already unassigned">{button}</Tooltip>;
      } else {
        return button;
      }
    } else {
      return null;
    }
  };

  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} data-testid="showSerialsDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={"md"} disableEnforceFocus>
      <div className="showSerialDialog">
        <DialogTitle data-testid="showSerialsDialogTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
          <Grid container item xs={12}>
            <Grid item xs={10}>
              {"EDIT " + dialogTitle}
            </Grid>
            <Grid item xs={2}>
              {renderUnassignButton()}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <SerialsTable onGoToAccount={handleGoToAccount} isSecureEdgeEdgeService={isSecureEdgeEdgeService}></SerialsTable>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: "1 0 0" }} />
          <Button data-testid="closeShowSerialsButton" size="large" onClick={onCancel}>
            CLOSE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ShowSerialsDialog;
