import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@cuda-networks/bds-core";
import CreditCardType from "../models/Invoices/CreditCardType";
import IExistingCreditCard from "../models/Invoices/IExistingCreditCard";
import { isCreditCardDateExpired, obfuscateCreditCard } from "../Utilities/financeHelper";
import AmexImage from "../assets/amex-credit-card-icon.png";
import DiscoverImage from "../assets/discover-credit-card-icon.png";
import MasterCardImage from "../assets/mastercard-credit-card-icon.png";
import VisaImage from "../assets/visa-credit-card-icon.png";
interface ISelectProps {
  label: string;
  options: IExistingCreditCard[];
  selectedOptionId: number;
  error: string;
  handleChange: (id: number) => void;
  onOpen?: (isOpen: boolean) => void;
  disabled: boolean;
}

const CreditCardDropDown: React.FC<ISelectProps> = ({ label, options, selectedOptionId, error, handleChange, disabled, onOpen }) => {
  const [dropdownRef, setDropdownRef] = useState<any>();
  const defaultOption = {
    value: "Add New Card",
    label: "Add New Card",
    idx: 0,
    default: false,
    expired: false,
    type: "",
    isSelected: false,
    isDisabled: false,
  };
  const loadedOptions = options.map((s, index) => ({
    value: obfuscateCreditCard(s.lastFourDigits),
    label: obfuscateCreditCard(s.lastFourDigits),
    idx: index + 1,
    default: s.default,
    expired: isCreditCardDateExpired(s),
    type: s.cardType,
    isSelected: false,
    isDisabled: false,
  }));
  const availableOptions = [defaultOption, ...loadedOptions];

  let selectedObject = availableOptions[selectedOptionId];
  const onHandleStateChange: any = (_e: any, newValue: any) => {
    selectedObject.isSelected = !selectedObject.isSelected;
    handleChange(Number(newValue.key.substring(2)));
  };
  selectedObject.isSelected = !selectedObject.isSelected;

  useEffect(() => {
    if (onOpen) {
      if (dropdownRef) {
        onOpen(true);
      } else {
        onOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const getCreditCardIcon = (type: string) => {
    switch (type) {
      case CreditCardType.MC:
        return <img data-testid={"MastercardCreditCardIcon"} src={MasterCardImage} alt="Icon MasterCard" className="creditCardIcon" />;
      case CreditCardType.AMEX:
        return <img data-testid={"AmexCreditCardIcon"} src={AmexImage} alt="Icon Amex" className="creditCardIcon" />;
      case CreditCardType.VISA:
        return <img data-testid={"VisaCreditCardIcon"} src={VisaImage} alt="Icon Visa Card" className="creditCardIcon" />;
      case CreditCardType.DISC:
        return <img data-testid={"DiscoverCreditCardIcon"} src={DiscoverImage} alt="Icon Discover Card" className="creditCardIcon" />;
      default:
        return null;
    }
  };
  const getCreditCardSuffixStatus = (isDefault: boolean, isExpired: boolean) => {
    let activeStauts = [
      " (",
      <p data-testid={"CreditCardActive"} className="creditCardActive">
        Active
      </p>,
      ")",
    ];
    let expiredStatus = [
      " (",
      <p data-testid={"CreditCardExpired"} className="creditCardExpired">
        Expired
      </p>,
      ")",
    ];
    if (isDefault && isExpired) {
      return [activeStauts, expiredStatus];
    } else if (isDefault) {
      return [activeStauts];
    } else if (isExpired) {
      return [expiredStatus];
    }
    return null;
  };

  return (
    <TextField select label={label} disabled={disabled} value={selectedObject?.label} onChange={onHandleStateChange} helperText={error} error={error.length > 0} variant="outlined" size={"small"} fullWidth>
      {availableOptions.map(availableOption => {
        return (
          <MenuItem
            ref={(element: any) => {
              setDropdownRef(element);
            }}
            key={availableOption.idx}
            value={availableOption.label}
            size={"small"}
            disabled={availableOption.isDisabled}
          >
            {availableOption.label !== "Add New Card" ? [getCreditCardIcon(availableOption.type), availableOption.label, getCreditCardSuffixStatus(availableOption.default, availableOption.expired)] : availableOption.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default CreditCardDropDown;
