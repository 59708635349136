import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { handleError } from "./actionsErrorHandler";
import { ActionTypes } from "./ActionTypes";

export enum ApiCredentialActionTypes {
  GET_APPLICATIONS = "GET_APPLICATIONS",
  ADD_APPLICATION = "ADD_APPLICATION",
  DELETE_APPLICATION = "DELETE_APPLICATION",
  RENEW_APPLICATION = "RENEW_APPLICATION",
}

export interface IApiCredentialApplication {
  name: string;
  email: string;
  application: string;
  clientId?: string;
  clientSecret?: string;
  justAdded?: boolean;
  showSecret?: boolean;
}

export interface IGetPartnerApplicationAction {
  type: ApiCredentialActionTypes.GET_APPLICATIONS;
  applicationsToDisplay: IApiCredentialApplication[];
  loadingApplications: boolean;
}

export interface IAddPartnerApplicationAction {
  type: ApiCredentialActionTypes.ADD_APPLICATION;
  applicationsToDisplay: IApiCredentialApplication[];
}

export interface IDeletePartnerApplicationAction {
  type: ApiCredentialActionTypes.DELETE_APPLICATION;
  applicationsToDisplay: IApiCredentialApplication[];
  pendingAction: boolean;
}

export interface IRenewPartnerApplicationAction {
  type: ApiCredentialActionTypes.RENEW_APPLICATION;
  applicationsToDisplay: IApiCredentialApplication[];
}

export type ApiCredentialActions = IGetPartnerApplicationAction | IAddPartnerApplicationAction | IDeletePartnerApplicationAction | IRenewPartnerApplicationAction;

export const getApiCredentialApplications: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetPartnerApplicationAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const msp = getState().generalState.mspAccountLoggedIn;
    dispatch({
      applicationsToDisplay: [],
      type: ApiCredentialActionTypes.GET_APPLICATIONS,
      loadingApplications: true,
    });
    try {
      const applications = await mspService.getApiCredentialApplications(apiUrl, msp.id.toString());
      dispatch({
        applicationsToDisplay: applications.data,
        type: ApiCredentialActionTypes.GET_APPLICATIONS,
        loadingApplications: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ApiCredentialActionTypes.GET_APPLICATIONS,
            applicationsToDisplay: [],
            loadingApplications: false,
          });
        },
        () => {},
        true,
      );
      return false;
    }
  };
};

export const addApplicationAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddPartnerApplicationAction>> = (newApplication: IApiCredentialApplication) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const msp = getState().generalState.mspAccountLoggedIn;
    const { applicationsToDisplay } = getState().apiCredentialApplicationState;
    try {
      const result = await mspService.addApiCredentialApplication(apiUrl, msp.id.toString(), newApplication);
      const addedApplication: IApiCredentialApplication = {
        application: newApplication.application,
        email: newApplication.email,
        name: newApplication.name,
        clientId: result.clientId,
        clientSecret: result.secret,
        justAdded: true,
      };

      dispatch({
        type: ApiCredentialActionTypes.ADD_APPLICATION,
        applicationsToDisplay: [addedApplication, ...applicationsToDisplay],
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ApiCredentialActionTypes.ADD_APPLICATION,
            applicationsToDisplay: getState().apiCredentialApplicationState.applicationsToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.AddApiCredentials,
      );
      return false;
    }
  };
};

export const deleteApplicationAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeletePartnerApplicationAction>> = (applicationId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const msp = getState().generalState.mspAccountLoggedIn;
    const { applicationsToDisplay } = getState().apiCredentialApplicationState;
    try {
      dispatch({
        type: ApiCredentialActionTypes.DELETE_APPLICATION,
        applicationsToDisplay: applicationsToDisplay,
        pendingAction: true,
      });

      await mspService.deleteApiCredentialApplication(apiUrl, msp.id.toString(), applicationId);
      const applicationIndex = applicationsToDisplay.findIndex(value => value.clientId === applicationId);
      if (applicationIndex > -1) {
        let applicationsToDisplayUpdated = [...applicationsToDisplay];
        applicationsToDisplayUpdated.splice(applicationIndex, 1);
        dispatch({
          type: ApiCredentialActionTypes.DELETE_APPLICATION,
          applicationsToDisplay: applicationsToDisplayUpdated,
          pendingAction: false,
        });
      } else {
        dispatch({
          type: ApiCredentialActionTypes.DELETE_APPLICATION,
          applicationsToDisplay: applicationsToDisplay,
          pendingAction: false,
        });
      }
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ApiCredentialActionTypes.DELETE_APPLICATION,
            applicationsToDisplay: applicationsToDisplay,
            pendingAction: false,
          });
        },
        () => {},
        true,
        ActionTypes.DeleteApiCredentials,
      );
      return false;
    }
  };
};

export const renewApplicationAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IRenewPartnerApplicationAction>> = (applicationId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const { applicationsToDisplay } = getState().apiCredentialApplicationState;
    const msp = getState().generalState.mspAccountLoggedIn;
    try {
      const applicationIndex = applicationsToDisplay.findIndex(value => value.clientId === applicationId);
      if (applicationIndex > -1) {
        let applicationsToDisplayUpdated = [...applicationsToDisplay];
        const renewApp = applicationsToDisplayUpdated[applicationIndex];

        const result = await mspService.renewApiCredentialApplication(apiUrl, msp.id.toString(), applicationId);
        renewApp.clientSecret = result.secret;
        renewApp.showSecret = true;

        dispatch({
          type: ApiCredentialActionTypes.RENEW_APPLICATION,
          applicationsToDisplay: applicationsToDisplayUpdated,
        });
        return true;
      }
      dispatch({
        type: ApiCredentialActionTypes.RENEW_APPLICATION,
        applicationsToDisplay: applicationsToDisplay,
      });
      return false;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ApiCredentialActionTypes.RENEW_APPLICATION,
            applicationsToDisplay: applicationsToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.RenewApiCredentials,
      );
      return false;
    }
  };
};
